import { Event } from './Event';
import { Asset } from './Asset';
import { Participant } from './Participant';

export interface Certificate {
  id: number;
  type: CertificateType;
  event: Event;
  codes: string[];
  participant: Participant | null;
  template: CertificateTemplate;
  asset: Asset | null;
  isSigned: boolean;
  eventHash?: string;
  signBatches: SignBatch[];
  isExternal: boolean;
  status: CertificateStatus;
}

export interface SignBatch {
  id: number;
  token: string;
  signerToken: string;
  batchId: string;
  certificateId: number;
  updatedAt: string;
}

export enum CertificateType {
  // eslint-disable-next-line no-unused-vars
  PARTICIPANT_CERTIFICATE = 'PARTICIPANT_CERTIFICATE',
}

export enum CertificateTemplate {
  // eslint-disable-next-line no-unused-vars
  BALTICUS = 'BALTICUS',
  // eslint-disable-next-line no-unused-vars
  CUSTOM_1 = 'CUSTOM_1',
  // eslint-disable-next-line no-unused-vars
  CUSTOM_2 = 'CUSTOM_2',
  // eslint-disable-next-line no-unused-vars
  CUSTOM_3 = 'CUSTOM_3',
  // eslint-disable-next-line no-unused-vars
  CUSTOM_4 = 'CUSTOM_4',
  // eslint-disable-next-line no-unused-vars
  CUSTOM_5 = 'CUSTOM_5',
  // eslint-disable-next-line no-unused-vars
  CUSTOM_UPLOAD = 'CUSTOM_UPLOAD',
}

export type CertificatesList = {
  result: Certificate[];
  total: number;
};

export enum CertificateType {
  // eslint-disable-next-line no-unused-vars
  QUALIFIED = 'QUALIFIED',
  // eslint-disable-next-line no-unused-vars
  UNQUALIFIED = 'UNQUALIFIED',
  // eslint-disable-next-line no-unused-vars
  UNCONFIRMED = 'UNCONFIRMED',
  // eslint-disable-next-line no-unused-vars
  DRAFT = 'DRAFT',
  // eslint-disable-next-line no-unused-vars
  GENERAL = 'GENERAL',
}

export enum CertificateStatus {
  // eslint-disable-next-line no-unused-vars
  DRAFT = 'DRAFT',
  // eslint-disable-next-line no-unused-vars
  CONFIRMED = 'CONFIRMED',
  // eslint-disable-next-line no-unused-vars
  UNCONFIRMED = 'UNCONFIRMED',
}

export enum CertificateExportToPdfType {
  // eslint-disable-next-line no-unused-vars
  TEXT = 'TEXT',
  // eslint-disable-next-line no-unused-vars
  FILES = 'FILES',
  // eslint-disable-next-line no-unused-vars
  COMBINED = 'COMBINED',
}

export enum CertificateActionType {
  // eslint-disable-next-line no-unused-vars
  UPLOAD = 'UPLOAD',
  // eslint-disable-next-line no-unused-vars
  UPDATE = 'UPDATE',
}
