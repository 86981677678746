import _, { trim } from 'lodash';
import { IntlShape } from 'react-intl';
import prettyBytes from 'pretty-bytes';
import moment from 'moment';

export type ValidationRule = {
  type: ValidationType;
  value?: string | string[] | number | File | File[];
  parameter?: any;
};

export type ErrorMessageObject = {
  label: string;
  parameter: string;
};

export enum ValidationType {
  // eslint-disable-next-line no-unused-vars
  REQUIRED = 'REQUIRED',
  // eslint-disable-next-line no-unused-vars
  EMAIL = 'EMAIL',
  // eslint-disable-next-line no-unused-vars
  MIN_LENGTH = 'MIN_LENGTH',
  // eslint-disable-next-line no-unused-vars
  MAX_LENGTH = 'MAX_LENGTH',
  // eslint-disable-next-line no-unused-vars
  NUMBERS_AND_STRINGS = 'NUMBERS_AND_STRINGS',
  // eslint-disable-next-line no-unused-vars
  MIN = 'MIN',
  // eslint-disable-next-line no-unused-vars
  MAX = 'MAX',
  // eslint-disable-next-line no-unused-vars
  TIME = 'TIME',
  // eslint-disable-next-line no-unused-vars
  MAX_FILES = 'MAX_FILES',
  // eslint-disable-next-line no-unused-vars
  FILE_SIZE = 'FILE_SIZE',
  // eslint-disable-next-line no-unused-vars
  TOTAL_FILES_SIZE = 'TOTAL_FILES_SIZE',
  // eslint-disable-next-line no-unused-vars
  ONLY_INTEGER = 'ONLY_INTEGER',
  // eslint-disable-next-line no-unused-vars
  SINGLE_FILE_SIZE = 'SINGLE_FILE_SIZE',
  // eslint-disable-next-line no-unused-vars
  IS_VALID_DATE = 'IS_VALID_DATE',
  // eslint-disable-next-line no-unused-vars
  CONTAINS_LEADING_ZERO = 'CONTAINS_LEADING_ZERO',
  // eslint-disable-next-line no-unused-vars
  IS_NUMBER = 'IS_NUMBER',
  // eslint-disable-next-line no-unused-vars
  IS_NUMBER_STRING = 'IS_NUMBER_STRING',
}

export const getValidationError = (
  value: string | string[] | number | File | File[],
  rules: Array<ValidationRule> | undefined,
  intl: IntlShape,
): Array<ErrorMessageObject> =>
  _.compact(
    _.map(rules, (rule) => {
      const validator = validatorFactory(rule);
      const validationValue =
        validator &&
        validator(
          Array.isArray(value) ||
            value instanceof File ||
            typeof value === 'object'
            ? value
            : value.toString(),
          intl,
          rule.parameter,
        );

      if (validationValue) {
        return validationValue;
      }
    }),
  );

const validatorFactory = (rule: ValidationRule) => {
  switch (rule.type) {
    case ValidationType.REQUIRED:
      return requiredValidator;
    case ValidationType.MIN_LENGTH:
      return minLengthValidator;
    case ValidationType.MAX_LENGTH:
      return maxLengthValidator;
    case ValidationType.EMAIL:
      return emailValidator;
    case ValidationType.NUMBERS_AND_STRINGS:
      return numbersAndStringsValidator;
    case ValidationType.MIN:
      return minValidator;
    case ValidationType.MAX:
      return maxValidator;
    case ValidationType.TIME:
      return timeValidator;
    case ValidationType.MAX_FILES:
      return maxFiles;
    case ValidationType.FILE_SIZE:
      return fileSizeValidator;
    case ValidationType.TOTAL_FILES_SIZE:
      return totalFilesSizeValidator;
    case ValidationType.ONLY_INTEGER:
      return onlyIntegerValidator;
    case ValidationType.SINGLE_FILE_SIZE:
      return singleFileSizeValidator;
    case ValidationType.IS_VALID_DATE:
      return isValidDate;
    case ValidationType.CONTAINS_LEADING_ZERO:
      return containsLeadingZero;
    case ValidationType.IS_NUMBER:
      return isNumber;
    case ValidationType.IS_NUMBER_STRING:
      return isNumberString;
  }
};

const onlyIntegerValidator = (
  value: string,
  intl: IntlShape,
): ErrorMessageObject | undefined =>
  value === '' || /^[0-9]*$/.test(trim(value.toString()))
    ? undefined
    : { label: 'VALIDATION.INTEGER', parameter: '' };

const requiredValidator = (
  value: string | string[],
  intl: IntlShape,
): ErrorMessageObject | undefined =>
  value.length === 0
    ? { label: 'VALIDATION.REQUIRED', parameter: '' }
    : undefined;

const minLengthValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): ErrorMessageObject | undefined => {
  if (Array.isArray(value)) {
    if (!length) {
      return;
    }

    const error = [];
    for (const stringValue of value) {
      error.push(
        stringValue.length < length
          ? { label: 'VALIDATION.MIN_LENGTH', parameter: length.toString() }
          : undefined,
      );
    }
    return error.every((value) => value === undefined)
      ? undefined
      : { label: 'VALIDATION.MIN_LENGTH', parameter: length.toString() };
  }

  return value && length && value.length < length
    ? { label: 'VALIDATION.MIN_LENGTH', parameter: length.toString() }
    : undefined;
};

const maxLengthValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): ErrorMessageObject | undefined => {
  if (Array.isArray(value)) {
    if (!length) {
      return;
    }

    const error = [];
    for (const stringValue of value) {
      error.push(
        stringValue.length > length
          ? { label: 'VALIDATION.MAX_LENGTH', parameter: length.toString() }
          : undefined,
      );
    }
    return error.every((value) => value === undefined)
      ? undefined
      : { label: 'VALIDATION.MAX_LENGTH', parameter: length.toString() };
  }

  return length && value.length > length
    ? { label: 'VALIDATION.MAX_LENGTH', parameter: length.toString() }
    : undefined;
};

const numbersAndStringsValidator = (
  value: string | string[],
  intl: IntlShape,
): ErrorMessageObject | undefined =>
  /^[a-zA-Z0-9]*$/.test(trim(value.toString()))
    ? undefined
    : { label: 'VALIDATION.NUMBERS_AND_STRINGS', parameter: '' };

const isNumber = (value: string | string[]): ErrorMessageObject | undefined =>
  /^[0-9]{1,9}([,.][0-9]{1})?$/.test(trim(value.toString()))
    ? undefined
    : { label: 'VALIDATION.NUMBERS', parameter: '' };

const emailValidator = (
  value: string | string[],
  intl: IntlShape,
): ErrorMessageObject | undefined => {
  if (Array.isArray(value)) {
    const error = [];
    for (const stringValue of value) {
      error.push(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trim(stringValue.toString())),
      );
    }

    return error.every((value) => value === true)
      ? undefined
      : { label: 'VALIDATION.EMAIL', parameter: '' };
  }

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trim(value.toString()))
    ? undefined
    : { label: 'VALIDATION.EMAIL', parameter: '' };
};

const minValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): ErrorMessageObject | undefined =>
  length !== undefined && +value < length
    ? { label: 'VALIDATION.MIN', parameter: length.toString() }
    : undefined;

const maxValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): ErrorMessageObject | undefined => {
  if (Array.isArray(value)) {
    if (!length) {
      return;
    }

    return length && +value.length > length
      ? { label: 'VALIDATION.MAX', parameter: length.toString() }
      : undefined;
  }

  return length && +value > length
    ? { label: 'VALIDATION.MAX', parameter: length.toString() }
    : undefined;
};

const timeValidator = (
  value: string | string[],
  intl: IntlShape,
): ErrorMessageObject | undefined =>
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(trim(value.toString()))
    ? undefined
    : { label: 'VALIDATION.TIME', parameter: length.toString() };

const maxFiles = (
  value: any,
  intl: IntlShape,
  length: number,
): ErrorMessageObject | undefined =>
  length && value.length > length
    ? { label: 'ERROR.UNEXPECTED_FIELD', parameter: length.toString() }
    : undefined;

const fileSizeValidator = (
  value: string,
  intl: IntlShape,
  size?: number,
): any => {
  // @ts-ignore
  const fileArray = value.map((file: any) => {
    if (!(file instanceof File)) {
      return undefined;
    }
    return file && size && size > file.size ? undefined : 'error';
  });

  const checkFileArrayErrors = fileArray.filter(
    (item: any) => item !== undefined,
  );

  return checkFileArrayErrors.length === 0
    ? undefined
    : {
        label: 'ERROR.FILE_TOO_LARGE',
        parameter: prettyBytes(size ?? 0).toString(),
      };
};

const singleFileSizeValidator = (
  value: string,
  intl: IntlShape,
  size?: number,
): ErrorMessageObject | undefined => {
  const file = value as unknown as File;

  return file && size && size > file.size
    ? undefined
    : {
        label: 'ERROR.FILE_TOO_LARGE',
        parameter: prettyBytes(size ?? 0).toString(),
      };
};

const totalFilesSizeValidator = (
  value: any,
  intl: IntlShape,
  maxSize: number,
): any => {
  // @ts-ignore

  const totalFilesSize = value.reduce(
    (totalSize: number, currentFile: File) => totalSize + currentFile.size,
    0,
  );

  return totalFilesSize < maxSize
    ? undefined
    : {
        label: 'ERROR.TOTAL_FILES_TOO_LARGE',
        parameter: prettyBytes(maxSize ?? 0).toString(),
      };
};

const isValidDate = (
  value: string | string[],
  intl: IntlShape,
): ErrorMessageObject | undefined =>
  value === '' || moment(value).isValid()
    ? undefined
    : { label: 'VALIDATION.DATE_INVALID', parameter: '' };

const containsLeadingZero = (
  value: string | string[],
  intl: IntlShape,
): ErrorMessageObject | undefined =>
  /^0[0-9].*$/.test(trim(value.toString()))
    ? { label: 'VALIDATION.LEADING_ZERO', parameter: '' }
    : undefined;

const isNumberString = (
  value: string,
  intl: IntlShape,
): ErrorMessageObject | undefined =>
  !/^[0-9]+$/.test(value.toString())
    ? { label: 'VALIDATION.NOT_NUMBER_STRING', parameter: '' }
    : undefined;
