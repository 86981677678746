import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { GlobalSetting } from '../../domain/GlobalSetting';

export const fetchGlobalSettingStart = () => ({
  type: actionTypes.FETCH_GLOBAL_SETTING_START,
});

export const fetchGlobalSettingSuccess = (globalSetting: GlobalSetting) => ({
  type: actionTypes.FETCH_GLOBAL_SETTING_SUCCESS,
  globalSetting,
});

export const fetchGlobalSettingFail = (globalSettingError: HttpError) => ({
  type: actionTypes.FETCH_GLOBAL_SETTING_FAIL,
  globalSettingError,
});

export const updateGlobalSettingStart = () => ({
  type: actionTypes.UPDATE_GLOBAL_SETTING_START,
});

export const updateGlobalSettingSuccess = () => ({
  type: actionTypes.UPDATE_GLOBAL_SETTING_SUCCESS,
});

export const updateGlobalSettingFail = (globalSettingUpdateError: string) => ({
  type: actionTypes.UPDATE_GLOBAL_SETTING_FAIL,
  globalSettingUpdateError,
});
