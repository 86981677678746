import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { EventPurpose, EventPurposesList } from '../../domain/EventPurpose';
import { ListParams } from '../../hooks/useList/useList';

export const fetchEventPurposesStart = () => ({
  type: actionTypes.FETCH_EVENT_PURPOSES_START,
});

export const fetchEventPurposesSuccess = (
  eventPurposesList: EventPurposesList,
) => ({
  type: actionTypes.FETCH_EVENT_PURPOSES_SUCCESS,
  eventPurposesList,
});

export const fetchEventPurposesFail = (eventPurposesListError: HttpError) => ({
  type: actionTypes.FETCH_EVENT_PURPOSES_FAIL,
  eventPurposesListError,
});

export const fetchEventPurposeStart = () => ({
  type: actionTypes.FETCH_EVENT_PURPOSE_START,
});

export const fetchEventPurposeSuccess = (eventPurpose: EventPurpose) => ({
  type: actionTypes.FETCH_EVENT_PURPOSE_SUCCESS,
  eventPurpose,
});

export const fetchEventPurposeFail = (eventPurposeError: HttpError) => ({
  type: actionTypes.FETCH_EVENT_PURPOSE_FAIL,
  eventPurposeError,
});

export const createEventPurposeStart = () => ({
  type: actionTypes.CREATE_EVENT_PURPOSE_START,
});

export const createEventPurposeSuccess = (
  createdEventPurpose: EventPurpose,
) => ({
  type: actionTypes.CREATE_EVENT_PURPOSE_SUCCESS,
  createdEventPurpose,
});

export const createEventPurposeFail = (eventPurposeCreateError: string) => ({
  type: actionTypes.CREATE_EVENT_PURPOSE_FAIL,
  eventPurposeCreateError,
});

export const updateEventPurposeStart = () => ({
  type: actionTypes.UPDATE_EVENT_PURPOSE_START,
});

export const updateEventPurposeSuccess = () => ({
  type: actionTypes.UPDATE_EVENT_PURPOSE_SUCCESS,
});

export const updateEventPurposeFail = (eventPurposeUpdateError: string) => ({
  type: actionTypes.UPDATE_EVENT_PURPOSE_FAIL,
  eventPurposeUpdateError,
});

export const deleteEventPurposeStart = () => ({
  type: actionTypes.DELETE_EVENT_PURPOSE_START,
});

export const deleteEventPurposeSuccess = () => ({
  type: actionTypes.DELETE_EVENT_PURPOSE_SUCCESS,
});

export const deleteEventPurposeFail = (eventPurposeDeleteError: string) => ({
  type: actionTypes.DELETE_EVENT_PURPOSE_FAIL,
  eventPurposeDeleteError,
});

export const setEventPurposesFilterValues = (
  eventPurposesFilter: ListParams,
) => ({
  type: actionTypes.SET_EVENT_PURPOSES_FILTER,
  eventPurposesFilter,
});

export const resetEventPurposeStore = () => ({
  type: actionTypes.RESET_EVENT_PURPOSE_STORE,
});

export const setUpdateNeeded = () => ({
  type: actionTypes.SET_EVENT_PURPOSE_UPDATE_NEEDED,
});

export const resetCreatedEventPurpose = () => ({
  type: actionTypes.RESET_CREATED_EVENT_PURPOSE,
});
