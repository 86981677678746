export const FETCH_EVENT_TYPES_START = 'FETCH_EVENT_TYPES_START';
export const FETCH_EVENT_TYPES_SUCCESS = 'FETCH_EVENT_TYPES_SUCCESS';
export const FETCH_EVENT_TYPES_FAIL = 'FETCH_EVENT_TYPES_FAIL';

export const FETCH_EVENT_TYPE_START = 'FETCH_EVENT_TYPE_START';
export const FETCH_EVENT_TYPE_SUCCESS = 'FETCH_EVENT_TYPE_SUCCESS';
export const FETCH_EVENT_TYPE_FAIL = 'FETCH_EVENT_TYPE_FAIL';

export const CREATE_EVENT_TYPE_START = 'CREATE_EVENT_TYPE_START';
export const CREATE_EVENT_TYPE_SUCCESS = 'CREATE_EVENT_TYPE_SUCCESS';
export const CREATE_EVENT_TYPE_FAIL = 'CREATE_EVENT_TYPE_FAIL';

export const UPDATE_EVENT_TYPE_START = 'UPDATE_EVENT_TYPE_START';
export const UPDATE_EVENT_TYPE_SUCCESS = 'UPDATE_EVENT_TYPE_SUCCESS';
export const UPDATE_EVENT_TYPE_FAIL = 'UPDATE_EVENT_TYPE_FAIL';

export const DELETE_EVENT_TYPE_START = 'DELETE_EVENT_TYPE_START';
export const DELETE_EVENT_TYPE_SUCCESS = 'DELETE_EVENT_TYPE_SUCCESS';
export const DELETE_EVENT_TYPE_FAIL = 'DELETE_EVENT_TYPE_FAIL';

export const SET_EVENT_TYPES_FILTER = 'SET_EVENT_TYPES_FILTER';

export const RESET_EVENT_TYPE_STORE = 'RESET_EVENT_TYPE_STORE';

export const SET_EVENT_TYPE_UPDATE_NEEDED = 'SET_EVENT_TYPE_UPDATE_NEEDED';

export const RESET_CREATED_EVENT_TYPE = 'RESET_CREATED_EVENT_TYPE';

export type EventTypeActionTypes =
  | typeof FETCH_EVENT_TYPES_START
  | typeof FETCH_EVENT_TYPES_SUCCESS
  | typeof FETCH_EVENT_TYPES_FAIL
  | typeof FETCH_EVENT_TYPE_START
  | typeof FETCH_EVENT_TYPE_SUCCESS
  | typeof FETCH_EVENT_TYPE_FAIL
  | typeof CREATE_EVENT_TYPE_START
  | typeof CREATE_EVENT_TYPE_SUCCESS
  | typeof CREATE_EVENT_TYPE_FAIL
  | typeof UPDATE_EVENT_TYPE_START
  | typeof UPDATE_EVENT_TYPE_SUCCESS
  | typeof UPDATE_EVENT_TYPE_FAIL
  | typeof DELETE_EVENT_TYPE_START
  | typeof DELETE_EVENT_TYPE_SUCCESS
  | typeof DELETE_EVENT_TYPE_FAIL
  | typeof SET_EVENT_TYPES_FILTER
  | typeof RESET_EVENT_TYPE_STORE
  | typeof SET_EVENT_TYPE_UPDATE_NEEDED
  | typeof RESET_CREATED_EVENT_TYPE;
