import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import instance from '../Axios/axios-instance';
import * as authActions from '../../store/auth/actions';
import * as authService from '../../store/auth/service';
import eventReducer, { EventStateType } from '../../store/event/reducer';
import participantReducer, {
  ParticipantStateType,
} from '../../store/participant/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import certificateReducer, {
  CertificateStateType,
} from '../../store/certificate/reducer';
import organisationReducer, {
  OrganisationStateType,
} from '../../store/organisation/reducer';
import certificateTypeReducer, {
  CertificateTypeStateType,
} from '../../store/certificateType/reducer';
import participantActivityReducer, {
  ParticipantActivityStateType,
} from '../../store/participantActivity/reducer';
import eventTypeReducer, {
  EventTypeStateType,
} from '../../store/eventType/reducer';
import eventPurposeReducer, {
  EventPurposeStateType,
} from '../../store/eventPurpose/reducer';
import eventRepresentativeReducer, {
  EventRepresentativeStateType,
} from '../../store/eventRepresentative/reducer';
import * as globalService from '../../store/globalService';
import userReducer, { UserStateType } from '../../store/user/reducer';
import routes from '../Router/routes';
import orderReducer, { OrderStateType } from '../../store/order/reducer';
import balanceHistoryReducer, {
  BalanceHistoryStateType,
} from '../../store/balanceHistory/reducer';
import organiserReducer, {
  OrganiserStateType,
} from '../../store/organiser/reducer';
import reviewReducer, { ReviewStateType } from '../../store/review/reducer';
import companyReducer, { CompanyStateType } from '../../store/company/reducer';
import creditPackageReducer, {
  CreditPackageStateType,
} from '../../store/creditPackage/reducer';
import contactUsReducer, {
  ContactUsStateType,
} from '../../store/conactUs/reducer';
import globalSettingReducer, {
  GlobalSettingStateType,
} from '../../store/globalSetting/reducer';
import managerReducer, { ManagerStateType } from '../../store/manager/reducer';

export type StoreState = {
  auth: AuthStateType;
  event: EventStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  participant: ParticipantStateType;
  certificate: CertificateStateType;
  organisation: OrganisationStateType;
  certificateType: CertificateTypeStateType;
  participantActivity: ParticipantActivityStateType;
  eventType: EventTypeStateType;
  eventPurpose: EventPurposeStateType;
  eventRepresentative: EventRepresentativeStateType;
  user: UserStateType;
  order: OrderStateType;
  balanceHistory: BalanceHistoryStateType;
  organiser: OrganiserStateType;
  review: ReviewStateType;
  company: CompanyStateType;
  creditPackage: CreditPackageStateType;
  contactUs: ContactUsStateType;
  globalSetting: GlobalSettingStateType;
  manager: ManagerStateType;
};

type Props = {
  children?: ReactNode;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  event: eventReducer,
  translation: translationReducer,
  language: languageReducer,
  participant: participantReducer,
  certificate: certificateReducer,
  organisation: organisationReducer,
  certificateType: certificateTypeReducer,
  participantActivity: participantActivityReducer,
  eventType: eventTypeReducer,
  eventPurpose: eventPurposeReducer,
  eventRepresentative: eventRepresentativeReducer,
  user: userReducer,
  order: orderReducer,
  balanceHistory: balanceHistoryReducer,
  organiser: organiserReducer,
  review: reviewReducer,
  company: companyReducer,
  creditPackage: creditPackageReducer,
  contactUs: contactUsReducer,
  globalSetting: globalSettingReducer,
  manager: managerReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store: Store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== routes.auth.refresh
    ) {
      const { url, method, params, data } = error.response.config;

      dispatch(
        // @ts-ignore
        authService.refreshToken(
          globalService.resolveService(url, method, params, data),
        ),
      );

      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      error.response.config.url === routes.auth.refresh
    ) {
      dispatch(authActions.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
