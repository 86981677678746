export const FETCH_CERTIFICATE_TYPES_START = 'FETCH_CERTIFICATE_TYPES_START';
export const FETCH_CERTIFICATE_TYPES_SUCCESS =
  'FETCH_CERTIFICATE_TYPES_SUCCESS';
export const FETCH_CERTIFICATE_TYPES_FAIL = 'FETCH_CERTIFICATE_TYPES_FAIL';

export const FETCH_CERTIFICATE_TYPE_START = 'FETCH_CERTIFICATE_TYPE_START';
export const FETCH_CERTIFICATE_TYPE_SUCCESS = 'FETCH_CERTIFICATE_TYPE_SUCCESS';
export const FETCH_CERTIFICATE_TYPE_FAIL = 'FETCH_CERTIFICATE_TYPE_FAIL';

export const CREATE_CERTIFICATE_TYPE_START = 'CREATE_CERTIFICATE_TYPE_START';
export const CREATE_CERTIFICATE_TYPE_SUCCESS =
  'CREATE_CERTIFICATE_TYPE_SUCCESS';
export const CREATE_CERTIFICATE_TYPE_FAIL = 'CREATE_CERTIFICATE_TYPE_FAIL';

export const UPDATE_CERTIFICATE_TYPE_START = 'UPDATE_CERTIFICATE_TYPE_START';
export const UPDATE_CERTIFICATE_TYPE_SUCCESS =
  'UPDATE_CERTIFICATE_TYPE_SUCCESS';
export const UPDATE_CERTIFICATE_TYPE_FAIL = 'UPDATE_CERTIFICATE_TYPE_FAIL';

export const DELETE_CERTIFICATE_TYPE_START = 'DELETE_CERTIFICATE_TYPE_START';
export const DELETE_CERTIFICATE_TYPE_SUCCESS =
  'DELETE_CERTIFICATE_TYPE_SUCCESS';
export const DELETE_CERTIFICATE_TYPE_FAIL = 'DELETE_CERTIFICATE_TYPE_FAIL';

export const SET_CERTIFICATE_TYPES_FILTER = 'SET_CERTIFICATE_TYPES_FILTER';

export const RESET_CERTIFICATE_TYPE_STORE = 'RESET_CERTIFICATE_TYPE_STORE';

export const SET_CERTIFICATE_TYPE_UPDATE_NEEDED =
  'SET_CERTIFICATE_TYPE_UPDATE_NEEDED';

export const RESET_CREATED_CERTIFICATE_TYPE = 'RESET_CREATED_CERTIFICATE_TYPE';

export type CertificateTypeActionTypes =
  | typeof FETCH_CERTIFICATE_TYPES_START
  | typeof FETCH_CERTIFICATE_TYPES_SUCCESS
  | typeof FETCH_CERTIFICATE_TYPES_FAIL
  | typeof FETCH_CERTIFICATE_TYPE_START
  | typeof FETCH_CERTIFICATE_TYPE_SUCCESS
  | typeof FETCH_CERTIFICATE_TYPE_FAIL
  | typeof CREATE_CERTIFICATE_TYPE_START
  | typeof CREATE_CERTIFICATE_TYPE_SUCCESS
  | typeof CREATE_CERTIFICATE_TYPE_FAIL
  | typeof UPDATE_CERTIFICATE_TYPE_START
  | typeof UPDATE_CERTIFICATE_TYPE_SUCCESS
  | typeof UPDATE_CERTIFICATE_TYPE_FAIL
  | typeof DELETE_CERTIFICATE_TYPE_START
  | typeof DELETE_CERTIFICATE_TYPE_SUCCESS
  | typeof DELETE_CERTIFICATE_TYPE_FAIL
  | typeof SET_CERTIFICATE_TYPES_FILTER
  | typeof RESET_CERTIFICATE_TYPE_STORE
  | typeof SET_CERTIFICATE_TYPE_UPDATE_NEEDED
  | typeof RESET_CREATED_CERTIFICATE_TYPE;
