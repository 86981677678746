import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './actionTypes';
import { User } from '../../domain/User';
import { HttpError } from '../../config/Axios/axios-instance';
import moment from 'moment';
import { Roles } from '../../domain/Role';

export type AuthStateType = {
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  loginGoogleLoading: boolean;
  loginGoogleError: string | null;
  loginFacebookLoading: boolean;
  loginFacebookError: string | null;
  connectUserLoading: boolean;
  connectUserError: string | null;
  connectUserSuccess: boolean;
  connectedUsers: User[];
  connectedUsersLoading: boolean;
  connectedUsersError: HttpError;
  confirmConnectUserLoading: boolean;
  confirmConnectUserSuccess: boolean;
  hasViewedProfile: boolean;
  loginLoading: boolean;
  loginError: HttpError;
  registerLoading: boolean;
  registerError: HttpError;
  registerSuccess: boolean;
  remindLoading: boolean;
  remindError: HttpError;
  isRemindSuccess: boolean;
  resetPasswordLoading: boolean;
  resetPasswordError: HttpError;
  validateCodeLoading: boolean;
  validateCodeError: HttpError;
  validatedUser: User | null;
  registerConfirmLoading: boolean;
  registerConfirmError: HttpError;
  role: Roles | null;
  roleSelectLoading: boolean;
  roleSelectSuccess: boolean;
  roleSelectError: HttpError;
  connectedUserDeleteLoading: boolean;
  connectedUserDeleteError: HttpError;
  connectedUserDeleteSuccess: boolean;
  selectedLanguage: string;
  jwtToken: string | null;
  lastStoreActionAt: moment.Moment | null;
  refreshTokenLoading: boolean;
  refreshTokenError: HttpError;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes;
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  loginFacebookLoading: false,
  loginFacebookError: null,
  connectUserLoading: false,
  connectUserError: null,
  connectUserSuccess: false,
  connectedUsers: [],
  connectedUsersLoading: false,
  connectedUsersError: null,
  confirmConnectUserLoading: false,
  confirmConnectUserSuccess: false,
  hasViewedProfile: !!localStorage.getItem('hasViewedProfile'),
  loginLoading: false,
  loginError: null,
  registerLoading: false,
  registerError: null,
  registerSuccess: false,
  remindLoading: false,
  remindError: null,
  isRemindSuccess: false,
  resetPasswordLoading: false,
  resetPasswordError: null,
  validateCodeLoading: false,
  validateCodeError: null,
  validatedUser: null,
  registerConfirmLoading: false,
  registerConfirmError: null,
  role: null,
  roleSelectLoading: false,
  roleSelectSuccess: false,
  roleSelectError: null,
  connectedUserDeleteLoading: false,
  connectedUserDeleteError: null,
  connectedUserDeleteSuccess: false,
  selectedLanguage: localStorage.getItem('selectedLanguage') ?? 'lt',
  jwtToken: localStorage.getItem('token'),
  refreshTokenLoading: false,
  refreshTokenError: null,
  lastStoreActionAt: null,
};

const loginGoogleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: true,
});

const loginGoogleSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginGoogleError: null,
  loginGoogleLoading: false,
  isInitCompleted: true,
  jwtToken: action.jwtToken,
});

const loginGoogleFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginGoogleError: action.loginGoogleError,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginFacebookStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginFacebookLoading: true,
});

const loginFacebookSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginFacebookError: null,
  loginFacebookLoading: false,
  isInitCompleted: true,
  jwtToken: action.jwtToken,
});

const loginFacebookFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginFacebookError: action.loginFacebookError,
  loginFacebookLoading: false,
  isInitCompleted: true,
});

const connectUserStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  connectUserLoading: true,
  connectUserSuccess: false,
});

const connectUserSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  connectUserError: null,
  connectUserLoading: false,
  connectUserSuccess: true,
});

const connectUserFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  connectUserError: action.connectUserError,
  connectUserLoading: false,
});

const fetchConnectedUsersStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  connectedUsersLoading: true,
  connectedUserDeleteSuccess: false,
});

const fetchConnectedUsersSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  connectedUsers: action.connectedUsers,
  connectedUsersLoading: false,
  connectedUsersError: null,
});

const fetchConnectedUsersFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  connectedUsersError: action.connectedUsersError,
  connectedUsersLoading: false,
});

const confirmConnectUserStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  confirmConnectUserLoading: true,
  confirmConnectUserSuccess: false,
});

const confirmConnectUserSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  confirmConnectUserLoading: false,
  confirmConnectUserSuccess: true,
});

const confirmConnectUserFail = (state: AuthStateType): AuthStateType => ({
  ...state,
  confirmConnectUserLoading: false,
});

const resetConnect = (state: AuthStateType): AuthStateType => ({
  ...state,
  connectUserSuccess: false,
  connectUserError: null,
});

const setViewedProfile = (state: AuthStateType): AuthStateType => ({
  ...state,
  hasViewedProfile: true,
});

const loginStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginLoading: true,
});

const loginSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginError: null,
  loginLoading: false,
  isInitCompleted: true,
  jwtToken: action.jwtToken,
});

const loginFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginError: action.loginError,
  loginLoading: false,
  isInitCompleted: true,
});

const registerStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerLoading: true,
  registerSuccess: false,
});

const registerSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerError: null,
  registerLoading: false,
  registerSuccess: true,
});

const registerFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  registerError: action.registerError,
  registerLoading: false,
});

const remindStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindLoading: true,
  validatedUser: null,
  validateCodeError: null,
});

const remindSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  remindError: null,
  remindLoading: false,
  isRemindSuccess: true,
});

const remindFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  remindError: action.remindError,
  remindLoading: false,
});

const resetPasswordStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  resetPasswordLoading: true,
});

const resetPasswordSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  resetPasswordError: null,
  resetPasswordLoading: false,
  jwtToken: action.jwtToken,
});

const resetPasswordFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  resetPasswordError: action.resetPasswordError,
  resetPasswordLoading: false,
  isInitCompleted: true,
});

const validateCodeStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  validateCodeLoading: true,
});

const validateCodeSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: null,
  validateCodeLoading: false,
  validatedUser: action.validatedUser,
});

const validateCodeFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  validateCodeError: action.validateCodeError,
  validateCodeLoading: false,
});

const registerConfirmStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  registerConfirmLoading: true,
});

const registerConfirmSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  isInitCompleted: true,
  registerConfirmError: null,
  registerConfirmLoading: false,
  jwtToken: action.jwtToken,
});

const registerConfirmFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  registerConfirmError: action.registerConfirmError,
  registerConfirmLoading: false,
  isInitCompleted: true,
});

const setRole = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  role: action.role,
});

const selectRoleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  roleSelectLoading: true,
});

const selectRoleSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  roleSelectLoading: false,
  roleSelectSuccess: true,
  roleSelectError: null,
  role: action.role,
});

const selectRoleFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  roleSelectError: action.roleSelectError,
  roleSelectLoading: false,
});

const deleteConnectedUserStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  connectedUserDeleteLoading: true,
});

const deleteConnectedUserSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  connectedUserDeleteLoading: false,
  connectedUserDeleteError: null,
  connectedUserDeleteSuccess: true,
});

const deleteConnectedUserFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  connectedUserDeleteLoading: false,
  connectedUserDeleteError: action.connectedUserDeleteError,
});

const selectLanguage = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => {
  localStorage.setItem('selectedLanguage', action.selectedLanguage);

  return {
    ...state,
    selectedLanguage: action.selectedLanguage,
  };
};

const refreshTokenStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  refreshTokenLoading: true,
});

const refreshTokenSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refreshTokenLoading: false,
  refreshTokenError: null,
  isInitCompleted: true,
  isAuthenticated: true,
  jwtToken: action.jwtToken,
});

const refreshTokenFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refreshTokenError: action.refreshTokenError,
  refreshTokenLoading: false,
});

const updateLastAction = (state: AuthStateType): AuthStateType => ({
  ...state,
  lastStoreActionAt: moment(),
});

const logout = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: false,
  loginGoogleLoading: false,
  isInitCompleted: true,
  loginFacebookLoading: false,
  connectUserError: null,
  connectUserLoading: false,
  connectUserSuccess: false,
  connectedUsersLoading: false,
  connectedUsersError: null,
  connectedUsers: [],
  confirmConnectUserLoading: false,
  confirmConnectUserSuccess: false,
  hasViewedProfile: !!localStorage.getItem('hasViewedProfile'),
  loginLoading: false,
  loginError: null,
  registerLoading: false,
  registerError: null,
  registerSuccess: false,
  remindLoading: false,
  remindError: null,
  isRemindSuccess: false,
  resetPasswordLoading: false,
  resetPasswordError: null,
  validateCodeLoading: false,
  validateCodeError: null,
  validatedUser: null,
  registerConfirmError: null,
  registerConfirmLoading: false,
  role: null,
  roleSelectLoading: false,
  roleSelectSuccess: false,
  roleSelectError: null,
  connectedUserDeleteLoading: false,
  connectedUserDeleteError: null,
  connectedUserDeleteSuccess: false,
  selectedLanguage: localStorage.getItem('selectedLanguage') ?? 'lt',
  jwtToken: localStorage.getItem('token'),
  refreshTokenLoading: false,
  refreshTokenError: null,
  lastStoreActionAt: null,
});

const resetLoginError = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleError: null,
  loginFacebookError: null,
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_GOOGLE_START:
      return loginGoogleStart(state);
    case actionTypes.LOGIN_GOOGLE_SUCCESS:
      return loginGoogleSuccess(state, action);
    case actionTypes.LOGIN_GOOGLE_FAIL:
      return loginGoogleFail(state, action);
    case actionTypes.LOGIN_FACEBOOK_START:
      return loginFacebookStart(state);
    case actionTypes.LOGIN_FACEBOOK_SUCCESS:
      return loginFacebookSuccess(state, action);
    case actionTypes.LOGIN_FACEBOOK_FAIL:
      return loginFacebookFail(state, action);
    case actionTypes.CONNECT_USER_START:
      return connectUserStart(state);
    case actionTypes.CONNECT_USER_SUCCESS:
      return connectUserSuccess(state);
    case actionTypes.CONNECT_USER_FAIL:
      return connectUserFail(state, action);
    case actionTypes.FETCH_CONNECTED_USERS_START:
      return fetchConnectedUsersStart(state);
    case actionTypes.FETCH_CONNECTED_USERS_SUCCESS:
      return fetchConnectedUsersSuccess(state, action);
    case actionTypes.FETCH_CONNECTED_USERS_FAIL:
      return fetchConnectedUsersFail(state, action);
    case actionTypes.CONFIRM_CONNECT_USER_START:
      return confirmConnectUserStart(state);
    case actionTypes.CONFIRM_CONNECT_USER_SUCCESS:
      return confirmConnectUserSuccess(state);
    case actionTypes.CONFIRM_CONNECT_USER_FAIL:
      return confirmConnectUserFail(state);
    case actionTypes.RESET_CONNECT:
      return resetConnect(state);
    case actionTypes.SET_VIEWED_PROFILE:
      return setViewedProfile(state);
    case actionTypes.LOGIN_START:
      return loginStart(state);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.REGISTER_START:
      return registerStart(state);
    case actionTypes.REGISTER_SUCCESS:
      return registerSuccess(state);
    case actionTypes.REGISTER_FAIL:
      return registerFail(state, action);
    case actionTypes.REMIND_START:
      return remindStart(state);
    case actionTypes.REMIND_SUCCESS:
      return remindSuccess(state);
    case actionTypes.REMIND_FAIL:
      return remindFail(state, action);
    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case actionTypes.VALIDATE_CODE_START:
      return validateCodeStart(state);
    case actionTypes.VALIDATE_CODE_SUCCESS:
      return validateCodeSuccess(state, action);
    case actionTypes.VALIDATE_CODE_FAIL:
      return validateCodeFail(state, action);
    case actionTypes.REGISTER_CONFIRM_START:
      return registerConfirmStart(state);
    case actionTypes.REGISTER_CONFIRM_SUCCESS:
      return registerConfirmSuccess(state, action);
    case actionTypes.REGISTER_CONFIRM_FAIL:
      return registerConfirmFail(state, action);
    case actionTypes.SET_ROLE:
      return setRole(state, action);
    case actionTypes.SELECT_ROLE_START:
      return selectRoleStart(state);
    case actionTypes.SELECT_ROLE_SUCCESS:
      return selectRoleSuccess(state, action);
    case actionTypes.SELECT_ROLE_FAIL:
      return selectRoleFail(state, action);
    case actionTypes.DELETE_CONNECTED_USER_START:
      return deleteConnectedUserStart(state);
    case actionTypes.DELETE_CONNECTED_USER_SUCCESS:
      return deleteConnectedUserSuccess(state);
    case actionTypes.DELETE_CONNECTED_USER_FAIL:
      return deleteConnectedUserFail(state, action);
    case actionTypes.REFRESH_TOKEN_START:
      return refreshTokenStart(state);
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return refreshTokenSuccess(state, action);
    case actionTypes.REFRESH_TOKEN_FAIL:
      return refreshTokenFail(state, action);
    case actionTypes.LOGOUT:
      return logout(state);
    case actionTypes.RESET_LOGIN_ERROR:
      return resetLoginError(state);
    case actionTypes.SELECT_LANGUAGE:
      return selectLanguage(state, action);
    default:
      return updateLastAction(state);
  }
};

export default reducer;
