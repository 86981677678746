import * as actionTypes from './actionTypes';
import { EventTypeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { EventType, EventTypesList } from '../../domain/EventType';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type EventTypeStateType = {
  eventTypesList: EventTypesList | null;
  eventTypesListLoading: boolean;
  eventTypesListError: HttpError;
  eventTypesFilter: ListParams;
  eventTypesListUpdateNeeded: boolean;
  eventType: EventType | null;
  eventTypeLoading: boolean;
  eventTypeError: HttpError;
  eventTypeCreateLoading: boolean;
  eventTypeCreateError: HttpError;
  eventTypeCreateSuccess: boolean;
  eventTypeUpdateLoading: boolean;
  eventTypeUpdateError: HttpError;
  eventTypeUpdateSuccess: boolean;
  eventTypeDeleteLoading: boolean;
  eventTypeDeleteError: HttpError;
  createdEventType: EventType | null;
};

export type EventTypeActionType = EventTypeStateType & {
  type: EventTypeActionTypes;
};

export const initialState: EventTypeStateType = {
  eventTypesList: null,
  eventTypesListLoading: true,
  eventTypesListError: null,
  eventTypesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  eventTypesListUpdateNeeded: false,
  eventType: null,
  eventTypeLoading: false,
  eventTypeError: null,
  eventTypeCreateLoading: false,
  eventTypeCreateError: null,
  eventTypeCreateSuccess: false,
  eventTypeUpdateLoading: false,
  eventTypeUpdateError: null,
  eventTypeUpdateSuccess: false,
  eventTypeDeleteLoading: false,
  eventTypeDeleteError: null,
  createdEventType: null,
};

const fetchEventTypesStart = (
  state: EventTypeStateType,
): EventTypeStateType => ({
  ...state,
  eventTypesListLoading: true,
  eventTypeUpdateSuccess: false,
  eventTypeCreateSuccess: false,
  eventTypeDeleteError: null,
  eventType: null,
});

const fetchEventTypesSuccess = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventTypesList: action.eventTypesList,
  eventTypesListLoading: false,
  eventTypesListError: null,
  eventTypesListUpdateNeeded: false,
});

const fetchEventTypesFail = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventTypesListError: action.eventTypesListError,
  eventTypesListLoading: false,
});

const fetchEventTypeStart = (
  state: EventTypeStateType,
): EventTypeStateType => ({
  ...state,
  eventTypeLoading: true,
  eventTypeUpdateSuccess: false,
  eventTypeCreateSuccess: false,
  eventTypeCreateError: null,
  eventTypeUpdateError: null,
});

const fetchEventTypeSuccess = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventType: action.eventType,
  eventTypeLoading: false,
  eventTypeError: null,
});

const fetchEventTypeFail = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventTypeError: action.eventTypeError,
  eventTypeLoading: false,
});

const createEventTypeStart = (
  state: EventTypeStateType,
): EventTypeStateType => ({
  ...state,
  eventTypeCreateLoading: true,
});

const createEventTypeSuccess = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventTypeCreateLoading: false,
  eventTypeCreateError: null,
  eventTypeCreateSuccess: true,
  eventTypesListUpdateNeeded: true,
  createdEventType: action.createdEventType,
});

const createEventTypeFail = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventTypeCreateLoading: false,
  eventTypeCreateError: action.eventTypeCreateError,
});

const updateEventTypeStart = (
  state: EventTypeStateType,
): EventTypeStateType => ({
  ...state,
  eventTypeUpdateLoading: true,
});

const updateEventTypeSuccess = (
  state: EventTypeStateType,
): EventTypeStateType => ({
  ...state,
  eventTypeUpdateLoading: false,
  eventTypeUpdateError: null,
  eventTypeUpdateSuccess: true,
  eventTypesListUpdateNeeded: true,
});

const updateEventTypeFail = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventTypeUpdateLoading: false,
  eventTypeUpdateError: action.eventTypeUpdateError,
});

const deleteEventTypeStart = (
  state: EventTypeStateType,
): EventTypeStateType => ({
  ...state,
  eventTypeDeleteLoading: true,
});

const deleteEventTypeSuccess = (
  state: EventTypeStateType,
): EventTypeStateType => ({
  ...state,
  eventTypeDeleteLoading: false,
  eventTypeDeleteError: null,
  eventTypesListUpdateNeeded: true,
});

const deleteEventTypeFail = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventTypeDeleteLoading: false,
  eventTypeDeleteError: action.eventTypeDeleteError,
});

const setEventTypesFilter = (
  state: EventTypeStateType,
  action: EventTypeActionType,
): EventTypeStateType => ({
  ...state,
  eventTypesFilter: action.eventTypesFilter,
});

const resetStore = (): EventTypeStateType => ({
  eventTypesList: null,
  eventTypesListLoading: true,
  eventTypesListError: null,
  eventTypesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  eventTypesListUpdateNeeded: false,
  eventType: null,
  eventTypeLoading: false,
  eventTypeError: null,
  eventTypeCreateLoading: false,
  eventTypeCreateError: null,
  eventTypeCreateSuccess: false,
  eventTypeUpdateLoading: false,
  eventTypeUpdateError: null,
  eventTypeUpdateSuccess: false,
  eventTypeDeleteLoading: false,
  eventTypeDeleteError: null,
  createdEventType: null,
});

const setUpdateNeeded = (state: EventTypeStateType): EventTypeStateType => ({
  ...state,
  eventTypesListUpdateNeeded: true,
});

const resetCreatedEventType = (
  state: EventTypeStateType,
): EventTypeStateType => ({
  ...state,
  createdEventType: null,
});

const reducer = (state = initialState, action: EventTypeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_TYPES_START:
      return fetchEventTypesStart(state);
    case actionTypes.FETCH_EVENT_TYPES_SUCCESS:
      return fetchEventTypesSuccess(state, action);
    case actionTypes.FETCH_EVENT_TYPES_FAIL:
      return fetchEventTypesFail(state, action);
    case actionTypes.FETCH_EVENT_TYPE_START:
      return fetchEventTypeStart(state);
    case actionTypes.FETCH_EVENT_TYPE_SUCCESS:
      return fetchEventTypeSuccess(state, action);
    case actionTypes.FETCH_EVENT_TYPE_FAIL:
      return fetchEventTypeFail(state, action);
    case actionTypes.CREATE_EVENT_TYPE_START:
      return createEventTypeStart(state);
    case actionTypes.CREATE_EVENT_TYPE_SUCCESS:
      return createEventTypeSuccess(state, action);
    case actionTypes.CREATE_EVENT_TYPE_FAIL:
      return createEventTypeFail(state, action);
    case actionTypes.UPDATE_EVENT_TYPE_START:
      return updateEventTypeStart(state);
    case actionTypes.UPDATE_EVENT_TYPE_SUCCESS:
      return updateEventTypeSuccess(state);
    case actionTypes.UPDATE_EVENT_TYPE_FAIL:
      return updateEventTypeFail(state, action);
    case actionTypes.DELETE_EVENT_TYPE_START:
      return deleteEventTypeStart(state);
    case actionTypes.DELETE_EVENT_TYPE_SUCCESS:
      return deleteEventTypeSuccess(state);
    case actionTypes.DELETE_EVENT_TYPE_FAIL:
      return deleteEventTypeFail(state, action);
    case actionTypes.SET_EVENT_TYPES_FILTER:
      return setEventTypesFilter(state, action);
    case actionTypes.RESET_EVENT_TYPE_STORE:
      return resetStore();
    case actionTypes.SET_EVENT_TYPE_UPDATE_NEEDED:
      return setUpdateNeeded(state);
    case actionTypes.RESET_CREATED_EVENT_TYPE:
      return resetCreatedEventType(state);
    default:
      return state;
  }
};

export default reducer;
