import axios from '../../config/Axios/axios-instance';
import {
  createEventTypeFail,
  createEventTypeStart,
  createEventTypeSuccess,
  deleteEventTypeFail,
  deleteEventTypeStart,
  deleteEventTypeSuccess,
  fetchEventTypeFail,
  fetchEventTypesFail,
  fetchEventTypesStart,
  fetchEventTypesSuccess,
  fetchEventTypeStart,
  fetchEventTypeSuccess,
  setEventTypesFilterValues,
  setUpdateNeeded,
  updateEventTypeFail,
  updateEventTypeStart,
  updateEventTypeSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

export type EventTypeCreateRequest = {
  name: string;
};

export type EventTypeUpdateRequest = {
  id: number;
  name: string;
};

export const fetchEventTypes =
  (params?: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchEventTypesStart());

    if (params && saveFilters) {
      dispatch(setEventTypesFilterValues(params));
    }

    return axios
      .get(API_URLS.eventType, { params })
      .then((response) => {
        dispatch(fetchEventTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchEventTypesFail(err.response?.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const fetchEventType = (eventTypeId: number) => (dispatch: Dispatch) => {
  dispatch(fetchEventTypeStart());
  return axios
    .get(`${API_URLS.eventType}/${eventTypeId}`)
    .then((response) => {
      dispatch(fetchEventTypeSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchEventTypeFail(err.response.data.message));
      if (
        err.response.data.statusCode !== 401 &&
        err.response.data.message !== 'Unauthorized'
      ) {
        showToast(err.response.data.message, 'error');
      }
    });
};

export const createEventType =
  (inputs: EventTypeCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(setUpdateNeeded());
    dispatch(createEventTypeStart());
    return axios
      .post(API_URLS.eventType, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createEventTypeSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.EVENT_TYPE_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createEventTypeFail(err.response.data.message));
      });
  };

export const updateEventType =
  (inputs: EventTypeUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateEventTypeStart());
    const { id, ...updatedEventType } = inputs;
    return axios
      .patch(`${API_URLS.eventType}/${id}`, {
        ...updatedEventType,
      })
      .then(() => {
        dispatch(updateEventTypeSuccess());
        dispatch(setUpdateNeeded());
        showToast(translate(intl, 'SUCCESS.EVENT_TYPE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateEventTypeFail(err.response.data.message));
      });
  };

export const deleteEventType =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteEventTypeStart());
    return axios
      .delete(`${API_URLS.eventType}/${id}`)
      .then(() => {
        dispatch(deleteEventTypeSuccess());
        dispatch(setUpdateNeeded());
        showToast(translate(intl, 'SUCCESS.EVENT_TYPE_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteEventTypeFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };
