export const FETCH_EVENT_REPRESENTATIVES_START =
  'FETCH_EVENT_REPRESENTATIVES_START';
export const FETCH_EVENT_REPRESENTATIVES_SUCCESS =
  'FETCH_EVENT_REPRESENTATIVES_SUCCESS';
export const FETCH_EVENT_REPRESENTATIVES_FAIL =
  'FETCH_EVENT_REPRESENTATIVES_FAIL';

export const FETCH_EVENT_REPRESENTATIVE_START =
  'FETCH_EVENT_REPRESENTATIVE_START';
export const FETCH_EVENT_REPRESENTATIVE_SUCCESS =
  'FETCH_EVENT_REPRESENTATIVE_SUCCESS';
export const FETCH_EVENT_REPRESENTATIVE_FAIL =
  'FETCH_EVENT_REPRESENTATIVE_FAIL';

export const CREATE_EVENT_REPRESENTATIVE_START =
  'CREATE_EVENT_REPRESENTATIVE_START';
export const CREATE_EVENT_REPRESENTATIVE_SUCCESS =
  'CREATE_EVENT_REPRESENTATIVE_SUCCESS';
export const CREATE_EVENT_REPRESENTATIVE_FAIL =
  'CREATE_EVENT_REPRESENTATIVE_FAIL';

export const UPDATE_EVENT_REPRESENTATIVE_START =
  'UPDATE_EVENT_REPRESENTATIVE_START';
export const UPDATE_EVENT_REPRESENTATIVE_SUCCESS =
  'UPDATE_EVENT_REPRESENTATIVE_SUCCESS';
export const UPDATE_EVENT_REPRESENTATIVE_FAIL =
  'UPDATE_EVENT_REPRESENTATIVE_FAIL';

export const DELETE_EVENT_REPRESENTATIVE_START =
  'DELETE_EVENT_REPRESENTATIVE_START';
export const DELETE_EVENT_REPRESENTATIVE_SUCCESS =
  'DELETE_EVENT_REPRESENTATIVE_SUCCESS';
export const DELETE_EVENT_REPRESENTATIVE_FAIL =
  'DELETE_EVENT_REPRESENTATIVE_FAIL';

export const SET_EVENT_REPRESENTATIVES_FILTER =
  'SET_EVENT_REPRESENTATIVES_FILTER';

export const RESET_EVENT_REPRESENTATIVE_STORE =
  'RESET_EVENT_REPRESENTATIVE_STORE';

export const SET_EVENT_REPRESENTATIVE_UPDATE_NEEDED =
  'SET_EVENT_REPRESENTATIVE_UPDATE_NEEDED';

export const RESET_CREATED_EVENT_REPRESENTATIVE =
  'RESET_CREATED_EVENT_REPRESENTATIVE';

export type EventRepresentativeActionTypes =
  | typeof FETCH_EVENT_REPRESENTATIVES_START
  | typeof FETCH_EVENT_REPRESENTATIVES_SUCCESS
  | typeof FETCH_EVENT_REPRESENTATIVES_FAIL
  | typeof FETCH_EVENT_REPRESENTATIVE_START
  | typeof FETCH_EVENT_REPRESENTATIVE_SUCCESS
  | typeof FETCH_EVENT_REPRESENTATIVE_FAIL
  | typeof CREATE_EVENT_REPRESENTATIVE_START
  | typeof CREATE_EVENT_REPRESENTATIVE_SUCCESS
  | typeof CREATE_EVENT_REPRESENTATIVE_FAIL
  | typeof UPDATE_EVENT_REPRESENTATIVE_START
  | typeof UPDATE_EVENT_REPRESENTATIVE_SUCCESS
  | typeof UPDATE_EVENT_REPRESENTATIVE_FAIL
  | typeof DELETE_EVENT_REPRESENTATIVE_START
  | typeof DELETE_EVENT_REPRESENTATIVE_SUCCESS
  | typeof DELETE_EVENT_REPRESENTATIVE_FAIL
  | typeof SET_EVENT_REPRESENTATIVES_FILTER
  | typeof RESET_EVENT_REPRESENTATIVE_STORE
  | typeof SET_EVENT_REPRESENTATIVE_UPDATE_NEEDED
  | typeof RESET_CREATED_EVENT_REPRESENTATIVE;
