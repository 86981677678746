import React, { ReactNode } from 'react';
import styles from './Sidebar.module.scss';
import { Link, NavLink } from 'react-router-dom';
import {
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarPlus,
  faFileAlt,
  faStamp,
  faUserCheck,
  faUserGraduate,
  faUser,
  faWallet,
  faLanguage,
  faUsers,
  faCube,
  faGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import procertyLogoOnBlue from '../../assets/procerty_logo_on_blue.png';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import ababaLogo from '../../assets/ababa_logo.svg';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import routes from '../../config/Router/routes';
import { getState } from '../../utility/navigationHelper/navigationHelper';
import { Roles } from '../../domain/Role';

type Props = {
  selectedRole: Roles | null;
  currentAccount?: ReactNode;
  selectedCompany: number | null;
};

const Sidebar = ({ selectedRole, currentAccount, selectedCompany }: Props) => {
  const intl = useIntl();

  const SIDEBAR_ITEMS = [
    {
      title: {
        name: translate(intl, 'SIDEBAR.GENERAL', 'General'),
        roles: [
          Roles.ORGANISER,
          Roles.ADMIN,
          Roles.USER,
          Roles.MANAGER,
          Roles.LECTURER,
        ],
        isCompanySelectNeeded: false,
      },
      items: [
        {
          label:
            selectedRole === Roles.ADMIN
              ? translate(intl, 'SIDEBAR.ORGANISER_EVENTS', 'Organiser events')
              : translate(intl, 'SIDEBAR.EVENTS', 'Events'),
          to:
            selectedRole === Roles.ADMIN
              ? routes.events.list
              : routes.organiserEvents,
          icon: faCalendarAlt,
          roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label: translate(intl, 'SIDEBAR.LECTURER_EVENTS', 'Lecturer Events'),
          to: routes.lecturerEvents.list,
          icon: faCalendarAlt,
          roles: [Roles.LECTURER],
          isCompanySelectNeeded: false,
        },
        {
          label:
            selectedRole === Roles.ADMIN
              ? translate(intl, 'SIDEBAR.ORGANISER_USERS', 'Organiser Users')
              : translate(intl, 'SIDEBAR.USERS', 'Users'),
          to: routes.users.list,
          icon: faUser,
          roles: [Roles.ORGANISER, Roles.ADMIN, Roles.MANAGER],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label:
            selectedRole === Roles.ADMIN
              ? translate(
                  intl,
                  'SIDEBAR.ORGANISER_COMPANIES',
                  'Organiser Companies',
                )
              : translate(intl, 'SIDEBAR.COMPANIES', 'Companies'),
          to: routes.companies.list,
          icon: faCube,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label: translate(intl, 'SIDEBAR.EVENTS', 'Events'),
          to: routes.events.list,
          icon: faCalendarAlt,
          roles: [Roles.USER],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label:
            selectedRole === Roles.USER ||
            selectedRole === Roles.LECTURER ||
            selectedRole === Roles.MANAGER ||
            selectedRole === Roles.ORGANISER
              ? translate(intl, 'SIDEBAR.CERTIFICATES', 'Certificates')
              : translate(
                  intl,
                  'SIDEBAR.ORGANISER_CERTIFICATES',
                  'Organiser Certificates',
                ),
          to:
            selectedRole === Roles.USER
              ? routes.certificates.participantCertificatesList
              : routes.certificates.list,
          icon: faFileAlt,
          roles: [Roles.USER, Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label: translate(intl, 'SIDEBAR.EVENTS', 'Events'),
          to: routes.events.allEventsList,
          icon: faCalendarAlt,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
        {
          label: translate(intl, 'SIDEBAR.USERS', 'Users'),
          to: routes.users.allUsersList,
          icon: faUser,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
        {
          label: translate(intl, 'SIDEBAR.COMPANIES', 'Companies'),
          to: routes.companies.allCompaniesList,
          icon: faCube,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
        {
          label: translate(intl, 'SIDEBAR.CERTIFICATES', 'Certificates'),
          to: routes.certificates.allCertificatesList,
          icon: faFileAlt,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
        {
          label: translate(intl, 'SIDEBAR.ORGANISERS_LIST', 'Organisers'),
          to: routes.organisers.list,
          icon: faUsers,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
        {
          label: translate(intl, 'SIDEBAR.ORDERS', 'Payment history'),
          to: routes.orders,
          icon: faWallet,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
        {
          label: translate(intl, 'SIDEBAR.CREDIT_PACKAGES', 'Credit Packages'),
          to: routes.creditPackages.list,
          icon: faCube,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
        {
          label: translate(
            intl,
            'SIDEBAR.TRANSLATIONS',
            'Translations manager',
          ),
          to: routes.translations,
          icon: faLanguage,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
        {
          label: translate(intl, 'SIDEBAR.GLOBAL_SETTINGS', 'Global settings'),
          to: routes.globalSettings,
          icon: faGear,
          roles: [Roles.ADMIN],
          isCompanySelectNeeded: false,
        },
      ],
    },
    {
      title: {
        name: translate(intl, 'SIDEBAR.CERTIFICATES_DATA', 'Certificates data'),
        roles: [Roles.ORGANISER, Roles.ADMIN, Roles.MANAGER],
        isCompanySelectNeeded: getState(selectedRole, selectedCompany),
      },
      items: [
        {
          label: translate(intl, 'SIDEBAR.ORGANISATIONS', 'Organisations'),
          to: routes.organisations.list,
          icon: faBuilding,
          roles: [Roles.ORGANISER, Roles.MANAGER, Roles.ADMIN],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label: translate(
            intl,
            'SIDEBAR.CERTIFICATE_TYPES',
            'Certificate types',
          ),
          to: routes.certificateTypes.list,
          icon: faStamp,
          roles: [Roles.ORGANISER, Roles.MANAGER, Roles.ADMIN],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label: translate(
            intl,
            'SIDEBAR.PARTICIPANT_ACTIVITIES',
            'Participant activities',
          ),
          to: routes.participantActivities.list,
          icon: faUserCheck,
          roles: [Roles.ORGANISER, Roles.MANAGER, Roles.ADMIN],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label: translate(intl, 'SIDEBAR.EVENT_TYPES', 'Event types'),
          to: routes.eventTypes.list,
          icon: faCalendarCheck,
          roles: [Roles.ORGANISER, Roles.MANAGER, Roles.ADMIN],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label: translate(intl, 'SIDEBAR.EVENT_PURPOSES', 'Event purposes'),
          to: routes.eventPurposes.list,
          icon: faCalendarPlus,
          roles: [Roles.ORGANISER, Roles.MANAGER, Roles.ADMIN],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
        {
          label: translate(
            intl,
            'SIDEBAR.EVENT_REPRESENTATIVES',
            'Event representatives',
          ),
          to: routes.eventRepresentatives.list,
          icon: faUserGraduate,
          roles: [Roles.ORGANISER, Roles.MANAGER, Roles.ADMIN],
          isCompanySelectNeeded: getState(selectedRole, selectedCompany),
        },
      ],
    },
  ];

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarContent}>
        <div className={styles.mainContent}>
          <div>
            <div className={styles.logoContainer}>
              <div className={styles.logo}>
                <Link to={routes.homepage}>
                  <img src={procertyLogoOnBlue} alt="Procerty Logo" />
                </Link>
              </div>
            </div>
            <hr className={styles.sidebarDivider} />
            {selectedRole !== Roles.USER && selectedRole !== Roles.LECTURER && (
              <>
                {currentAccount}
                <hr className={styles.sidebarDivider} />
              </>
            )}
            <div className={styles.menuItemsContainer}>
              {SIDEBAR_ITEMS.map((sidebarItem) => (
                <div className={styles.menuItems} key={sidebarItem.title.name}>
                  <div className={styles.menuItemsTitle}>
                    {selectedRole &&
                      sidebarItem.title.roles.includes(selectedRole) &&
                      sidebarItem.title.isCompanySelectNeeded === false &&
                      sidebarItem.title.name}
                  </div>
                  <div className={styles.menuSubItemsContainer}>
                    <div className={styles.menuSubItems}>
                      {sidebarItem.items
                        .filter(
                          (navItem) =>
                            selectedRole &&
                            navItem.roles.includes(selectedRole) &&
                            navItem.isCompanySelectNeeded === false,
                        )
                        .map((navItem) => (
                          <div
                            key={navItem.to + navItem.label}
                            className={styles.subItemContainer}
                          >
                            <NavLink
                              to={navItem.to}
                              className={styles.subItem}
                              activeClassName={styles.activeSubItem}
                            >
                              <FontAwesomeIcon
                                icon={navItem.icon}
                                className={styles.navigationIcon}
                                fixedWidth
                              />
                              <div className={styles.navigationText}>
                                {navItem.label}
                              </div>
                            </NavLink>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.poweredBy}>
            <div className={styles.ababa}>Powered by</div>
            <a
              href="https://ababa.tech"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={ababaLogo}
                alt="AbabaTech logo"
                className={styles.ababaLogo}
              />
            </a>
          </div>
        </div>
        <div className={styles.bottomScrollContainer}>
          <div className={styles.bottomScroll} />
        </div>
        <div className={styles.rightScrollContainer}>
          <div className={styles.rightScroll} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedRole: state.auth.role,
  selectedCompany: state.company.selectedCompany,
});

export default connect(mapStateToProps)(Sidebar);
