export const FETCH_MANAGER_START = 'FETCH_MANAGER_START';
export const FETCH_MANAGER_SUCCESS = 'FETCH_MANAGER_SUCCESS';
export const FETCH_MANAGER_FAIL = 'FETCH_MANAGER_FAIL';

export const MANAGER_CONFIRM_START = 'MANAGER_CONFIRM_START';
export const MANAGER_CONFIRM_SUCCESS = 'MANAGER_CONFIRM_SUCCESS';
export const MANAGER_CONFIRM_FAIL = 'MANAGER_CONFIRM_FAIL';

export type ManagerActionTypes =
  | typeof FETCH_MANAGER_START
  | typeof FETCH_MANAGER_SUCCESS
  | typeof FETCH_MANAGER_FAIL
  | typeof MANAGER_CONFIRM_START
  | typeof MANAGER_CONFIRM_SUCCESS
  | typeof MANAGER_CONFIRM_FAIL;
