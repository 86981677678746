import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ManagerActionTypes } from './actionTypes';
import { Manager } from '../../domain/Manager';

export type ManagerStateType = {
  manager: Manager | null;
  managerSuccess: boolean;
  managerLoading: boolean;
  managerError: HttpError;
  managerConfirmLoading: boolean;
  managerConfirmSuccess: boolean;
  managerConfirmError: HttpError;
};

export type ManagerActionType = ManagerStateType & {
  type: ManagerActionTypes;
};

export const initialState: ManagerStateType = {
  manager: null,
  managerLoading: true,
  managerSuccess: false,
  managerError: null,
  managerConfirmLoading: false,
  managerConfirmSuccess: false,
  managerConfirmError: null,
};

const fetchManagerStart = (state: ManagerStateType): ManagerStateType => ({
  ...state,
  managerLoading: true,
});

const fetchManagerSuccess = (
  state: ManagerStateType,
  action: ManagerActionType,
): ManagerStateType => ({
  ...state,
  manager: action.manager,
  managerLoading: false,
  managerSuccess: true,
  managerError: null,
});

const fetchManagerFail = (
  state: ManagerStateType,
  action: ManagerActionType,
): ManagerStateType => ({
  ...state,
  managerError: action.managerError,
  managerLoading: false,
});

const managerConfirmStart = (state: ManagerStateType): ManagerStateType => ({
  ...state,
  managerConfirmLoading: true,
});

const managerConfirmSuccess = (state: ManagerStateType): ManagerStateType => ({
  ...state,
  managerConfirmLoading: false,
  managerConfirmError: null,
  managerConfirmSuccess: true,
});

const managerConfirmFail = (
  state: ManagerStateType,
  action: ManagerActionType,
): ManagerStateType => ({
  ...state,
  managerConfirmError: action.managerConfirmError,
  managerConfirmLoading: false,
});

const reducer = (state = initialState, action: ManagerActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_MANAGER_START:
      return fetchManagerStart(state);
    case actionTypes.FETCH_MANAGER_SUCCESS:
      return fetchManagerSuccess(state, action);
    case actionTypes.FETCH_MANAGER_FAIL:
      return fetchManagerFail(state, action);
    case actionTypes.MANAGER_CONFIRM_START:
      return managerConfirmStart(state);
    case actionTypes.MANAGER_CONFIRM_SUCCESS:
      return managerConfirmSuccess(state);
    case actionTypes.MANAGER_CONFIRM_FAIL:
      return managerConfirmFail(state, action);
    default:
      return state;
  }
};

export default reducer;
