import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Participant, ParticipantsList } from '../../domain/Participant';
import { ListParams } from '../../hooks/useList/useList';
import { AssetBlob } from '../event/reducer';

export const fetchParticipantsStart = () => ({
  type: actionTypes.FETCH_PARTICIPANTS_START,
});

export const fetchParticipantsSuccess = (
  participantsList: ParticipantsList,
) => ({
  type: actionTypes.FETCH_PARTICIPANTS_SUCCESS,
  participantsList,
});

export const fetchParticipantsFail = (participantsListError: HttpError) => ({
  type: actionTypes.FETCH_PARTICIPANTS_FAIL,
  participantsListError,
});

export const fetchParticipantStart = () => ({
  type: actionTypes.FETCH_PARTICIPANT_START,
});

export const fetchParticipantSuccess = (participant: Participant) => ({
  type: actionTypes.FETCH_PARTICIPANT_SUCCESS,
  participant,
});

export const fetchParticipantFail = (participantError: HttpError) => ({
  type: actionTypes.FETCH_PARTICIPANT_FAIL,
  participantError,
});

export const createParticipantStart = () => ({
  type: actionTypes.CREATE_PARTICIPANT_START,
});

export const createParticipantSuccess = () => ({
  type: actionTypes.CREATE_PARTICIPANT_SUCCESS,
});

export const createParticipantFail = (participantCreateError: string) => ({
  type: actionTypes.CREATE_PARTICIPANT_FAIL,
  participantCreateError,
});

export const importParticipantsStart = () => ({
  type: actionTypes.IMPORT_PARTICIPANTS_START,
});

export const importParticipantsSuccess = () => ({
  type: actionTypes.IMPORT_PARTICIPANTS_SUCCESS,
});

export const importParticipantsFail = (participantsImportError: string) => ({
  type: actionTypes.IMPORT_PARTICIPANTS_FAIL,
  participantsImportError,
});

export const importParticipantsReset = () => ({
  type: actionTypes.IMPORT_PARTICIPANTS_RESET,
});

export const updateParticipantStart = () => ({
  type: actionTypes.UPDATE_PARTICIPANT_START,
});

export const updateParticipantSuccess = () => ({
  type: actionTypes.UPDATE_PARTICIPANT_SUCCESS,
});

export const updateParticipantFail = (participantUpdateError: string) => ({
  type: actionTypes.UPDATE_PARTICIPANT_FAIL,
  participantUpdateError,
});

export const deleteParticipantStart = () => ({
  type: actionTypes.DELETE_PARTICIPANT_START,
});

export const deleteParticipantSuccess = () => ({
  type: actionTypes.DELETE_PARTICIPANT_SUCCESS,
});

export const deleteParticipantFail = (participantDeleteError: string) => ({
  type: actionTypes.DELETE_PARTICIPANT_FAIL,
  participantDeleteError,
});

export const deleteMultipleParticipantsStart = () => ({
  type: actionTypes.DELETE_MULTIPLE_PARTICIPANT_START,
});

export const deleteMultipleParticipantsSuccess = () => ({
  type: actionTypes.DELETE_MULTIPLE_PARTICIPANT_SUCCESS,
});

export const deleteMultipleParticipantsFail = (
  participantMultipleDeleteError: string,
) => ({
  type: actionTypes.DELETE_MULTIPLE_PARTICIPANT_FAIL,
  participantMultipleDeleteError,
});

export const setParticipantsFilterValues = (
  participantsFilter: ListParams,
) => ({
  type: actionTypes.SET_PARTICIPANTS_FILTER,
  participantsFilter,
});

export const resetParticipantStore = () => ({
  type: actionTypes.RESET_PARTICIPANT_STORE,
});

export const setUpdateNeeded = () => ({
  type: actionTypes.SET_PARTICIPANTS_UPDATE_NEEDED,
});

export const participantConfirmStart = () => ({
  type: actionTypes.PARTICIPANT_CONFIRM_START,
});

export const participantConfirmSuccess = () => ({
  type: actionTypes.PARTICIPANT_CONFIRM_SUCCESS,
});

export const participantConfirmFail = (participantConfirmError: HttpError) => ({
  type: actionTypes.PARTICIPANT_CONFIRM_FAIL,
  participantConfirmError,
});

export const downloadFileStart = () => ({
  type: actionTypes.DOWNLOAD_FILE_START,
});

export const downloadFileSuccess = () => ({
  type: actionTypes.DOWNLOAD_FILE_SUCCESS,
});

export const downloadFileFail = (downloadFileError: HttpError) => ({
  type: actionTypes.DOWNLOAD_FILE_FAIL,
  downloadFileError,
});

export const downloadAllAssetsStart = (id?: number) => ({
  type: actionTypes.DOWNLOAD_ALL_ASSETS_START,
  id,
});

export const downloadAllAssetsSuccess = (id?: number) => ({
  type: actionTypes.DOWNLOAD_ALL_ASSETS_SUCCESS,
  id,
});

export const downloadAllAssetsFail = (downloadAllAssetsFail: HttpError) => ({
  type: actionTypes.DOWNLOAD_ALL_ASSETS_FAIL,
  downloadAllAssetsFail,
});

export const updateAssetStart = (id?: number) => ({
  type: actionTypes.UPDATE_ASSET_START,
  id,
});

export const updateAssetSuccess = (participant: Participant, id?: number) => ({
  type: actionTypes.UPDATE_ASSET_SUCCESS,
  participant,
  id,
});

export const updateAssetFail = (assetUpdateError: string) => ({
  type: actionTypes.UPDATE_ASSET_FAIL,
  assetUpdateError,
});

export const resetParticipantAssetSuccessStatus = () => ({
  type: actionTypes.RESET_PARTICIPANT_ASSET_SUCCESS_STATUS,
});

export const getAssetThumbnailStart = () => ({
  type: actionTypes.GET_ASSET_THUMBNAIL_START,
});

export const getAssetThumbnailSuccess = (assetThumbnailBlob: AssetBlob) => ({
  type: actionTypes.GET_ASSET_THUMBNAIL_SUCCESS,
  assetThumbnailBlob,
});

export const getAssetThumbnailFail = (getAssetThumbnailError: HttpError) => ({
  type: actionTypes.GET_ASSET_THUMBNAIL_FAIL,
  getAssetThumbnailError,
});
