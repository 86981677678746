import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Organisation, OrganisationsList } from '../../domain/Organisation';
import { ListParams } from '../../hooks/useList/useList';

export const fetchOrganisationsStart = () => ({
  type: actionTypes.FETCH_ORGANISATIONS_START,
});

export const fetchOrganisationsSuccess = (
  organisationsList: OrganisationsList,
) => ({
  type: actionTypes.FETCH_ORGANISATIONS_SUCCESS,
  organisationsList,
});

export const fetchOrganisationsFail = (organisationsListError: HttpError) => ({
  type: actionTypes.FETCH_ORGANISATIONS_FAIL,
  organisationsListError,
});

export const fetchOrganisationStart = () => ({
  type: actionTypes.FETCH_ORGANISATION_START,
});

export const fetchOrganisationSuccess = (organisation: Organisation) => ({
  type: actionTypes.FETCH_ORGANISATION_SUCCESS,
  organisation,
});

export const fetchOrganisationFail = (organisationError: HttpError) => ({
  type: actionTypes.FETCH_ORGANISATION_FAIL,
  organisationError,
});

export const createOrganisationStart = () => ({
  type: actionTypes.CREATE_ORGANISATION_START,
});

export const createOrganisationSuccess = (
  createdOrganisation: Organisation,
) => ({
  type: actionTypes.CREATE_ORGANISATION_SUCCESS,
  createdOrganisation,
});

export const createOrganisationFail = (organisationCreateError: string) => ({
  type: actionTypes.CREATE_ORGANISATION_FAIL,
  organisationCreateError,
});

export const updateOrganisationStart = () => ({
  type: actionTypes.UPDATE_ORGANISATION_START,
});

export const updateOrganisationSuccess = () => ({
  type: actionTypes.UPDATE_ORGANISATION_SUCCESS,
});

export const updateOrganisationFail = (organisationUpdateError: string) => ({
  type: actionTypes.UPDATE_ORGANISATION_FAIL,
  organisationUpdateError,
});

export const deleteOrganisationStart = () => ({
  type: actionTypes.DELETE_ORGANISATION_START,
});

export const deleteOrganisationSuccess = () => ({
  type: actionTypes.DELETE_ORGANISATION_SUCCESS,
});

export const deleteOrganisationFail = (organisationDeleteError: string) => ({
  type: actionTypes.DELETE_ORGANISATION_FAIL,
  organisationDeleteError,
});

export const setOrganisationsFilterValues = (
  organisationsFilter: ListParams,
) => ({
  type: actionTypes.SET_ORGANISATIONS_FILTER,
  organisationsFilter,
});

export const resetOrganisationStore = () => ({
  type: actionTypes.RESET_ORGANISATION_STORE,
});

export const setUpdateNeeded = () => ({
  type: actionTypes.SET_ORGANISATION_UPDATE_NEEDED,
});

export const resetCreatedOrganisation = () => ({
  type: actionTypes.RESET_CREATED_ORGANISATION,
});
