import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Navigation.module.scss';
import { IconButton, Popover } from '@material-ui/core';
import {
  faBars,
  faChevronDown,
  faCog,
  faSignOutAlt,
  faTimes,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarPlus,
  faFileAlt,
  faStamp,
  faUserCheck,
  faUserGraduate,
  faUser,
  faUsers,
  faWallet,
  faCube,
  faLanguage,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../store/auth/service';
import { Link, NavLink, useHistory } from 'react-router-dom';
import Alert from '../Alert/Alert';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { User } from '../../domain/User';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import routes from '../../config/Router/routes';
import { fetchCompany } from '../../store/company/service';
import { Company } from '../../domain/Company';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import CreditPackagesModal from '../../components/CreditPackage/CreditPackagesModal/CreditPackagesModal';
import { getState } from '../../utility/navigationHelper/navigationHelper';
import * as companyService from '../../store/company/service';
import { Roles } from '../../domain/Role';
import * as userService from '../../store/user/service';

type Props = {
  onLogout: () => void;
  hasViewedProfile: boolean;
  selectedRole: Roles | null;
  currentUser: User | null;
  currentAccount?: ReactNode;
  selectedCompany: number | null;
  onFetchCompany: (selectedCompany: number) => void;
  company: Company | null;
  onSelectedRoleUpdate: (selectedRole: Roles) => void;
  onSelectedCompanyUpdate: (selectedCompanyId: number | null) => void;
  onFetchCurrentUser: () => void;
  isParticipantConfirmSuccess: boolean;
};

const MOBILE_BREAK_POINT = 1200;

export const Navigation = ({
  onLogout,
  hasViewedProfile,
  selectedRole,
  currentUser,
  currentAccount,
  selectedCompany,
  onFetchCompany,
  company,
  onSelectedRoleUpdate,
  onSelectedCompanyUpdate,
  onFetchCurrentUser,
  isParticipantConfirmSuccess,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCreditPackagesModalOpen, setIsCreditPackagesModalOpen] =
    useState(false);

  const { width } = useWindowSize();
  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    if (isParticipantConfirmSuccess) {
      onFetchCurrentUser();
    }
  }, [isParticipantConfirmSuccess]);

  const roles = currentUser?.roles;

  useEffect(() => {
    if (selectedCompany) {
      onFetchCompany(selectedCompany);
    }
  }, [selectedCompany]);

  const handleRoleSwitcher = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleRoleClick = (clickedRole: Roles) => {
    onSelectedRoleUpdate(clickedRole);
    setIsPopoverOpen(false);

    if (clickedRole !== selectedRole) {
      localStorage.removeItem('selectedCompany');
      onSelectedCompanyUpdate(null);
    }

    if (clickedRole === Roles.ADMIN) {
      return history.push(routes.events.allEventsList);
    }

    return history.push(routes.organiserEvents);
  };

  const roleSelections = [
    {
      label: translate(intl, 'NAVIGATION.ROLE_ADMIN', 'Administrator'),
      isActive: selectedRole === Roles.ADMIN,
      visible: Roles.ADMIN,
      role: Roles.ADMIN,
    },
    {
      label: translate(intl, 'NAVIGATION.ROLE_ORGANISER', 'Organiser'),
      isActive: selectedRole === Roles.ORGANISER,
      visible: Roles.ORGANISER,
      role: Roles.ORGANISER,
    },
    {
      label: translate(intl, 'NAVIGATION.ROLE_USER', 'User'),
      isActive: selectedRole === Roles.USER,
      visible: Roles.USER,
      role: Roles.USER,
    },
    {
      label: translate(intl, 'NAVIGATION.ROLE_MANAGER', 'Manager'),
      isActive: selectedRole === Roles.MANAGER,
      visible: Roles.MANAGER,
      role: Roles.MANAGER,
    },
    {
      label: translate(intl, 'NAVIGATION.ROLE_LECTURER', 'Lecturer'),
      isActive: selectedRole === Roles.LECTURER,
      visible: Roles.LECTURER,
      role: Roles.LECTURER,
    },
  ];

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'SIDEBAR.ORGANISER_EVENTS', 'Organiser events'),
      to:
        selectedRole === Roles.ADMIN
          ? routes.events.list
          : routes.organiserEvents,
      icon: faCalendarAlt,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(intl, 'SIDEBAR.LECTURER_EVENTS', 'Lecturer Events'),
      to: routes.lecturerEvents.list,
      icon: faCalendarAlt,
      roles: [Roles.LECTURER],
      isCompanySelectNeeded: false,
    },
    {
      label: translate(intl, 'SIDEBAR.ORGANISER_USERS', 'Organiser Users'),
      to: routes.users.list,
      icon: faUser,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(
        intl,
        'SIDEBAR.ORGANISER_COMPANIES',
        'Organiser Companies',
      ),
      to: routes.companies.list,
      icon: faCube,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label:
        selectedRole === Roles.USER || selectedRole === Roles.LECTURER
          ? translate(intl, 'SIDEBAR.CERTIFICATES', 'Certificates')
          : translate(
              intl,
              'SIDEBAR.ORGANISER_CERTIFICATES',
              'Organiser Certificates',
            ),
      to: routes.certificates.list,
      icon: faFileAlt,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.USER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(intl, 'SIDEBAR.EVENTS', 'Events'),
      to: routes.events.allEventsList,
      icon: faCalendarAlt,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: false,
    },
    {
      label: translate(intl, 'SIDEBAR.USERS', 'Users'),
      to: routes.users.allUsersList,
      icon: faUser,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: false,
    },
    {
      label: translate(intl, 'SIDEBAR.COMPANIES', 'Companies'),
      to: routes.companies.allCompaniesList,
      icon: faCube,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: false,
    },
    {
      label: translate(intl, 'SIDEBAR.CERTIFICATES', 'Certificates'),
      to: routes.certificates.allCertificatesList,
      icon: faFileAlt,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: false,
    },
    {
      label: translate(intl, 'SIDEBAR.ORGANISERS_LIST', 'Organisers'),
      to: routes.organisers.list,
      icon: faUsers,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: false,
    },
    {
      label: translate(intl, 'SIDEBAR.ORDERS', 'Payment history'),
      to: routes.orders,
      icon: faWallet,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: false,
    },
    {
      label: translate(intl, 'SIDEBAR.CREDIT_PACKAGES', 'Credit Packages'),
      to: routes.creditPackages.list,
      icon: faCube,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: false,
    },
    {
      label: translate(intl, 'SIDEBAR.ORGANISATIONS', 'Organisations'),
      to: routes.organisations.list,
      icon: faBuilding,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(intl, 'SIDEBAR.CERTIFICATE_TYPES', 'Certificate types'),
      to: routes.certificateTypes.list,
      icon: faStamp,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(
        intl,
        'SIDEBAR.PARTICIPANT_ACTIVITIES',
        'Participant activities',
      ),
      to: routes.participantActivities.list,
      icon: faUserCheck,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(intl, 'SIDEBAR.EVENT_TYPES', 'Event types'),
      to: routes.eventTypes.list,
      icon: faCalendarCheck,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(intl, 'SIDEBAR.EVENT_PURPOSES', 'Event purposes'),
      to: routes.eventPurposes.list,
      icon: faCalendarPlus,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(
        intl,
        'SIDEBAR.EVENT_REPRESENTATIVES',
        'Event representatives',
      ),
      to: routes.eventRepresentatives.list,
      icon: faUserGraduate,
      roles: [Roles.ADMIN, Roles.ORGANISER, Roles.MANAGER],
      isCompanySelectNeeded: getState(selectedRole, selectedCompany),
    },
    {
      label: translate(intl, 'SIDEBAR.TRANSLATIONS', 'Translations manager'),
      to: routes.translations,
      icon: faLanguage,
      roles: [Roles.ADMIN],
      isCompanySelectNeeded: false,
    },
  ];

  return (
    <>
      <header
        className={cx(styles.navigationContainer, {
          [styles.connectProfileLabel]:
            (!hasViewedProfile && selectedRole === Roles.USER) ||
            (!hasViewedProfile && selectedRole === Roles.LECTURER),
        })}
      >
        {(selectedRole === Roles.ORGANISER ||
          selectedRole === Roles.MANAGER ||
          (selectedRole === Roles.ADMIN && selectedCompany)) && (
          <div className={styles.balanceInfo}>
            <div>
              {`${translate(intl, 'NAVIGATION.BALANCE', 'My balance')}:`}
            </div>
            <div className={styles.balance}>{company?.balance}</div>
            <Button
              className={styles.topUpButton}
              onClick={() => setIsCreditPackagesModalOpen(true)}
              buttonVariant="contained"
              color="primary"
              type="submit"
            >
              {translate(intl, 'BALANCE.TOP_UP', 'Top up')}
            </Button>
          </div>
        )}
        {(selectedRole === Roles.USER || selectedRole === Roles.LECTURER) &&
          !hasViewedProfile && (
            <div>
              <Alert variant="info" isText={false}>
                <div className={styles.connectAccountInfo}>
                  {translate(
                    intl,
                    'NAVIGATION.HAVE_OTHER_ACCOUNTS',
                    'Have another account?',
                  )}
                  <Link to={routes.profile.details}>
                    {translate(intl, 'NAVIGATION.CONNECT', 'Connect here')}
                  </Link>
                </div>
              </Alert>
            </div>
          )}
        {(selectedRole === Roles.USER || selectedRole === Roles.LECTURER) &&
          currentUser &&
          !currentUser.roles.some((role) => role.role === Roles.ORGANISER) && (
            <Button
              className={styles.becomeOrganiserButton}
              buttonVariant="text"
              color="primary"
              type="button"
              onClick={() => history.push(routes.organisers.registration)}
            >
              {translate(
                intl,
                'NAVIGATION.BECOME_ORGANISER',
                'Become organiser',
              )}
            </Button>
          )}
        <div className={styles.rightItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => setIsMobileMenuOpen((prev) => !prev)}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
        </div>
        <div className={styles.rightItems}>
          {(selectedRole === Roles.USER ||
            selectedRole === Roles.ORGANISER ||
            selectedRole === Roles.LECTURER) && (
            <IconButton onClick={() => history.push(routes.profile.details)}>
              <FontAwesomeIcon icon={faCog} fixedWidth size="sm" />
            </IconButton>
          )}

          <IconButton onClick={handleRoleSwitcher}>
            <FontAwesomeIcon icon={faChevronDown} fixedWidth size="sm" />
          </IconButton>
          <LanguageSwitcher />
          <IconButton onClick={() => onLogout()}>
            <FontAwesomeIcon icon={faSignOutAlt} fixedWidth size="sm" />
          </IconButton>
        </div>
      </header>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobile}>
          {selectedRole !== Roles.USER && selectedRole !== Roles.LECTURER && (
            <div>{currentAccount}</div>
          )}
          {SIDEBAR_ITEMS.filter(
            (navItem) =>
              selectedRole &&
              navItem.roles.includes(selectedRole) &&
              navItem.isCompanySelectNeeded === false,
          ).map((navItem) => (
            <NavLink
              key={navItem.to + navItem.label}
              to={navItem.to}
              className={styles.navigationItem}
              activeClassName={styles.activeNavigationItem}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <FontAwesomeIcon
                icon={navItem.icon as IconProp}
                fixedWidth
                className={styles.navigationItemIcon}
              />
              <div className={styles.navigationText}>{navItem.label}</div>
            </NavLink>
          ))}
        </div>
      )}
      <Popover
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          <div className={styles.loginAs}>
            {translate(intl, 'NAVIGATION.SWITCH_ROLE', 'Switch to:')}
          </div>
          <ul className={styles.popoverList}>
            {roleSelections
              .filter(
                (roleSelection) =>
                  roles &&
                  currentUser.roles.some(
                    (role) => roleSelection.visible === role.role,
                  ),
              )
              .map((roleSelection) => (
                <li
                  key={roleSelection.label}
                  className={cx(styles.popoverListItem, {
                    [styles.active]: roleSelection.isActive,
                  })}
                  onClick={() => handleRoleClick(roleSelection.role)}
                >
                  {roleSelection.label}
                </li>
              ))}
          </ul>
        </div>
      </Popover>
      <Modal
        isOpen={isCreditPackagesModalOpen}
        onClose={() => setIsCreditPackagesModalOpen(false)}
        isLoading={false}
        isBig
        isFooterHidden
      >
        {isCreditPackagesModalOpen && (
          <CreditPackagesModal
            onClose={() => setIsCreditPackagesModalOpen(false)}
          />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  hasViewedProfile: state.auth.hasViewedProfile,
  selectedRole: state.auth.role,
  currentUser: state.user.currentUser,
  selectedCompany: state.company.selectedCompany,
  company: state.company.company,
  isParticipantConfirmSuccess: state.participant.participantConfirmSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
  onSelectedRoleUpdate: (selectedRole: Roles) =>
    dispatch(authService.selectRole(selectedRole)),
  onFetchCompany: (selectedCompany: number) =>
    dispatch(fetchCompany(selectedCompany)),
  onSelectedCompanyUpdate: (selectedCompanyId: number | null) =>
    dispatch(companyService.selectCompany(selectedCompanyId)),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
