import axios from '../../config/Axios/axios-instance';
import {
  fetchTranslationsFail,
  fetchTranslationsStart,
  fetchTranslationsSuccess,
  updateTranslationsFail,
  updateTranslationsStart,
  updateTranslationsSuccess,
  setTranslationsFilterValues,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { API_URLS } from '../globalService';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

export type FetchTranslationsParams = ListParams & {
  languageId?: string;
};

export type UpdateTranslationRequest = {
  id: number;
  value: string;
};

export const fetchTranslations =
  (params: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchTranslationsStart());

    if (saveFilters) {
      dispatch(setTranslationsFilterValues(params));
    }

    return axios
      .get(API_URLS.translation, { params })
      .then((response) => {
        dispatch(fetchTranslationsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchTranslationsFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };

export const updateTranslation =
  (inputs: UpdateTranslationRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateTranslationsStart());
    const { id, ...updatedTranslation } = inputs;

    return axios
      .patch(`${API_URLS.translation}/${id}`, { ...updatedTranslation })
      .then((response) => {
        dispatch(updateTranslationsSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.TRANSLATION_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateTranslationsFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };
