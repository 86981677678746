import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Company } from '../../domain/Company';
import { ListResults } from '../../common/List/List';

export const fetchCompanyOptionsStart = () => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_START,
});

export const fetchCompanyOptionsSuccess = (companyOptions: Company[]) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS,
  companyOptions,
});

export const fetchCompanyOptionsFail = (companyOptionsError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_OPTIONS_FAIL,
  companyOptionsError,
});

export const selectCompanyStart = () => ({
  type: actionTypes.SELECT_COMPANY_START,
});

export const selectCompanySuccess = (selectedCompany: number) => ({
  type: actionTypes.SELECT_COMPANY_SUCCESS,
  selectedCompany,
});

export const selectCompanyFail = (selectCompanyError: HttpError) => ({
  type: actionTypes.SELECT_COMPANY_FAIL,
  selectCompanyError,
});

export const setSelectedCompany = (selectedCompany: number | null) => {
  selectedCompany &&
    localStorage.setItem('selectedCompany', selectedCompany.toString());

  return {
    type: actionTypes.SET_SELECTED_COMPANY,
    selectedCompany,
  };
};

export const fetchCompanyStart = () => ({
  type: actionTypes.FETCH_COMPANY_START,
});

export const fetchCompanySuccess = (company: Company) => ({
  type: actionTypes.FETCH_COMPANY_SUCCESS,
  company,
});

export const fetchCompanyFail = (companyError: HttpError) => ({
  type: actionTypes.FETCH_COMPANY_FAIL,
  companyError,
});

export const fetchCompaniesStart = () => ({
  type: actionTypes.FETCH_COMPANIES_START,
});

export const fetchCompaniesSuccess = (companiesList: ListResults<Company>) => ({
  type: actionTypes.FETCH_COMPANIES_SUCCESS,
  companiesList,
});

export const fetchCompaniesFail = (companiesError: HttpError) => ({
  type: actionTypes.FETCH_COMPANIES_FAIL,
  companiesError,
});

export const resetCompaniesListStore = () => ({
  type: actionTypes.RESET_COMPANIES_LIST_STORE,
});

export const resetCompanyStore = () => ({
  type: actionTypes.RESET_COMPANY_STORE,
});

export const resetCompanyCreateUpdateStore = () => ({
  type: actionTypes.RESET_COMPANY_CREATE_STORE,
});

export const updateCompanyStart = () => ({
  type: actionTypes.UPDATE_COMPANY_START,
});

export const updateCompanySuccess = () => ({
  type: actionTypes.UPDATE_COMPANY_SUCCESS,
});

export const updateCompanyFail = (companyUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_COMPANY_FAIL,
  companyUpdateError,
});

export const createCompanyStart = () => ({
  type: actionTypes.CREATE_COMPANY_START,
});

export const createCompanySuccess = (createdCompany: Company) => ({
  type: actionTypes.CREATE_COMPANY_SUCCESS,
  createdCompany,
});

export const createCompanyFail = (companyCreateError: HttpError) => ({
  type: actionTypes.CREATE_COMPANY_FAIL,
  companyCreateError,
});

export const fetchEditCompanyStart = () => ({
  type: actionTypes.FETCH_EDIT_COMPANY_START,
});

export const fetchEditCompanySuccess = (editCompany: Company) => ({
  type: actionTypes.FETCH_EDIT_COMPANY_SUCCESS,
  editCompany,
});

export const fetchEditCompanyFail = (editCompanyError: HttpError) => ({
  type: actionTypes.FETCH_EDIT_COMPANY_FAIL,
  editCompanyError,
});

export const deleteCompanyStart = () => ({
  type: actionTypes.DELETE_COMPANY_START,
});

export const deleteCompanySuccess = () => ({
  type: actionTypes.DELETE_COMPANY_SUCCESS,
});

export const deleteCompanyFail = (companyDeleteError: string) => ({
  type: actionTypes.DELETE_COMPANY_FAIL,
  companyDeleteError,
});

export const addCompanyCreditsStart = () => ({
  type: actionTypes.ADD_COMPANY_CREDITS_START,
});

export const addCompanyCreditsSuccess = () => ({
  type: actionTypes.ADD_COMPANY_CREDITS_SUCCESS,
});

export const addCompanyCreditsFail = (companyCreditsAddError: HttpError) => ({
  type: actionTypes.ADD_COMPANY_CREDITS_FAIL,
  companyCreditsAddError,
});

export const resetCompanyCreditsAddStore = () => ({
  type: actionTypes.RESET_COMPANY_CREDITS_ADD_STORE,
});
