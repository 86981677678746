import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Certificate, CertificatesList } from '../../domain/Certificate';
import { ListParams } from '../../hooks/useList/useList';

export const fetchCertificatesStart = () => ({
  type: actionTypes.FETCH_CERTIFICATES_START,
});

export const fetchCertificatesSuccess = (
  certificatesList: CertificatesList,
) => ({
  type: actionTypes.FETCH_CERTIFICATES_SUCCESS,
  certificatesList,
});

export const fetchCertificatesFail = (certificatesListError: HttpError) => ({
  type: actionTypes.FETCH_CERTIFICATES_FAIL,
  certificatesListError,
});

export const fetchCertificateTotalCountStart = () => ({
  type: actionTypes.FETCH_CERTIFICATE_TOTAL_COUNT_START,
});

export const fetchCertificateTotalCountSuccess = (
  certificateTotalCount: number,
) => ({
  type: actionTypes.FETCH_CERTIFICATE_TOTAL_COUNT_SUCCESS,
  certificateTotalCount,
});

export const fetchCertificateTotalCountFail = (
  certificateTotalCountError: HttpError,
) => ({
  type: actionTypes.FETCH_CERTIFICATE_TOTAL_COUNT_FAIL,
  certificateTotalCountError,
});

export const fetchAllUserCertificateHashesStart = () => ({
  type: actionTypes.FETCH_ALL_USER_CERTIFICATES_HASHES_START,
});

export const fetchAllUserCertificateHashesSuccess = (
  certificateHashes: string[],
) => ({
  type: actionTypes.FETCH_ALL_USER_CERTIFICATES_HASHES_SUCCESS,
  certificateHashes,
});

export const fetchAllUserCertificateHashesFail = (
  certificateHashesError: HttpError,
) => ({
  type: actionTypes.FETCH_ALL_USER_CERTIFICATES_HASHES_FAIL,
  certificateHashesError,
});

export const setCertificatesFilterValues = (
  certificatesFilter: ListParams,
) => ({
  type: actionTypes.SET_CERTIFICATES_FILTER,
  certificatesFilter,
});

export const resetCertificateStore = () => ({
  type: actionTypes.RESET_CERTIFICATE_STORE,
});

export const fetchCertificateStart = () => ({
  type: actionTypes.FETCH_CERTIFICATE_START,
});

export const fetchCertificateSuccess = (certificate: Certificate) => ({
  type: actionTypes.FETCH_CERTIFICATE_SUCCESS,
  certificate,
});

export const fetchCertificateFail = (certificateError: HttpError) => ({
  type: actionTypes.FETCH_CERTIFICATE_FAIL,
  certificateError,
});

export const createExternalCertificateStart = () => ({
  type: actionTypes.CREATE_EXTERNAL_CERTIFICATE_START,
});

export const createExternalCertificateSuccess = (
  externalCertificate: Certificate,
) => ({
  type: actionTypes.CREATE_EXTERNAL_CERTIFICATE_SUCCESS,
  externalCertificate,
});

export const createExternalCertificateFail = (
  createExternalCertificateError: HttpError,
) => ({
  type: actionTypes.CREATE_EXTERNAL_CERTIFICATE_FAIL,
  createExternalCertificateError,
});

export const updateExternalCertificateStart = () => ({
  type: actionTypes.UPDATE_EXTERNAL_CERTIFICATE_START,
});

export const updateExternalCertificateSuccess = (
  externalCertificate: Certificate,
) => ({
  type: actionTypes.UPDATE_EXTERNAL_CERTIFICATE_SUCCESS,
  externalCertificate,
});

export const updateExternalCertificateFail = (
  updateExternalCertificateError: HttpError,
) => ({
  type: actionTypes.UPDATE_EXTERNAL_CERTIFICATE_FAIL,
  updateExternalCertificateError,
});

export const reportIssueStart = () => ({
  type: actionTypes.REPORT_ISSUE_START,
});

export const reportIssueSuccess = () => ({
  type: actionTypes.REPORT_ISSUE_SUCCESS,
});

export const reportIssueFail = (reportIssueError: HttpError) => ({
  type: actionTypes.REPORT_ISSUE_FAIL,
  reportIssueError,
});

export const downloadFileStart = () => ({
  type: actionTypes.DOWNLOAD_FILE_START,
});

export const downloadFileSuccess = (downloadedFile: File | null) => ({
  type: actionTypes.DOWNLOAD_FILE_SUCCESS,
  downloadedFile,
});

export const downloadFileFail = (downloadFileError: HttpError) => ({
  type: actionTypes.DOWNLOAD_FILE_FAIL,
  downloadFileError,
});

export const prepareToSignCertificateStart = () => ({
  type: actionTypes.PREPARE_TO_SIGN_CERTIFICATE_START,
});

export const prepareToSignCertificateSuccess = (
  prepareToSignBatchId: string,
) => ({
  type: actionTypes.PREPARE_TO_SIGN_CERTIFICATE_SUCCESS,
  prepareToSignBatchId,
});

export const prepareToSignCertificateFail = (
  prepareToSignError: HttpError,
) => ({
  type: actionTypes.PREPARE_TO_SIGN_CERTIFICATE_FAIL,
  prepareToSignError,
});

export const startSignProcessStart = () => ({
  type: actionTypes.START_SIGN_PROCESS_START,
});

export const startSignProcessSuccess = (startSignProcessBatchId: string) => ({
  type: actionTypes.START_SIGN_PROCESS_SUCCESS,
  startSignProcessBatchId,
});

export const startSignProcessFail = (startSignProcessError: HttpError) => ({
  type: actionTypes.START_SIGN_PROCESS_FAIL,
  startSignProcessError,
});

export const prepareToSignCertificateReset = () => ({
  type: actionTypes.PREPARE_TO_SIGN_CERTIFICATE_RESET,
});

export const getSignUrlStart = () => ({
  type: actionTypes.GET_SIGN_URL_START,
});

export const getSignUrlStartSuccess = (
  signUrl: string,
  signUrlBatchId: string,
) => ({
  type: actionTypes.GET_SIGN_URL_SUCCESS,
  signUrl,
  signUrlBatchId,
});

export const getSignUrlStartFail = (signUrlError: HttpError) => ({
  type: actionTypes.GET_SIGN_URL_FAIL,
  signUrlError,
});

export const resetExternalCertificateStore = () => ({
  type: actionTypes.RESET_EXTERNAL_CERTIFICATE_STORE,
});

export const updateCertificateStatusStart = () => ({
  type: actionTypes.UPDATE_CERTIFICATE_STATUS_START,
});

export const updateCertificateStatusSuccess = () => ({
  type: actionTypes.UPDATE_CERTIFICATE_STATUS_SUCCESS,
});

export const updateCertificateStatusFail = (
  certificateStatusUpdateError: string,
) => ({
  type: actionTypes.UPDATE_CERTIFICATE_STATUS_FAIL,
  certificateStatusUpdateError,
});

export const resetAllUserCertificateHashes = () => ({
  type: actionTypes.RESET_ALL_USER_CERTIFICATE_HASHES,
});

export const exportCertificatesToPdfStart = () => ({
  type: actionTypes.EXPORT_CERTIFICATES_TO_PDF_START,
});

export const exportCertificatesToPdfSuccess = () => ({
  type: actionTypes.EXPORT_CERTIFICATES_TO_PDF_SUCCESS,
});

export const exportCertificatesToPdfFail = (
  certificatesToPdfExportError: HttpError,
) => ({
  type: actionTypes.EXPORT_CERTIFICATES_TO_PDF_FAIL,
  certificatesToPdfExportError,
});

export const resetExportCertificatesToPdf = () => ({
  type: actionTypes.RESET_EXPORT_CERTIFICATES_TO_PDF,
});

export const confirmExternalCertificateStart = () => ({
  type: actionTypes.CONFIRM_EXTERNAL_CERTIFICATE_START,
});

export const confirmExternalCertificateSuccess = () => ({
  type: actionTypes.CONFIRM_EXTERNAL_CERTIFICATE_SUCCESS,
});

export const confirmExternalCertificateFail = (
  confirmExternalCertificateError: string,
) => ({
  type: actionTypes.CONFIRM_EXTERNAL_CERTIFICATE_FAIL,
  confirmExternalCertificateError,
});
