import * as actionTypes from './actionTypes';
import { CreditPackageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  CreditPackage,
  CreditPackageListType,
} from '../../domain/CreditPackage';

export type CreditPackageStateType = {
  creditPackagesList: CreditPackageListType | null;
  creditPackagesLoading: boolean;
  creditPackagesError: HttpError;
  creditPackagesListUpdateNeeded: boolean;
  creditPackageCreateLoading: boolean;
  creditPackageCreateError: HttpError;
  creditPackageCreateSuccess: boolean;
  createdCreditPackage: CreditPackage | null;
  creditPackageUpdateLoading: boolean;
  creditPackageUpdateError: HttpError;
  creditPackageUpdateSuccess: boolean;
  creditPackageDeleteLoading: boolean;
  creditPackageDeleteError: HttpError;
  creditPackageDeleteSuccess: boolean;
  creditPackage: CreditPackage | null;
  creditPackageLoading: boolean;
  creditPackageError: HttpError;
};

export type CreditPackageActionType = CreditPackageStateType & {
  type: CreditPackageActionTypes;
};

export const initialState: CreditPackageStateType = {
  creditPackagesList: null,
  creditPackagesLoading: true,
  creditPackagesError: null,
  creditPackagesListUpdateNeeded: false,
  creditPackageCreateLoading: false,
  creditPackageCreateError: null,
  creditPackageCreateSuccess: false,
  createdCreditPackage: null,
  creditPackageUpdateLoading: false,
  creditPackageUpdateError: null,
  creditPackageUpdateSuccess: false,
  creditPackageDeleteLoading: false,
  creditPackageDeleteError: null,
  creditPackageDeleteSuccess: false,
  creditPackage: null,
  creditPackageLoading: false,
  creditPackageError: null,
};

const fetchCreditPackagesStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackagesLoading: true,
});

const fetchCreditPackagesSuccess = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackagesList: action.creditPackagesList,
  creditPackagesLoading: false,
  creditPackagesError: null,
  creditPackagesListUpdateNeeded: false,
});

const fetchCreditPackagesFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackagesError: action.creditPackagesError,
  creditPackagesLoading: false,
});

const fetchCreditPackageStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageLoading: true,
  creditPackageCreateSuccess: false,
  creditPackageCreateError: null,
});

const fetchCreditPackageSuccess = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackage: action.creditPackage,
  creditPackageLoading: false,
  creditPackageError: null,
});

const fetchCreditPackageFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackageError: action.creditPackageError,
  creditPackageLoading: false,
});

const createCreditPackageStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageCreateLoading: true,
});

const createCreditPackageSuccess = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackageCreateLoading: false,
  creditPackageCreateError: null,
  creditPackageCreateSuccess: true,
  createdCreditPackage: action.createdCreditPackage,
});

const createCreditPackageFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackageCreateLoading: false,
  creditPackageCreateError: action.creditPackageCreateError,
});

const updateCreditPackageStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageUpdateLoading: true,
  creditPackageUpdateSuccess: false,
});

const updateCreditPackageSuccess = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageUpdateLoading: false,
  creditPackageUpdateError: null,
  creditPackageUpdateSuccess: true,
});

const updateCreditPackageFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackageUpdateLoading: false,
  creditPackageUpdateError: action.creditPackageUpdateError,
});

const deleteCreditPackageStart = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageDeleteLoading: true,
});

const deleteCreditPackageSuccess = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageDeleteLoading: false,
  creditPackageDeleteError: null,
  creditPackageDeleteSuccess: true,
  creditPackagesListUpdateNeeded: true,
});

const deleteCreditPackageFail = (
  state: CreditPackageStateType,
  action: CreditPackageActionType,
): CreditPackageStateType => ({
  ...state,
  creditPackageDeleteLoading: false,
  creditPackageDeleteError: action.creditPackageDeleteError,
});

const resetCreatedOrEditedCreditPackage = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
  creditPackageCreateSuccess: false,
  creditPackageUpdateSuccess: false,
});

const resetCreditPackagesStore = (
  state: CreditPackageStateType,
): CreditPackageStateType => ({
  ...state,
});

const completelyResetCreditPackageStore = (): CreditPackageStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CreditPackageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CREDIT_PACKAGES_START:
      return fetchCreditPackagesStart(state);
    case actionTypes.FETCH_CREDIT_PACKAGES_SUCCESS:
      return fetchCreditPackagesSuccess(state, action);
    case actionTypes.FETCH_CREDIT_PACKAGES_FAIL:
      return fetchCreditPackagesFail(state, action);
    case actionTypes.FETCH_CREDIT_PACKAGE_START:
      return fetchCreditPackageStart(state);
    case actionTypes.FETCH_CREDIT_PACKAGE_SUCCESS:
      return fetchCreditPackageSuccess(state, action);
    case actionTypes.FETCH_CREDIT_PACKAGE_FAIL:
      return fetchCreditPackageFail(state, action);
    case actionTypes.CREATE_CREDIT_PACKAGE_START:
      return createCreditPackageStart(state);
    case actionTypes.CREATE_CREDIT_PACKAGE_SUCCESS:
      return createCreditPackageSuccess(state, action);
    case actionTypes.CREATE_CREDIT_PACKAGE_FAIL:
      return createCreditPackageFail(state, action);
    case actionTypes.UPDATE_CREDIT_PACKAGE_START:
      return updateCreditPackageStart(state);
    case actionTypes.UPDATE_CREDIT_PACKAGE_SUCCESS:
      return updateCreditPackageSuccess(state);
    case actionTypes.UPDATE_CREDIT_PACKAGE_FAIL:
      return updateCreditPackageFail(state, action);
    case actionTypes.DELETE_CREDIT_PACKAGE_START:
      return deleteCreditPackageStart(state);
    case actionTypes.DELETE_CREDIT_PACKAGE_SUCCESS:
      return deleteCreditPackageSuccess(state);
    case actionTypes.DELETE_CREDIT_PACKAGE_FAIL:
      return deleteCreditPackageFail(state, action);
    case actionTypes.RESET_CREATED_OR_EDITED_CREDIT_PACKAGE:
      return resetCreatedOrEditedCreditPackage(state);
    case actionTypes.RESET_CREDIT_PACKAGES_STORE:
      return resetCreditPackagesStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCreditPackageStore();
    default:
      return state;
  }
};

export default reducer;
