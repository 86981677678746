import * as actionTypes from './actionTypes';
import { ParticipantActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Participant, ParticipantsList } from '../../domain/Participant';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';
import { AssetBlob } from '../event/reducer';

export type ParticipantStateType = {
  participantsList: ParticipantsList | null;
  participantsListLoading: boolean;
  participantsListError: HttpError;
  participantsFilter: ListParams;
  participantError: HttpError;
  participantsListUpdateNeeded: boolean;
  participant: Participant | null;
  participantSuccess: boolean;
  participantLoading: boolean;
  participantCreateLoading: boolean;
  participantCreateError: HttpError;
  participantCreateSuccess: boolean;
  participantUpdateLoading: boolean;
  participantUpdateError: HttpError;
  participantUpdateSuccess: boolean;
  participantDeleteLoading: boolean;
  participantDeleteError: HttpError;
  participantMultipleDeleteLoading: boolean;
  participantMultipleDeleteError: HttpError;
  participantsImportLoading: boolean;
  participantsImportError: HttpError;
  participantsImportSuccess: boolean;
  participantConfirmLoading: boolean;
  participantConfirmError: HttpError;
  participantConfirmSuccess: boolean;
  downloadFileLoading: boolean;
  downloadFileError: HttpError;
  downloadAllAssetsLoading: Record<number, boolean>;
  downloadAllAssetsSuccess: boolean;
  downloadAllAssetsFail: HttpError;
  assetUpdateLoading: Record<number, boolean>;
  assetUpdateError: HttpError;
  assetUpdateSuccess: boolean;
  getAssetThumbnailLoading: boolean;
  getAssetThumbnailError: HttpError;
  getAssetThumbnailSuccess: boolean;
  assetThumbnailBlobs: AssetBlob[];
};

export type ParticipantActionType = ParticipantStateType & {
  type: ParticipantActionTypes;
  assetThumbnailBlob: AssetBlob;
  id: number;
};

export const initialState: ParticipantStateType = {
  participantsList: null,
  participantsListLoading: true,
  participantsListError: null,
  participantsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'firstName',
  },
  participantsListUpdateNeeded: false,
  participant: null,
  participantLoading: true,
  participantSuccess: false,
  participantError: null,
  participantCreateLoading: false,
  participantCreateError: null,
  participantCreateSuccess: false,
  participantUpdateLoading: false,
  participantUpdateError: null,
  participantUpdateSuccess: false,
  participantDeleteLoading: false,
  participantDeleteError: null,
  participantMultipleDeleteLoading: false,
  participantMultipleDeleteError: null,
  participantsImportLoading: false,
  participantsImportError: null,
  participantsImportSuccess: false,
  participantConfirmLoading: false,
  participantConfirmError: null,
  participantConfirmSuccess: false,
  downloadFileLoading: false,
  downloadFileError: null,
  downloadAllAssetsLoading: {},
  downloadAllAssetsSuccess: false,
  downloadAllAssetsFail: null,
  assetUpdateLoading: {},
  assetUpdateError: null,
  assetUpdateSuccess: false,
  getAssetThumbnailLoading: false,
  getAssetThumbnailError: null,
  getAssetThumbnailSuccess: false,
  assetThumbnailBlobs: [],
};

const fetchParticipantsStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsListLoading: true,
  participantUpdateSuccess: false,
  participantCreateSuccess: false,
  participantDeleteError: null,
});

const fetchParticipantsSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsList: action.participantsList,
  participantsListLoading: false,
  participantsListError: null,
  participantsListUpdateNeeded: false,
});

const fetchParticipantsFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsListError: action.participantsListError,
  participantsListLoading: false,
});

const fetchParticipantStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantLoading: true,
});

const fetchParticipantSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participant: action.participant,
  participantLoading: false,
  participantSuccess: true,
  participantError: null,
});

const fetchParticipantFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantError: action.participantError,
  participantLoading: false,
});

const createParticipantStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantCreateLoading: true,
});

const createParticipantSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantCreateLoading: false,
  participantCreateError: null,
  participantCreateSuccess: true,
  participantsListUpdateNeeded: true,
});

const createParticipantFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantCreateLoading: false,
  participantCreateError: action.participantCreateError,
});

const updateParticipantStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantUpdateLoading: true,
});

const updateParticipantSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantUpdateLoading: false,
  participantUpdateError: null,
  participantUpdateSuccess: true,
  participantsListUpdateNeeded: true,
});

const updateParticipantFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantUpdateLoading: false,
  participantUpdateError: action.participantUpdateError,
});

const deleteParticipantStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantDeleteLoading: true,
});

const deleteParticipantSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantDeleteLoading: false,
  participantDeleteError: null,
  participantsListUpdateNeeded: true,
});

const deleteParticipantFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantDeleteLoading: false,
  participantDeleteError: action.participantDeleteError,
});

const deleteMultipleParticipantsStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantMultipleDeleteLoading: true,
});

const deleteMultipleParticipantsSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantMultipleDeleteLoading: false,
  participantMultipleDeleteError: null,
  participantsListUpdateNeeded: true,
});

const deleteMultipleParticipantsFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantMultipleDeleteLoading: false,
  participantMultipleDeleteError: action.participantMultipleDeleteError,
});

const setParticipantsFilter = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsFilter: action.participantsFilter,
});

const importParticipantsStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsImportLoading: true,
});

const importParticipantsSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsImportLoading: false,
  participantsImportError: null,
  participantsImportSuccess: true,
  participantsListUpdateNeeded: true,
});

const importParticipantsFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantsImportLoading: false,
  participantsImportError: action.participantsImportError,
});

const importParticipantsReset = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsImportLoading: false,
  participantsImportError: null,
});

const participantConfirmStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantConfirmLoading: true,
});

const participantConfirmSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantConfirmError: null,
  participantConfirmLoading: false,
  participantConfirmSuccess: true,
});

const participantConfirmFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  participantConfirmError: action.participantConfirmError,
  participantConfirmLoading: false,
});

const downloadFileStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  downloadFileLoading: true,
});

const downloadFileSuccess = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  downloadFileLoading: false,
  downloadFileError: null,
});

const downloadFileFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  downloadFileError: action.downloadFileError,
  downloadFileLoading: false,
});

const downloadAllAssetsStart = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => {
  const loadings = state.downloadAllAssetsLoading;
  loadings[action.id] = true;

  return { ...state, downloadAllAssetsLoading: loadings };
};

const downloadAllAssetsSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => {
  const loadings = state.downloadAllAssetsLoading;
  loadings[action.id] = false;

  return {
    ...state,
    downloadAllAssetsLoading: loadings,
    downloadAllAssetsSuccess: true,
  };
};

const downloadAllAssetsFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => {
  const loadings = state.downloadAllAssetsLoading;
  loadings[action.id] = false;

  return {
    ...state,
    downloadAllAssetsLoading: loadings,
    downloadAllAssetsFail: action.downloadAllAssetsFail,
  };
};

const updateAssetStart = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => {
  const loadings = state.assetUpdateLoading;
  loadings[action.id] = true;

  return {
    ...state,
    assetUpdateLoading: loadings,
  };
};

const updateAssetSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => {
  const loadings = state.assetUpdateLoading;
  loadings[action.id] = false;

  return {
    ...state,
    assetUpdateLoading: loadings,
    assetUpdateError: null,
    assetUpdateSuccess: true,
    participant: action.participant,
  };
};

const updateAssetFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => {
  const loadings = state.assetUpdateLoading;
  loadings[action.id] = false;

  return {
    ...state,
    assetUpdateLoading: loadings,
    assetUpdateError: action.assetUpdateError,
  };
};

const getAssetThumbnailStart = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  getAssetThumbnailLoading: true,
});

const getAssetThumbnailSuccess = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  getAssetThumbnailLoading: false,
  getAssetThumbnailError: null,
  assetThumbnailBlobs: [
    ...state.assetThumbnailBlobs,
    action.assetThumbnailBlob,
  ],
});

const getAssetThumbnailFail = (
  state: ParticipantStateType,
  action: ParticipantActionType,
): ParticipantStateType => ({
  ...state,
  getAssetThumbnailError: action.getAssetThumbnailError,
  getAssetThumbnailLoading: false,
});

const resetStore = (): ParticipantStateType => ({
  participantsList: null,
  participantsListLoading: true,
  participantsListError: null,
  participantsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'firstName',
  },
  participantsListUpdateNeeded: false,
  participant: null,
  participantLoading: true,
  participantSuccess: false,
  participantError: null,
  participantCreateLoading: false,
  participantCreateError: null,
  participantCreateSuccess: false,
  participantUpdateLoading: false,
  participantUpdateError: null,
  participantUpdateSuccess: false,
  participantDeleteLoading: false,
  participantDeleteError: null,
  participantMultipleDeleteLoading: false,
  participantMultipleDeleteError: null,
  participantsImportLoading: false,
  participantsImportError: null,
  participantsImportSuccess: false,
  participantConfirmLoading: false,
  participantConfirmError: null,
  participantConfirmSuccess: false,
  downloadFileLoading: false,
  downloadFileError: null,
  downloadAllAssetsLoading: {},
  downloadAllAssetsSuccess: false,
  downloadAllAssetsFail: null,
  assetUpdateLoading: {},
  assetUpdateError: null,
  assetUpdateSuccess: false,
  getAssetThumbnailLoading: false,
  getAssetThumbnailError: null,
  getAssetThumbnailSuccess: false,
  assetThumbnailBlobs: [],
});

const setUpdateNeeded = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  participantsListUpdateNeeded: true,
});

const resetParticipantAssetSuccessStatus = (
  state: ParticipantStateType,
): ParticipantStateType => ({
  ...state,
  assetUpdateSuccess: false,
});

const reducer = (state = initialState, action: ParticipantActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PARTICIPANTS_START:
      return fetchParticipantsStart(state);
    case actionTypes.FETCH_PARTICIPANTS_SUCCESS:
      return fetchParticipantsSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANTS_FAIL:
      return fetchParticipantsFail(state, action);
    case actionTypes.FETCH_PARTICIPANT_START:
      return fetchParticipantStart(state);
    case actionTypes.FETCH_PARTICIPANT_SUCCESS:
      return fetchParticipantSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANT_FAIL:
      return fetchParticipantFail(state, action);
    case actionTypes.CREATE_PARTICIPANT_START:
      return createParticipantStart(state);
    case actionTypes.CREATE_PARTICIPANT_SUCCESS:
      return createParticipantSuccess(state);
    case actionTypes.CREATE_PARTICIPANT_FAIL:
      return createParticipantFail(state, action);
    case actionTypes.UPDATE_PARTICIPANT_START:
      return updateParticipantStart(state);
    case actionTypes.UPDATE_PARTICIPANT_SUCCESS:
      return updateParticipantSuccess(state);
    case actionTypes.UPDATE_PARTICIPANT_FAIL:
      return updateParticipantFail(state, action);
    case actionTypes.DELETE_PARTICIPANT_START:
      return deleteParticipantStart(state);
    case actionTypes.DELETE_PARTICIPANT_SUCCESS:
      return deleteParticipantSuccess(state);
    case actionTypes.DELETE_PARTICIPANT_FAIL:
      return deleteParticipantFail(state, action);
    case actionTypes.DELETE_MULTIPLE_PARTICIPANT_START:
      return deleteMultipleParticipantsStart(state);
    case actionTypes.DELETE_MULTIPLE_PARTICIPANT_SUCCESS:
      return deleteMultipleParticipantsSuccess(state);
    case actionTypes.DELETE_MULTIPLE_PARTICIPANT_FAIL:
      return deleteMultipleParticipantsFail(state, action);
    case actionTypes.SET_PARTICIPANTS_FILTER:
      return setParticipantsFilter(state, action);
    case actionTypes.IMPORT_PARTICIPANTS_START:
      return importParticipantsStart(state);
    case actionTypes.IMPORT_PARTICIPANTS_SUCCESS:
      return importParticipantsSuccess(state);
    case actionTypes.IMPORT_PARTICIPANTS_FAIL:
      return importParticipantsFail(state, action);
    case actionTypes.IMPORT_PARTICIPANTS_RESET:
      return importParticipantsReset(state);
    case actionTypes.PARTICIPANT_CONFIRM_START:
      return participantConfirmStart(state);
    case actionTypes.PARTICIPANT_CONFIRM_SUCCESS:
      return participantConfirmSuccess(state);
    case actionTypes.PARTICIPANT_CONFIRM_FAIL:
      return participantConfirmFail(state, action);
    case actionTypes.RESET_PARTICIPANT_STORE:
      return resetStore();
    case actionTypes.SET_PARTICIPANTS_UPDATE_NEEDED:
      return setUpdateNeeded(state);
    case actionTypes.DOWNLOAD_FILE_START:
      return downloadFileStart(state);
    case actionTypes.DOWNLOAD_FILE_SUCCESS:
      return downloadFileSuccess(state);
    case actionTypes.DOWNLOAD_FILE_FAIL:
      return downloadFileFail(state, action);
    case actionTypes.DOWNLOAD_ALL_ASSETS_START:
      return downloadAllAssetsStart(state, action);
    case actionTypes.DOWNLOAD_ALL_ASSETS_SUCCESS:
      return downloadAllAssetsSuccess(state, action);
    case actionTypes.DOWNLOAD_ALL_ASSETS_FAIL:
      return downloadAllAssetsFail(state, action);
    case actionTypes.UPDATE_ASSET_START:
      return updateAssetStart(state, action);
    case actionTypes.UPDATE_ASSET_SUCCESS:
      return updateAssetSuccess(state, action);
    case actionTypes.UPDATE_ASSET_FAIL:
      return updateAssetFail(state, action);
    case actionTypes.GET_ASSET_THUMBNAIL_START:
      return getAssetThumbnailStart(state);
    case actionTypes.GET_ASSET_THUMBNAIL_SUCCESS:
      return getAssetThumbnailSuccess(state, action);
    case actionTypes.GET_ASSET_THUMBNAIL_FAIL:
      return getAssetThumbnailFail(state, action);
    case actionTypes.RESET_PARTICIPANT_ASSET_SUCCESS_STATUS:
      return resetParticipantAssetSuccessStatus(state);
    default:
      return state;
  }
};

export default reducer;
