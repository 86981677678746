import React from 'react';
import StoreProvider from './config/StoreProvider/StoreProvider';
import Router from './config/Router/Router';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => (
  <GoogleOAuthProvider clientId="46926192957-g66mcnguf75aslg2bhfhkiqct8u8bb6s.apps.googleusercontent.com">
    <StoreProvider>
      <Router />
      <ToastContainer />
    </StoreProvider>
  </GoogleOAuthProvider>
);

export default App;
