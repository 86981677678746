export const FETCH_COMPANY_OPTIONS_START = 'FETCH_COMPANY_OPTIONS_START';
export const FETCH_COMPANY_OPTIONS_SUCCESS = 'FETCH_COMPANY_OPTIONS_SUCCESS';
export const FETCH_COMPANY_OPTIONS_FAIL = 'FETCH_COMPANY_OPTIONS_FAIL';

export const FETCH_COMPANY_START = 'FETCH_COMPANY_START';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

export const SELECT_COMPANY_START = 'SELECT_COMPANY_START';
export const SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS';
export const SELECT_COMPANY_FAIL = 'SELECT_COMPANY_FAIL';

export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';

export const FETCH_COMPANIES_START = 'FETCH_COMPANIES_START';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL';

export const RESET_COMPANIES_LIST_STORE = 'RESET_COMPANY_LIST_STORE';
export const RESET_COMPANY_STORE = 'RESET_COMPANY_STORE';
export const RESET_COMPANY_CREATE_STORE = 'RESET_COMPANY_CREATE_STORE';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';

export const FETCH_EDIT_COMPANY_START = 'FETCH_EDIT_COMPANY_START';
export const FETCH_EDIT_COMPANY_SUCCESS = 'FETCH_EDIT_COMPANY_SUCCESS';
export const FETCH_EDIT_COMPANY_FAIL = 'FETCH_EDIT_COMPANY_FAIL';

export const DELETE_COMPANY_START = 'DELETE_COMPANY_START';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';

export const ADD_COMPANY_CREDITS_START = 'ADD_COMPANY_CREDITS_START';
export const ADD_COMPANY_CREDITS_SUCCESS = 'ADD_COMPANY_CREDITS_SUCCESS';
export const ADD_COMPANY_CREDITS_FAIL = 'ADD_COMPANY_CREDITS_FAIL';

export const RESET_COMPANY_CREDITS_ADD_STORE =
  'RESET_COMPANY_CREDITS_ADD_STORE';

export type CompanyActionTypes =
  | typeof FETCH_COMPANY_OPTIONS_START
  | typeof FETCH_COMPANY_OPTIONS_SUCCESS
  | typeof FETCH_COMPANY_OPTIONS_FAIL
  | typeof FETCH_COMPANY_START
  | typeof FETCH_COMPANY_SUCCESS
  | typeof FETCH_COMPANY_FAIL
  | typeof SELECT_COMPANY_START
  | typeof SELECT_COMPANY_SUCCESS
  | typeof SELECT_COMPANY_FAIL
  | typeof SET_SELECTED_COMPANY
  | typeof FETCH_COMPANIES_START
  | typeof FETCH_COMPANIES_SUCCESS
  | typeof FETCH_COMPANIES_FAIL
  | typeof RESET_COMPANIES_LIST_STORE
  | typeof RESET_COMPANY_STORE
  | typeof RESET_COMPANY_CREATE_STORE
  | typeof UPDATE_COMPANY_START
  | typeof UPDATE_COMPANY_SUCCESS
  | typeof UPDATE_COMPANY_FAIL
  | typeof CREATE_COMPANY_START
  | typeof CREATE_COMPANY_SUCCESS
  | typeof CREATE_COMPANY_FAIL
  | typeof FETCH_EDIT_COMPANY_START
  | typeof FETCH_EDIT_COMPANY_SUCCESS
  | typeof FETCH_EDIT_COMPANY_FAIL
  | typeof DELETE_COMPANY_START
  | typeof DELETE_COMPANY_SUCCESS
  | typeof DELETE_COMPANY_FAIL
  | typeof ADD_COMPANY_CREDITS_START
  | typeof ADD_COMPANY_CREDITS_SUCCESS
  | typeof ADD_COMPANY_CREDITS_FAIL
  | typeof RESET_COMPANY_CREDITS_ADD_STORE;
