import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  fetchCompanyOptionsStart,
  fetchCompanyOptionsSuccess,
  fetchCompanyOptionsFail,
  fetchCompanyStart,
  fetchCompanySuccess,
  fetchCompanyFail,
  selectCompanyStart,
  selectCompanySuccess,
  selectCompanyFail,
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  updateCompanyStart,
  updateCompanySuccess,
  updateCompanyFail,
  createCompanyStart,
  createCompanySuccess,
  createCompanyFail,
  fetchEditCompanyFail,
  fetchEditCompanyStart,
  fetchEditCompanySuccess,
  deleteCompanyStart,
  deleteCompanySuccess,
  deleteCompanyFail,
  addCompanyCreditsStart,
  addCompanyCreditsSuccess,
  addCompanyCreditsFail,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { ListParams } from '../../hooks/useList/useList';
import { Company } from '../../domain/Company';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';

export type CompanyUpdateRequest = {
  id: number;
  company: Company;
};

export type CompanyCreateRequest = {
  company: Company;
};

export type CompanyCreditsAddRequest = {
  id: number;
  amount: number;
};

export const fetchCompanyOptions =
  (params?: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchCompanyOptionsStart());
    return axios
      .get(`${API_URLS.company}/options`, { params })
      .then((response) => {
        dispatch(fetchCompanyOptionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompanyOptionsFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };

export const fetchCompany = (companyId: number) => (dispatch: Dispatch) => {
  dispatch(fetchCompanyStart());
  return axios
    .get(`${API_URLS.company}/${companyId}`)
    .then((response) => {
      dispatch(fetchCompanySuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCompanyFail(err.response.data.message));
      showToast(err.response.data.message, 'error');
    });
};

export const fetchEditCompany = (companyId: number) => (dispatch: Dispatch) => {
  dispatch(fetchEditCompanyStart());
  return axios
    .get(`${API_URLS.company}/${companyId}`)
    .then((response) => {
      dispatch(fetchEditCompanySuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchEditCompanyFail(err.response.data.message));
      showToast(err.response.data.message, 'error');
    });
};

export const fetchCompanies = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchCompaniesStart());
  return axios
    .get(API_URLS.company, { params })
    .then((response) => {
      dispatch(fetchCompaniesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCompaniesFail(err.response.data.message));
      showToast(err.response.data.message, 'error');
    });
};

export const updateCompany =
  (inputs: CompanyUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateCompanyStart());
    return axios
      .patch(`${API_URLS.company}/${inputs.id}`, {
        ...inputs.company,
      })
      .then(() => {
        dispatch(updateCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCompanyFail(err.response.data.message));
      });
  };

export const createCompany =
  (inputs: CompanyCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createCompanyStart());
    return axios
      .post(API_URLS.company, {
        ...inputs.company,
      })
      .then((response) => {
        dispatch(createCompanySuccess(response.data));
        showToast(translate(intl, 'SUCCESS.COMPANY_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createCompanyFail(err.response.data.message));
      });
  };

export const deleteCompany =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteCompanyStart());
    return axios
      .delete(`${API_URLS.company}/${id}`)
      .then(() => {
        dispatch(deleteCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteCompanyFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const selectCompany =
  (selectedCompanyId: number | null) => (dispatch: Dispatch) => {
    dispatch(selectCompanyStart());
    return axios
      .patch(`${API_URLS.users}/meta-data`, { selectedCompanyId })
      .then((response) => {
        dispatch(selectCompanySuccess(response.data.selectedCompanyId));
      })
      .catch((err) => {
        dispatch(selectCompanyFail(err.response.data.message));
      });
  };

export const addCompanyCredits =
  (inputs: CompanyCreditsAddRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    const { id, amount } = inputs;

    dispatch(addCompanyCreditsStart());
    return axios
      .patch(`${API_URLS.company}/${id}/add-credits`, { amount })
      .then(() => {
        dispatch(addCompanyCreditsSuccess());
        showToast(translate(intl, 'SUCCESS.CREDITS_ADD'), 'success');
      })
      .catch((err) => {
        dispatch(addCompanyCreditsFail(err.response.data.message));
      });
  };
