import React, { ReactElement, ReactNode, useState, MouseEvent } from 'react';
import { Popper } from '@material-ui/core';
import styles from './InfoPopover.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

type Props = {
  base: string | ReactNode;
  text: string;
  placement?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'left-start'
    | 'left'
    | 'left-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end';
};

const InfoPopover = ({ base, text, placement }: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const intl = useIntl();

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const translatedText = translate(intl, text, '');
  return (
    <>
      <div
        className={styles.popoverBaseContainer}
        aria-describedby={id}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {base}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={styles.popoverContainer}
        placement={placement ?? 'right'}
      >
        <div className={styles.popoverText}>{translatedText}</div>
      </Popper>
    </>
  );
};

export default InfoPopover;
