export const routes = {
  homepage: '/',
  auth: {
    main: '/auth',
    refresh: '/auth/refresh',
  },
  participants: '/participants',
  translations: '/translations',
  orders: '/orders',
  certificates: {
    allCertificatesList: '/all-certificates',
    allCertificatesDetails: '/all-certificates/:id/details',
    list: '/certificates',
    details: '/certificates/:id/details',
    externalCertificateForm: '/external-certificate-form',
    participantCertificatesList: '/participant-certificates',
    edit: '/certificates/:id/edit',
  },
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  dataUsage: '/data-usage',
  profile: {
    details: '/profile',
    balance: {
      ordersResponse: '/profile/balance/:orderNumber/response',
    },
  },
  events: {
    allEventsList: '/all-events',
    allEventsListEdit: '/all-events/:id/edit',
    list: '/events',
    create: '/events/new',
    edit: '/events/:id/edit',
  },
  organiserEvents: '/organiser-events',
  lecturerEvents: {
    list: '/lecturer-events',
    material: '/lecturer-events/:id/material',
  },
  organisations: {
    list: '/organisations',
    create: '/organisations/new',
    edit: '/organisations/:id/edit',
  },
  certificateTypes: {
    list: '/certificate-types',
    create: '/certificate-types/new',
    edit: '/certificate-types/:id/edit',
  },
  participantActivities: {
    list: '/participant-activities',
    create: '/participant-activities/new',
    edit: '/participant-activities/:id/edit',
  },
  eventTypes: {
    list: '/event-types',
    create: '/event-types/new',
    edit: '/evet-types/:id/edit',
  },
  eventPurposes: {
    list: '/event-purposes',
    create: '/event-purposes/new',
    edit: '/event-purposes/:id/edit',
  },
  eventRepresentatives: {
    list: '/event-representatives',
    create: '/event-representatives/new',
    edit: '/event-representatives/:id/edit',
  },
  users: {
    allUsersList: '/all-users',
    allUsersListEdit: '/all-users/:id/edit',
    list: '/users',
    create: '/users/new',
    edit: '/users/:id/edit',
  },
  balanceHistory: {
    list: '/balance-history',
  },
  organisers: {
    list: '/organisers',
    registration: '/organisers/register',
  },
  companies: {
    allCompaniesList: '/all-companies',
    allCompaniesListEdit: '/all-companies/:id/edit',
    list: '/companies',
    edit: '/companies/:id/edit',
    create: '/companies/new',
  },
  creditPackages: {
    list: '/credit-packages',
    create: '/credit-packages/new',
    edit: '/credit-packages/:id',
  },
  invoice: '/invoice',
  review: '/review/:reviewRequestToken',
  login: '/login',
  passwordRemind: '/password-remind',
  passwordReset: '/password-reset/:code',
  registration: '/register',
  registrationConfirmation: '/confirm-registration/:code',
  confirmConnection: '/confirm-connection/:token',
  signRedirect: '/sign-redirect-handle',
  contact: '/contact',
  globalSettings: '/global-settings',
  managerConfirmation: '/confirm-manager/:confirmationToken',
  participantConfirmation: '/confirm-participant/:confirmationToken',
};

export default routes;
