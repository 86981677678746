import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import Layout from '../../common/Layout/Layout';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { IntlProvider } from 'react-intl';
import routes from './routes';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, setRole } from '../../store/auth/actions';
import jwtDecode from 'jwt-decode';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User as UserInterface } from '../../domain/User';
import { setSelectedCompany } from '../../store/company/actions';
import { Roles } from '../../domain/Role';

const PrivacyPolicy = React.lazy(
  () => import('../../pages/PrivacyPolicy/PrivacyPolicy'),
);
const TermsOfUse = React.lazy(
  () => import('../../pages/TermsOfUse/TermsOfUse'),
);
const DataUsage = React.lazy(() => import('../../pages/DataUsage/DataUsage'));
const Login = React.lazy(() => import('../../pages/Auth/Login/Login'));
const PasswordRemind = React.lazy(
  () => import('../../pages/Auth/PasswordRemind/PasswordRemind'),
);
const Registration = React.lazy(
  () => import('../../pages/Auth/Registration/Registration'),
);
const RegistrationConfirmation = React.lazy(
  () =>
    import(
      '../../pages/Auth/RegistrationConfirmation/RegistrationConfirmation'
    ),
);
const PasswordReset = React.lazy(
  () => import('../../pages/Auth/PasswordReset/PasswordReset'),
);
const Event = React.lazy(() => import('../../pages/Event/Event'));
const OrganiserEvent = React.lazy(
  () => import('../../pages/Event/OrganiserEvent'),
);
const LecturerEvent = React.lazy(
  () => import('../../pages/Event/LecturerEvent'),
);
const Certificate = React.lazy(
  () => import('../../pages/Certificate/Certificate'),
);
const CertificateDetails = React.lazy(
  () => import('../../pages/Certificate/CertificateDetails/CertificateDetails'),
);

const ExternalCertificateFormPage = React.lazy(
  () =>
    import(
      '../../pages/Certificate/ExternalCertificateFormPage/ExternalCertificateFormPage'
    ),
);

const EventCreate = React.lazy(
  () => import('../../pages/Event/EventCreate/EventCreate'),
);
const EventEdit = React.lazy(
  () => import('../../pages/Event/EventEdit/EventEdit'),
);
const Profile = React.lazy(() => import('../../pages/Profile/Profile'));
const ConfirmConnection = React.lazy(
  () => import('../../pages/Profile/ConfirmConnection/ConfirmConnection'),
);
const Organisation = React.lazy(
  () => import('../../pages/Organisation/Organisation'),
);
const OrganisationCreate = React.lazy(
  () =>
    import('../../pages/Organisation/OrganisationCreate/OrganisationCreate'),
);
const OrganisationEdit = React.lazy(
  () => import('../../pages/Organisation/OrganisationEdit/OrganisationEdit'),
);
const CertificateType = React.lazy(
  () => import('../../pages/CertificateType/CertificateType'),
);
const CertificateTypeCreate = React.lazy(
  () =>
    import(
      '../../pages/CertificateType/CertificateTypeCreate/CertificateTypeCreate'
    ),
);
const CertificateTypeEdit = React.lazy(
  () =>
    import(
      '../../pages/CertificateType/CertificateTypeEdit/CertificateTypeEdit'
    ),
);
const ParticipantActivity = React.lazy(
  () => import('../../pages/ParticipantActivity/ParticipantActivity'),
);
const ParticipantActivityCreate = React.lazy(
  () =>
    import(
      '../../pages/ParticipantActivity/ParticipantActivityCreate/ParticipantActivityCreate'
    ),
);
const ParticipantActivityEdit = React.lazy(
  () =>
    import(
      '../../pages/ParticipantActivity/ParticipantActivityEdit/ParticipantActivityEdit'
    ),
);
const EventType = React.lazy(() => import('../../pages/EventType/EventType'));
const EventTypeCreate = React.lazy(
  () => import('../../pages/EventType/EventTypeCreate/EventTypeCreate'),
);
const EventTypeEdit = React.lazy(
  () => import('../../pages/EventType/EventTypeEdit/EventTypeEdit'),
);
const EventPurpose = React.lazy(
  () => import('../../pages/EventPurpose/EventPurpose'),
);
const EventPurposeCreate = React.lazy(
  () =>
    import('../../pages/EventPurpose/EventPurposeCreate/EventPurposeCreate'),
);
const EventPurposeEdit = React.lazy(
  () => import('../../pages/EventPurpose/EventPurposeEdit/EventPurposeEdit'),
);
const EventRepresentative = React.lazy(
  () => import('../../pages/EventRepresentative/EventRepresentative'),
);
const EventRepresentativeCreate = React.lazy(
  () =>
    import(
      '../../pages/EventRepresentative/EventRepresentativeCreate/EventRepresentativeCreate'
    ),
);
const EventRepresentativeEdit = React.lazy(
  () =>
    import(
      '../../pages/EventRepresentative/EventRepresentativeEdit/EventRepresentativeEdit'
    ),
);
const Order = React.lazy(() => import('../../pages/Order/Order'));
const OrderResponse = React.lazy(
  () => import('../../pages/Order/OrderResponse'),
);
const User = React.lazy(() => import('../../pages/User/User'));
const UserEdit = React.lazy(() => import('../../pages/User/UserEdit/UserEdit'));
const Translation = React.lazy(
  () => import('../../pages/Translation/Translation'),
);
const OrganiserRegistration = React.lazy(
  () => import('../../pages/OrganiserRegistration/OrganiserRegistration'),
);
const Organiser = React.lazy(() => import('../../pages/Organiser/Organiser'));
const Review = React.lazy(() => import('../../pages/Review/Review'));
const SignRedirect = React.lazy(
  () => import('../../pages/SignRedirect/SignRedirect'),
);
const CreditPackagesListPage = React.lazy(
  () =>
    import(
      '../../pages/CreditPackage/CreditPackagesListPage/CreditPackagesListPage'
    ),
);
const CreditPackagesCreatePage = React.lazy(
  () =>
    import(
      '../../pages/CreditPackage/CreditPackagesCreatePage/CreditPackagesCreatePage'
    ),
);
const CreditPackagesEditPage = React.lazy(
  () =>
    import(
      '../../pages/CreditPackage/CreditPackagesEditPage/CreditPackagesEditPage'
    ),
);
const Company = React.lazy(() => import('../../pages/Company/Company'));
const CompanyEdit = React.lazy(
  () => import('../../pages/Company/CompanyEdit/CompanyEdit'),
);
const CompanyCreate = React.lazy(
  () => import('../../pages/Company/CompanyCreate/CompanyCreate'),
);
const UserCreate = React.lazy(
  () => import('../../pages/User/UserCreate/UserCreate'),
);

const Homepage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);
const AllEvents = React.lazy(() => import('../../pages/Event/AllEvents'));
const AllEventsEdit = React.lazy(
  () => import('../../pages/Event/EventEdit/EventEdit'),
);
const AllUsers = React.lazy(() => import('../../pages/User/AllUsers'));
const AllUsersEdit = React.lazy(
  () => import('../../pages/User/UserEdit/UserEdit'),
);
const AllCompanies = React.lazy(
  () => import('../../pages/Company/AllCompanies'),
);
const AllCompaniesEdit = React.lazy(
  () => import('../../pages/Company/CompanyEdit/CompanyEdit'),
);
const AllCertificates = React.lazy(
  () => import('../../pages/Certificate/AllCertificates/AllCertificates'),
);
const AllCertificatesDetails = React.lazy(
  () =>
    import(
      '../../pages/Certificate/AllCertificatesDetails/AllCertificatesDetails'
    ),
);
const GlobalSettings = React.lazy(
  () => import('../../pages/GlobalSettings/GlobalSettings'),
);
const ManagerConfirmation = React.lazy(
  () => import('../../pages/Manager/ManagerConfirmation/ManagerConfirmation'),
);
const ParticipantConfirmation = React.lazy(
  () =>
    import(
      '../../pages/Participant/ParticipantConfirmation/ParticipantConfirmation'
    ),
);
const EventMaterial = React.lazy(
  () => import('../../pages/Event/EventMaterial/EventMaterial'),
);
const ParticipantCertificate = React.lazy(
  () =>
    import(
      '../../pages/Certificate/ParticipantCertificate/ParticipantCertificate'
    ),
);

const ExternalCertificateEdit = React.lazy(
  () =>
    import(
      '../../pages/Certificate/ExternalCertificateEdit/ExternalCertificateEdit'
    ),
);

const ParticipantEventList = React.lazy(
  () => import('../../pages/Event/ParticipantEvents/ParticipantEvents'),
);

const PersonalEventCreate = React.lazy(
  () => import('../../pages/Event/PersonalEventCreate/PersonalEventCreate'),
);

const PersonalEventEdit = React.lazy(
  () => import('../../pages/Event/PersonalEventEdit/PersonalEventEdit'),
);

export type Props = {
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  onTryAutoSignup: () => void;
  languageLoading: boolean;
  onLanguageInit: (locale: string) => void;
  onLanguagesInit: () => void;
  language: Language | null;
  selectedRole: Roles | null;
  selectedLanguage: string;
  onFetchCurrentUser: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onRefreshToken: () => void;
  onLogout: () => void;
  refreshTokenLoading: boolean;
  selectedCompany: number | null;
  isLoading: boolean;
  currentUser: UserInterface | null;
  onCompanySelect: (selectCompanyId: number | null) => void;
  onRoleSelected: (role: Roles | null) => void;
  isSelectedCompanyLoading: boolean;
  isRoleLoading: boolean;
};

export const Router = ({
  isAuthenticated,
  isInitCompleted,
  onTryAutoSignup,
  onLanguageInit,
  onLanguagesInit,
  language,
  selectedRole,
  selectedLanguage,
  onFetchCurrentUser,
  jwtToken,
  lastActionAt,
  onRefreshToken,
  onLogout,
  refreshTokenLoading,
  selectedCompany,
  isLoading,
  currentUser,
  onCompanySelect,
  onRoleSelected,
  isSelectedCompanyLoading,
  isRoleLoading,
}: Props) => {
  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  useEffect(() => {
    onLanguageInit('lt');
  }, [onLanguageInit]);

  useEffect(() => {
    onFetchCurrentUser();
  }, [selectedCompany, selectedRole]);

  useEffect(() => {
    if (isAuthenticated) {
      onCompanySelect(currentUser && currentUser.profile.selectedCompanyId);
      onRoleSelected(currentUser && currentUser.profile.selectedRole);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    onCompanySelect(currentUser && currentUser.profile.selectedCompanyId);
  }, [currentUser?.profile.selectedCompanyId]);

  useEffect(() => {
    onLanguageInit(selectedLanguage);
    onLanguagesInit();
  }, [selectedLanguage]);

  useEffect(() => {
    onRoleSelected(currentUser && currentUser.profile.selectedRole);
  }, [currentUser?.profile.selectedRole]);

  const mappedTranslations = language?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = (selectedRole: Roles) => {
    if (selectedRole === Roles.LECTURER) {
      return (
        <Switch>
          <Route
            path={routes.lecturerEvents.list}
            exact
            component={LecturerEvent}
          />
          <Route
            path={routes.lecturerEvents.material}
            exact
            component={EventMaterial}
          />
          <Route path={routes.privacyPolicy} exact component={PrivacyPolicy} />
          <Route path={routes.termsOfUse} exact component={TermsOfUse} />
          <Route path={routes.dataUsage} exact component={DataUsage} />
          <Route path={routes.profile.details} exact component={Profile} />
          <Route
            path={routes.confirmConnection}
            exact
            component={ConfirmConnection}
          />
          <Route
            path={routes.organisers.registration}
            exact
            component={OrganiserRegistration}
          />
          <Route path={routes.homepage} exact component={Homepage} />
          <Route
            path={routes.managerConfirmation}
            exact
            component={ManagerConfirmation}
          />
          <Route
            path={routes.participantConfirmation}
            exact
            component={ParticipantConfirmation}
          />
          <Redirect to={routes.lecturerEvents.list} />
        </Switch>
      );
    }

    if (selectedRole === Roles.USER) {
      return (
        <Switch>
          <Route
            path={routes.certificates.participantCertificatesList}
            exact
            component={ParticipantCertificate}
          />
          <Route
            path={routes.certificates.details}
            exact
            component={CertificateDetails}
          />
          <Route
            path={routes.certificates.externalCertificateForm}
            exact
            component={ExternalCertificateFormPage}
          />
          <Route
            path={routes.certificates.edit}
            exact
            component={ExternalCertificateEdit}
          />
          <Route
            path={routes.events.list}
            exact
            component={ParticipantEventList}
          />
          <Route
            path={routes.events.create}
            exact
            component={PersonalEventCreate}
          />
          <Route
            path={routes.events.edit}
            exact
            component={PersonalEventEdit}
          />
          <Route path={routes.privacyPolicy} exact component={PrivacyPolicy} />
          <Route path={routes.termsOfUse} exact component={TermsOfUse} />
          <Route path={routes.dataUsage} exact component={DataUsage} />
          <Route path={routes.profile.details} exact component={Profile} />
          <Route
            path={routes.confirmConnection}
            exact
            component={ConfirmConnection}
          />
          <Route
            path={routes.organisers.registration}
            exact
            component={OrganiserRegistration}
          />
          <Route path={routes.homepage} exact component={Homepage} />
          <Route
            path={routes.managerConfirmation}
            exact
            component={ManagerConfirmation}
          />
          <Route
            path={routes.participantConfirmation}
            exact
            component={ParticipantConfirmation}
          />
          <Route
            path={routes.lecturerEvents.material}
            exact
            component={EventMaterial}
          />
          <Redirect to={routes.events.list} />
        </Switch>
      );
    }
  };

  if (isAuthenticated && !currentUser) {
    return <Loader isLoading isFullScreen />;
  }

  return (
    <BrowserRouter basename={routes.homepage}>
      {isLoading && <Loader isLoading isFullScreen />}
      {isInitCompleted && language && !isLoading && !isRoleLoading ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? 'lt'}
          defaultLocale="lt"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                {isAuthenticated && currentUser ? (
                  selectedRole &&
                  (selectedRole === Roles.USER ||
                    selectedRole === Roles.LECTURER) ? (
                    getRoutes(selectedRole)
                  ) : (
                    <Switch>
                      <Route
                        path={routes.events.edit}
                        exact
                        component={EventEdit}
                      />
                      <Route
                        path={routes.events.create}
                        exact
                        component={EventCreate}
                      />
                      <Route
                        path={routes.organisations.edit}
                        exact
                        component={OrganisationEdit}
                      />
                      <Route
                        path={routes.organisations.create}
                        exact
                        component={OrganisationCreate}
                      />
                      <Route
                        path={routes.organisations.list}
                        exact
                        component={Organisation}
                      />
                      <Route
                        path={routes.certificateTypes.edit}
                        exact
                        component={CertificateTypeEdit}
                      />
                      <Route
                        path={routes.certificateTypes.create}
                        exact
                        component={CertificateTypeCreate}
                      />
                      <Route
                        path={routes.certificateTypes.list}
                        exact
                        component={CertificateType}
                      />
                      <Route
                        path={routes.participantActivities.edit}
                        exact
                        component={ParticipantActivityEdit}
                      />
                      <Route
                        path={routes.participantActivities.create}
                        exact
                        component={ParticipantActivityCreate}
                      />
                      <Route
                        path={routes.participantActivities.list}
                        exact
                        component={ParticipantActivity}
                      />
                      <Route
                        path={routes.eventTypes.edit}
                        exact
                        component={EventTypeEdit}
                      />
                      <Route
                        path={routes.eventTypes.create}
                        exact
                        component={EventTypeCreate}
                      />
                      <Route
                        path={routes.eventTypes.list}
                        exact
                        component={EventType}
                      />
                      <Route
                        path={routes.eventPurposes.edit}
                        exact
                        component={EventPurposeEdit}
                      />
                      <Route
                        path={routes.eventPurposes.create}
                        exact
                        component={EventPurposeCreate}
                      />
                      <Route
                        path={routes.eventPurposes.list}
                        exact
                        component={EventPurpose}
                      />
                      <Route
                        path={routes.eventRepresentatives.edit}
                        exact
                        component={EventRepresentativeEdit}
                      />
                      <Route
                        path={routes.eventRepresentatives.create}
                        exact
                        component={EventRepresentativeCreate}
                      />
                      <Route
                        path={routes.eventRepresentatives.list}
                        exact
                        component={EventRepresentative}
                      />
                      <Route
                        path={routes.privacyPolicy}
                        exact
                        component={PrivacyPolicy}
                      />
                      <Route
                        path={routes.confirmConnection}
                        exact
                        component={ConfirmConnection}
                      />
                      <Route
                        path={routes.lecturerEvents.material}
                        exact
                        component={EventMaterial}
                      />
                      {selectedCompany && (
                        <Route
                          path={routes.certificates.list}
                          exact
                          component={Certificate}
                        />
                      )}
                      <Route
                        path={routes.certificates.details}
                        exact
                        component={CertificateDetails}
                      />
                      {selectedCompany && (
                        <Route
                          path={routes.users.list}
                          exact
                          component={User}
                        />
                      )}
                      <Route
                        path={routes.profile.details}
                        exact
                        component={Profile}
                      />
                      <Route
                        path={routes.companies.edit}
                        exact
                        component={CompanyEdit}
                      />
                      <Route
                        path={routes.companies.create}
                        exact
                        component={CompanyCreate}
                      />
                      <Route
                        path={routes.homepage}
                        exact
                        component={Homepage}
                      />
                      <Route
                        path={routes.privacyPolicy}
                        exact
                        component={PrivacyPolicy}
                      />
                      <Route
                        path={routes.termsOfUse}
                        exact
                        component={TermsOfUse}
                      />
                      <Route
                        path={routes.signRedirect}
                        exact
                        component={SignRedirect}
                      />
                      <Route
                        path={routes.managerConfirmation}
                        exact
                        component={ManagerConfirmation}
                      />
                      <Route
                        path={routes.participantConfirmation}
                        exact
                        component={ParticipantConfirmation}
                      />
                      {selectedRole && selectedRole === Roles.ADMIN && (
                        <>
                          <Switch>
                            <Route
                              path={routes.events.allEventsList}
                              exact
                              component={AllEvents}
                            />
                            <Route
                              path={routes.events.allEventsListEdit}
                              exact
                              component={AllEventsEdit}
                            />
                            <Route
                              path={routes.users.allUsersList}
                              exact
                              component={AllUsers}
                            />
                            <Route
                              path={routes.users.allUsersListEdit}
                              exact
                              component={AllUsersEdit}
                            />
                            <Route
                              path={routes.companies.allCompaniesList}
                              exact
                              component={AllCompanies}
                            />
                            <Route
                              path={routes.companies.allCompaniesListEdit}
                              exact
                              component={AllCompaniesEdit}
                            />
                            <Route
                              path={routes.certificates.allCertificatesList}
                              exact
                              component={AllCertificates}
                            />
                            <Route
                              path={routes.certificates.allCertificatesDetails}
                              exact
                              component={AllCertificatesDetails}
                            />
                            {selectedCompany && (
                              <Route
                                path={routes.events.list}
                                exact
                                component={Event}
                              />
                            )}
                            <Route
                              path={routes.users.edit}
                              exact
                              component={UserEdit}
                            />
                            <Route
                              path={routes.translations}
                              exact
                              component={Translation}
                            />
                            <Route
                              path={routes.orders}
                              exact
                              component={Order}
                            />
                            <Route
                              path={routes.organisers.list}
                              exact
                              component={Organiser}
                            />
                            <Route
                              path={routes.organisers.registration}
                              exact
                              component={OrganiserRegistration}
                            />
                            <Route
                              path={routes.signRedirect}
                              exact
                              component={SignRedirect}
                            />
                            <Route
                              path={routes.creditPackages.list}
                              exact
                              component={CreditPackagesListPage}
                            />
                            <Route
                              path={routes.creditPackages.create}
                              exact
                              component={CreditPackagesCreatePage}
                            />
                            <Route
                              path={routes.creditPackages.edit}
                              exact
                              component={CreditPackagesEditPage}
                            />
                            <Route
                              path={routes.users.create}
                              exact
                              component={UserCreate}
                            />
                            {selectedCompany && (
                              <Route
                                path={routes.companies.list}
                                exact
                                component={Company}
                              />
                            )}
                            <Route
                              path={routes.globalSettings}
                              exact
                              component={GlobalSettings}
                            />
                            <Route
                              path={routes.participantConfirmation}
                              exact
                              component={ParticipantConfirmation}
                            />
                            <Route
                              path={routes.certificates.externalCertificateForm}
                              exact
                              component={ExternalCertificateFormPage}
                            />
                            {selectedCompany ||
                            (!selectedCompany && isSelectedCompanyLoading) ? (
                              <Redirect to={routes.events.list} />
                            ) : (
                              <Redirect to={routes.events.allEventsList} />
                            )}
                          </Switch>
                        </>
                      )}
                      {selectedRole &&
                        (selectedRole === Roles.ORGANISER ||
                          selectedRole === Roles.MANAGER) && (
                          <>
                            <Switch>
                              <Route
                                path={routes.organiserEvents}
                                exact
                                component={OrganiserEvent}
                              />
                              <Route
                                path={routes.profile.balance.ordersResponse}
                                exact
                                component={OrderResponse}
                              />
                              <Redirect to={routes.organiserEvents} />
                            </Switch>
                          </>
                        )}
                      {selectedRole &&
                        (selectedRole === Roles.ADMIN ? (
                          <Redirect to={routes.events.allEventsList} />
                        ) : (
                          <Redirect to={routes.organisers.list} />
                        ))}
                    </Switch>
                  )
                ) : (
                  <Switch>
                    <Route path={routes.login} exact component={Login} />
                    <Route
                      path={routes.passwordRemind}
                      exact
                      component={PasswordRemind}
                    />
                    <Route
                      path={routes.registration}
                      exact
                      component={Registration}
                    />
                    <Route
                      path={routes.passwordReset}
                      exact
                      component={PasswordReset}
                    />
                    <Route
                      path={routes.registrationConfirmation}
                      exact
                      component={RegistrationConfirmation}
                    />
                    <Route
                      path={routes.confirmConnection}
                      exact
                      component={ConfirmConnection}
                    />
                    <Route
                      path={routes.privacyPolicy}
                      exact
                      component={PrivacyPolicy}
                    />
                    <Route
                      path={routes.termsOfUse}
                      exact
                      component={TermsOfUse}
                    />
                    <Route
                      path={routes.dataUsage}
                      exact
                      component={DataUsage}
                    />
                    <Route
                      path={routes.managerConfirmation}
                      exact
                      component={ManagerConfirmation}
                    />
                    <Route path={routes.homepage} exact component={Homepage} />
                    <Route path={routes.review} exact component={Review} />
                    <Route
                      path={routes.participantConfirmation}
                      exact
                      component={ParticipantConfirmation}
                    />
                    <Redirect to={routes.login} />
                  </Switch>
                )}
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isInitCompleted: state.auth.isInitCompleted,
  languageLoading: state.language.languageLoading,
  language: state.language.language,
  selectedRole: state.auth.role,
  selectedLanguage: state.auth.selectedLanguage,
  lastActionAt: state.auth.lastStoreActionAt,
  jwtToken: state.auth.jwtToken,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  selectedCompany: state.company.selectedCompany,
  isLoading: state.auth.loginFacebookLoading || state.auth.loginGoogleLoading,
  currentUser: state.user.currentUser,
  isSelectedCompanyLoading: state.company.companySelectLoading,
  isRoleLoading: state.auth.roleSelectLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onLanguageInit: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onCompanySelect: (selectCompanyId: number | null) =>
    dispatch(setSelectedCompany(selectCompanyId)),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLogout: () => dispatch(logout()),
  onRoleSelected: (role: Roles | null) => dispatch(setRole(role)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
