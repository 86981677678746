import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListParams } from '../../hooks/useList/useList';
import { User, UsersList } from '../../domain/User';

export const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = (userList: UsersList) => ({
  type: actionTypes.FETCH_USERS_SUCCESS,
  userList,
});

export const fetchUsersFail = (userListError: HttpError) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  userListError,
});

export const setUsersFilterValues = (userFilter: ListParams) => ({
  type: actionTypes.SET_USERS_FILTER,
  userFilter,
});

export const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFail = (userUpdateError: string) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  userUpdateError,
});

export const createUserStart = () => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccess = () => ({
  type: actionTypes.CREATE_USER_SUCCESS,
});

export const createUserFail = (userCreateError: string) => ({
  type: actionTypes.CREATE_USER_FAIL,
  userCreateError,
});

export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user: User) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  user,
});

export const fetchUserFail = (userError: HttpError) => ({
  type: actionTypes.FETCH_USER_FAIL,
  userError,
});

export const resetUserStore = () => ({
  type: actionTypes.RESET_USER_STORE,
});

export const fetchCurrentUserStart = () => ({
  type: actionTypes.FETCH_CURRENT_USER_START,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_CURRENT_USER_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_CURRENT_USER_FAIL,
});

export const createTopUpStart = () => ({
  type: actionTypes.CREATE_TOP_UP_START,
});

export const createTopUpSuccess = (topUpSuccessRedirect: string) => ({
  type: actionTypes.CREATE_TOP_UP_SUCCESS,
  topUpSuccessRedirect,
});

export const createTopUpFail = (topUpCreateError: string) => ({
  type: actionTypes.CREATE_TOP_UP_FAIL,
  topUpCreateError,
});

export const resetTopUpSuccessStatus = () => ({
  type: actionTypes.RESET_TOP_UP_SUCCESS_STATUS,
});

export const resetUsersListStore = () => ({
  type: actionTypes.RESET_USERS_LIST_STORE,
});

export const fetchPublicUsersNumberStart = () => ({
  type: actionTypes.FETCH_PUBLIC_USERS_NUMBER_START,
});

export const fetchPublicUsersNumberSuccess = (usersNumber: number) => ({
  type: actionTypes.FETCH_PUBLIC_USERS_NUMBER_SUCCESS,
  usersNumber,
});

export const fetchPublicUsersNumberFail = (usersNumberError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_USERS_NUMBER_FAIL,
  usersNumberError,
});
