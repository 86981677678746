export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAIL = 'FETCH_CURRENT_USER_FAIL';

export const RESET_USER_STORE = 'RESET_USER_STORE';

export const SET_USERS_FILTER = 'SET_USERS_FILTER';

export const CREATE_TOP_UP_START = 'CREATE_TOP_UP_START';
export const CREATE_TOP_UP_SUCCESS = 'CREATE_TOP_UP_SUCCESS';
export const CREATE_TOP_UP_FAIL = 'CREATE_TOP_UP_FAIL';

export const FETCH_PUBLIC_USERS_NUMBER_START =
  'FETCH_PUBLIC_USERS_NUMBER_START';
export const FETCH_PUBLIC_USERS_NUMBER_SUCCESS =
  'FETCH_PUBLIC_USERS_NUMBER_SUCCESS';
export const FETCH_PUBLIC_USERS_NUMBER_FAIL = 'FETCH_PUBLIC_USERS_NUMBER_FAIL';

export const RESET_TOP_UP_SUCCESS_STATUS = 'RESET_TOP_UP_SUCCESS_STATUS';
export const RESET_USERS_LIST_STORE = 'RESET_USERS_LIST_STORE';

export type UserActionTypes =
  | typeof FETCH_USERS_START
  | typeof FETCH_USERS_SUCCESS
  | typeof FETCH_USERS_FAIL
  | typeof FETCH_USER_START
  | typeof FETCH_USER_SUCCESS
  | typeof FETCH_USER_FAIL
  | typeof FETCH_CURRENT_USER_START
  | typeof FETCH_CURRENT_USER_SUCCESS
  | typeof FETCH_CURRENT_USER_FAIL
  | typeof RESET_USER_STORE
  | typeof SET_USERS_FILTER
  | typeof UPDATE_USER_START
  | typeof UPDATE_USER_SUCCESS
  | typeof UPDATE_USER_FAIL
  | typeof CREATE_USER_START
  | typeof CREATE_USER_SUCCESS
  | typeof CREATE_USER_FAIL
  | typeof CREATE_TOP_UP_START
  | typeof CREATE_TOP_UP_SUCCESS
  | typeof CREATE_TOP_UP_FAIL
  | typeof RESET_TOP_UP_SUCCESS_STATUS
  | typeof RESET_USERS_LIST_STORE
  | typeof FETCH_PUBLIC_USERS_NUMBER_START
  | typeof FETCH_PUBLIC_USERS_NUMBER_SUCCESS
  | typeof FETCH_PUBLIC_USERS_NUMBER_FAIL;
