import * as actionTypes from './actionTypes';
import { LanguageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Language } from '../../domain/Language';

export type LanguageStateType = {
  language: Language | null;
  languageLoading: boolean;
  languageError: HttpError;
  eventLanguage: Language | null;
  eventLanguageLoading: boolean;
  eventLanguageError: HttpError;
  languages: Language[] | null;
  languagesLoading: boolean;
  languagesError: HttpError;
  certificateLanguages: Language[] | null;
  certificateLanguagesLoading: boolean;
  certificateLanguagesError: HttpError;
};

export type LanguageActionType = LanguageStateType & {
  type: LanguageActionTypes;
};

export const initialState: LanguageStateType = {
  language: null,
  languageLoading: true,
  languageError: null,
  eventLanguage: null,
  eventLanguageLoading: true,
  eventLanguageError: null,
  languages: null,
  languagesLoading: false,
  languagesError: null,
  certificateLanguages: null,
  certificateLanguagesLoading: false,
  certificateLanguagesError: null,
};

const fetchLanguageStart = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languageLoading: true,
});

const fetchLanguageSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  language: action.language,
  languageLoading: false,
  languageError: null,
});

const fetchLanguageFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languageError: action.languageError,
  languageLoading: false,
});

const fetchEventLanguageStart = (
  state: LanguageStateType,
): LanguageStateType => ({
  ...state,
  eventLanguageLoading: true,
});

const fetchEventLanguageSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  eventLanguage: action.eventLanguage,
  eventLanguageLoading: false,
  eventLanguageError: null,
});

const fetchEventLanguageFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  eventLanguageError: action.languageError,
  eventLanguageLoading: false,
});

const fetchLanguagesStart = (state: LanguageStateType): LanguageStateType => ({
  ...state,
  languagesLoading: true,
});

const fetchLanguagesSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languages: action.languages,
  languagesLoading: false,
  languagesError: null,
});

const fetchLanguagesFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  languagesError: action.languagesError,
  languagesLoading: false,
});

const fetchCertificateLanguagesStart = (
  state: LanguageStateType,
): LanguageStateType => ({
  ...state,
  certificateLanguagesLoading: true,
});

const fetchCertificateLanguagesSuccess = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  certificateLanguages: action.languages,
  certificateLanguagesLoading: false,
  certificateLanguagesError: null,
});

const fetchCertificateLanguagesFail = (
  state: LanguageStateType,
  action: LanguageActionType,
): LanguageStateType => ({
  ...state,
  certificateLanguagesError: action.languagesError,
  certificateLanguagesLoading: false,
});

const reducer = (state = initialState, action: LanguageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LANGUAGE_START:
      return fetchLanguageStart(state);
    case actionTypes.FETCH_LANGUAGE_SUCCESS:
      return fetchLanguageSuccess(state, action);
    case actionTypes.FETCH_LANGUAGE_FAIL:
      return fetchLanguageFail(state, action);
    case actionTypes.FETCH_EVENT_LANGUAGE_START:
      return fetchEventLanguageStart(state);
    case actionTypes.FETCH_EVENT_LANGUAGE_SUCCESS:
      return fetchEventLanguageSuccess(state, action);
    case actionTypes.FETCH_EVENT_LANGUAGE_FAIL:
      return fetchEventLanguageFail(state, action);
    case actionTypes.FETCH_LANGUAGES_START:
      return fetchLanguagesStart(state);
    case actionTypes.FETCH_LANGUAGES_SUCCESS:
      return fetchLanguagesSuccess(state, action);
    case actionTypes.FETCH_LANGUAGES_FAIL:
      return fetchLanguagesFail(state, action);
    case actionTypes.FETCH_CERTIFICATE_LANGUAGES_START:
      return fetchCertificateLanguagesStart(state);
    case actionTypes.FETCH_CERTIFICATE_LANGUAGES_SUCCESS:
      return fetchCertificateLanguagesSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_LANGUAGES_FAIL:
      return fetchCertificateLanguagesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
