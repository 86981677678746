import * as actionTypes from './actionTypes';
import { TranslationActionTypes } from './actionTypes';
import { TranslationsList, Translation } from '../../domain/Translation';
import { HttpError } from '../../config/Axios/axios-instance';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type TranslationStateType = {
  translationsList: TranslationsList | null;
  translationsListLoading: boolean;
  translationsListError: HttpError;
  translationsFilter: ListParams;
  updateTranslationLoading: boolean;
  updateTranslationSuccess: boolean;
  updateTranslationError: HttpError;
  updatedTranslation: Translation | null;
};

export type TranslationActionType = TranslationStateType & {
  type: TranslationActionTypes;
};

export const initialState: TranslationStateType = {
  translationsList: null,
  translationsListLoading: true,
  translationsListError: null,
  translationsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'createdAt',
  },
  updateTranslationLoading: false,
  updateTranslationError: null,
  updateTranslationSuccess: false,
  updatedTranslation: null,
};

const fetchTranslationsStart = (
  state: TranslationStateType,
): TranslationStateType => ({
  ...state,
  translationsListLoading: true,
});

const fetchTranslationsSuccess = (
  state: TranslationStateType,
  action: TranslationActionType,
): TranslationStateType => {
  return {
    ...state,
    translationsList: action.translationsList,
    translationsListLoading: false,
    translationsListError: null,
  };
};

const fetchTranslationsFail = (
  state: TranslationStateType,
  action: TranslationActionType,
): TranslationStateType => ({
  ...state,
  translationsListError: action.translationsListError,
  translationsListLoading: false,
});

const updateTranslationStart = (
  state: TranslationStateType,
): TranslationStateType => ({
  ...state,
  updateTranslationLoading: true,
});

const updateTranslationSuccess = (
  state: TranslationStateType,
  action: TranslationActionType,
): TranslationStateType => ({
  ...state,
  updateTranslationSuccess: true,
  updateTranslationLoading: false,
  updateTranslationError: null,
  updatedTranslation: action.updatedTranslation,
  translationsList: state.translationsList
    ? {
        ...state.translationsList,
        result: state.translationsList.result.map((translation) => {
          if (
            action.updatedTranslation &&
            action.updatedTranslation.id === translation.id
          ) {
            return { ...translation, value: action.updatedTranslation.value };
          }

          return translation;
        }),
      }
    : null,
});

const updateTranslationFail = (
  state: TranslationStateType,
  action: TranslationActionType,
): TranslationStateType => ({
  ...state,
  updateTranslationError: action.updateTranslationError,
  updateTranslationLoading: false,
});

const setTranslationsFilter = (
  state: TranslationStateType,
  action: TranslationActionType,
): TranslationStateType => ({
  ...state,
  translationsFilter: action.translationsFilter,
});

const resetTranslationStore = (): TranslationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TranslationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSLATIONS_START:
      return fetchTranslationsStart(state);
    case actionTypes.FETCH_TRANSLATIONS_SUCCESS:
      return fetchTranslationsSuccess(state, action);
    case actionTypes.FETCH_TRANSLATIONS_FAIL:
      return fetchTranslationsFail(state, action);
    case actionTypes.UPDATE_TRANSLATIONS_START:
      return updateTranslationStart(state);
    case actionTypes.UPDATE_TRANSLATIONS_SUCCESS:
      return updateTranslationSuccess(state, action);
    case actionTypes.UPDATE_TRANSLATIONS_FAIL:
      return updateTranslationFail(state, action);
    case actionTypes.SET_TRANSLATIONS_FILTER:
      return setTranslationsFilter(state, action);
    case actionTypes.RESET_TRANSLATIONS_STORE:
      return resetTranslationStore();
    default:
      return state;
  }
};

export default reducer;
