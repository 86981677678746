import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { EventsList, Event, CertificationState } from '../../domain/Event';
import { ListParams } from '../../hooks/useList/useList';
import { AssetBlob } from './reducer';

export const fetchEventsStart = () => ({
  type: actionTypes.FETCH_EVENTS_START,
});

export const fetchEventsSuccess = (eventsList: EventsList) => ({
  type: actionTypes.FETCH_EVENTS_SUCCESS,
  eventsList,
});

export const fetchEventsFail = (eventsListError: HttpError) => ({
  type: actionTypes.FETCH_EVENTS_FAIL,
  eventsListError,
});

export const fetchEventStart = () => ({
  type: actionTypes.FETCH_EVENT_START,
});

export const fetchEventSuccess = (event: Event) => ({
  type: actionTypes.FETCH_EVENT_SUCCESS,
  event,
});

export const fetchEventFail = (eventError: HttpError) => ({
  type: actionTypes.FETCH_EVENT_FAIL,
  eventError,
});

export const createEventStart = () => ({
  type: actionTypes.CREATE_EVENT_START,
});

export const createEventSuccess = (createdEvent: Event) => ({
  type: actionTypes.CREATE_EVENT_SUCCESS,
  createdEvent,
});

export const createEventFail = (eventCreateError: string) => ({
  type: actionTypes.CREATE_EVENT_FAIL,
  eventCreateError,
});

export const updateEventStart = () => ({
  type: actionTypes.UPDATE_EVENT_START,
});

export const updateEventSuccess = () => ({
  type: actionTypes.UPDATE_EVENT_SUCCESS,
});

export const updateEventFail = (eventUpdateError: string) => ({
  type: actionTypes.UPDATE_EVENT_FAIL,
  eventUpdateError,
});

export const deleteEventStart = () => ({
  type: actionTypes.DELETE_EVENT_START,
});

export const deleteEventSuccess = () => ({
  type: actionTypes.DELETE_EVENT_SUCCESS,
});

export const deleteEventFail = (eventDeleteError: string) => ({
  type: actionTypes.DELETE_EVENT_FAIL,
  eventDeleteError,
});

export const setEventsFilterValues = (eventsFilter: ListParams) => ({
  type: actionTypes.SET_EVENTS_FILTER,
  eventsFilter,
});

export const generateCertificatesStart = () => ({
  type: actionTypes.GENERATE_CERTIFICATES_START,
});

export const generateCertificatesSuccess = () => ({
  type: actionTypes.GENERATE_CERTIFICATES_SUCCESS,
});

export const generateCertificatesFail = (
  generateCertificatesError: string,
) => ({
  type: actionTypes.GENERATE_CERTIFICATES_FAIL,
  generateCertificatesError,
});

export const generateParticipantCertificateStart = () => ({
  type: actionTypes.GENERATE_PARTICIPANT_CERTIFICATE_START,
});

export const generateParticipantCertificateSuccess = () => ({
  type: actionTypes.GENERATE_PARTICIPANT_CERTIFICATE_SUCCESS,
});

export const generateParticipantCertificateFail = (
  generateParticipantCertificateError: string,
) => ({
  type: actionTypes.GENERATE_PARTICIPANT_CERTIFICATE_FAIL,
  generateParticipantCertificateError,
});

export const sendCertificatesStart = () => ({
  type: actionTypes.SEND_CERTIFICATES_START,
});

export const sendCertificatesSuccess = () => ({
  type: actionTypes.SEND_CERTIFICATES_SUCCESS,
});

export const sendCertificatesFail = (sendCertificatesError: string) => ({
  type: actionTypes.SEND_CERTIFICATES_FAIL,
  sendCertificatesError,
});

export const sendParticipantCertificateStart = () => ({
  type: actionTypes.SEND_PARTICIPANT_CERTIFICATE_START,
});

export const sendParticipantCertificateSuccess = () => ({
  type: actionTypes.SEND_PARTICIPANT_CERTIFICATE_SUCCESS,
});

export const sendParticipantCertificateFail = (
  sendParticipantCertificateError: string,
) => ({
  type: actionTypes.SEND_PARTICIPANT_CERTIFICATE_FAIL,
  sendParticipantCertificateError,
});

export const sendBulkParticipantsCertificatesStart = () => ({
  type: actionTypes.SEND_PARTICIPANTS_CERTIFICATES_START,
});

export const sendBulkParticipantsCertificatesSuccess = () => ({
  type: actionTypes.SEND_PARTICIPANTS_CERTIFICATES_SUCCESS,
});

export const sendBulkParticipantsCertificatesFail = (
  sendParticipantsCertificatesError: string,
) => ({
  type: actionTypes.SEND_PARTICIPANTS_CERTIFICATES_FAIL,
  sendParticipantsCertificatesError,
});

export const getCertificationStateStart = () => ({
  type: actionTypes.GET_CERTIFICATION_STATE_START,
});

export const getCertificationStateSuccess = (
  certificationState: CertificationState,
) => ({
  type: actionTypes.GET_CERTIFICATION_STATE_SUCCESS,
  certificationState,
});

export const getCertificationStateFail = (certificationStateError: string) => ({
  type: actionTypes.GET_CERTIFICATION_STATE_FAIL,
  certificationStateError,
});

export const resetEventStore = () => ({
  type: actionTypes.RESET_EVENT_STORE,
});

export const setUpdateNeeded = () => ({
  type: actionTypes.SET_EVENTS_UPDATE_NEEDED,
});

export const setCurrentEventData = (inputsData: any) => ({
  type: actionTypes.SET_CURRENT_EVENT_DATA,
  payload: {
    inputsData,
  },
});

export const resetCurrentEventData = () => ({
  type: actionTypes.RESET_CURRENT_EVENT_DATA,
});

export const downloadFileStart = () => ({
  type: actionTypes.DOWNLOAD_FILE_START,
});

export const downloadFileSuccess = () => ({
  type: actionTypes.DOWNLOAD_FILE_SUCCESS,
});

export const downloadFileFail = (downloadFileError: HttpError) => ({
  type: actionTypes.DOWNLOAD_FILE_FAIL,
  downloadFileError,
});

export const updateAssetStart = () => ({
  type: actionTypes.UPDATE_ASSET_START,
});

export const updateAssetSuccess = (event: Event) => ({
  type: actionTypes.UPDATE_ASSET_SUCCESS,
  event,
});

export const updateAssetFail = (assetUpdateError: string) => ({
  type: actionTypes.UPDATE_ASSET_FAIL,
  assetUpdateError,
});

export const resetEventAssetError = () => ({
  type: actionTypes.RESET_EVENT_ASSET_ERROR,
});

export const resetEventAssetSuccessStatus = () => ({
  type: actionTypes.RESET_EVENT_ASSET_SUCCESS_STATUS,
});

export const getAssetThumbnailStart = () => ({
  type: actionTypes.GET_ASSET_THUMBNAIL_START,
});

export const getAssetThumbnailSuccess = (assetThumbnailBlob: AssetBlob) => ({
  type: actionTypes.GET_ASSET_THUMBNAIL_SUCCESS,
  assetThumbnailBlob,
});

export const getAssetThumbnailFail = (getAssetThumbnailError: HttpError) => ({
  type: actionTypes.GET_ASSET_THUMBNAIL_FAIL,
  getAssetThumbnailError,
});

export const resetEventError = () => ({
  type: actionTypes.RESET_EVENT_ERROR,
});
