import axios from '../../config/Axios/axios-instance';
import {
  confirmConnectUserFail,
  confirmConnectUserStart,
  confirmConnectUserSuccess,
  connectUserFail,
  connectUserStart,
  connectUserSuccess,
  deleteConnectedUserFail,
  deleteConnectedUserStart,
  deleteConnectedUserSuccess,
  fetchConnectedUsersFail,
  fetchConnectedUsersStart,
  fetchConnectedUsersSuccess,
  loginFacebookFail,
  loginFacebookStart,
  loginFacebookSuccess,
  loginFail,
  loginGoogleFail,
  loginGoogleStart,
  loginGoogleSuccess,
  loginStart,
  loginSuccess,
  logout,
  refreshTokenFail,
  refreshTokenStart,
  refreshTokenSuccess,
  registerConfirmFail,
  registerConfirmStart,
  registerConfirmSuccess,
  registerFail,
  registerStart,
  registerSuccess,
  remindFail,
  remindStart,
  remindSuccess,
  resetPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
  selectRoleFail,
  selectRoleStart,
  selectRoleSuccess,
  setRole,
  validateCodeFail,
  validateCodeStart,
  validateCodeSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { resetCertificateStore } from '../certificate/actions';
import { resetEventStore } from '../event/actions';
import { resetParticipantStore } from '../participant/actions';
import { API_URLS } from '../globalService';
import { showToast } from '../../utility/toast/toast';
import { resetCompanyStore } from '../company/actions';
import { resetUserStore } from '../user/actions';
import { Roles } from '../../domain/Role';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';

export type LoginGoogleRequest = {
  token: string;
  isLogin?: boolean;
};

export type LoginFacebookRequest = {
  token: string;
  isLogin?: boolean;
};

export type ConnectUserRequest = {
  email: string;
};

export type LoginRequest = {
  email: string;
  password: string;
};

export type RegisterRequest = {
  email: string;
  password: string;
  name: string;
  surname: string;
};

export type RemindRequest = {
  email: string;
};

export type ResetPasswordRequest = {
  email: string;
  password: string;
  code: string;
};

export type ValidateCodeRequest = {
  code: string;
};

export type ConfirmRegistrationRequest = {
  code: string;
};

export const loginWithGoogle =
  (inputs: LoginGoogleRequest) => (dispatch: Dispatch) => {
    dispatch(loginGoogleStart());
    return axios
      .post(`${API_URLS.auth}/google`, {
        ...inputs,
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(setRole(response.data.role));
        dispatch(loginGoogleSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(loginGoogleFail(err.response.data.message));
      });
  };

export const loginWithFacebook =
  (inputs: LoginFacebookRequest) => (dispatch: Dispatch) => {
    dispatch(loginFacebookStart());
    return axios
      .post(`${API_URLS.auth}/facebook`, {
        ...inputs,
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(setRole(response.data.role));
        dispatch(loginFacebookSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(loginFacebookFail(err.response.data.message));
      });
  };

export const connectUser =
  (inputs: ConnectUserRequest) => (dispatch: Dispatch) => {
    dispatch(connectUserStart());
    return axios
      .post(`${API_URLS.auth}/connect-user`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(connectUserSuccess());
      })
      .catch((err) => {
        dispatch(connectUserFail(err.response.data.message));
      });
  };

export const fetchConnectUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchConnectedUsersStart());
  return axios
    .get(`${API_URLS.auth}/connected-users`)
    .then((response) => {
      dispatch(fetchConnectedUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchConnectedUsersFail(err.response.data.message));
      showToast(err.response.data.message, 'error');
    });
};

export const confirmConnectUser = (token: string) => (dispatch: Dispatch) => {
  dispatch(confirmConnectUserStart());
  return axios
    .patch(`${API_URLS.auth}/confirm-connection/${token}`)
    .then((response) => {
      dispatch(confirmConnectUserSuccess());
    })
    .catch((err) => {
      dispatch(confirmConnectUserFail());
    });
};

export const login = (inputs: LoginRequest) => (dispatch: Dispatch) => {
  dispatch(loginStart());
  return axios
    .post(`${API_URLS.auth}/login`, {
      ...inputs,
    })
    .then(async (response) => {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('role', response.data.role);
      dispatch(setRole(response.data.role));
      dispatch(loginSuccess(response.data.token));
    })
    .catch((err) => {
      dispatch(loginFail(err.response?.data?.message));
    });
};

export const register = (inputs: RegisterRequest) => (dispatch: Dispatch) => {
  dispatch(registerStart());
  return axios
    .post(`${API_URLS.auth}/register`, {
      ...inputs,
    })
    .then(async () => {
      dispatch(registerSuccess());
    })
    .catch((err) => {
      dispatch(registerFail(err.response.data.message));
    });
};

export const confirmRegistration =
  (inputs: ConfirmRegistrationRequest) => (dispatch: Dispatch) => {
    dispatch(registerConfirmStart());
    return axios
      .post(`${API_URLS.auth}/confirm-registration`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(setRole(response.data.role));
        dispatch(registerConfirmSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(registerConfirmFail(err.response.data.message));
      });
  };

export const remind = (inputs: RemindRequest) => (dispatch: Dispatch) => {
  dispatch(remindStart());
  return axios
    .post(`${API_URLS.auth}/remind`, {
      ...inputs,
    })
    .then(async (response) => {
      dispatch(remindSuccess());
    })
    .catch((err) => {
      dispatch(remindFail(err.response.data.message));
    });
};

export const resetPassword =
  (inputs: ResetPasswordRequest) => (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    return axios
      .patch(`${API_URLS.auth}/reset-password`, {
        ...inputs,
      })
      .then(async (response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        dispatch(setRole(response.data.role));
        dispatch(resetPasswordSuccess(response.data.token));
      })
      .catch((err) => {
        dispatch(resetPasswordFail(err.response.data.message));
      });
  };

export const validateCode =
  (inputs: ValidateCodeRequest) => (dispatch: Dispatch) => {
    dispatch(validateCodeStart());
    return axios
      .post(`${API_URLS.auth}/validate-confirmation-code`, {
        ...inputs,
      })
      .then(async (response) => {
        dispatch(validateCodeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(validateCodeFail(err.response.data.message));
      });
  };

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(loginGoogleSuccess(token)) : dispatch(logout());
};

export const refreshToken = (action?: any) => (dispatch: Dispatch) => {
  dispatch(refreshTokenStart());
  return axios
    .post(`${API_URLS.auth}/refresh`, { refreshToken })
    .then(async (response) => {
      localStorage.setItem('token', response.data.token);
      dispatch(refreshTokenSuccess(response.data.token));

      if (action) {
        dispatch(action.call());
      }
    })
    .catch((err) => {
      dispatch(refreshTokenFail(err.response.data.message));
    });
};

export const logoutUser = () => (dispatch: Dispatch) => {
  dispatch(resetCertificateStore());
  dispatch(resetEventStore());
  dispatch(resetParticipantStore());
  dispatch(resetCompanyStore());
  dispatch(resetUserStore());
  dispatch(logout());
};

export const deleteConnectedUser =
  (childUserId: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteConnectedUserStart());
    return axios
      .patch(`${API_URLS.auth}/connected-users/${childUserId}`)
      .then(() => {
        dispatch(deleteConnectedUserSuccess());
        showToast(translate(intl, 'SUCCESS.CONNECT_USER_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteConnectedUserFail(err.response.data.message));
      });
  };

export const selectRole = (selectedRole: Roles) => (dispatch: Dispatch) => {
  dispatch(selectRoleStart());
  return axios
    .patch(`${API_URLS.users}/meta-data`, { selectedRole })
    .then((response) => {
      dispatch(selectRoleSuccess(response.data.selectedRole));
    })
    .catch((err) => {
      dispatch(selectRoleFail(err.response.data.message));
    });
};
