export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const FETCH_ORDER_START = 'FETCH_ORDER_START';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAIL = 'FETCH_ORDER_FAIL';

export const SET_ORDERS_FILTER = 'SET_ORDERS_FILTER';

export type OrderActionTypes =
  | typeof FETCH_ORDERS_START
  | typeof FETCH_ORDERS_SUCCESS
  | typeof FETCH_ORDERS_FAIL
  | typeof SET_ORDERS_FILTER
  | typeof FETCH_ORDER_START
  | typeof FETCH_ORDER_SUCCESS
  | typeof FETCH_ORDER_FAIL;
