import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './CompanySelectForm.module.scss';
import { useIntl } from 'react-intl';
import { Company } from '../../../domain/Company';
import { translate } from '../../../utility/messageTranslator/translate';
import Form from '../../../common/Form/Form';
import Button from '../../../common/Button/Button';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { useForm } from '../../../hooks/useForm/useForm';
import { HttpError } from '../../../config/Axios/axios-instance';
import Autocomplete from '../../../common/Autocomplete/Autocomplete';
import { User } from '../../../domain/User';
import * as companyService from '../../../store/company/service';
import { Roles } from '../../../domain/Role';
import { ValidationType } from '../../../utility/validation/validation';
import { FormInputType } from '../../../domain/Common';

type Props = {
  isLoading?: boolean;
  error: HttpError;
  companyOptions: Company[];
  onSuccessSelect?: () => void;
  selectedCompany: number | null;
  currentUser: User | null;
  selectedRole: Roles | null;
  onSelectedCompanyUpdate: (selectedCompanyId: number | null) => void;
};

type FormInputs = {
  companyId: string;
};

export const CompanySelectForm = ({
  isLoading,
  error,
  companyOptions,
  onSuccessSelect,
  selectedCompany,
  currentUser,
  selectedRole,
  onSelectedCompanyUpdate,
}: Props) => {
  const intl = useIntl();

  const getCompanyLabel = (company: Company) => {
    if (!currentUser) {
      return;
    }

    if (
      currentUser.roles.every((role) => role.role === Roles.ORGANISER) ||
      selectedRole === Roles.ORGANISER
    ) {
      return company.name;
    }

    return `${company.owner?.profile?.firstName} ${company.owner?.profile?.lastName} - ${company.name}`;
  };

  const companiesOptions = useMemo(
    () =>
      companyOptions.map((company: Company) => {
        return {
          value: company.id?.toString(),
          label: getCompanyLabel(company),
        };
      }) || [],
    [companyOptions],
  );

  const INPUTS = [
    {
      name: 'companyId',
      label: translate(intl, 'COMPANIES.SELECT_FORM_NAME'),
      type: FormInputType.AUTOCOMPLETE,
      validation: [
        {
          type: ValidationType.REQUIRED,
        },
      ],
      options: companiesOptions,
      value: selectedCompany?.toString() ?? '',
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    onSelectedCompanyUpdate(Number(submitInputs.companyId));
    onSuccessSelect?.();
  };

  const {
    inputs,
    onSubmit,
    onLoseInputFocus,
    onSelectChange,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (companiesOptions) {
      setNewInputObject('companyId', {
        options: companiesOptions,
      });
    }
  }, [companiesOptions]);

  return (
    <div className={styles.companySelectForm}>
      <Form className={styles.form} error={error} onSubmit={onSubmit}>
        <>
          {inputs.map((input) => {
            return (
              <Autocomplete
                key={input.name}
                value={input.value?.toString() ?? ''}
                label={input.label ?? ''}
                errors={input.validationErrors ?? []}
                onBlur={onLoseInputFocus}
                name={input.name}
                options={input.options || []}
                onChange={onSelectChange}
              />
            );
          })}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {translate(intl, 'COMPANIES.SELECT_COMPANY')}
          </Button>
        </>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.company.companyOptionsError,
  isLoading: state.company.companyOptionsLoading,
  companyOptions: state.company.companyOptions,
  selectedCompany: state.company.selectedCompany,
  currentUser: state.user.currentUser,
  selectedRole: state.auth.role,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSelectedCompanyUpdate: (selectedCompanyId: number | null) =>
    dispatch(companyService.selectCompany(selectedCompanyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelectForm);
