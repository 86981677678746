import * as actionTypes from './actionTypes';
import { ContactUsActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type ContactUsStateType = {
  contactUsSuccess: boolean;
  contactUsError: HttpError;
  contactUsLoading: boolean;
};

export type ContactUsActionType = ContactUsStateType & {
  type: ContactUsActionTypes;
};

export const initialState: ContactUsStateType = {
  contactUsSuccess: false,
  contactUsError: null,
  contactUsLoading: false,
};

const contactUsStart = (state: ContactUsStateType): ContactUsStateType => ({
  ...state,
  contactUsLoading: true,
  contactUsSuccess: false,
});

const contactUsSuccess = (state: ContactUsStateType): ContactUsStateType => ({
  ...state,
  contactUsLoading: false,
  contactUsError: null,
  contactUsSuccess: true,
});

const contactUsFail = (
  state: ContactUsStateType,
  action: ContactUsActionType,
): ContactUsStateType => ({
  ...state,
  contactUsError: action.contactUsError,
  contactUsLoading: false,
  contactUsSuccess: false,
});

const resetStore = (): ContactUsStateType => ({
  contactUsSuccess: false,
  contactUsError: null,
  contactUsLoading: false,
});

const reducer = (state = initialState, action: ContactUsActionType) => {
  switch (action.type) {
    case actionTypes.CONTACT_US_START:
      return contactUsStart(state);
    case actionTypes.CONTACT_US_SUCCESS:
      return contactUsSuccess(state);
    case actionTypes.CONTACT_US_FAIL:
      return contactUsFail(state, action);
    case actionTypes.RESET_CONTACT_US_STORE:
      return resetStore();
    default:
      return state;
  }
};

export default reducer;
