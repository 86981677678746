import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  ErrorMessageObject,
  getValidationError,
  ValidationRule,
} from '../../utility/validation/validation';
import _, { trim } from 'lodash';
import { useIntl } from 'react-intl';
import { Asset } from '../../domain/Asset';
import { FormInputType } from '../../domain/Common';

const IGNORED_TRIM = [
  FormInputType.PASSWORD,
  FormInputType.FILE,
  FormInputType.AUTOCOMPLETE,
  FormInputType.CUSTOM,
];

export type NestedInputProps = {
  name: string;
  label?: string;
  type: string;
  value?: string | string[] | any[] | any | null;
};

export type FormInputBlueprint = {
  name: string;
  label?: string;
  popover?: {
    icon: JSX.Element;
    text: string;
  };
  type: FormInputType;
  validation?: Array<ValidationRule>;
  value?: string | string[] | number | File | File[] | any[] | any | null;
  isHidden?: boolean;
  options?: Array<any>;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  capitalize?: boolean;
  locale?: string;
  disableClearable?: boolean;
  multiple?: boolean;
  onlyImages?: boolean;
  onValidation?: (messages: undefined | Array<ErrorMessageObject>) => void;
  backgroundImageProps?: Array<NestedInputProps>;
  creatable?: boolean;
  modalToOpen?: boolean;
  translationNeeded?: boolean;
  topLabel?: string;
  freeSolo?: boolean;
  infoLabel?: string;
  limit?: number;
};

export type FormInput = FormInputBlueprint & {
  isValidatable?: boolean;
  validationErrors?: Array<ErrorMessageObject>;
};

export type FormBehavior = {
  submitOnChange?: boolean;
};

export type FormSubmitInput = {
  [key: string]: string;
};

export const useForm = <T>(
  inputBlueprints: Array<FormInputBlueprint>,
  onFormSubmit?: (inputs: T, sendNotification?: boolean) => void,
  isUpdateNeeded?: boolean,
  onFormValidated?: (state: boolean) => void,
  formBehavior?: FormBehavior,
) => {
  const visibleInputs = useMemo(
    () => inputBlueprints.filter((inputBlueprint) => !inputBlueprint.isHidden),
    [inputBlueprints],
  );

  const [inputs, setInputs] = useState<Array<FormInput>>(visibleInputs);
  const intl = useIntl();

  useEffect(() => {
    if (isUpdateNeeded) {
      setInputs(visibleInputs);
    }
  }, [isUpdateNeeded]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) => {
        return prevInput.name === event.target.name
          ? {
              ...prevInput,
              value: prevInput.capitalize
                ? event.target.value.toUpperCase()
                : event.target.value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(
                    event.target.value,
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput };
      });

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onCheckboxChange = (name: string, value: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onTimeChange = (name: string, value: string) => {
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      );

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const file = event.target.files ? event.target.files[0] : null;

    setInputs((prevState) =>
      prevState.map((prevInput) => {
        return prevInput.name === event.target.name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? [...prevInput.value, file]
                  : file,
              validationErrors: prevInput.isValidatable
                ? getValidationError(
                    event.target.value,
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput };
      }),
    );
  };

  const onSingleOrMultipleFilesChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();

    const files = Array.prototype.slice.call(event.target.files);

    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? [...prevInput.value, ...files]
                  : files,
              validationErrors: prevInput.isValidatable
                ? getValidationError(
                    event.target.value,
                    prevInput.validation,
                    intl,
                  )
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onSelectChange = (
    value: string | string[] | any[],
    name: string,
    freeSolo?: boolean,
  ) => {
    setInputs((prevState) => {
      const inputs = prevState.map((prevInput) => {
        const getOptions = () => {
          const optionLength = value.length - 1;

          const optionExists = prevInput.options?.find(
            (option) => option.value === value[optionLength],
          );

          if (optionExists) {
            return prevInput?.options ? [...prevInput.options] : [];
          }

          if (freeSolo) {
            return prevInput.options && value.length > 0
              ? [
                  ...prevInput.options,
                  { value: value[optionLength], label: value[optionLength] },
                ]
              : [];
          }

          return prevInput.options ? [...prevInput.options] : undefined;
        };

        return prevInput.name === name
          ? {
              ...prevInput,
              value: value,
              options: getOptions(),
              validationErrors: prevInput.isValidatable
                ? getValidationError(value, prevInput.validation, intl)
                : [],
            }
          : { ...prevInput };
      });

      formBehavior?.submitOnChange &&
        onFormSubmit &&
        onFormSubmit(getSubmitInputs(inputs));

      return inputs;
    });
  };

  const onClearInput = (name: string) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: '',
              validationErrors: prevInput.isValidatable
                ? getValidationError('', prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const onLoseInputFocus = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === event.target.name
          ? {
              ...prevInput,
              isValidatable: true,
              validationErrors: getValidationError(
                prevInput.value instanceof File
                  ? ''
                  : (IGNORED_TRIM.some((value) => value === prevInput.type)
                      ? prevInput.value
                      : trim(prevInput.value?.toString())) || '',
                prevInput.validation,
                intl,
              ),
            }
          : { ...prevInput },
      ),
    );
  };

  const setNewInputObject = (name: string, newValue: any) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              ...newValue,
            }
          : { ...prevInput },
      ),
    );
  };

  const onSubmit = (event?: ChangeEvent) => {
    event?.preventDefault();

    const inputsToUpdate = inputs.map((input) => ({
      ...input,
      validationErrors: [],
    }));

    setInputs(inputsToUpdate);

    const validatedInputs = inputsToUpdate.map((input) => {
      const errors =
        input.type === FormInputType.CUSTOM
          ? input.validationErrors
          : getValidationError(
              input.value instanceof File
                ? input.value
                : (IGNORED_TRIM.some((value) => value === input.type)
                    ? input.value
                    : trim(input.value?.toString())) || '',
              input.validation,
              intl,
            );

      input.onValidation?.(errors);

      return {
        ...input,
        validationErrors: errors,
      };
    });

    const hasErrors = _.find(
      validatedInputs,
      (validatedInput) => !_.isEmpty(validatedInput.validationErrors),
    );

    onFormValidated?.(!hasErrors);

    if (hasErrors) {
      setInputs(validatedInputs);
      return;
    }

    onFormSubmit &&
      onFormSubmit(
        Object.assign(
          {},
          ...inputsToUpdate.map((input) => ({
            [input.name]: IGNORED_TRIM.some((value) => value === input.type)
              ? input.value
              : trim(input.value?.toString()),
          })),
        ),
      );
  };

  const onFileDelete = (name: string, file: string | File | Asset) => {
    setInputs((prevState) =>
      prevState.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value:
                prevInput.multiple && Array.isArray(prevInput.value)
                  ? prevInput.value.filter((val: string | File) => {
                      if (file instanceof File && val instanceof File) {
                        return file.name !== val.name;
                      }

                      return val !== file;
                    })
                  : '',
              validationErrors: prevInput.isValidatable
                ? getValidationError('', prevInput.validation, intl)
                : [],
            }
          : { ...prevInput },
      ),
    );
  };

  const getSubmitInputs = (submitInputs: Array<FormInput>) =>
    Object.assign(
      {},
      ...submitInputs.map((input) => ({
        [input.name]:
          IGNORED_TRIM.some((value) => value === input.type) ||
          Array.isArray(input.value)
            ? input.value
            : trim(input.value?.toString()),
      })),
    );

  const setFieldValidationMessages = (
    name: string,
    message: Array<ErrorMessageObject> | undefined,
  ) => {
    if (!message || !message.length) {
      return;
    }

    setInputs((prevState) =>
      prevState.map((prevInput) => {
        return prevInput.name === name
          ? {
              ...prevInput,
              validationErrors: [
                ...(prevInput.validationErrors || []),
                ...(Array.isArray(message) ? message : [message]),
              ],
            }
          : { ...prevInput };
      }),
    );
  };

  const isFormHasErrors = () => {
    const validatedInputs = inputs.map((input: FormInput) => {
      const errors =
        input.type === FormInputType.CUSTOM
          ? input.validationErrors
          : getValidationError(
              input.value instanceof File
                ? input.value
                : (IGNORED_TRIM.some((value) => value === input.type)
                    ? input.value
                    : trim(input.value?.toString())) || '',
              input.validation,
              intl,
            );

      return {
        ...input,
        validationErrors: errors,
      };
    });

    const hasErrors = _.find(
      validatedInputs,
      (validatedInput) => !_.isEmpty(validatedInput.validationErrors),
    );

    if (hasErrors) {
      setInputs(validatedInputs);
      return true;
    }

    return false;
  };

  return {
    inputs,
    onInputChange,
    onCheckboxChange,
    onTimeChange,
    onFileChange,
    onSingleOrMultipleFilesChange,
    onLoseInputFocus,
    onSubmit,
    onClearInput,
    setNewInputObject,
    onSelectChange,
    onFileDelete,
    getSubmitInputs,
    setFieldValidationMessages,
    isFormHasErrors,
  };
};
