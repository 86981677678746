import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  ParticipantActivity,
  ParticipantActivitiesList,
} from '../../domain/ParticipantActivity';
import { ListParams } from '../../hooks/useList/useList';

export const fetchParticipantActivitiesStart = () => ({
  type: actionTypes.FETCH_PARTICIPANT_ACTIVITIES_START,
});

export const fetchParticipantActivitiesSuccess = (
  participantActivitiesList: ParticipantActivitiesList,
) => ({
  type: actionTypes.FETCH_PARTICIPANT_ACTIVITIES_SUCCESS,
  participantActivitiesList,
});

export const fetchParticipantActivitiesFail = (
  participantActivitiesListError: HttpError,
) => ({
  type: actionTypes.FETCH_PARTICIPANT_ACTIVITIES_FAIL,
  participantActivitiesListError,
});

export const fetchParticipantActivityStart = () => ({
  type: actionTypes.FETCH_PARTICIPANT_ACTIVITY_START,
});

export const fetchParticipantActivitySuccess = (
  participantActivity: ParticipantActivity,
) => ({
  type: actionTypes.FETCH_PARTICIPANT_ACTIVITY_SUCCESS,
  participantActivity,
});

export const fetchParticipantActivityFail = (
  participantActivityError: HttpError,
) => ({
  type: actionTypes.FETCH_PARTICIPANT_ACTIVITY_FAIL,
  participantActivityError,
});

export const createParticipantActivityStart = () => ({
  type: actionTypes.CREATE_PARTICIPANT_ACTIVITY_START,
});

export const createParticipantActivitySuccess = (
  createdParticipantActivity: ParticipantActivity,
) => ({
  type: actionTypes.CREATE_PARTICIPANT_ACTIVITY_SUCCESS,
  createdParticipantActivity,
});

export const createParticipantActivityFail = (
  participantActivityCreateError: string,
) => ({
  type: actionTypes.CREATE_PARTICIPANT_ACTIVITY_FAIL,
  participantActivityCreateError,
});

export const updateParticipantActivityStart = () => ({
  type: actionTypes.UPDATE_PARTICIPANT_ACTIVITY_START,
});

export const updateParticipantActivitySuccess = () => ({
  type: actionTypes.UPDATE_PARTICIPANT_ACTIVITY_SUCCESS,
});

export const updateParticipantActivityFail = (
  participantActivityUpdateError: string,
) => ({
  type: actionTypes.UPDATE_PARTICIPANT_ACTIVITY_FAIL,
  participantActivityUpdateError,
});

export const deleteParticipantActivityStart = () => ({
  type: actionTypes.DELETE_PARTICIPANT_ACTIVITY_START,
});

export const deleteParticipantActivitySuccess = () => ({
  type: actionTypes.DELETE_PARTICIPANT_ACTIVITY_SUCCESS,
});

export const deleteParticipantActivityFail = (
  participantActivityDeleteError: string,
) => ({
  type: actionTypes.DELETE_PARTICIPANT_ACTIVITY_FAIL,
  participantActivityDeleteError,
});

export const setParticipantActivitiesFilterValues = (
  participantActivitiesFilter: ListParams,
) => ({
  type: actionTypes.SET_PARTICIPANT_ACTIVITIES_FILTER,
  participantActivitiesFilter,
});

export const resetParticipantActivityStore = () => ({
  type: actionTypes.RESET_PARTICIPANT_ACTIVITY_STORE,
});

export const setUpdateNeeded = () => ({
  type: actionTypes.SET_PARTICIPANT_ACTIVITY_UPDATE_NEEDED,
});

export const resetCreatedParticipantActivity = () => ({
  type: actionTypes.RESET_CREATED_PARTICIPANT_ACTIVITY,
});
