import { SelectChangeEvent } from '@material-ui/core/Select';
import { ChangeEvent, useEffect, useState } from 'react';
import { parseInputValue } from '../../utility/inputParser/inputParser';
import { Roles } from '../../domain/Role';
import { ListType } from '../../domain/Common';
import { CertificateType } from '../../domain/Certificate';
import { IntlShape } from 'react-intl';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export type SortBy = string | null;
export type SortDirection = typeof SORT_ASC | typeof SORT_DESC;

export const DEFAULT_PAGE = 0;
export const DEFAULT_LIMIT = 10;

const TIMEOUT_BETWEEN_FETCH = 300;

export const DEFAULT_LIST_PARAMS = {
  search: '',
  sortBy: null,
  sortDirection: SORT_ASC,
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
} as ListParams;

export type ListParams = {
  search?: string;
  sortBy?: string | null;
  sortDirection?: SortDirection;
  page?: number;
  limit?: number;
  selectedRole?: Roles | null;
  userId?: number;
  companyId?: number | null;
  listType?: ListType;
  certificateType?: CertificateType;
};

export const useList = (
  isUpdateNeeded: boolean,
  onFetch: (params: ListParams, intl: IntlShape) => void,
  intl: IntlShape,
  defaultParams?: ListParams,
) => {
  const [tableParams, setTableParams] = useState<ListParams>({
    ...DEFAULT_LIST_PARAMS,
    ...defaultParams,
  });

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      onFetch(
        {
          ...tableParams,
        },
        intl,
      );
    }, TIMEOUT_BETWEEN_FETCH);

    return () => clearTimeout(timeoutRef);
  }, [tableParams]);

  useEffect(() => {
    isUpdateNeeded && onFetch(tableParams, intl);
  }, [isUpdateNeeded, tableParams]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
    setTableParams((prevState) => ({
      ...prevState,
      page: DEFAULT_PAGE,
      search: parseInputValue(event),
    }));

  const onCategoriesChange = (event: SelectChangeEvent) =>
    setTableParams((prevState) => {
      const {
        target: { value },
      } = event;
      return {
        ...prevState,
        page: DEFAULT_PAGE,
        categoryIds: typeof value === 'string' ? value.split(',') : value,
      };
    });

  const onSearchSubmit = () =>
    setTableParams((prevState) => ({
      ...prevState,
      page: DEFAULT_PAGE,
    }));

  const onSort = (sortBy: SortBy, sortDirection: SortDirection) =>
    setTableParams((prevState) => ({
      ...prevState,
      sortBy,
      sortDirection,
      page: DEFAULT_PAGE,
    }));

  const onPageChange = (event: any | null, page: number) => {
    event?.preventDefault();
    setTableParams((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const onLimitChange = (event: any) => {
    event.preventDefault();
    setTableParams((prevState) => ({
      ...prevState,
      limit: event.target.value,
      page: DEFAULT_PAGE,
    }));
  };

  return {
    tableParams,
    setTableParams,
    onSearchChange,
    onSearchSubmit,
    onSort,
    onPageChange,
    onLimitChange,
    onCategoriesChange,
  };
};
