import * as actionTypes from './actionTypes';
import { OrganiserActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Organiser, OrganisersList } from '../../domain/Organiser';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type OrganiserStateType = {
  organiser: Organiser | null;
  organiserLoading: boolean;
  organiserError: HttpError;
  organiserCreateLoading: boolean;
  organiserCreateError: HttpError;
  organiserCreateSuccess: boolean;
  organisersList: OrganisersList | null;
  organisersListLoading: boolean;
  organisersListError: HttpError;
  organisersFilter: ListParams;
  organiserStatusUpdateLoading: boolean;
  organiserStatusUpdateError: HttpError;
  organiserStatusUpdateSuccess: boolean;
  organiserListUpdateNeeded: boolean;
};

export type OrganiserActionType = OrganiserStateType & {
  type: OrganiserActionTypes;
};

export const initialState: OrganiserStateType = {
  organiser: null,
  organiserLoading: false,
  organiserError: null,
  organiserCreateLoading: false,
  organiserCreateError: null,
  organiserCreateSuccess: false,
  organisersList: null,
  organisersListLoading: true,
  organisersListError: null,
  organisersFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'organiser.createdAt',
    sortDirection: 'DESC',
  },
  organiserStatusUpdateLoading: false,
  organiserStatusUpdateError: null,
  organiserStatusUpdateSuccess: false,
  organiserListUpdateNeeded: false,
};

const fetchOrganiserStart = (
  state: OrganiserStateType,
): OrganiserStateType => ({
  ...state,
  organiserLoading: true,
  organiserCreateSuccess: false,
  organiserCreateError: null,
});

const fetchOrganiserSuccess = (
  state: OrganiserStateType,
  action: OrganiserActionType,
): OrganiserStateType => ({
  ...state,
  organiser: action.organiser,
  organiserLoading: false,
  organiserError: null,
});

const fetchOrganiserFail = (
  state: OrganiserStateType,
  action: OrganiserActionType,
): OrganiserStateType => ({
  ...state,
  organiserError: action.organiserError,
  organiserLoading: false,
});

const createOrganiserStart = (
  state: OrganiserStateType,
): OrganiserStateType => ({
  ...state,
  organiserCreateLoading: true,
});

const createOrganiserSuccess = (
  state: OrganiserStateType,
): OrganiserStateType => ({
  ...state,
  organiserCreateLoading: false,
  organiserCreateError: null,
  organiserCreateSuccess: true,
});

const createOrganiserFail = (
  state: OrganiserStateType,
  action: OrganiserActionType,
): OrganiserStateType => ({
  ...state,
  organiserCreateLoading: false,
  organiserCreateError: action.organiserCreateError,
});

const fetchOrganisersStart = (
  state: OrganiserStateType,
): OrganiserStateType => ({
  ...state,
  organisersListLoading: true,
});

const fetchOrganisersSuccess = (
  state: OrganiserStateType,
  action: OrganiserActionType,
): OrganiserStateType => ({
  ...state,
  organisersList: action.organisersList,
  organisersListLoading: false,
  organisersListError: null,
});

const fetchOrganisersFail = (
  state: OrganiserStateType,
  action: OrganiserActionType,
): OrganiserStateType => ({
  ...state,
  organisersListError: action.organisersListError,
  organisersListLoading: false,
});

const updateOrganiserStatusStart = (
  state: OrganiserStateType,
): OrganiserStateType => ({
  ...state,
  organiserStatusUpdateLoading: true,
  organiserListUpdateNeeded: false,
});

const updateOrganiserStatusSuccess = (
  state: OrganiserStateType,
): OrganiserStateType => ({
  ...state,
  organiserStatusUpdateLoading: false,
  organiserStatusUpdateError: null,
  organiserStatusUpdateSuccess: true,
  organiserListUpdateNeeded: true,
});

const updateOrganiserStatusFail = (
  state: OrganiserStateType,
  action: OrganiserActionType,
): OrganiserStateType => ({
  ...state,
  organiserStatusUpdateLoading: false,
  organiserStatusUpdateError: action.organiserStatusUpdateError,
});

const reducer = (state = initialState, action: OrganiserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORGANISER_START:
      return fetchOrganiserStart(state);
    case actionTypes.FETCH_ORGANISER_SUCCESS:
      return fetchOrganiserSuccess(state, action);
    case actionTypes.FETCH_ORGANISER_FAIL:
      return fetchOrganiserFail(state, action);
    case actionTypes.CREATE_ORGANISER_START:
      return createOrganiserStart(state);
    case actionTypes.CREATE_ORGANISER_SUCCESS:
      return createOrganiserSuccess(state);
    case actionTypes.CREATE_ORGANISER_FAIL:
      return createOrganiserFail(state, action);
    case actionTypes.FETCH_ORGANISERS_START:
      return fetchOrganisersStart(state);
    case actionTypes.FETCH_ORGANISERS_SUCCESS:
      return fetchOrganisersSuccess(state, action);
    case actionTypes.FETCH_ORGANISERS_FAIL:
      return fetchOrganisersFail(state, action);
    case actionTypes.UPDATE_ORGANISER_STATUS_START:
      return updateOrganiserStatusStart(state);
    case actionTypes.UPDATE_ORGANISER_STATUS_SUCCESS:
      return updateOrganiserStatusSuccess(state);
    case actionTypes.UPDATE_ORGANISER_STATUS_FAIL:
      return updateOrganiserStatusFail(state, action);
    default:
      return state;
  }
};

export default reducer;
