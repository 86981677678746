export const FETCH_ORGANISATIONS_START = 'FETCH_ORGANISATIONS_START';
export const FETCH_ORGANISATIONS_SUCCESS = 'FETCH_ORGANISATIONS_SUCCESS';
export const FETCH_ORGANISATIONS_FAIL = 'FETCH_ORGANISATIONS_FAIL';

export const FETCH_ORGANISATION_START = 'FETCH_ORGANISATION_START';
export const FETCH_ORGANISATION_SUCCESS = 'FETCH_ORGANISATION_SUCCESS';
export const FETCH_ORGANISATION_FAIL = 'FETCH_ORGANISATION_FAIL';

export const CREATE_ORGANISATION_START = 'CREATE_ORGANISATION_START';
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';
export const CREATE_ORGANISATION_FAIL = 'CREATE_ORGANISATION_FAIL';

export const UPDATE_ORGANISATION_START = 'UPDATE_ORGANISATION_START';
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const UPDATE_ORGANISATION_FAIL = 'UPDATE_ORGANISATION_FAIL';

export const DELETE_ORGANISATION_START = 'DELETE_ORGANISATION_START';
export const DELETE_ORGANISATION_SUCCESS = 'DELETE_ORGANISATION_SUCCESS';
export const DELETE_ORGANISATION_FAIL = 'DELETE_ORGANISATION_FAIL';

export const SET_ORGANISATIONS_FILTER = 'SET_ORGANISATIONS_FILTER';

export const RESET_ORGANISATION_STORE = 'RESET_ORGANISATION_STORE';

export const SET_ORGANISATION_UPDATE_NEEDED = 'SET_ORGANISATION_UPDATE_NEEDED';

export const RESET_CREATED_ORGANISATION = 'RESET_CREATED_ORGANISATION';

export type OrganisationActionTypes =
  | typeof FETCH_ORGANISATIONS_START
  | typeof FETCH_ORGANISATIONS_SUCCESS
  | typeof FETCH_ORGANISATIONS_FAIL
  | typeof FETCH_ORGANISATION_START
  | typeof FETCH_ORGANISATION_SUCCESS
  | typeof FETCH_ORGANISATION_FAIL
  | typeof CREATE_ORGANISATION_START
  | typeof CREATE_ORGANISATION_SUCCESS
  | typeof CREATE_ORGANISATION_FAIL
  | typeof UPDATE_ORGANISATION_START
  | typeof UPDATE_ORGANISATION_SUCCESS
  | typeof UPDATE_ORGANISATION_FAIL
  | typeof DELETE_ORGANISATION_START
  | typeof DELETE_ORGANISATION_SUCCESS
  | typeof DELETE_ORGANISATION_FAIL
  | typeof SET_ORGANISATIONS_FILTER
  | typeof RESET_ORGANISATION_STORE
  | typeof SET_ORGANISATION_UPDATE_NEEDED
  | typeof RESET_CREATED_ORGANISATION;
