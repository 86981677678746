import axios from '../../config/Axios/axios-instance';
import {
  createCertificateTypeFail,
  createCertificateTypeStart,
  createCertificateTypeSuccess,
  deleteCertificateTypeFail,
  deleteCertificateTypeStart,
  deleteCertificateTypeSuccess,
  fetchCertificateTypeFail,
  fetchCertificateTypesFail,
  fetchCertificateTypesStart,
  fetchCertificateTypesSuccess,
  fetchCertificateTypeStart,
  fetchCertificateTypeSuccess,
  setCertificateTypesFilterValues,
  setUpdateNeeded,
  updateCertificateTypeFail,
  updateCertificateTypeStart,
  updateCertificateTypeSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

export type CertificateTypeCreateRequest = {
  name: string;
};

export type CertificateTypeUpdateRequest = {
  id: number;
  name: string;
};

export const fetchCertificateTypes =
  (params?: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchCertificateTypesStart());

    if (params && saveFilters) {
      dispatch(setCertificateTypesFilterValues(params));
    }

    return axios
      .get(API_URLS.certificateType, { params })
      .then((response) => {
        dispatch(fetchCertificateTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCertificateTypesFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const fetchCertificateType =
  (certificateTypeId: number) => (dispatch: Dispatch) => {
    dispatch(fetchCertificateTypeStart());
    return axios
      .get(`${API_URLS.certificateType}/${certificateTypeId}`)
      .then((response) => {
        dispatch(fetchCertificateTypeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCertificateTypeFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const createCertificateType =
  (inputs: CertificateTypeCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setUpdateNeeded());
    dispatch(createCertificateTypeStart());
    return axios
      .post(API_URLS.certificateType, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createCertificateTypeSuccess(response.data));
        showToast(
          translate(intl, 'SUCCESS.CERTIFICATE_TYPE_CREATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(createCertificateTypeFail(err.response.data.message));
      });
  };

export const updateCertificateType =
  (inputs: CertificateTypeUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateCertificateTypeStart());
    const { id, ...updatedCertificateType } = inputs;
    return axios
      .patch(`${API_URLS.certificateType}/${id}`, {
        ...updatedCertificateType,
      })
      .then(() => {
        dispatch(updateCertificateTypeSuccess());
        dispatch(setUpdateNeeded());
        showToast(
          translate(intl, 'SUCCESS.CERTIFICATE_TYPE_UPDATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(updateCertificateTypeFail(err.response.data.message));
      });
  };

export const deleteCertificateType =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteCertificateTypeStart());
    return axios
      .delete(`${API_URLS.certificateType}/${id}`)
      .then(() => {
        dispatch(deleteCertificateTypeSuccess());
        dispatch(setUpdateNeeded());
        showToast(
          translate(intl, 'SUCCESS.CERTIFICATE_TYPE_DELETE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteCertificateTypeFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };
