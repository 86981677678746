import * as actionTypes from './actionTypes';
import { CertificateTypeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  CertificateType,
  CertificateTypesList,
} from '../../domain/CertificateType';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type CertificateTypeStateType = {
  certificateTypesList: CertificateTypesList | null;
  certificateTypesListLoading: boolean;
  certificateTypesListError: HttpError;
  certificateTypesFilter: ListParams;
  certificateTypesListUpdateNeeded: boolean;
  certificateType: CertificateType | null;
  certificateTypeLoading: boolean;
  certificateTypeError: HttpError;
  certificateTypeCreateLoading: boolean;
  certificateTypeCreateError: HttpError;
  certificateTypeCreateSuccess: boolean;
  certificateTypeUpdateLoading: boolean;
  certificateTypeUpdateError: HttpError;
  certificateTypeUpdateSuccess: boolean;
  certificateTypeDeleteLoading: boolean;
  certificateTypeDeleteError: HttpError;
  createdCertificateType: CertificateType | null;
};

export type CertificateTypeActionType = CertificateTypeStateType & {
  type: CertificateTypeActionTypes;
};

export const initialState: CertificateTypeStateType = {
  certificateTypesList: null,
  certificateTypesListLoading: true,
  certificateTypesListError: null,
  certificateTypesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  certificateTypesListUpdateNeeded: false,
  certificateType: null,
  certificateTypeLoading: false,
  certificateTypeError: null,
  certificateTypeCreateLoading: false,
  certificateTypeCreateError: null,
  certificateTypeCreateSuccess: false,
  certificateTypeUpdateLoading: false,
  certificateTypeUpdateError: null,
  certificateTypeUpdateSuccess: false,
  certificateTypeDeleteLoading: false,
  certificateTypeDeleteError: null,
  createdCertificateType: null,
};

const fetchCertificateTypesStart = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypesListLoading: true,
  certificateTypeUpdateSuccess: false,
  certificateTypeCreateSuccess: false,
  certificateTypeDeleteError: null,
  certificateType: null,
});

const fetchCertificateTypesSuccess = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypesList: action.certificateTypesList,
  certificateTypesListLoading: false,
  certificateTypesListError: null,
  certificateTypesListUpdateNeeded: false,
});

const fetchCertificateTypesFail = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypesListError: action.certificateTypesListError,
  certificateTypesListLoading: false,
});

const fetchCertificateTypeStart = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeLoading: true,
  certificateTypeUpdateSuccess: false,
  certificateTypeCreateSuccess: false,
  certificateTypeCreateError: null,
  certificateTypeUpdateError: null,
});

const fetchCertificateTypeSuccess = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateType: action.certificateType,
  certificateTypeLoading: false,
  certificateTypeError: null,
});

const fetchCertificateTypeFail = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeError: action.certificateTypeError,
  certificateTypeLoading: false,
});

const createCertificateTypeStart = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeCreateLoading: true,
});

const createCertificateTypeSuccess = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeCreateLoading: false,
  certificateTypeCreateError: null,
  certificateTypeCreateSuccess: true,
  certificateTypesListUpdateNeeded: true,
  createdCertificateType: action.createdCertificateType,
});

const createCertificateTypeFail = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeCreateLoading: false,
  certificateTypeCreateError: action.certificateTypeCreateError,
});

const updateCertificateTypeStart = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeUpdateLoading: true,
});

const updateCertificateTypeSuccess = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeUpdateLoading: false,
  certificateTypeUpdateError: null,
  certificateTypeUpdateSuccess: true,
  certificateTypesListUpdateNeeded: true,
});

const updateCertificateTypeFail = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeUpdateLoading: false,
  certificateTypeUpdateError: action.certificateTypeUpdateError,
});

const deleteCertificateTypeStart = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeDeleteLoading: true,
});

const deleteCertificateTypeSuccess = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeDeleteLoading: false,
  certificateTypeDeleteError: null,
  certificateTypesListUpdateNeeded: true,
});

const deleteCertificateTypeFail = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypeDeleteLoading: false,
  certificateTypeDeleteError: action.certificateTypeDeleteError,
});

const setCertificateTypesFilter = (
  state: CertificateTypeStateType,
  action: CertificateTypeActionType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypesFilter: action.certificateTypesFilter,
});

const resetStore = (): CertificateTypeStateType => ({
  certificateTypesList: null,
  certificateTypesListLoading: true,
  certificateTypesListError: null,
  certificateTypesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  certificateTypesListUpdateNeeded: false,
  certificateType: null,
  certificateTypeLoading: false,
  certificateTypeError: null,
  certificateTypeCreateLoading: false,
  certificateTypeCreateError: null,
  certificateTypeCreateSuccess: false,
  certificateTypeUpdateLoading: false,
  certificateTypeUpdateError: null,
  certificateTypeUpdateSuccess: false,
  certificateTypeDeleteLoading: false,
  certificateTypeDeleteError: null,
  createdCertificateType: null,
});

const setUpdateNeeded = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  certificateTypesListUpdateNeeded: true,
});

const resetCreatedCertificateType = (
  state: CertificateTypeStateType,
): CertificateTypeStateType => ({
  ...state,
  createdCertificateType: null,
});

const reducer = (state = initialState, action: CertificateTypeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CERTIFICATE_TYPES_START:
      return fetchCertificateTypesStart(state);
    case actionTypes.FETCH_CERTIFICATE_TYPES_SUCCESS:
      return fetchCertificateTypesSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_TYPES_FAIL:
      return fetchCertificateTypesFail(state, action);
    case actionTypes.FETCH_CERTIFICATE_TYPE_START:
      return fetchCertificateTypeStart(state);
    case actionTypes.FETCH_CERTIFICATE_TYPE_SUCCESS:
      return fetchCertificateTypeSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_TYPE_FAIL:
      return fetchCertificateTypeFail(state, action);
    case actionTypes.CREATE_CERTIFICATE_TYPE_START:
      return createCertificateTypeStart(state);
    case actionTypes.CREATE_CERTIFICATE_TYPE_SUCCESS:
      return createCertificateTypeSuccess(state, action);
    case actionTypes.CREATE_CERTIFICATE_TYPE_FAIL:
      return createCertificateTypeFail(state, action);
    case actionTypes.UPDATE_CERTIFICATE_TYPE_START:
      return updateCertificateTypeStart(state);
    case actionTypes.UPDATE_CERTIFICATE_TYPE_SUCCESS:
      return updateCertificateTypeSuccess(state);
    case actionTypes.UPDATE_CERTIFICATE_TYPE_FAIL:
      return updateCertificateTypeFail(state, action);
    case actionTypes.DELETE_CERTIFICATE_TYPE_START:
      return deleteCertificateTypeStart(state);
    case actionTypes.DELETE_CERTIFICATE_TYPE_SUCCESS:
      return deleteCertificateTypeSuccess(state);
    case actionTypes.DELETE_CERTIFICATE_TYPE_FAIL:
      return deleteCertificateTypeFail(state, action);
    case actionTypes.SET_CERTIFICATE_TYPES_FILTER:
      return setCertificateTypesFilter(state, action);
    case actionTypes.RESET_CERTIFICATE_TYPE_STORE:
      return resetStore();
    case actionTypes.SET_CERTIFICATE_TYPE_UPDATE_NEEDED:
      return setUpdateNeeded(state);
    case actionTypes.RESET_CREATED_CERTIFICATE_TYPE:
      return resetCreatedCertificateType(state);
    default:
      return state;
  }
};

export default reducer;
