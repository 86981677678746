import React, { useEffect } from 'react';
import styles from './CreditPackagesModal.module.scss';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { CreditPackageListType } from '../../../domain/CreditPackage';
import { ListParams } from '../../../hooks/useList/useList';
import * as creditPackageService from '../../../store/creditPackage/service';
import CreditPackageItem from '../CreditPackageItem/CreditPackageItem';
import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { resetCreditPackagesStore } from '../../../store/creditPackage/actions';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import Loader from '../../../common/Loader/Loader';

type Props = {
  creditPackagesList: CreditPackageListType | null;
  onCreditPackagesFetch: (listParams: ListParams) => void;
  onClose: () => void;
  onResetCreditPackagesStore: () => void;
  isLoading: boolean;
};

export const CreditPackagesModal = ({
  creditPackagesList,
  onCreditPackagesFetch,
  onClose,
  onResetCreditPackagesStore,
  isLoading,
}: Props) => {
  const intl = useIntl();

  const DEFAULT_LIST_PARAMS: ListParams = {
    sortBy: 'creditPackage.price',
    search: '',
    sortDirection: 'ASC',
    page: 0,
    limit: 0,
  };

  useEffect(() => {
    return () => onResetCreditPackagesStore();
  }, []);

  useEffect(() => {
    onCreditPackagesFetch(DEFAULT_LIST_PARAMS);
  }, []);

  return (
    <div className={styles.creditPackagesModalContainer}>
      <IconButton onClick={onClose} className={styles.closeButton}>
        <CloseIcon />
      </IconButton>
      <div className={styles.subTitle}>
        {translate(intl, 'CREDIT_PACKAGE.TITLE')}
      </div>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading &&
      creditPackagesList &&
      creditPackagesList.result.length > 0 ? (
        <div className={styles.creditPackageList}>
          {creditPackagesList.result.map((creditPackage) => (
            <CreditPackageItem
              key={creditPackage.id}
              creditPackage={creditPackage}
            />
          ))}
        </div>
      ) : (
        <div className={styles.subTitle}>
          {translate(intl, 'CREDIT_PACKAGE.NO_PACKAGES_FOUND')}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  creditPackagesList: state.creditPackage.creditPackagesList,
  isLoading: state.creditPackage.creditPackagesLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCreditPackagesFetch: (listParams: ListParams) =>
    dispatch(creditPackageService.fetchCreditPackages(listParams)),
  onResetCreditPackagesStore: () => dispatch(resetCreditPackagesStore()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditPackagesModal);
