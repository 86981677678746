import axios from '../../config/Axios/axios-instance';
import {
  updateGlobalSettingStart,
  updateGlobalSettingSuccess,
  updateGlobalSettingFail,
  fetchGlobalSettingStart,
  fetchGlobalSettingSuccess,
  fetchGlobalSettingFail,
} from './actions';
import { Dispatch } from 'redux';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { GlobalSettingName } from '../../domain/GlobalSetting';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

export type GlobalSettingUpdateRequest = {
  id: number;
  value: string;
};

export const fetchGlobalSetting =
  (name: GlobalSettingName) => (dispatch: Dispatch) => {
    dispatch(fetchGlobalSettingStart());
    return axios
      .get(`${API_URLS.globalSetting}/${name}`)
      .then((response) => {
        dispatch(fetchGlobalSettingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchGlobalSettingFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };

export const updateGlobalSetting =
  (inputs: GlobalSettingUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateGlobalSettingStart());
    const { id, ...updatedGlobalSetting } = inputs;
    return axios
      .patch(`${API_URLS.globalSetting}/${id}`, {
        ...updatedGlobalSetting,
      })
      .then(() => {
        dispatch(updateGlobalSettingSuccess());
        showToast(translate(intl, 'SUCCESS.GLOBAL_SETTINGS_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateGlobalSettingFail(err.response.data.message));
      });
  };
