import * as actionTypes from './actionTypes';
import { OrderActionTypes } from './actionTypes';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';
import { HttpError } from '../../config/Axios/axios-instance';
import { Order, OrdersList } from '../../domain/Order';

export type OrderStateType = {
  ordersList: OrdersList | null;
  ordersListLoading: boolean;
  ordersListError: HttpError;
  ordersFilter: ListParams;
  orderLoading: boolean;
  order: Order | null;
  orderError: HttpError;
};

export type OrderActionType = OrderStateType & {
  type: OrderActionTypes;
};

export const initialState: OrderStateType = {
  ordersList: null,
  ordersListLoading: true,
  ordersListError: null,
  ordersFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'orders.createdAt',
    sortDirection: 'DESC',
  },
  orderLoading: false,
  order: null,
  orderError: null,
};

const fetchOrdersStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  ordersListLoading: true,
});

const fetchOrdersSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersList: action.ordersList,
  ordersListLoading: false,
  ordersListError: null,
});

const fetchOrdersFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersListError: action.ordersListError,
  ordersListLoading: false,
});

const fetchOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderLoading: true,
});

const fetchOrderSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  order: action.order,
  orderLoading: false,
  orderError: null,
});

const fetchOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  ordersListError: action.ordersListError,
  ordersListLoading: false,
});

const reducer = (state = initialState, action: OrderActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERS_START:
      return fetchOrdersStart(state);
    case actionTypes.FETCH_ORDERS_SUCCESS:
      return fetchOrdersSuccess(state, action);
    case actionTypes.FETCH_ORDERS_FAIL:
      return fetchOrdersFail(state, action);
    case actionTypes.FETCH_ORDER_START:
      return fetchOrderStart(state);
    case actionTypes.FETCH_ORDER_SUCCESS:
      return fetchOrderSuccess(state, action);
    case actionTypes.FETCH_ORDER_FAIL:
      return fetchOrderFail(state, action);
    default:
      return state;
  }
};

export default reducer;
