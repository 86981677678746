export const FETCH_ORGANISER_START = 'FETCH_ORGANISER_START';
export const FETCH_ORGANISER_SUCCESS = 'FETCH_ORGANISER_SUCCESS';
export const FETCH_ORGANISER_FAIL = 'FETCH_ORGANISER_FAIL';

export const CREATE_ORGANISER_START = 'CREATE_ORGANISER_START';
export const CREATE_ORGANISER_SUCCESS = 'CREATE_ORGANISER_SUCCESS';
export const CREATE_ORGANISER_FAIL = 'CREATE_ORGANISER_FAIL';

export const FETCH_ORGANISERS_START = 'FETCH_ORGANISERS_START';
export const FETCH_ORGANISERS_SUCCESS = 'FETCH_ORGANISERS_SUCCESS';
export const FETCH_ORGANISERS_FAIL = 'FETCH_ORGANISERS_FAIL';

export const UPDATE_ORGANISER_STATUS_START = 'UPDATE_ORGANISER_STATUS_START';
export const UPDATE_ORGANISER_STATUS_SUCCESS =
  'UPDATE_ORGANISER_STATUS_SUCCESS';
export const UPDATE_ORGANISER_STATUS_FAIL = 'UPDATE_ORGANISER_STATUS_FAIL';

export const SET_ORGANISERS_FILTER = 'SET_ORGANISERS_FILTER';

export type OrganiserActionTypes =
  | typeof FETCH_ORGANISER_START
  | typeof FETCH_ORGANISER_SUCCESS
  | typeof FETCH_ORGANISER_FAIL
  | typeof CREATE_ORGANISER_START
  | typeof CREATE_ORGANISER_SUCCESS
  | typeof CREATE_ORGANISER_FAIL
  | typeof FETCH_ORGANISERS_START
  | typeof FETCH_ORGANISERS_SUCCESS
  | typeof FETCH_ORGANISERS_FAIL
  | typeof SET_ORGANISERS_FILTER
  | typeof UPDATE_ORGANISER_STATUS_START
  | typeof UPDATE_ORGANISER_STATUS_SUCCESS
  | typeof UPDATE_ORGANISER_STATUS_FAIL;
