import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';

export const loginGoogleStart = () => ({
  type: actionTypes.LOGIN_GOOGLE_START,
});

export const loginGoogleSuccess = (jwtToken: string) => ({
  type: actionTypes.LOGIN_GOOGLE_SUCCESS,
  jwtToken,
});

export const loginGoogleFail = (loginGoogleError: string) => ({
  type: actionTypes.LOGIN_GOOGLE_FAIL,
  loginGoogleError,
});

export const loginFacebookStart = () => ({
  type: actionTypes.LOGIN_FACEBOOK_START,
});

export const loginFacebookSuccess = (jwtToken: string) => ({
  type: actionTypes.LOGIN_FACEBOOK_SUCCESS,
  jwtToken,
});

export const loginFacebookFail = (loginFacebookError: string) => ({
  type: actionTypes.LOGIN_FACEBOOK_FAIL,
  loginFacebookError,
});

export const connectUserStart = () => ({
  type: actionTypes.CONNECT_USER_START,
});

export const connectUserSuccess = () => ({
  type: actionTypes.CONNECT_USER_SUCCESS,
});

export const connectUserFail = (connectUserError: string) => ({
  type: actionTypes.CONNECT_USER_FAIL,
  connectUserError,
});

export const fetchConnectedUsersStart = () => ({
  type: actionTypes.FETCH_CONNECTED_USERS_START,
});

export const fetchConnectedUsersSuccess = (connectedUsers: User) => ({
  type: actionTypes.FETCH_CONNECTED_USERS_SUCCESS,
  connectedUsers,
});

export const fetchConnectedUsersFail = (connectedUsersError: HttpError) => ({
  type: actionTypes.FETCH_CONNECTED_USERS_FAIL,
  connectedUsersError,
});

export const confirmConnectUserStart = () => ({
  type: actionTypes.CONFIRM_CONNECT_USER_START,
});

export const confirmConnectUserSuccess = () => ({
  type: actionTypes.CONFIRM_CONNECT_USER_SUCCESS,
});

export const confirmConnectUserFail = () => ({
  type: actionTypes.CONFIRM_CONNECT_USER_FAIL,
});

export const resetConnect = () => ({
  type: actionTypes.RESET_CONNECT,
});

export const setViewedProfile = () => ({
  type: actionTypes.SET_VIEWED_PROFILE,
});

export const loginStart = () => ({
  type: actionTypes.LOGIN_START,
});

export const loginSuccess = (jwtToken: string) => ({
  type: actionTypes.LOGIN_SUCCESS,
  jwtToken,
});

export const loginFail = (loginError: HttpError) => ({
  type: actionTypes.LOGIN_FAIL,
  loginError,
});

export const registerStart = () => ({
  type: actionTypes.REGISTER_START,
});

export const registerSuccess = () => ({
  type: actionTypes.REGISTER_SUCCESS,
});

export const registerFail = (registerError: HttpError) => ({
  type: actionTypes.REGISTER_FAIL,
  registerError,
});

export const remindStart = () => ({
  type: actionTypes.REMIND_START,
});

export const remindSuccess = () => ({
  type: actionTypes.REMIND_SUCCESS,
});

export const remindFail = (remindError: HttpError) => ({
  type: actionTypes.REMIND_FAIL,
  remindError,
});

export const resetPasswordStart = () => ({
  type: actionTypes.RESET_PASSWORD_START,
});

export const resetPasswordSuccess = (jwtToken: string) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  jwtToken,
});

export const resetPasswordFail = (resetPasswordError: HttpError) => ({
  type: actionTypes.RESET_PASSWORD_FAIL,
  resetPasswordError,
});

export const validateCodeStart = () => ({
  type: actionTypes.VALIDATE_CODE_START,
});

export const validateCodeSuccess = (validatedUser: User) => ({
  type: actionTypes.VALIDATE_CODE_SUCCESS,
  validatedUser,
});

export const validateCodeFail = (validateCodeError: HttpError) => ({
  type: actionTypes.VALIDATE_CODE_FAIL,
  validateCodeError,
});

export const registerConfirmStart = () => ({
  type: actionTypes.REGISTER_CONFIRM_START,
});

export const registerConfirmSuccess = (jwtToken: string) => ({
  type: actionTypes.REGISTER_CONFIRM_SUCCESS,
  jwtToken,
});

export const registerConfirmFail = (registerConfirmError: HttpError) => ({
  type: actionTypes.REGISTER_CONFIRM_FAIL,
  registerConfirmError,
});

export const refreshTokenStart = () => ({
  type: actionTypes.REFRESH_TOKEN_START,
});

export const refreshTokenSuccess = (jwtToken: string) => ({
  type: actionTypes.REFRESH_TOKEN_SUCCESS,
  jwtToken,
});

export const refreshTokenFail = (refreshTokenError: HttpError) => ({
  type: actionTypes.REFRESH_TOKEN_FAIL,
  refreshTokenError,
});

export const setRole = (role: Roles | null) => ({
  type: actionTypes.SET_ROLE,
  role,
});

export const selectRoleStart = () => ({
  type: actionTypes.SELECT_ROLE_START,
});

export const selectRoleSuccess = (role: Roles) => ({
  type: actionTypes.SELECT_ROLE_SUCCESS,
  role,
});

export const selectRoleFail = (roleSelectError: HttpError) => ({
  type: actionTypes.SELECT_ROLE_FAIL,
  roleSelectError,
});

export const deleteConnectedUserStart = () => ({
  type: actionTypes.DELETE_CONNECTED_USER_START,
});

export const deleteConnectedUserSuccess = () => ({
  type: actionTypes.DELETE_CONNECTED_USER_SUCCESS,
});

export const deleteConnectedUserFail = (connectedUserDeleteError: string) => ({
  type: actionTypes.DELETE_CONNECTED_USER_FAIL,
  connectedUserDeleteError,
});

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('selectedCompany');
  localStorage.removeItem('currentPage');
  return {
    type: actionTypes.LOGOUT,
  };
};

export const selectLanguage = (selectedLanguage: string) => ({
  type: actionTypes.SELECT_LANGUAGE,
  selectedLanguage,
});

export const resetLoginError = () => ({
  type: actionTypes.RESET_LOGIN_ERROR,
});
