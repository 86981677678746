import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';
import { User, UsersList } from '../../domain/User';

export type UserStateType = {
  userList: UsersList | null;
  userListLoading: boolean;
  userListError: HttpError;
  userFilter: ListParams;
  userUpdateLoading: boolean;
  userUpdateError: HttpError;
  userUpdateSuccess: boolean;
  user: User | null;
  userLoading: boolean;
  userError: HttpError;
  currentUser: User | null;
  currentUserLoading: boolean;
  topUpCreateError: HttpError;
  topUpCreateLoading: boolean;
  topUpCreateSuccess: boolean;
  topUpSuccessRedirect: string | null;
  usersNumber: number | null;
  usersNumberLoading: boolean;
  usersNumberError: HttpError;
  userCreateLoading: boolean;
  userCreateError: HttpError;
  userCreateSuccess: boolean;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
};

export const initialState: UserStateType = {
  userList: null,
  userListLoading: true,
  userListError: null,
  userFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'users.email',
  },
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  user: null,
  userLoading: false,
  userError: null,
  currentUser: null,
  currentUserLoading: true,
  topUpCreateError: null,
  topUpCreateLoading: false,
  topUpCreateSuccess: false,
  topUpSuccessRedirect: null,
  usersNumber: null,
  usersNumberLoading: false,
  usersNumberError: null,
  userCreateLoading: true,
  userCreateError: null,
  userCreateSuccess: false,
};

const fetchUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  userListLoading: true,
  user: null,
});

const fetchUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userList: action.userList,
  userListLoading: false,
  userListError: null,
  userFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'users.email',
  },
});

const fetchUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userListError: action.userListError,
  userListLoading: false,
});

const fetchUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userLoading: true,
  userUpdateSuccess: false,
  userUpdateError: null,
});

const fetchUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  user: action.user,
  userLoading: false,
  userError: null,
});

const fetchUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  userLoading: false,
});

const setUsersFilter = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userFilter: action.userFilter,
});

const resetStore = (): UserStateType => ({
  userList: null,
  userListLoading: true,
  userListError: null,
  userFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'users.email',
  },
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  user: null,
  userLoading: false,
  userError: null,
  currentUser: null,
  currentUserLoading: true,
  topUpCreateError: null,
  topUpCreateLoading: false,
  topUpCreateSuccess: false,
  topUpSuccessRedirect: null,
  usersNumber: null,
  usersNumberLoading: false,
  usersNumberError: null,
  userCreateLoading: true,
  userCreateError: null,
  userCreateSuccess: false,
});

const updateUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: true,
});

const updateUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: null,
  userUpdateSuccess: true,
});

const updateUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: action.userUpdateError,
});

const fetchCurrentUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: true,
});

const fetchCurrentUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const createTopUpStart = (state: UserStateType): UserStateType => ({
  ...state,
  topUpCreateLoading: true,
});

const createTopUpSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  topUpCreateLoading: false,
  topUpCreateError: null,
  topUpCreateSuccess: true,
  topUpSuccessRedirect: action.topUpSuccessRedirect,
});

const createTopUpFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  topUpCreateLoading: false,
  topUpCreateError: action.topUpCreateError,
});

const createUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: true,
});

const createUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: null,
  userCreateSuccess: true,
});

const createUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: action.userCreateError,
});

const resetTopUpSuccessStatus = (state: UserStateType): UserStateType => ({
  ...state,
  topUpCreateSuccess: false,
  topUpSuccessRedirect: null,
});

const resetUsersListStore = (state: UserStateType) => ({
  ...state,
  userList: null,
  userListError: null,
  userCreateSuccess: false,
  userCreateLoading: false,
  userCreateError: null,
  userUpdateSuccess: false,
});

const fetchPublicUsersNumberStart = (state: UserStateType): UserStateType => ({
  ...state,
  usersNumberLoading: true,
  usersNumberError: null,
});

const fetchPublicUsersNumberSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersNumber: action.usersNumber,
  usersNumberLoading: false,
  usersNumberError: null,
});

const fetchPublicUsersNumberFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersNumberError: action.usersNumberError,
  usersNumberLoading: false,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUsersFail(state, action);
    case actionTypes.SET_USERS_FILTER:
      return setUsersFilter(state, action);
    case actionTypes.RESET_USER_STORE:
      return resetStore();
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.CREATE_USER_START:
      return createUserStart(state);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.FETCH_CURRENT_USER_START:
      return fetchCurrentUserStart(state);
    case actionTypes.FETCH_CURRENT_USER_SUCCESS:
      return fetchCurrentUserSuccess(state, action);
    case actionTypes.FETCH_CURRENT_USER_FAIL:
      return fetchCurrentUserFail(state);
    case actionTypes.CREATE_TOP_UP_START:
      return createTopUpStart(state);
    case actionTypes.CREATE_TOP_UP_SUCCESS:
      return createTopUpSuccess(state, action);
    case actionTypes.CREATE_TOP_UP_FAIL:
      return createTopUpFail(state, action);
    case actionTypes.RESET_USERS_LIST_STORE:
      return resetUsersListStore(state);
    case actionTypes.FETCH_PUBLIC_USERS_NUMBER_START:
      return fetchPublicUsersNumberStart(state);
    case actionTypes.FETCH_PUBLIC_USERS_NUMBER_SUCCESS:
      return fetchPublicUsersNumberSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_USERS_NUMBER_FAIL:
      return fetchPublicUsersNumberFail(state, action);
    case actionTypes.RESET_TOP_UP_SUCCESS_STATUS:
      return resetTopUpSuccessStatus(state);
    default:
      return state;
  }
};

export default reducer;
