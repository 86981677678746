export interface Role {
  id: number;
  role: Roles;
}

export enum Roles {
  // eslint-disable-next-line no-unused-vars
  ADMIN = 'ADMIN',
  // eslint-disable-next-line no-unused-vars
  ORGANISER = 'ORGANISER',
  // eslint-disable-next-line no-unused-vars
  USER = 'USER',
  // eslint-disable-next-line no-unused-vars
  MANAGER = 'MANAGER',
  // eslint-disable-next-line no-unused-vars
  OBSERVER = 'OBSERVER',
  // eslint-disable-next-line no-unused-vars
  API_USER = 'API_USER',
  // eslint-disable-next-line no-unused-vars
  LECTURER = 'LECTURER',
}
