import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Language } from '../../domain/Language';

export const fetchLanguageStart = () => ({
  type: actionTypes.FETCH_LANGUAGE_START,
});

export const fetchLanguageSuccess = (language: Language) => ({
  type: actionTypes.FETCH_LANGUAGE_SUCCESS,
  language,
});

export const fetchLanguageFail = (languageError: HttpError) => ({
  type: actionTypes.FETCH_LANGUAGE_FAIL,
  languageError,
});

export const fetchEventLanguageStart = () => ({
  type: actionTypes.FETCH_EVENT_LANGUAGE_START,
});

export const fetchEventLanguageSuccess = (eventLanguage: Language) => ({
  type: actionTypes.FETCH_EVENT_LANGUAGE_SUCCESS,
  eventLanguage,
});

export const fetchEventLanguageFail = (languageError: HttpError) => ({
  type: actionTypes.FETCH_EVENT_LANGUAGE_FAIL,
  languageError,
});

export const fetchLanguagesStart = () => ({
  type: actionTypes.FETCH_LANGUAGES_START,
});

export const fetchLanguagesSuccess = (languages: Language[]) => ({
  type: actionTypes.FETCH_LANGUAGES_SUCCESS,
  languages,
});

export const fetchLanguagesFail = (languagesError: HttpError) => ({
  type: actionTypes.FETCH_LANGUAGES_FAIL,
  languagesError,
});

export const fetchCertificateLanguagesStart = () => ({
  type: actionTypes.FETCH_CERTIFICATE_LANGUAGES_START,
});

export const fetchCertificateLanguagesSuccess = (languages: Language[]) => ({
  type: actionTypes.FETCH_CERTIFICATE_LANGUAGES_SUCCESS,
  languages,
});

export const fetchCertificateLanguagesFail = (languagesError: HttpError) => ({
  type: actionTypes.FETCH_CERTIFICATE_LANGUAGES_FAIL,
  languagesError,
});
