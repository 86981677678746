import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  EventRepresentative,
  EventRepresentativesList,
} from '../../domain/EventRepresentative';
import { ListParams } from '../../hooks/useList/useList';

export const fetchEventRepresentativesStart = () => ({
  type: actionTypes.FETCH_EVENT_REPRESENTATIVES_START,
});

export const fetchEventRepresentativesSuccess = (
  eventRepresentativesList: EventRepresentativesList,
) => ({
  type: actionTypes.FETCH_EVENT_REPRESENTATIVES_SUCCESS,
  eventRepresentativesList,
});

export const fetchEventRepresentativesFail = (
  eventRepresentativesListError: HttpError,
) => ({
  type: actionTypes.FETCH_EVENT_REPRESENTATIVES_FAIL,
  eventRepresentativesListError,
});

export const fetchEventRepresentativeStart = () => ({
  type: actionTypes.FETCH_EVENT_REPRESENTATIVE_START,
});

export const fetchEventRepresentativeSuccess = (
  eventRepresentative: EventRepresentative,
) => ({
  type: actionTypes.FETCH_EVENT_REPRESENTATIVE_SUCCESS,
  eventRepresentative,
});

export const fetchEventRepresentativeFail = (
  eventRepresentativeError: HttpError,
) => ({
  type: actionTypes.FETCH_EVENT_REPRESENTATIVE_FAIL,
  eventRepresentativeError,
});

export const createEventRepresentativeStart = () => ({
  type: actionTypes.CREATE_EVENT_REPRESENTATIVE_START,
});

export const createEventRepresentativeSuccess = (
  createdEventRepresentative: EventRepresentative,
) => ({
  type: actionTypes.CREATE_EVENT_REPRESENTATIVE_SUCCESS,
  createdEventRepresentative,
});

export const createEventRepresentativeFail = (
  eventRepresentativeCreateError: string,
) => ({
  type: actionTypes.CREATE_EVENT_REPRESENTATIVE_FAIL,
  eventRepresentativeCreateError,
});

export const updateEventRepresentativeStart = () => ({
  type: actionTypes.UPDATE_EVENT_REPRESENTATIVE_START,
});

export const updateEventRepresentativeSuccess = () => ({
  type: actionTypes.UPDATE_EVENT_REPRESENTATIVE_SUCCESS,
});

export const updateEventRepresentativeFail = (
  eventRepresentativeUpdateError: string,
) => ({
  type: actionTypes.UPDATE_EVENT_REPRESENTATIVE_FAIL,
  eventRepresentativeUpdateError,
});

export const deleteEventRepresentativeStart = () => ({
  type: actionTypes.DELETE_EVENT_REPRESENTATIVE_START,
});

export const deleteEventRepresentativeSuccess = () => ({
  type: actionTypes.DELETE_EVENT_REPRESENTATIVE_SUCCESS,
});

export const deleteEventRepresentativeFail = (
  eventRepresentativeDeleteError: string,
) => ({
  type: actionTypes.DELETE_EVENT_REPRESENTATIVE_FAIL,
  eventRepresentativeDeleteError,
});

export const setEventRepresentativesFilterValues = (
  eventRepresentativesFilter: ListParams,
) => ({
  type: actionTypes.SET_EVENT_REPRESENTATIVES_FILTER,
  eventRepresentativesFilter,
});

export const resetEventRepresentativeStore = () => ({
  type: actionTypes.RESET_EVENT_REPRESENTATIVE_STORE,
});

export const setUpdateNeeded = () => ({
  type: actionTypes.SET_EVENT_REPRESENTATIVE_UPDATE_NEEDED,
});

export const resetCreatedEventRepresentative = () => ({
  type: actionTypes.RESET_CREATED_EVENT_REPRESENTATIVE,
});
