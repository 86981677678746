import axios from '../../config/Axios/axios-instance';
import {
  createOrganiserFail,
  createOrganiserStart,
  createOrganiserSuccess,
  fetchOrganiserFail,
  fetchOrganiserStart,
  fetchOrganiserSuccess,
  fetchOrganisersFail,
  fetchOrganisersStart,
  fetchOrganisersSuccess,
  setOrganisersFilterValues,
  updateOrganiserStatusStart,
  updateOrganiserStatusSuccess,
  updateOrganiserStatusFail,
} from './actions';
import { Dispatch } from 'redux';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { ListParams } from '../../hooks/useList/useList';
import { Company } from '../../domain/Company';
import { Roles } from '../../domain/Role';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';

export type OrganiserCreateRequest = {
  agreement: boolean;
  company: Company;
};

export type FetchOrganiserParams = ListParams & {
  status?: boolean;
};

export const fetchOrganiser = () => (dispatch: Dispatch) => {
  dispatch(fetchOrganiserStart());
  return axios
    .get(`${API_URLS.organiser}/me`)
    .then((response) => {
      dispatch(fetchOrganiserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchOrganiserFail(err.response.data.message));
      showToast(err.response.data.message, 'error');
    });
};

export const createOrganiser =
  (inputs: OrganiserCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createOrganiserStart());
    return axios
      .post(API_URLS.organiser, {
        ...inputs,
      })
      .then((response) => {
        localStorage.setItem('role', Roles.ORGANISER);
        dispatch(createOrganiserSuccess());
        showToast(translate(intl, 'SUCCESS.ORGANISER_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createOrganiserFail(err.response.data.message));
      });
  };

export const fetchOrganisers =
  (params: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchOrganisersStart());

    if (saveFilters) {
      dispatch(setOrganisersFilterValues(params));
    }

    return axios
      .get(API_URLS.organiser, { params })
      .then((response) => {
        dispatch(fetchOrganisersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchOrganisersFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };

export const updateOrganiserStatus =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateOrganiserStatusStart());

    return axios
      .patch(`${API_URLS.organiser}/${id}`)
      .then(() => {
        dispatch(updateOrganiserStatusSuccess());
        showToast(
          translate(intl, 'SUCCESS.ORGANISER_STATUS_UPDATE', 'success'),
        );
      })
      .catch((err) => {
        dispatch(updateOrganiserStatusFail(err.response.data.message));
      });
  };
