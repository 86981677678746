export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';

export const FETCH_EVENT_START = 'FETCH_EVENT_START';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAIL = 'FETCH_EVENT_FAIL';

export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';

export const UPDATE_EVENT_START = 'UPDATE_EVENT_START';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';

export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';

export const SET_EVENTS_FILTER = 'SET_EVENTS_FILTER';

export const GENERATE_CERTIFICATES_START = 'GENERATE_CERTIFICATES_START';
export const GENERATE_CERTIFICATES_SUCCESS = 'GENERATE_CERTIFICATES_SUCCESS';
export const GENERATE_CERTIFICATES_FAIL = 'GENERATE_CERTIFICATES_FAIL';

export const GENERATE_PARTICIPANT_CERTIFICATE_START =
  'GENERATE_PARTICIPANT_CERTIFICATE_START';
export const GENERATE_PARTICIPANT_CERTIFICATE_SUCCESS =
  'GENERATE_PARTICIPANT_CERTIFICATE_SUCCESS';
export const GENERATE_PARTICIPANT_CERTIFICATE_FAIL =
  'GENERATE_PARTICIPANT_CERTIFICATESFAIL';

export const SEND_CERTIFICATES_START = 'SEND_CERTIFICATES_START';
export const SEND_CERTIFICATES_SUCCESS = 'SEND_CERTIFICATES_SUCCESS';
export const SEND_CERTIFICATES_FAIL = 'SEND_CERTIFICATES_FAIL';

export const SEND_PARTICIPANT_CERTIFICATE_START =
  'SEND_PARTICIPANT_CERTIFICATE_START';
export const SEND_PARTICIPANT_CERTIFICATE_SUCCESS =
  'SEND_PARTICIPANT_CERTIFICATE_SUCCESS';
export const SEND_PARTICIPANT_CERTIFICATE_FAIL =
  'SEND_PARTICIPANT_CERTIFICATE_FAIL';

export const SEND_PARTICIPANTS_CERTIFICATES_START =
  'SEND_PARTICIPANTS_CERTIFICATE_START';
export const SEND_PARTICIPANTS_CERTIFICATES_SUCCESS =
  'SEND_PARTICIPANTS_CERTIFICATES_SUCCESS';
export const SEND_PARTICIPANTS_CERTIFICATES_FAIL =
  'SEND_PARTICIPANTS_CERTIFICATES_FAIL';

export const GET_CERTIFICATION_STATE_START = 'GET_CERTIFICATION_STATE_START';
export const GET_CERTIFICATION_STATE_SUCCESS =
  'GET_CERTIFICATION_STATE_SUCCESS';
export const GET_CERTIFICATION_STATE_FAIL = 'GET_CERTIFICATION_STATE_FAIL';

export const DOWNLOAD_FILE_START = 'DOWNLOAD_FILE_START';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';

export const UPDATE_ASSET_START = 'UPDATE_ASSET_START';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_FAIL = 'UPDATE_ASSET_FAIL';

export const GET_ASSET_THUMBNAIL_START = 'GET_ASSET_THUMBNAIL_START';
export const GET_ASSET_THUMBNAIL_SUCCESS = 'GET_ASSET_THUMBNAIL_SUCCESS';
export const GET_ASSET_THUMBNAIL_FAIL = 'GET_ASSET_THUMBNAIL_FAIL';

export const RESET_EVENT_STORE = 'RESET_EVENT_STORE';

export const SET_EVENTS_UPDATE_NEEDED = 'SET_EVENTS_UPDATE_NEEDED';

export const SET_CURRENT_EVENT_DATA = 'SET_CURRENT_EVENT_DATA';
export const RESET_CURRENT_EVENT_DATA = 'RESET_CURRENT_EVENT_DATA';

export const RESET_EVENT_ASSET_ERROR = 'RESET_EVENT_ERRORS';

export const RESET_EVENT_ASSET_SUCCESS_STATUS =
  'RESET_EVENT_ASSET_SUCCESS_STATUS';

export const RESET_EVENT_ERROR = 'RESET_EVENT_ERROR';

export type EventActionTypes =
  | typeof FETCH_EVENTS_START
  | typeof FETCH_EVENTS_SUCCESS
  | typeof FETCH_EVENTS_FAIL
  | typeof FETCH_EVENT_START
  | typeof FETCH_EVENT_SUCCESS
  | typeof FETCH_EVENT_FAIL
  | typeof CREATE_EVENT_START
  | typeof CREATE_EVENT_SUCCESS
  | typeof CREATE_EVENT_FAIL
  | typeof UPDATE_EVENT_START
  | typeof UPDATE_EVENT_SUCCESS
  | typeof UPDATE_EVENT_FAIL
  | typeof DELETE_EVENT_START
  | typeof DELETE_EVENT_SUCCESS
  | typeof DELETE_EVENT_FAIL
  | typeof SET_EVENTS_FILTER
  | typeof GENERATE_CERTIFICATES_START
  | typeof GENERATE_CERTIFICATES_SUCCESS
  | typeof GENERATE_CERTIFICATES_FAIL
  | typeof GENERATE_PARTICIPANT_CERTIFICATE_START
  | typeof GENERATE_PARTICIPANT_CERTIFICATE_SUCCESS
  | typeof GENERATE_PARTICIPANT_CERTIFICATE_FAIL
  | typeof SEND_CERTIFICATES_START
  | typeof SEND_CERTIFICATES_SUCCESS
  | typeof SEND_CERTIFICATES_FAIL
  | typeof SEND_PARTICIPANT_CERTIFICATE_START
  | typeof SEND_PARTICIPANT_CERTIFICATE_SUCCESS
  | typeof SEND_PARTICIPANT_CERTIFICATE_FAIL
  | typeof SEND_PARTICIPANTS_CERTIFICATES_SUCCESS
  | typeof SEND_PARTICIPANTS_CERTIFICATES_START
  | typeof SEND_PARTICIPANTS_CERTIFICATES_FAIL
  | typeof GET_CERTIFICATION_STATE_START
  | typeof GET_CERTIFICATION_STATE_SUCCESS
  | typeof GET_CERTIFICATION_STATE_FAIL
  | typeof DOWNLOAD_FILE_START
  | typeof DOWNLOAD_FILE_SUCCESS
  | typeof DOWNLOAD_FILE_FAIL
  | typeof UPDATE_ASSET_START
  | typeof UPDATE_ASSET_SUCCESS
  | typeof UPDATE_ASSET_FAIL
  | typeof RESET_EVENT_STORE
  | typeof SET_EVENTS_UPDATE_NEEDED
  | typeof SET_CURRENT_EVENT_DATA
  | typeof RESET_CURRENT_EVENT_DATA
  | typeof RESET_EVENT_ASSET_ERROR
  | typeof RESET_EVENT_ASSET_SUCCESS_STATUS
  | typeof GET_ASSET_THUMBNAIL_START
  | typeof GET_ASSET_THUMBNAIL_SUCCESS
  | typeof GET_ASSET_THUMBNAIL_FAIL
  | typeof RESET_EVENT_ERROR;
