export const FETCH_PARTICIPANTS_START = 'FETCH_PARTICIPANTS_START';
export const FETCH_PARTICIPANTS_SUCCESS = 'FETCH_PARTICIPANTS_SUCCESS';
export const FETCH_PARTICIPANTS_FAIL = 'FETCH_PARTICIPANTS_FAIL';

export const FETCH_PARTICIPANT_START = 'FETCH_PARTICIPANT_START';
export const FETCH_PARTICIPANT_SUCCESS = 'FETCH_PARTICIPANT_SUCCESS';
export const FETCH_PARTICIPANT_FAIL = 'FETCH_PARTICIPANT_FAIL';

export const FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_START =
  'FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_START';
export const FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_SUCCESS =
  'FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_SUCCESS';
export const FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_FAIL =
  'FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_FAIL';

export const CREATE_PARTICIPANT_START = 'CREATE_PARTICIPANT_START';
export const CREATE_PARTICIPANT_SUCCESS = 'CREATE_PARTICIPANT_SUCCESS';
export const CREATE_PARTICIPANT_FAIL = 'CREATE_PARTICIPANT_FAIL';

export const IMPORT_PARTICIPANTS_START = 'IMPORT_PARTICIPANTS_START';
export const IMPORT_PARTICIPANTS_SUCCESS = 'IMPORT_PARTICIPANTS_SUCCESS';
export const IMPORT_PARTICIPANTS_FAIL = 'IMPORT_PARTICIPANTS_FAIL';
export const IMPORT_PARTICIPANTS_RESET = 'IMPORT_PARTICIPANTS_RESET';

export const UPDATE_PARTICIPANT_START = 'UPDATE_PARTICIPANT_START';
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS';
export const UPDATE_PARTICIPANT_FAIL = 'UPDATE_PARTICIPANT_FAIL';

export const DELETE_PARTICIPANT_START = 'DELETE_PARTICIPANT_START';
export const DELETE_PARTICIPANT_SUCCESS = 'DELETE_PARTICIPANT_SUCCESS';
export const DELETE_PARTICIPANT_FAIL = 'DELETE_PARTICIPANT_FAIL';

export const DELETE_MULTIPLE_PARTICIPANT_START =
  'DELETE_MULTIPLE_PARTICIPANT_START';
export const DELETE_MULTIPLE_PARTICIPANT_SUCCESS =
  'DELETE_MULTIPLE_PARTICIPANT_SUCCESS';
export const DELETE_MULTIPLE_PARTICIPANT_FAIL =
  'DELETE_MULTIPLE_PARTICIPANT_FAIL';

export const SET_PARTICIPANTS_FILTER = 'SET_PARTICIPANTS_FILTER';

export const RESET_PARTICIPANT_STORE = 'RESET_PARTICIPANT_STORE';

export const SET_PARTICIPANTS_UPDATE_NEEDED = 'SET_PARTICIPANTS_UPDATE_NEEDED';

export const PARTICIPANT_CONFIRM_START = 'PARTICIPANT_CONFIRM_START';
export const PARTICIPANT_CONFIRM_SUCCESS = 'PARTICIPANT_CONFIRM_SUCCESS';
export const PARTICIPANT_CONFIRM_FAIL = 'PARTICIPANT_CONFIRM_FAIL';

export const DOWNLOAD_FILE_START = 'DOWNLOAD_FILE_START';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';

export const DOWNLOAD_ALL_ASSETS_START = 'DOWNLOAD_ALL_ASSETS_START';
export const DOWNLOAD_ALL_ASSETS_SUCCESS = 'DOWNLOAD_ALL_ASSETS_SUCCESS';
export const DOWNLOAD_ALL_ASSETS_FAIL = 'DOWNLOAD_ALL_ASSETS_FAIL';

export const UPDATE_ASSET_START = 'UPDATE_ASSET_START';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_FAIL = 'UPDATE_ASSET_FAIL';

export const GET_ASSET_THUMBNAIL_START = 'GET_ASSET_THUMBNAIL_START';
export const GET_ASSET_THUMBNAIL_SUCCESS = 'GET_ASSET_THUMBNAIL_SUCCESS';
export const GET_ASSET_THUMBNAIL_FAIL = 'GET_ASSET_THUMBNAIL_FAIL';

export const RESET_PARTICIPANT_ASSET_SUCCESS_STATUS =
  'RESET_PARTICIPANT_ASSET_SUCCESS_STATUS';

export type ParticipantActionTypes =
  | typeof FETCH_PARTICIPANTS_START
  | typeof FETCH_PARTICIPANTS_SUCCESS
  | typeof FETCH_PARTICIPANTS_FAIL
  | typeof FETCH_PARTICIPANT_START
  | typeof FETCH_PARTICIPANT_SUCCESS
  | typeof FETCH_PARTICIPANT_FAIL
  | typeof FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_START
  | typeof FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_SUCCESS
  | typeof FETCH_PARTICIPANT_BY_CONFIRMATION_CODE_FAIL
  | typeof CREATE_PARTICIPANT_START
  | typeof CREATE_PARTICIPANT_SUCCESS
  | typeof CREATE_PARTICIPANT_FAIL
  | typeof IMPORT_PARTICIPANTS_START
  | typeof IMPORT_PARTICIPANTS_SUCCESS
  | typeof IMPORT_PARTICIPANTS_FAIL
  | typeof IMPORT_PARTICIPANTS_RESET
  | typeof UPDATE_PARTICIPANT_START
  | typeof UPDATE_PARTICIPANT_SUCCESS
  | typeof UPDATE_PARTICIPANT_FAIL
  | typeof DELETE_PARTICIPANT_START
  | typeof DELETE_PARTICIPANT_SUCCESS
  | typeof DELETE_PARTICIPANT_FAIL
  | typeof DELETE_MULTIPLE_PARTICIPANT_START
  | typeof DELETE_MULTIPLE_PARTICIPANT_SUCCESS
  | typeof DELETE_MULTIPLE_PARTICIPANT_FAIL
  | typeof SET_PARTICIPANTS_FILTER
  | typeof RESET_PARTICIPANT_STORE
  | typeof SET_PARTICIPANTS_UPDATE_NEEDED
  | typeof PARTICIPANT_CONFIRM_START
  | typeof PARTICIPANT_CONFIRM_SUCCESS
  | typeof PARTICIPANT_CONFIRM_FAIL
  | typeof DOWNLOAD_FILE_START
  | typeof DOWNLOAD_FILE_SUCCESS
  | typeof DOWNLOAD_FILE_FAIL
  | typeof DOWNLOAD_ALL_ASSETS_START
  | typeof DOWNLOAD_ALL_ASSETS_SUCCESS
  | typeof DOWNLOAD_ALL_ASSETS_FAIL
  | typeof UPDATE_ASSET_START
  | typeof UPDATE_ASSET_SUCCESS
  | typeof UPDATE_ASSET_FAIL
  | typeof RESET_PARTICIPANT_ASSET_SUCCESS_STATUS
  | typeof GET_ASSET_THUMBNAIL_START
  | typeof GET_ASSET_THUMBNAIL_SUCCESS
  | typeof GET_ASSET_THUMBNAIL_FAIL;
