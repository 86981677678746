import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { LogLevel } from '@sentry/types';

if (process.env.SENTRY_ENV && process.env.SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://3d1b3006202c42d29db2efa1774493e2@o1101437.ingest.sentry.io/6134108',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.SENTRY_ENV,
    logLevel: LogLevel.Debug,
    debug: false,
    tracesSampleRate: 1.0,
  });
}

// @ts-ignore
window['__react-beautiful-dnd-disable-dev-warnings'] = true;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
