import * as actionTypes from './actionTypes';
import { CertificateActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Certificate, CertificatesList } from '../../domain/Certificate';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type CertificateStateType = {
  certificatesList: CertificatesList | null;
  certificatesListLoading: boolean;
  certificatesListError: HttpError;
  certificateListUpdateNeeded: boolean;
  certificatesFilter: ListParams;
  certificate: Certificate | null;
  certificateLoading: boolean;
  certificateError: HttpError;
  reportIssueSuccess: boolean;
  reportIssueError: HttpError;
  reportIssueLoading: boolean;
  downloadFileLoading: boolean;
  downloadFileError: HttpError;
  downloadedFile: File | null;
  externalCertificateCreateLoading: boolean;
  externalCertificateCreateError: HttpError;
  externalCertificateCreateSuccess: boolean;
  prepareToSignLoading: boolean;
  prepareToSignError: HttpError;
  prepareToSignSuccess: boolean;
  prepareToSignBatchId: string | null;
  signUrlLoading: boolean;
  signUrlError: HttpError;
  signUrl: string | null;
  signUrlBatchId: string | null;
  startSignProcessLoading: boolean;
  startSignProcessSuccess: boolean;
  startSignProcessError: HttpError;
  startSignProcessBatchId: string | null;
  certificateTotalCount: number | null;
  certificateTotalCountLoading: boolean;
  certificateTotalCountError: HttpError;
  certificateHashes: string[] | null;
  certificateHashesLoading: boolean;
  certificateHashesError: HttpError;
  certificateStatusUpdateLoading: boolean;
  certificateStatusUpdateError: HttpError;
  certificateStatusUpdateSuccess: boolean;
  createExternalCertificateLoading: boolean;
  createExternalCertificateSuccess: boolean;
  createExternalCertificateError: HttpError;
  updateExternalCertificateLoading: boolean;
  updateExternalCertificateSuccess: boolean;
  updateExternalCertificateError: HttpError;
  externalCertificate: Certificate | null;
  certificatesToPdfExportLoading: boolean;
  certificatesToPdfExportError: HttpError;
  certificatesToPdfExportSuccess: boolean;
  externalCertificateConfirmLoading: boolean;
  externalCertificateConfirmSuccess: boolean;
  externalCertificateConfirmError: HttpError;
};

export type CertificateActionType = CertificateStateType & {
  type: CertificateActionTypes;
};

export const initialState: CertificateStateType = {
  certificatesList: null,
  certificatesListLoading: true,
  certificatesListError: null,
  certificateListUpdateNeeded: false,
  certificatesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'event.eventDate',
  },
  certificate: null,
  certificateError: null,
  certificateLoading: true,
  reportIssueSuccess: false,
  reportIssueError: null,
  reportIssueLoading: false,
  downloadFileLoading: false,
  downloadFileError: null,
  downloadedFile: null,
  externalCertificateCreateLoading: false,
  externalCertificateCreateError: null,
  externalCertificateCreateSuccess: false,
  prepareToSignError: null,
  prepareToSignLoading: false,
  prepareToSignSuccess: false,
  prepareToSignBatchId: null,
  signUrlError: null,
  signUrlLoading: false,
  signUrl: null,
  signUrlBatchId: null,
  startSignProcessBatchId: null,
  startSignProcessError: null,
  startSignProcessLoading: false,
  startSignProcessSuccess: false,
  certificateTotalCount: null,
  certificateTotalCountLoading: false,
  certificateTotalCountError: null,
  certificateHashes: null,
  certificateHashesLoading: false,
  certificateHashesError: null,
  certificateStatusUpdateLoading: false,
  certificateStatusUpdateError: null,
  certificateStatusUpdateSuccess: false,
  createExternalCertificateLoading: false,
  createExternalCertificateSuccess: false,
  createExternalCertificateError: null,
  updateExternalCertificateLoading: false,
  updateExternalCertificateSuccess: false,
  updateExternalCertificateError: null,
  externalCertificate: null,
  certificatesToPdfExportLoading: false,
  certificatesToPdfExportError: null,
  certificatesToPdfExportSuccess: false,
  externalCertificateConfirmLoading: false,
  externalCertificateConfirmSuccess: false,
  externalCertificateConfirmError: null,
};

const fetchCertificatesStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificatesListLoading: true,
  certificate: null,
});

const fetchCertificatesSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  certificatesList: action.certificatesList,
  certificatesListLoading: false,
  certificatesListError: null,
  certificateStatusUpdateSuccess: false,
  externalCertificateConfirmSuccess: false,
  certificateListUpdateNeeded: false,
});

const fetchCertificatesFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  certificatesListError: action.certificatesListError,
  certificatesListLoading: false,
});

const setCertificatesFilter = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  certificatesFilter: action.certificatesFilter,
});

const fetchCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateLoading: true,
});

const fetchCertificateSuccess = (
  state: CertificateStateType,
  action: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificate: action.certificate,
  certificateLoading: false,
  certificateError: null,
});

const fetchCertificateFail = (
  state: CertificateStateType,
  action: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateError: action.certificateError,
  certificateLoading: false,
});

const fetchCertificateTotalCountStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateLoading: true,
});

const fetchCertificateTotalCountSuccess = (
  state: CertificateStateType,
  action: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateTotalCountLoading: false,
  certificateTotalCount: action.certificateTotalCount,
});

const fetchCertificateTotalCountFail = (
  state: CertificateStateType,
  action: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateLoading: false,
  certificateTotalCountError: action.certificateTotalCountError,
});

const fetchAllUserCertificateHashesStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateHashesLoading: true,
});

const fetchAllUserCertificateHashesSuccess = (
  state: CertificateStateType,
  action: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateHashesLoading: false,
  certificateHashes: action.certificateHashes,
});

const fetchAllUserCertificateHashesFail = (
  state: CertificateStateType,
  action: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateHashesLoading: false,
  certificateHashesError: action.certificateHashesError,
});

const createExternalCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  createExternalCertificateLoading: true,
});

const createExternalCertificateSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  createExternalCertificateLoading: false,
  createExternalCertificateSuccess: true,
  externalCertificate: action.externalCertificate,
});

const createExternalCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  createExternalCertificateLoading: false,
  createExternalCertificateError: action.createExternalCertificateError,
});

const updateExternalCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  updateExternalCertificateLoading: true,
  updateExternalCertificateSuccess: false,
});

const updateExternalCertificateSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  updateExternalCertificateLoading: false,
  updateExternalCertificateSuccess: true,
  externalCertificate: action.externalCertificate,
  certificateListUpdateNeeded: true,
});

const updateExternalCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  updateExternalCertificateLoading: false,
  updateExternalCertificateError: action.updateExternalCertificateError,
});

const reportIssueStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  reportIssueLoading: true,
  reportIssueSuccess: false,
});

const reportIssueSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  reportIssueLoading: false,
  reportIssueError: null,
  reportIssueSuccess: true,
});

const reportIssueFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  reportIssueError: action.reportIssueError,
  reportIssueLoading: false,
  reportIssueSuccess: false,
});

const downloadFileStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  downloadFileLoading: true,
});

const downloadFileSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => {
  return {
    ...state,
    downloadFileLoading: false,
    downloadFileError: null,
    downloadedFile: action.downloadedFile,
  };
};

const downloadFileFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  downloadFileError: action.downloadFileError,
  downloadFileLoading: false,
});

const prepareToSignCertificateStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  prepareToSignLoading: true,
  prepareToSignSuccess: false,
  prepareToSignBatchId: null,
});

const prepareToSignCertificateSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  prepareToSignLoading: false,
  prepareToSignError: null,
  prepareToSignSuccess: true,
  prepareToSignBatchId: action.prepareToSignBatchId,
});

const prepareToSignCertificateFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  prepareToSignError: action.prepareToSignError,
  prepareToSignLoading: false,
  prepareToSignSuccess: false,
});

const prepareToSignCertificateReset = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  signUrl: null,
  signUrlBatchId: null,
  prepareToSignSuccess: false,
  prepareToSignError: null,
  prepareToSignBatchId: null,
  startSignProcessSuccess: false,
  startSignProcessBatchId: null,
  startSignProcessError: null,
});

const startSignProcessStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  startSignProcessLoading: true,
  startSignProcessError: null,
  startSignProcessBatchId: null,
  startSignProcessSuccess: false,
});

const startSignProcessSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  startSignProcessLoading: false,
  startSignProcessError: null,
  startSignProcessSuccess: true,
  startSignProcessBatchId: action.startSignProcessBatchId,
});

const startSignProcessFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  startSignProcessError: action.startSignProcessError,
  startSignProcessLoading: false,
  startSignProcessSuccess: false,
});

const getSignUrlStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  signUrlLoading: true,
});

const getSignUrlSuccess = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  signUrlLoading: false,
  signUrlError: null,
  signUrl: action.signUrl,
  signUrlBatchId: action.signUrlBatchId,
});

const getSignUrlFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  signUrlError: action.signUrlError,
  signUrlLoading: false,
});

const updateCertificateStatusStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateStatusUpdateLoading: true,
  updateExternalCertificateSuccess: false,
});

const updateCertificateStatusSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateStatusUpdateLoading: false,
  certificateStatusUpdateError: null,
  certificateStatusUpdateSuccess: true,
});

const updateCertificateStatusFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  certificateStatusUpdateLoading: false,
  certificateStatusUpdateError: action.certificateStatusUpdateError,
});

const resetAllUserCertificateHashes = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificateHashes: null,
  certificateHashesLoading: false,
  certificateHashesError: null,
});

const exportCertificatesToPdfStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificatesToPdfExportLoading: true,
});

const exportCertificatesToPdfSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificatesToPdfExportLoading: false,
  certificatesToPdfExportError: null,
  certificatesToPdfExportSuccess: true,
});

const exportCertificatesToPdfFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  certificatesToPdfExportError: action.certificatesToPdfExportError,
  certificatesToPdfExportLoading: false,
});

const resetExportCertificatesToPdf = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  certificatesToPdfExportLoading: false,
  certificatesToPdfExportSuccess: false,
  certificatesToPdfExportError: null,
});

const resetStore = (): CertificateStateType => ({
  ...initialState,
});

const externalCertificateConfirmStart = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  externalCertificateConfirmLoading: true,
  updateExternalCertificateSuccess: false,
});

const externalCertificateConfirmSuccess = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  externalCertificateConfirmLoading: false,
  externalCertificateConfirmError: null,
  externalCertificateConfirmSuccess: true,
});

const externalCertificateConfirmFail = (
  state: CertificateStateType,
  action: CertificateActionType,
): CertificateStateType => ({
  ...state,
  externalCertificateConfirmLoading: false,
  externalCertificateConfirmError: action.certificateStatusUpdateError,
});

const resetExternalCertificateStore = (
  state: CertificateStateType,
): CertificateStateType => ({
  ...state,
  createExternalCertificateLoading: false,
  createExternalCertificateError: null,
  createExternalCertificateSuccess: false,
  updateExternalCertificateLoading: false,
  updateExternalCertificateSuccess: true,
  updateExternalCertificateError: null,
  externalCertificate: null,
});

const reducer = (state = initialState, action: CertificateActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CERTIFICATES_START:
      return fetchCertificatesStart(state);
    case actionTypes.FETCH_CERTIFICATES_SUCCESS:
      return fetchCertificatesSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATES_FAIL:
      return fetchCertificatesFail(state, action);
    case actionTypes.FETCH_CERTIFICATE_TOTAL_COUNT_START:
      return fetchCertificateTotalCountStart(state);
    case actionTypes.FETCH_CERTIFICATE_TOTAL_COUNT_SUCCESS:
      return fetchCertificateTotalCountSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_TOTAL_COUNT_FAIL:
      return fetchCertificateTotalCountFail(state, action);
    case actionTypes.FETCH_ALL_USER_CERTIFICATES_HASHES_START:
      return fetchAllUserCertificateHashesStart(state);
    case actionTypes.FETCH_ALL_USER_CERTIFICATES_HASHES_SUCCESS:
      return fetchAllUserCertificateHashesSuccess(state, action);
    case actionTypes.FETCH_ALL_USER_CERTIFICATES_HASHES_FAIL:
      return fetchAllUserCertificateHashesFail(state, action);
    case actionTypes.SET_CERTIFICATES_FILTER:
      return setCertificatesFilter(state, action);
    case actionTypes.RESET_CERTIFICATE_STORE:
      return resetStore();
    case actionTypes.FETCH_CERTIFICATE_START:
      return fetchCertificateStart(state);
    case actionTypes.FETCH_CERTIFICATE_SUCCESS:
      return fetchCertificateSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_FAIL:
      return fetchCertificateFail(state, action);
    case actionTypes.CREATE_EXTERNAL_CERTIFICATE_START:
      return createExternalCertificateStart(state);
    case actionTypes.CREATE_EXTERNAL_CERTIFICATE_SUCCESS:
      return createExternalCertificateSuccess(state, action);
    case actionTypes.CREATE_EXTERNAL_CERTIFICATE_FAIL:
      return createExternalCertificateFail(state, action);
    case actionTypes.UPDATE_EXTERNAL_CERTIFICATE_START:
      return updateExternalCertificateStart(state);
    case actionTypes.UPDATE_EXTERNAL_CERTIFICATE_SUCCESS:
      return updateExternalCertificateSuccess(state, action);
    case actionTypes.UPDATE_EXTERNAL_CERTIFICATE_FAIL:
      return updateExternalCertificateFail(state, action);
    case actionTypes.REPORT_ISSUE_START:
      return reportIssueStart(state);
    case actionTypes.REPORT_ISSUE_SUCCESS:
      return reportIssueSuccess(state);
    case actionTypes.REPORT_ISSUE_FAIL:
      return reportIssueFail(state, action);
    case actionTypes.DOWNLOAD_FILE_START:
      return downloadFileStart(state);
    case actionTypes.DOWNLOAD_FILE_SUCCESS:
      return downloadFileSuccess(state, action);
    case actionTypes.DOWNLOAD_FILE_FAIL:
      return downloadFileFail(state, action);
    case actionTypes.PREPARE_TO_SIGN_CERTIFICATE_START:
      return prepareToSignCertificateStart(state);
    case actionTypes.PREPARE_TO_SIGN_CERTIFICATE_SUCCESS:
      return prepareToSignCertificateSuccess(state, action);
    case actionTypes.PREPARE_TO_SIGN_CERTIFICATE_RESET:
      return prepareToSignCertificateReset(state);
    case actionTypes.GET_SIGN_URL_START:
      return getSignUrlStart(state);
    case actionTypes.GET_SIGN_URL_SUCCESS:
      return getSignUrlSuccess(state, action);
    case actionTypes.GET_SIGN_URL_FAIL:
      return getSignUrlFail(state, action);
    case actionTypes.PREPARE_TO_SIGN_CERTIFICATE_FAIL:
      return prepareToSignCertificateFail(state, action);
    case actionTypes.START_SIGN_PROCESS_START:
      return startSignProcessStart(state);
    case actionTypes.START_SIGN_PROCESS_SUCCESS:
      return startSignProcessSuccess(state, action);
    case actionTypes.START_SIGN_PROCESS_FAIL:
      return startSignProcessFail(state, action);
    case actionTypes.UPDATE_CERTIFICATE_STATUS_START:
      return updateCertificateStatusStart(state);
    case actionTypes.UPDATE_CERTIFICATE_STATUS_SUCCESS:
      return updateCertificateStatusSuccess(state);
    case actionTypes.UPDATE_CERTIFICATE_STATUS_FAIL:
      return updateCertificateStatusFail(state, action);
    case actionTypes.EXPORT_CERTIFICATES_TO_PDF_START:
      return exportCertificatesToPdfStart(state);
    case actionTypes.EXPORT_CERTIFICATES_TO_PDF_SUCCESS:
      return exportCertificatesToPdfSuccess(state);
    case actionTypes.EXPORT_CERTIFICATES_TO_PDF_FAIL:
      return exportCertificatesToPdfFail(state, action);
    case actionTypes.RESET_EXPORT_CERTIFICATES_TO_PDF:
      return resetExportCertificatesToPdf(state);
    case actionTypes.RESET_EXTERNAL_CERTIFICATE_STORE:
      return resetExternalCertificateStore(state);
    case actionTypes.RESET_ALL_USER_CERTIFICATE_HASHES:
      return resetAllUserCertificateHashes(state);
    case actionTypes.CONFIRM_EXTERNAL_CERTIFICATE_START:
      return externalCertificateConfirmStart(state);
    case actionTypes.CONFIRM_EXTERNAL_CERTIFICATE_SUCCESS:
      return externalCertificateConfirmSuccess(state);
    case actionTypes.CONFIRM_EXTERNAL_CERTIFICATE_FAIL:
      return externalCertificateConfirmFail(state, action);
    default:
      return state;
  }
};

export default reducer;
