import * as actionTypes from './actionTypes';
import { ReviewActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Review } from '../../domain/Review';

export type ReviewStateType = {
  setReviewLoading: boolean;
  setReviewSuccess: boolean;
  setReviewError: HttpError;
  review: Review | null;
  reviewLoading: boolean;
  reviewError: HttpError;
};

export type ReviewActionType = ReviewStateType & {
  type: ReviewActionTypes;
};

export const initialState: ReviewStateType = {
  setReviewLoading: true,
  setReviewSuccess: false,
  setReviewError: null,
  review: null,
  reviewLoading: false,
  reviewError: null,
};

const fetchReviewStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewLoading: true,
});

const fetchReviewSuccess = (
  state: ReviewStateType,
  action: ReviewStateType,
): ReviewStateType => ({
  ...state,
  review: action.review,
  reviewLoading: false,
  reviewError: null,
});

const fetchReviewFail = (
  state: ReviewStateType,
  action: ReviewStateType,
): ReviewStateType => ({
  ...state,
  reviewError: action.reviewError,
  reviewLoading: false,
});

const setReviewStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  setReviewLoading: true,
});

const setReviewSuccess = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  setReviewLoading: false,
  setReviewError: null,
  setReviewSuccess: true,
});

const setReviewFail = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  setReviewError: action.setReviewError,
  setReviewLoading: false,
});

const reducer = (state = initialState, action: ReviewActionType) => {
  switch (action.type) {
    case actionTypes.SET_REVIEW_START:
      return setReviewStart(state);
    case actionTypes.SET_REVIEW_SUCCESS:
      return setReviewSuccess(state);
    case actionTypes.SET_REVIEW_FAIL:
      return setReviewFail(state, action);
    case actionTypes.FETCH_REVIEW_START:
      return fetchReviewStart(state);
    case actionTypes.FETCH_REVIEW_SUCCESS:
      return fetchReviewSuccess(state, action);
    case actionTypes.FETCH_REVIEW_FAIL:
      return fetchReviewFail(state, action);
    default:
      return state;
  }
};

export default reducer;
