import axios from '../../config/Axios/axios-instance';
import {
  fetchLanguageFail,
  fetchLanguageStart,
  fetchLanguageSuccess,
  fetchEventLanguageStart,
  fetchEventLanguageSuccess,
  fetchEventLanguageFail,
  fetchLanguagesStart,
  fetchLanguagesSuccess,
  fetchLanguagesFail,
  fetchCertificateLanguagesFail,
  fetchCertificateLanguagesSuccess,
  fetchCertificateLanguagesStart,
} from './actions';
import { Dispatch } from 'redux';
import { showToast } from '../../utility/toast/toast';

const API_LANGUAGE_URL = '/languages';

export const fetchLanguage = (locale: string) => (dispatch: Dispatch) => {
  dispatch(fetchLanguageStart());
  return axios
    .get(`${API_LANGUAGE_URL}/${locale}`)
    .then((response) => {
      dispatch(fetchLanguageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguageFail(err.response.data.error));
      showToast(err.response.data.message, 'error');
    });
};

export const fetchEventLanguage = (locale: string) => (dispatch: Dispatch) => {
  dispatch(fetchEventLanguageStart());
  return axios
    .get(`${API_LANGUAGE_URL}/${locale}`)
    .then((response) => {
      dispatch(fetchEventLanguageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchEventLanguageFail(err.response.data.error));
      showToast(err.response.data.message, 'error');
    });
};

export const fetchLanguages = () => (dispatch: Dispatch) => {
  dispatch(fetchLanguagesStart());
  return axios
    .get(API_LANGUAGE_URL)
    .then((response) => {
      dispatch(fetchLanguagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLanguagesFail(err.response.data.error));
      showToast(err.response.data.message, 'error');
    });
};

export const fetchLanguagesForCertificate = () => (dispatch: Dispatch) => {
  dispatch(fetchCertificateLanguagesStart());
  return axios
    .get(`${API_LANGUAGE_URL}/certificate`)
    .then((response) => {
      dispatch(fetchCertificateLanguagesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCertificateLanguagesFail(err.response.data.error));
      showToast(err.response.data.message, 'error');
    });
};
