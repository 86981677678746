import * as authService from './auth/service';
import * as certificateService from './certificate/service';
import * as certificateTypeService from './certificateType/service';
import * as eventService from './event/service';
import * as eventPurposeService from './eventPurpose/service';
import * as eventRepresentativeService from './eventRepresentative/service';
import * as eventTypeService from './eventType/service';
import * as organisationService from './organisation/service';
import * as participantService from './participant/service';
import * as participantActivityService from './participantActivity/service';
import * as translationService from './translation/service';
import * as invoiceService from './invoice/service';
import * as organiserService from './organiser/service';
import * as globalSettingService from './globalSetting/service';
import routes from '../config/Router/routes';

export const API_URLS = {
  auth: routes.auth.main,
  certificate: routes.certificates.list,
  certificateType: routes.certificateTypes.list,
  events: routes.events.list,
  eventPurpose: routes.eventPurposes.list,
  eventRepresentative: routes.eventRepresentatives.list,
  eventType: routes.eventTypes.list,
  organisation: routes.organisations.list,
  participant: routes.participants,
  participantActivity: routes.participantActivities.list,
  translation: routes.translations,
  users: routes.users.list,
  orders: routes.orders,
  balanceHistory: routes.balanceHistory.list,
  invoice: routes.invoice,
  organiser: routes.organisers.list,
  review: routes.review,
  company: routes.companies.list,
  contact: routes.contact,
  globalSetting: routes.globalSettings,
};

const mapServices = {
  [API_URLS.auth]: authService,
  [API_URLS.certificate]: certificateService,
  [API_URLS.certificateType]: certificateTypeService,
  [API_URLS.events]: eventService,
  [API_URLS.eventPurpose]: eventPurposeService,
  [API_URLS.eventRepresentative]: eventRepresentativeService,
  [API_URLS.eventType]: eventTypeService,
  [API_URLS.organisation]: organisationService,
  [API_URLS.participant]: participantService,
  [API_URLS.participantActivity]: participantActivityService,
  [API_URLS.translation]: translationService,
  [API_URLS.invoice]: invoiceService,
  [API_URLS.organiser]: organiserService,
  [API_URLS.globalSetting]: globalSettingService,
};

export const resolveService = (
  url: string,
  method: string,
  params?: any,
  data?: any,
) => {
  const urlPrefix = '/' + url.split('/')[1];

  const foundServiceUrl = Object.keys(mapServices).find(
    (serviceUrl) => serviceUrl === urlPrefix,
  );

  if (!foundServiceUrl) {
    return;
  }

  // @ts-ignore
  return mapServices[foundServiceUrl].resolveHttpMethod(
    url,
    method,
    params,
    data ? (data instanceof FormData ? data : JSON.parse(data)) : undefined,
  );
};
