export const LOGIN_GOOGLE_START = 'LOGIN_GOOGLE_START';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAIL = 'LOGIN_GOOGLE_FAIL';

export const LOGIN_FACEBOOK_START = 'LOGIN_FACEBOOK_START';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_FACEBOOK_FAIL = 'LOGIN_FACEBOOK_FAIL';

export const CONNECT_USER_START = 'CONNECT_USER_START';
export const CONNECT_USER_SUCCESS = 'CONNECT_USER_SUCCESS';
export const CONNECT_USER_FAIL = 'CONNECT_USER_FAIL';

export const FETCH_CONNECTED_USERS_START = 'FETCH_CONNECTED_USERS_START';
export const FETCH_CONNECTED_USERS_SUCCESS = 'FETCH_CONNECTED_USERS_SUCCESS';
export const FETCH_CONNECTED_USERS_FAIL = 'FETCH_CONNECTED_USERS_FAIL';

export const CONFIRM_CONNECT_USER_START = 'CONFIRM_CONNECT_USER_START';
export const CONFIRM_CONNECT_USER_SUCCESS = 'CONFIRM_CONNECT_USER_SUCCESS';
export const CONFIRM_CONNECT_USER_FAIL = 'CONFIRM_CONNECT_USER_FAIL';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const REMIND_START = 'REMIND_START';
export const REMIND_SUCCESS = 'REMIND_SUCCESS';
export const REMIND_FAIL = 'REMIND_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const VALIDATE_CODE_START = 'VALIDATE_CODE_START';
export const VALIDATE_CODE_SUCCESS = 'VALIDATE_CODE_SUCCESS';
export const VALIDATE_CODE_FAIL = 'VALIDATE_CODE_FAIL';

export const REGISTER_CONFIRM_START = 'REGISTER_CONFIRM_START';
export const REGISTER_CONFIRM_SUCCESS = 'REGISTER_CONFIRM_SUCCESS';
export const REGISTER_CONFIRM_FAIL = 'REGISTER_CONFIRM_FAIL';

export const DELETE_CONNECTED_USER_START = 'DELETE_CONNECTED_USER_START';
export const DELETE_CONNECTED_USER_SUCCESS = 'DELETE_CONNECTED_USER_SUCCESS';
export const DELETE_CONNECTED_USER_FAIL = 'DELETE_CONNECTED_USER_FAIL';

export const REFRESH_TOKEN_START = 'REFRESH_TOKEN_START';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';

export const RESET_CONNECT = 'RESET_CONNECT';

export const SET_VIEWED_PROFILE = 'SET_VIEWED_PROFILE';
export const SET_ROLE = 'SET_ROLE';

export const SELECT_ROLE_START = 'SELECT_ROLE_START';
export const SELECT_ROLE_SUCCESS = 'SELECT_ROLE_SUCCESS';
export const SELECT_ROLE_FAIL = 'SELECT_ROLE_FAIL';

export const LOGOUT = 'LOGOUT';

export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';

export type AuthActionTypes =
  | typeof LOGIN_GOOGLE_START
  | typeof LOGIN_GOOGLE_SUCCESS
  | typeof LOGIN_GOOGLE_FAIL
  | typeof LOGIN_FACEBOOK_START
  | typeof LOGIN_FACEBOOK_SUCCESS
  | typeof LOGIN_FACEBOOK_FAIL
  | typeof CONNECT_USER_START
  | typeof CONNECT_USER_SUCCESS
  | typeof CONNECT_USER_FAIL
  | typeof FETCH_CONNECTED_USERS_START
  | typeof FETCH_CONNECTED_USERS_SUCCESS
  | typeof FETCH_CONNECTED_USERS_FAIL
  | typeof CONFIRM_CONNECT_USER_START
  | typeof CONFIRM_CONNECT_USER_SUCCESS
  | typeof CONFIRM_CONNECT_USER_FAIL
  | typeof LOGIN_START
  | typeof LOGIN_SUCCESS
  | typeof LOGIN_FAIL
  | typeof REGISTER_START
  | typeof REGISTER_SUCCESS
  | typeof REGISTER_FAIL
  | typeof REMIND_START
  | typeof REMIND_SUCCESS
  | typeof REMIND_FAIL
  | typeof RESET_PASSWORD_START
  | typeof RESET_PASSWORD_SUCCESS
  | typeof RESET_PASSWORD_FAIL
  | typeof VALIDATE_CODE_START
  | typeof VALIDATE_CODE_SUCCESS
  | typeof VALIDATE_CODE_FAIL
  | typeof RESET_CONNECT
  | typeof SET_VIEWED_PROFILE
  | typeof SET_ROLE
  | typeof SELECT_ROLE_START
  | typeof SELECT_ROLE_SUCCESS
  | typeof SELECT_ROLE_FAIL
  | typeof REGISTER_CONFIRM_START
  | typeof REGISTER_CONFIRM_SUCCESS
  | typeof REGISTER_CONFIRM_FAIL
  | typeof DELETE_CONNECTED_USER_START
  | typeof DELETE_CONNECTED_USER_SUCCESS
  | typeof DELETE_CONNECTED_USER_FAIL
  | typeof REFRESH_TOKEN_START
  | typeof REFRESH_TOKEN_SUCCESS
  | typeof REFRESH_TOKEN_FAIL
  | typeof LOGOUT
  | typeof SELECT_LANGUAGE
  | typeof RESET_LOGIN_ERROR;
