import * as actionTypes from './actionTypes';
import { ParticipantActivityActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  ParticipantActivity,
  ParticipantActivitiesList,
} from '../../domain/ParticipantActivity';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type ParticipantActivityStateType = {
  participantActivitiesList: ParticipantActivitiesList | null;
  participantActivitiesListLoading: boolean;
  participantActivitiesListError: HttpError;
  participantActivitiesFilter: ListParams;
  participantActivitiesListUpdateNeeded: boolean;
  participantActivity: ParticipantActivity | null;
  participantActivityLoading: boolean;
  participantActivityError: HttpError;
  participantActivityCreateLoading: boolean;
  participantActivityCreateError: HttpError;
  participantActivityCreateSuccess: boolean;
  participantActivityUpdateLoading: boolean;
  participantActivityUpdateError: HttpError;
  participantActivityUpdateSuccess: boolean;
  participantActivityDeleteLoading: boolean;
  participantActivityDeleteError: HttpError;
  createdParticipantActivity: ParticipantActivity | null;
};

export type ParticipantActivityActionType = ParticipantActivityStateType & {
  type: ParticipantActivityActionTypes;
};

export const initialState: ParticipantActivityStateType = {
  participantActivitiesList: null,
  participantActivitiesListLoading: true,
  participantActivitiesListError: null,
  participantActivitiesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  participantActivitiesListUpdateNeeded: false,
  participantActivity: null,
  participantActivityLoading: false,
  participantActivityError: null,
  participantActivityCreateLoading: false,
  participantActivityCreateError: null,
  participantActivityCreateSuccess: false,
  participantActivityUpdateLoading: false,
  participantActivityUpdateError: null,
  participantActivityUpdateSuccess: false,
  participantActivityDeleteLoading: false,
  participantActivityDeleteError: null,
  createdParticipantActivity: null,
};

const fetchParticipantActivitiesStart = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivitiesListLoading: true,
  participantActivityUpdateSuccess: false,
  participantActivityCreateSuccess: false,
  participantActivityDeleteError: null,
  participantActivity: null,
});

const fetchParticipantActivitiesSuccess = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivitiesList: action.participantActivitiesList,
  participantActivitiesListLoading: false,
  participantActivitiesListError: null,
  participantActivitiesListUpdateNeeded: false,
});

const fetchParticipantActivitiesFail = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivitiesListError: action.participantActivitiesListError,
  participantActivitiesListLoading: false,
});

const fetchParticipantActivityStart = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityLoading: true,
  participantActivityUpdateSuccess: false,
  participantActivityCreateSuccess: false,
  participantActivityCreateError: null,
  participantActivityUpdateError: null,
});

const fetchParticipantActivitySuccess = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivity: action.participantActivity,
  participantActivityLoading: false,
  participantActivityError: null,
});

const fetchParticipantActivityFail = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityError: action.participantActivityError,
  participantActivityLoading: false,
});

const createParticipantActivityStart = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityCreateLoading: true,
});

const createParticipantActivitySuccess = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityCreateLoading: false,
  participantActivityCreateError: null,
  participantActivityCreateSuccess: true,
  participantActivitiesListUpdateNeeded: true,
  createdParticipantActivity: action.createdParticipantActivity,
});

const createParticipantActivityFail = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityCreateLoading: false,
  participantActivityCreateError: action.participantActivityCreateError,
});

const updateParticipantActivityStart = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityUpdateLoading: true,
});

const updateParticipantActivitySuccess = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityUpdateLoading: false,
  participantActivityUpdateError: null,
  participantActivityUpdateSuccess: true,
  participantActivitiesListUpdateNeeded: true,
});

const updateParticipantActivityFail = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityUpdateLoading: false,
  participantActivityUpdateError: action.participantActivityUpdateError,
});

const deleteParticipantActivityStart = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityDeleteLoading: true,
});

const deleteParticipantActivitySuccess = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityDeleteLoading: false,
  participantActivityDeleteError: null,
  participantActivitiesListUpdateNeeded: true,
});

const deleteParticipantActivityFail = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivityDeleteLoading: false,
  participantActivityDeleteError: action.participantActivityDeleteError,
});

const setParticipantActivitiesFilter = (
  state: ParticipantActivityStateType,
  action: ParticipantActivityActionType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivitiesFilter: action.participantActivitiesFilter,
});

const resetStore = (): ParticipantActivityStateType => ({
  participantActivitiesList: null,
  participantActivitiesListLoading: true,
  participantActivitiesListError: null,
  participantActivitiesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  participantActivitiesListUpdateNeeded: false,
  participantActivity: null,
  participantActivityLoading: false,
  participantActivityError: null,
  participantActivityCreateLoading: false,
  participantActivityCreateError: null,
  participantActivityCreateSuccess: false,
  participantActivityUpdateLoading: false,
  participantActivityUpdateError: null,
  participantActivityUpdateSuccess: false,
  participantActivityDeleteLoading: false,
  participantActivityDeleteError: null,
  createdParticipantActivity: null,
});

const setUpdateNeeded = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  participantActivitiesListUpdateNeeded: true,
});

const resetCreatedParticipantActivity = (
  state: ParticipantActivityStateType,
): ParticipantActivityStateType => ({
  ...state,
  createdParticipantActivity: null,
});

const reducer = (
  state = initialState,
  action: ParticipantActivityActionType,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PARTICIPANT_ACTIVITIES_START:
      return fetchParticipantActivitiesStart(state);
    case actionTypes.FETCH_PARTICIPANT_ACTIVITIES_SUCCESS:
      return fetchParticipantActivitiesSuccess(state, action);
    case actionTypes.FETCH_PARTICIPANT_ACTIVITIES_FAIL:
      return fetchParticipantActivitiesFail(state, action);
    case actionTypes.FETCH_PARTICIPANT_ACTIVITY_START:
      return fetchParticipantActivityStart(state);
    case actionTypes.FETCH_PARTICIPANT_ACTIVITY_SUCCESS:
      return fetchParticipantActivitySuccess(state, action);
    case actionTypes.FETCH_PARTICIPANT_ACTIVITY_FAIL:
      return fetchParticipantActivityFail(state, action);
    case actionTypes.CREATE_PARTICIPANT_ACTIVITY_START:
      return createParticipantActivityStart(state);
    case actionTypes.CREATE_PARTICIPANT_ACTIVITY_SUCCESS:
      return createParticipantActivitySuccess(state, action);
    case actionTypes.CREATE_PARTICIPANT_ACTIVITY_FAIL:
      return createParticipantActivityFail(state, action);
    case actionTypes.UPDATE_PARTICIPANT_ACTIVITY_START:
      return updateParticipantActivityStart(state);
    case actionTypes.UPDATE_PARTICIPANT_ACTIVITY_SUCCESS:
      return updateParticipantActivitySuccess(state);
    case actionTypes.UPDATE_PARTICIPANT_ACTIVITY_FAIL:
      return updateParticipantActivityFail(state, action);
    case actionTypes.DELETE_PARTICIPANT_ACTIVITY_START:
      return deleteParticipantActivityStart(state);
    case actionTypes.DELETE_PARTICIPANT_ACTIVITY_SUCCESS:
      return deleteParticipantActivitySuccess(state);
    case actionTypes.DELETE_PARTICIPANT_ACTIVITY_FAIL:
      return deleteParticipantActivityFail(state, action);
    case actionTypes.SET_PARTICIPANT_ACTIVITIES_FILTER:
      return setParticipantActivitiesFilter(state, action);
    case actionTypes.RESET_PARTICIPANT_ACTIVITY_STORE:
      return resetStore();
    case actionTypes.SET_PARTICIPANT_ACTIVITY_UPDATE_NEEDED:
      return setUpdateNeeded(state);
    case actionTypes.RESET_CREATED_PARTICIPANT_ACTIVITY:
      return resetCreatedParticipantActivity(state);
    default:
      return state;
  }
};

export default reducer;
