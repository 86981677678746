import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';

export const downloadInvoiceStart = () => ({
  type: actionTypes.DOWNLOAD_INVOICE_START,
});

export const downloadInvoiceSuccess = () => ({
  type: actionTypes.DOWNLOAD_INVOICE_SUCCESS,
});

export const downloadInvoiceFail = (downloadInvoiceError: HttpError) => ({
  type: actionTypes.DOWNLOAD_INVOICE_FAIL,
  downloadInvoiceError,
});
