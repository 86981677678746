export const FETCH_BALANCE_HISTORY_START = 'FETCH_BALANCE_HISTORY_START';
export const FETCH_BALANCE_HISTORY_SUCCESS = 'FETCH_BALANCE_HISTORY_SUCCESS';
export const FETCH_BALANCE_HISTORY_FAIL = 'FETCH_BALANCE_HISTORY_FAIL';

export const SET_BALANCE_HISTORY_FILTER = 'SET_BALANCE_HISTORY_FILTER';

export type BalanceHistoryActionTypes =
  | typeof FETCH_BALANCE_HISTORY_START
  | typeof FETCH_BALANCE_HISTORY_SUCCESS
  | typeof FETCH_BALANCE_HISTORY_FAIL
  | typeof SET_BALANCE_HISTORY_FILTER;
