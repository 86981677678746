import axios from '../../config/Axios/axios-instance';
import {
  fetchCertificateFail,
  fetchCertificatesFail,
  fetchCertificatesStart,
  fetchCertificatesSuccess,
  fetchCertificateStart,
  fetchCertificateSuccess,
  setCertificatesFilterValues,
  reportIssueStart,
  reportIssueSuccess,
  reportIssueFail,
  downloadFileFail,
  downloadFileStart,
  downloadFileSuccess,
  createExternalCertificateStart,
  createExternalCertificateSuccess,
  createExternalCertificateFail,
  resetCertificateStore,
  prepareToSignCertificateStart,
  prepareToSignCertificateSuccess,
  prepareToSignCertificateFail,
  getSignUrlStart,
  getSignUrlStartSuccess,
  getSignUrlStartFail,
  startSignProcessStart,
  startSignProcessSuccess,
  startSignProcessFail,
  fetchCertificateTotalCountStart,
  fetchCertificateTotalCountSuccess,
  fetchCertificateTotalCountFail,
  fetchAllUserCertificateHashesSuccess,
  fetchAllUserCertificateHashesFail,
  fetchAllUserCertificateHashesStart,
  updateCertificateStatusStart,
  updateCertificateStatusSuccess,
  updateCertificateStatusFail,
  updateExternalCertificateStart,
  updateExternalCertificateSuccess,
  updateExternalCertificateFail,
  exportCertificatesToPdfStart,
  exportCertificatesToPdfSuccess,
  exportCertificatesToPdfFail,
  confirmExternalCertificateStart,
  confirmExternalCertificateSuccess,
  confirmExternalCertificateFail,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import download from 'downloadjs';
import { CertificateFile } from '../../domain/ExternalCertificate';
import { getFormData } from '../../utility/form/formData';
import { EventHoursType } from '../../domain/Event';
import { Roles } from '../../domain/Role';
import {
  CertificateActionType,
  CertificateStatus,
} from '../../domain/Certificate';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

export type ExternalCertificateCreateRequest = {
  firstName: string;
  lastName: string;
};

export type CreateExternalCertificateDTO = {
  firstName: string;
  lastName: string;
  certificateCodes?: string[];
  eventDate?: string;
  eventName?: string;
  hours?: number;
  hoursType?: EventHoursType;
  certificateFile?: CertificateFile;
  status?: CertificateStatus;
  hash?: string;
};

export type UpdateExternalCertificateDTO = CreateExternalCertificateDTO & {
  id: number;
};

export type GetCertificateRequest = {
  companyId?: number | null;
  selectedRole?: Roles | null;
};

export type StartSignProcessRequest = {
  certificateIds: number[];
  eventId: number;
  selectedRole: Roles | null;
};

export type SignCertificateRequest = {
  id: number;
  file: File;
  batchId: string;
  isMulti: boolean;
};

export type GetSignUrlRequest = {
  batchId: string;
};

export type FetchCertificatesParams = ListParams & {
  dateFrom?: string;
  dateTo?: string;
};

export type ExportCertificatesToPdfRequest = {
  type: string;
  certificateIds: number[];
  certificateFiles?: File[];
};

export const fetchCertificates =
  (params: FetchCertificatesParams, intl: IntlShape, saveFilters?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchCertificatesStart());

    if (saveFilters) {
      dispatch(setCertificatesFilterValues(params));
    }

    return axios
      .get(API_URLS.certificate, { params })
      .then((response) => {
        dispatch(fetchCertificatesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCertificatesFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(translate(intl, err.response.data.message), 'error');
        }
      });
  };

export const fetchCertificate =
  (certificateId: number, params: GetCertificateRequest) =>
  (dispatch: Dispatch) => {
    dispatch(fetchCertificateStart());
    return axios
      .get(`${API_URLS.certificate}/${certificateId}`, { params })
      .then((response) => {
        dispatch(fetchCertificateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCertificateFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const fetchAllUserCertificateHashes = () => (dispatch: Dispatch) => {
  dispatch(fetchAllUserCertificateHashesStart());
  return axios
    .get(`${API_URLS.certificate}/all-user-certificate-hashes`)
    .then((response) => {
      dispatch(fetchAllUserCertificateHashesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllUserCertificateHashesFail(err?.response?.data?.message));
      showToast(err?.response?.data?.message, 'error');
    });
};

export const createExternalCertificate =
  (inputs: CreateExternalCertificateDTO, intl?: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createExternalCertificateStart());
    return axios
      .post(
        `${API_URLS.certificate}/create-external-certificate`,
        getFormData(inputs),
      )
      .then((response) => {
        dispatch(createExternalCertificateSuccess(response.data));

        if (intl) {
          showToast(
            translate(intl, 'SUCCESS.CERTIFICATE_UPLOAD_SUCCESS'),
            'success',
          );
        }
      })
      .catch((err) => {
        dispatch(createExternalCertificateFail(err?.response?.data?.message));
      });
  };

export const updateExternalCertificate =
  (
    inputs: UpdateExternalCertificateDTO,
    intl: IntlShape,
    certificateActionType: CertificateActionType,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(updateExternalCertificateStart());
    return axios
      .patch(
        `${API_URLS.certificate}/external-certificate/${inputs.id}`,
        getFormData(inputs),
      )
      .then((response) => {
        dispatch(updateExternalCertificateSuccess(response.data));

        if (certificateActionType === CertificateActionType.UPLOAD) {
          showToast(
            translate(intl, 'SUCCESS.CERTIFICATE_UPLOAD_SUCCESS'),
            'success',
          );
        }

        if (certificateActionType === CertificateActionType.UPDATE) {
          showToast(
            translate(intl, 'SUCCESS.EXTERNAL_CERTIFICATE_UPDATE'),
            'success',
          );
        }
      })
      .catch((err) => {
        dispatch(updateExternalCertificateFail(err?.response?.data?.message));
      });
  };

export const reportIssue =
  (certificateId: number, description: string, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(reportIssueStart());
    return axios
      .post(`${API_URLS.certificate}/${certificateId}/reportIssue`, {
        description,
      })
      .then((response) => {
        dispatch(reportIssueSuccess());
        showToast(translate(intl, 'SUCCESS.REPORT_ISSUE'), 'success');
      })
      .catch((err) => {
        dispatch(reportIssueFail(err.response.data.message));
      });
  };

export const downloadFile =
  (
    certificateId: number,
    assetId: number,
    fileName?: string,
    onlyDownload: boolean = true,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(downloadFileStart());
    return axios
      .get(
        `${API_URLS.certificate}/${certificateId}/download-file/${assetId}`,
        { responseType: 'blob' },
      )
      .then((response) => {
        const blob = response.data;
        const file = new File([blob], 'filename', { type: blob.type });
        dispatch(downloadFileSuccess(onlyDownload ? null : file));

        if (onlyDownload) {
          download(
            new Blob([response.data], { type: 'application/pdf' }),
            fileName,
            'application/pdf',
          );
        }
      })
      .catch((err) => {
        dispatch(downloadFileFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };

export const startSignProcess =
  (inputs: StartSignProcessRequest) => (dispatch: Dispatch) => {
    dispatch(startSignProcessStart());
    return axios
      .post(`${API_URLS.certificate}/start-sign-process`, { ...inputs })
      .then((response) => {
        dispatch(startSignProcessSuccess(response.data));
      })
      .catch((err) => {
        dispatch(startSignProcessFail(err.response.data.message));
      });
  };

export const prepareToSignCertificate =
  (inputs: SignCertificateRequest) => (dispatch: Dispatch) => {
    const { id, ...props } = inputs;
    dispatch(prepareToSignCertificateStart());
    return axios
      .post(`${API_URLS.certificate}/${id}/prepare-to-sign`, getFormData(props))
      .then(() => {
        dispatch(prepareToSignCertificateSuccess(inputs.batchId));
      })
      .catch((err) => {
        dispatch(prepareToSignCertificateFail(err.response.data.message));
      });
  };

export const getSignUrl =
  (inputs: GetSignUrlRequest) => (dispatch: Dispatch) => {
    dispatch(getSignUrlStart());
    return axios
      .post(`${API_URLS.certificate}/sign-url`, { ...inputs })
      .then((response) => {
        dispatch(getSignUrlStartSuccess(response.data, inputs.batchId));
      })
      .catch((err) => {
        dispatch(getSignUrlStartFail(err.response.data.message));
      });
  };

export const resetCertificatesStore = () => (dispatch: Dispatch) => {
  dispatch(resetCertificateStore());
};

export const fetchCertificateTotalCount = () => (dispatch: Dispatch) => {
  dispatch(fetchCertificateTotalCountStart());
  return axios
    .get(`${API_URLS.certificate}/total-count`)
    .then((response) => {
      dispatch(fetchCertificateTotalCountSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCertificateTotalCountFail(err?.response?.data?.message));
    });
};

export const updateCertificateStatus =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateCertificateStatusStart());

    return axios
      .patch(`${API_URLS.certificate}/${id}`)
      .then(() => {
        dispatch(updateCertificateStatusSuccess());
        showToast(
          translate(intl, 'SUCCESS.CERTIFICATE_STATUS_UPDATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(updateCertificateStatusFail(err.response.data.message));
      });
  };

export const exportCertificatesToPdf =
  (inputs: ExportCertificatesToPdfRequest) => (dispatch: Dispatch) => {
    dispatch(exportCertificatesToPdfStart());

    return axios
      .post(
        `${API_URLS.certificate}/export-certificates-pdf`,
        getFormData(inputs),
        { responseType: 'blob' },
      )
      .then((response) => {
        const fileName = 'combined-certificates.pdf';
        const mimeType = 'application/pdf';

        dispatch(exportCertificatesToPdfSuccess());
        download(
          new Blob([response.data], { type: mimeType }),
          fileName,
          mimeType,
        );
      })
      .catch(async (err) => {
        const errorResponse = JSON.parse(await err.response.data.text());

        dispatch(exportCertificatesToPdfFail(errorResponse.message));
      });
  };

export const confirmExternalCertificate =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(confirmExternalCertificateStart());

    return axios
      .patch(`${API_URLS.certificate}/confirm/${id}`)
      .then(() => {
        dispatch(confirmExternalCertificateSuccess());
        showToast(translate(intl, 'SUCCESS.CONFIRM_CERTIFICATE'), 'success');
      })
      .catch((err) => {
        dispatch(confirmExternalCertificateFail(err.response.data.message));
      });
  };
