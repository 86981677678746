import { Certificate, CertificateTemplate } from './Certificate';
import { Participant } from './Participant';
import { Organisation } from './Organisation';
import { CertificateType } from './CertificateType';
import { ParticipantActivity } from './ParticipantActivity';
import { EventType } from './EventType';
import { EventPurpose } from './EventPurpose';
import { EventRepresentative } from './EventRepresentative';
import { TemplateSettings } from './TemplateSettings';
import { Company } from './Company';
import { Asset } from './Asset';

export interface Event {
  id: number;
  name: string;
  hours: number;
  hoursType: EventHoursType;
  eventDate: string;
  eventDateTo: string | null;
  certificateIssueDate: string | null;
  prefixes: string[];
  purpose: string;
  location: string;
  certificateType: CertificateType;
  certificateTemplate: CertificateTemplate;
  certificates?: Certificate[];
  participants?: Participant[];
  organisations: Organisation[];
  participantActivity: ParticipantActivity;
  eventType: EventType;
  eventPurposes: EventPurpose[];
  eventRepresentatives: EventRepresentative[];
  documents?: Asset[];
  programme?: Asset[];
  background?: Asset;
  isExternal: boolean;
  isPersonal: boolean;
  templateSettings: TemplateSettings;
  templateSettingsId: number;
  eventLanguage: EventLanguage;
  hash?: string;
  company: Company;
  companyId: number;
  status: EventStatus;
  country?: string;
  city?: string;
}

export type EventsList = {
  result: Event[];
  total: number;
};

export interface CertificationState {
  isAllNotified: boolean;
  isAllGenerated: boolean;
}

export enum EventHoursType {
  // eslint-disable-next-line no-unused-vars
  DEFAULT = 'DEFAULT',
  // eslint-disable-next-line no-unused-vars
  ACADEMIC = 'ACADEMIC',
}

export enum EventLanguage {
  // eslint-disable-next-line no-unused-vars
  LITHUANIAN = 'LITHUANIAN',
  // eslint-disable-next-line no-unused-vars
  ENGLISH = 'ENGLISH',
}

export type EventState = {
  eventId: number;
};

export enum EventStatus {
  // eslint-disable-next-line no-unused-vars
  DRAFT = 'DRAFT',
  // eslint-disable-next-line no-unused-vars
  SAVED = 'SAVED',
}

export enum GetEventTabType {
  // eslint-disable-next-line no-unused-vars
  GENERAL = 'GENERAL',
  // eslint-disable-next-line no-unused-vars
  PARTICIPANTS = 'PARTICIPANTS',
  // eslint-disable-next-line no-unused-vars
  LECTURERS = 'LECTURERS',
  // eslint-disable-next-line no-unused-vars
  PROGRAMME = 'PROGRAMME',
  // eslint-disable-next-line no-unused-vars
  DOCUMENTS = 'DOCUMENTS',
}

export enum EventListType {
  // eslint-disable-next-line no-unused-vars
  ALL = 'ALL',
  // eslint-disable-next-line no-unused-vars
  GENERAL = 'GENERAL',
  // eslint-disable-next-line no-unused-vars
  PERSONAL = 'PERSONAL',
  // eslint-disable-next-line no-unused-vars
  DRAFT = 'DRAFT',
}
