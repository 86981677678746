import axios from '../../config/Axios/axios-instance';
import {
  createEventRepresentativeFail,
  createEventRepresentativeStart,
  createEventRepresentativeSuccess,
  deleteEventRepresentativeFail,
  deleteEventRepresentativeStart,
  deleteEventRepresentativeSuccess,
  fetchEventRepresentativeFail,
  fetchEventRepresentativesFail,
  fetchEventRepresentativesStart,
  fetchEventRepresentativesSuccess,
  fetchEventRepresentativeStart,
  fetchEventRepresentativeSuccess,
  setEventRepresentativesFilterValues,
  setUpdateNeeded,
  updateEventRepresentativeFail,
  updateEventRepresentativeStart,
  updateEventRepresentativeSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';

export type EventRepresentativeCreateRequest = {
  position: string;
  initials: string;
};

export type EventRepresentativeUpdateRequest = {
  id: number;
  position: string;
  initials: string;
};

export const fetchEventRepresentatives =
  (params?: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchEventRepresentativesStart());

    if (params && saveFilters) {
      dispatch(setEventRepresentativesFilterValues(params));
    }

    return axios
      .get(API_URLS.eventRepresentative, { params })
      .then((response) => {
        dispatch(fetchEventRepresentativesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchEventRepresentativesFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const fetchEventRepresentative =
  (eventRepresentativeId: number) => (dispatch: Dispatch) => {
    dispatch(fetchEventRepresentativeStart());
    return axios
      .get(`${API_URLS.eventRepresentative}/${eventRepresentativeId}`)
      .then((response) => {
        dispatch(fetchEventRepresentativeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchEventRepresentativeFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const createEventRepresentative =
  (inputs: EventRepresentativeCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setUpdateNeeded());
    dispatch(createEventRepresentativeStart());
    return axios
      .post(API_URLS.eventRepresentative, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createEventRepresentativeSuccess(response.data));
        showToast(
          translate(intl, 'SUCCESS.EVENT_REPRESENTATIVE_CREATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(createEventRepresentativeFail(err.response.data.message));
      });
  };

export const updateEventRepresentative =
  (inputs: EventRepresentativeUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateEventRepresentativeStart());
    const { id, ...updatedEventRepresentative } = inputs;
    return axios
      .patch(`${API_URLS.eventRepresentative}/${id}`, {
        ...updatedEventRepresentative,
      })
      .then(() => {
        dispatch(updateEventRepresentativeSuccess());
        dispatch(setUpdateNeeded());
        showToast(
          translate(intl, 'SUCCESS.EVENT_REPRESENTATIVE_UPDATE', 'success'),
        );
      })
      .catch((err) => {
        dispatch(updateEventRepresentativeFail(err.response.data.message));
      });
  };

export const deleteEventRepresentative =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteEventRepresentativeStart());
    return axios
      .delete(`${API_URLS.eventRepresentative}/${id}`)
      .then(() => {
        dispatch(deleteEventRepresentativeSuccess());
        dispatch(setUpdateNeeded());
        showToast(
          translate(intl, 'SUCCESS.EVENT_REPRESENTATIVE_DELETE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteEventRepresentativeFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };
