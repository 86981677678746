export const FETCH_CERTIFICATES_START = 'FETCH_CERTIFICATES_START';
export const FETCH_CERTIFICATES_SUCCESS = 'FETCH_CERTIFICATES_SUCCESS';
export const FETCH_CERTIFICATES_FAIL = 'FETCH_CERTIFICATES_FAIL';

export const FETCH_CERTIFICATE_START = 'FETCH_CERTIFICATE_START';
export const FETCH_CERTIFICATE_SUCCESS = 'FETCH_CERTIFICATE_SUCCESS';
export const FETCH_CERTIFICATE_FAIL = 'FETCH_CERTIFICATE_FAIL';

export const FETCH_CERTIFICATE_TOTAL_COUNT_START =
  'FETCH_CERTIFICATE_TOTAL_COUNT_START';
export const FETCH_CERTIFICATE_TOTAL_COUNT_SUCCESS =
  'FETCH_CERTIFICATE_TOTAL_COUNT_SUCCESS';
export const FETCH_CERTIFICATE_TOTAL_COUNT_FAIL =
  'FETCH_CERTIFICATE_TOTAL_COUNT_FAIL';

export const FETCH_ALL_USER_CERTIFICATES_HASHES_START =
  'FETCH_ALL_USER_CERTIFICATES_HASHES_START';
export const FETCH_ALL_USER_CERTIFICATES_HASHES_SUCCESS =
  'FETCH_ALL_USER_CERTIFICATES_HASHES_SUCCESS';
export const FETCH_ALL_USER_CERTIFICATES_HASHES_FAIL =
  'FETCH_ALL_USER_CERTIFICATES_HASHES_FAIL';

export const CREATE_EXTERNAL_CERTIFICATE_START =
  'CREATE_EXTERNAL_CERTIFICATE_START';
export const CREATE_EXTERNAL_CERTIFICATE_SUCCESS =
  'CREATE_EXTERNAL_CERTIFICATE_SUCCESS';
export const CREATE_EXTERNAL_CERTIFICATE_FAIL =
  'CREATE_EXTERNAL_CERTIFICATE_FAIL';

export const UPDATE_EXTERNAL_CERTIFICATE_START =
  'UPDATE_EXTERNAL_CERTIFICATE_START';
export const UPDATE_EXTERNAL_CERTIFICATE_SUCCESS =
  'UPDATE_EXTERNAL_CERTIFICATE_SUCCESS';
export const UPDATE_EXTERNAL_CERTIFICATE_FAIL =
  'UPDATE_EXTERNAL_CERTIFICATE_FAIL';

export const REPORT_ISSUE_START = 'REPORT_ISSUE_START';
export const REPORT_ISSUE_SUCCESS = 'REPORT_ISSUE_SUCCESS';
export const REPORT_ISSUE_FAIL = 'REPORT_ISSUE_FAIL';

export const SET_CERTIFICATES_FILTER = 'SET_CERTIFICATES_FILTER';

export const DOWNLOAD_FILE_START = 'DOWNLOAD_FILE_START';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';

export const PREPARE_TO_SIGN_CERTIFICATE_START =
  'PREPARE_TO_SIGN_CERTIFICATE_START';
export const PREPARE_TO_SIGN_CERTIFICATE_SUCCESS =
  'PREPARE_TO_SIGN_CERTIFICATE_SUCCESS';
export const PREPARE_TO_SIGN_CERTIFICATE_FAIL =
  'PREPARE_TO_SIGN_CERTIFICATE_FAIL';
export const PREPARE_TO_SIGN_CERTIFICATE_RESET =
  'PREPARE_TO_SIGN_CERTIFICATE_RESET';

export const START_SIGN_PROCESS_START = 'START_SIGN_PROCESS_START';
export const START_SIGN_PROCESS_SUCCESS = 'START_SIGN_PROCESS_SUCCESS';
export const START_SIGN_PROCESS_FAIL = 'START_SIGN_PROCESS_FAIL';

export const GET_SIGN_URL_START = 'GET_SIGN_URL_START';
export const GET_SIGN_URL_SUCCESS = 'GET_SIGN_URL_SUCCESS';
export const GET_SIGN_URL_FAIL = 'GET_SIGN_URL_FAIL';

export const RESET_EXTERNAL_CERTIFICATE_STORE =
  'RESEt_EXTERNAL_CERTIFICATE_STORE';
export const RESET_ALL_USER_CERTIFICATE_HASHES =
  'RESET_ALL_USER_CERTIFICATE_HASHES';
export const RESET_CERTIFICATE_STORE = 'RESET_CERTIFICATE_STORE';

export const UPDATE_CERTIFICATE_STATUS_START =
  'UPDATE_CERTIFICATE_STATUS_START';
export const UPDATE_CERTIFICATE_STATUS_SUCCESS =
  'UPDATE_CERTIFICATE_STATUS_SUCCESS';
export const UPDATE_CERTIFICATE_STATUS_FAIL = 'UPDATE_CERTIFICATE_STATUS_FAIL';

export const EXPORT_CERTIFICATES_TO_PDF_START =
  'EXPORT_CERTIFICATES_TO_PDF_START';
export const EXPORT_CERTIFICATES_TO_PDF_SUCCESS =
  'EXPORT_CERTIFICATES_TO_PDF_SUCCESS';
export const EXPORT_CERTIFICATES_TO_PDF_FAIL =
  'EXPORT_CERTIFICATES_TO_PDF_FAIL';

export const RESET_EXPORT_CERTIFICATES_TO_PDF =
  'RESET_EXPORT_CERTIFICATES_TO_PDF';

export const CONFIRM_EXTERNAL_CERTIFICATE_START =
  'CONFIRM_EXTERNAL_CERTIFICATE_START';
export const CONFIRM_EXTERNAL_CERTIFICATE_SUCCESS =
  'CONFIRM_EXTERNAL_CERTIFICATE_SUCCESS';
export const CONFIRM_EXTERNAL_CERTIFICATE_FAIL =
  'CONFIRM_EXTERNAL_CERTIFICATE_FAIL';

export type CertificateActionTypes =
  | typeof FETCH_CERTIFICATES_START
  | typeof FETCH_CERTIFICATES_SUCCESS
  | typeof FETCH_CERTIFICATES_FAIL
  | typeof FETCH_CERTIFICATE_START
  | typeof FETCH_CERTIFICATE_SUCCESS
  | typeof FETCH_CERTIFICATE_FAIL
  | typeof FETCH_CERTIFICATE_TOTAL_COUNT_START
  | typeof FETCH_CERTIFICATE_TOTAL_COUNT_SUCCESS
  | typeof FETCH_CERTIFICATE_TOTAL_COUNT_FAIL
  | typeof FETCH_ALL_USER_CERTIFICATES_HASHES_START
  | typeof FETCH_ALL_USER_CERTIFICATES_HASHES_SUCCESS
  | typeof FETCH_ALL_USER_CERTIFICATES_HASHES_FAIL
  | typeof CREATE_EXTERNAL_CERTIFICATE_START
  | typeof CREATE_EXTERNAL_CERTIFICATE_FAIL
  | typeof CREATE_EXTERNAL_CERTIFICATE_SUCCESS
  | typeof UPDATE_EXTERNAL_CERTIFICATE_START
  | typeof UPDATE_EXTERNAL_CERTIFICATE_FAIL
  | typeof UPDATE_EXTERNAL_CERTIFICATE_SUCCESS
  | typeof REPORT_ISSUE_START
  | typeof REPORT_ISSUE_SUCCESS
  | typeof REPORT_ISSUE_FAIL
  | typeof SET_CERTIFICATES_FILTER
  | typeof DOWNLOAD_FILE_START
  | typeof DOWNLOAD_FILE_SUCCESS
  | typeof DOWNLOAD_FILE_FAIL
  | typeof PREPARE_TO_SIGN_CERTIFICATE_START
  | typeof PREPARE_TO_SIGN_CERTIFICATE_SUCCESS
  | typeof PREPARE_TO_SIGN_CERTIFICATE_FAIL
  | typeof PREPARE_TO_SIGN_CERTIFICATE_RESET
  | typeof GET_SIGN_URL_START
  | typeof GET_SIGN_URL_SUCCESS
  | typeof GET_SIGN_URL_FAIL
  | typeof START_SIGN_PROCESS_START
  | typeof START_SIGN_PROCESS_SUCCESS
  | typeof START_SIGN_PROCESS_FAIL
  | typeof UPDATE_CERTIFICATE_STATUS_START
  | typeof UPDATE_CERTIFICATE_STATUS_SUCCESS
  | typeof UPDATE_CERTIFICATE_STATUS_FAIL
  | typeof EXPORT_CERTIFICATES_TO_PDF_START
  | typeof EXPORT_CERTIFICATES_TO_PDF_FAIL
  | typeof EXPORT_CERTIFICATES_TO_PDF_SUCCESS
  | typeof RESET_EXPORT_CERTIFICATES_TO_PDF
  | typeof RESET_EXTERNAL_CERTIFICATE_STORE
  | typeof RESET_ALL_USER_CERTIFICATE_HASHES
  | typeof RESET_CERTIFICATE_STORE
  | typeof CONFIRM_EXTERNAL_CERTIFICATE_START
  | typeof CONFIRM_EXTERNAL_CERTIFICATE_SUCCESS
  | typeof CONFIRM_EXTERNAL_CERTIFICATE_FAIL;
