import React, { ReactNode } from 'react';
import { Roles } from '../../domain/Role';
import styles from './CurrentAccount.module.scss';

type Props = {
  title: string;
  subTitle: string;
  companyName: string | undefined;
  children: ReactNode;
  onClick: () => void;
  companyOwner: string;
  selectedRole: Roles | null;
};

export const CurrentAccount = ({
  title,
  subTitle,
  companyName,
  children,
  onClick,
  companyOwner,
  selectedRole,
}: Props) => {
  return (
    <div className={styles.currentAccountContainer} onClick={onClick}>
      <div className={styles.content}>
        {!companyName && (
          <>
            <div className={styles.title}>{title}</div>

            <div className={styles.subTitle}>{subTitle}</div>
          </>
        )}
        {companyName && (
          <>
            {selectedRole === Roles.ADMIN && (
              <div className={styles.companyName}>{companyOwner}</div>
            )}
            <div className={styles.companyName}>{companyName}</div>
          </>
        )}
      </div>
      {children}
    </div>
  );
};

export default CurrentAccount;
