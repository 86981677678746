export const FETCH_EVENT_PURPOSES_START = 'FETCH_EVENT_PURPOSES_START';
export const FETCH_EVENT_PURPOSES_SUCCESS = 'FETCH_EVENT_PURPOSES_SUCCESS';
export const FETCH_EVENT_PURPOSES_FAIL = 'FETCH_EVENT_PURPOSES_FAIL';

export const FETCH_EVENT_PURPOSE_START = 'FETCH_EVENT_PURPOSE_START';
export const FETCH_EVENT_PURPOSE_SUCCESS = 'FETCH_EVENT_PURPOSE_SUCCESS';
export const FETCH_EVENT_PURPOSE_FAIL = 'FETCH_EVENT_PURPOSE_FAIL';

export const CREATE_EVENT_PURPOSE_START = 'CREATE_EVENT_PURPOSE_START';
export const CREATE_EVENT_PURPOSE_SUCCESS = 'CREATE_EVENT_PURPOSE_SUCCESS';
export const CREATE_EVENT_PURPOSE_FAIL = 'CREATE_EVENT_PURPOSE_FAIL';

export const UPDATE_EVENT_PURPOSE_START = 'UPDATE_EVENT_PURPOSE_START';
export const UPDATE_EVENT_PURPOSE_SUCCESS = 'UPDATE_EVENT_PURPOSE_SUCCESS';
export const UPDATE_EVENT_PURPOSE_FAIL = 'UPDATE_EVENT_PURPOSE_FAIL';

export const DELETE_EVENT_PURPOSE_START = 'DELETE_EVENT_PURPOSE_START';
export const DELETE_EVENT_PURPOSE_SUCCESS = 'DELETE_EVENT_PURPOSE_SUCCESS';
export const DELETE_EVENT_PURPOSE_FAIL = 'DELETE_EVENT_PURPOSE_FAIL';

export const SET_EVENT_PURPOSES_FILTER = 'SET_EVENT_PURPOSES_FILTER';

export const RESET_EVENT_PURPOSE_STORE = 'RESET_EVENT_PURPOSE_STORE';

export const SET_EVENT_PURPOSE_UPDATE_NEEDED =
  'SET_EVENT_PURPOSE_UPDATE_NEEDED';

export const RESET_CREATED_EVENT_PURPOSE = 'RESET_CREATED_EVENT_PURPOSE';

export type EventPurposeActionTypes =
  | typeof FETCH_EVENT_PURPOSES_START
  | typeof FETCH_EVENT_PURPOSES_SUCCESS
  | typeof FETCH_EVENT_PURPOSES_FAIL
  | typeof FETCH_EVENT_PURPOSE_START
  | typeof FETCH_EVENT_PURPOSE_SUCCESS
  | typeof FETCH_EVENT_PURPOSE_FAIL
  | typeof CREATE_EVENT_PURPOSE_START
  | typeof CREATE_EVENT_PURPOSE_SUCCESS
  | typeof CREATE_EVENT_PURPOSE_FAIL
  | typeof UPDATE_EVENT_PURPOSE_START
  | typeof UPDATE_EVENT_PURPOSE_SUCCESS
  | typeof UPDATE_EVENT_PURPOSE_FAIL
  | typeof DELETE_EVENT_PURPOSE_START
  | typeof DELETE_EVENT_PURPOSE_SUCCESS
  | typeof DELETE_EVENT_PURPOSE_FAIL
  | typeof SET_EVENT_PURPOSES_FILTER
  | typeof RESET_EVENT_PURPOSE_STORE
  | typeof SET_EVENT_PURPOSE_UPDATE_NEEDED
  | typeof RESET_CREATED_EVENT_PURPOSE;
