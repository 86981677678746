import { HttpError } from '../../config/Axios/axios-instance';
import { Organiser, OrganisersList } from '../../domain/Organiser';
import { ListParams } from '../../hooks/useList/useList';
import * as actionTypes from './actionTypes';

export const fetchOrganiserStart = () => ({
  type: actionTypes.FETCH_ORGANISER_START,
});

export const fetchOrganiserSuccess = (organiser: Organiser) => ({
  type: actionTypes.FETCH_ORGANISER_SUCCESS,
  organiser,
});

export const fetchOrganiserFail = (organiserError: HttpError) => ({
  type: actionTypes.FETCH_ORGANISER_FAIL,
  organiserError,
});

export const createOrganiserStart = () => ({
  type: actionTypes.CREATE_ORGANISER_START,
});

export const createOrganiserSuccess = () => ({
  type: actionTypes.CREATE_ORGANISER_SUCCESS,
});

export const createOrganiserFail = (organiserCreateError: string) => ({
  type: actionTypes.CREATE_ORGANISER_FAIL,
  organiserCreateError,
});

export const fetchOrganisersStart = () => ({
  type: actionTypes.FETCH_ORGANISERS_START,
});

export const fetchOrganisersSuccess = (organisersList: OrganisersList) => ({
  type: actionTypes.FETCH_ORGANISERS_SUCCESS,
  organisersList,
});

export const fetchOrganisersFail = (organisersListError: string) => ({
  type: actionTypes.FETCH_ORGANISERS_FAIL,
  organisersListError,
});

export const setOrganisersFilterValues = (organisersFilter: ListParams) => ({
  type: actionTypes.SET_ORGANISERS_FILTER,
  organisersFilter,
});

export const updateOrganiserStatusStart = () => ({
  type: actionTypes.UPDATE_ORGANISER_STATUS_START,
});

export const updateOrganiserStatusSuccess = () => ({
  type: actionTypes.UPDATE_ORGANISER_STATUS_SUCCESS,
});

export const updateOrganiserStatusFail = (
  organiserStatusUpdateError: string,
) => ({
  type: actionTypes.UPDATE_ORGANISER_STATUS_FAIL,
  organiserStatusUpdateError,
});
