import React, { useState } from 'react';
import { Autocomplete as MuiAutocomplete, TextField } from '@material-ui/core';
import styles from './Autocomplete.module.scss';
import innerStyles from '../TextField/TextField.module.scss';
import cx from 'classnames';
import Button from '../Button/Button';
import {
  getTranslatedError,
  getTranslatedLabel,
  getTranslatedPlaceholder,
  translate,
} from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { ErrorMessageObject } from '../../utility/validation/validation';
import InfoPopover from '../InfoPopover/InfoPopover';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';

export type AutocompleteOptionProps = {
  label: string;
  value: string;
};

export type PopoverProps = { icon: JSX.Element; text: string };

export type AutocompleteProps = {
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  errors?: Array<ErrorMessageObject>;
  id?: string;
  label?: string;
  loading?: boolean;
  name: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (
    value: string | string[],
    name: string,
    freeSolo?: boolean,
  ) => void;
  options?: AutocompleteOptionProps[];
  placeholder?: string;
  required?: boolean;
  value?: string | string[];
  multiple?: boolean;
  disableClearable?: boolean;
  onCreate?: () => void;
  creatable?: boolean;
  translationNeeded?: boolean;
  isEnabled?: boolean;
  freeSolo?: boolean;
  popover?: PopoverProps;
  mobileBreakPoint?: number;
  limit?: number;
};

const Autocomplete = ({
  id,
  disabled,
  label,
  required,
  options = [],
  onChange,
  name,
  onBlur,
  loading,
  errors,
  disableCloseOnSelect,
  placeholder,
  multiple = false,
  value,
  disableClearable = false,
  onCreate,
  creatable,
  translationNeeded,
  isEnabled,
  freeSolo,
  popover,
  mobileBreakPoint,
  limit,
}: AutocompleteProps) => {
  const intl = useIntl();
  const { width } = useWindowSize();
  const [autocompleteInput, setAutocompleteInput] = useState<string>('');

  const getMultipleSelectionValue = (values?: string[]) => {
    const hasSelections = values && !(values.length === 1 && values[0] === '');

    if (options.length) {
      return options.filter(
        (option) =>
          !!values?.find(
            (singleValue) =>
              singleValue?.toString() === option.value?.toString(),
          ),
      );
    }

    if (hasSelections && freeSolo) {
      return values.map((value) => {
        return { label: value.toString(), value: value.toString() };
      });
    }

    return [];
  };

  const getSelectionValue = () => {
    if (Array.isArray(value)) {
      return options.filter((option) => value.includes(option.value));
    }

    if (multiple) {
      const values = value?.toString().split(',');
      return getMultipleSelectionValue(values);
    }

    return options.find(
      (option) => option.value.toString() === value?.toString(),
    );
  };

  const getOptionLabel = () => {
    if (!translationNeeded) {
      return (option: AutocompleteOptionProps) => option.label;
    }

    return (option: AutocompleteOptionProps) =>
      translate(intl, option.label, '');
  };

  const getOptionDisabled = () => {
    const value = getSelectionValue();

    return Array.isArray(value) && value.length === limit;
  };

  const getFreeSoloValue = () => {
    if (!limit) {
      return freeSolo;
    }

    const value = getSelectionValue();

    if (!Array.isArray(value)) {
      return;
    }

    return !(value.length === limit);
  };

  return (
    <>
      <MuiAutocomplete
        id={name}
        freeSolo={getFreeSoloValue()}
        className={styles.autocomplete}
        multiple={multiple}
        options={options}
        getOptionLabel={getOptionLabel()}
        filterSelectedOptions
        value={getSelectionValue() ?? null}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        disableClearable={disableClearable}
        inputValue={multiple ? autocompleteInput : undefined}
        getOptionDisabled={limit ? getOptionDisabled : undefined}
        onChange={(_, values) => {
          setAutocompleteInput('');

          if (Array.isArray(values)) {
            return onChange?.(
              (values as AutocompleteOptionProps[] | string[]).map((value) => {
                if (typeof value === 'string') {
                  return value;
                }

                return value.value;
              }),
              name,
              freeSolo,
            );
          }

          if (typeof values === 'object') {
            return onChange?.(values?.value || '', name, freeSolo);
          }

          onChange?.(values?.valueOf() || '', name, freeSolo);
        }}
        onBlur={onBlur}
        renderInput={(params: any) => (
          <TextField
            {...params}
            className={cx(innerStyles.textField)}
            label={getTranslatedLabel(label, intl)}
            placeholder={getTranslatedPlaceholder(placeholder, intl)}
            helperText={getTranslatedError(errors, intl)}
            error={errors && errors.length > 0}
            required={required}
            name={name}
            onChange={(event) => setAutocompleteInput(event.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {creatable && (
                    <Button
                      isDisabled={!isEnabled}
                      buttonVariant="text"
                      onClick={() => onCreate?.()}
                    >
                      {translate(intl, 'AUTOCOMPLETE.CREATE', 'Create ')}
                    </Button>
                  )}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {popover && mobileBreakPoint && (
        <InfoPopover
          base={popover.icon}
          text={popover.text}
          placement={width && width < mobileBreakPoint ? 'left' : 'right'}
        />
      )}
    </>
  );
};

export default Autocomplete;
