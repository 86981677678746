export const CONTACT_US_START = 'CONTACT_US_START';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL';

export const RESET_CONTACT_US_STORE = 'RESET_CONTACT_US_STORE';

export type ContactUsActionTypes =
  | typeof CONTACT_US_START
  | typeof CONTACT_US_SUCCESS
  | typeof CONTACT_US_FAIL
  | typeof RESET_CONTACT_US_STORE;
