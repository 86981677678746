export const FETCH_CREDIT_PACKAGES_START = 'FETCH_CREDIT_PACKAGES_START';
export const FETCH_CREDIT_PACKAGES_SUCCESS = 'FETCH_CREDIT_PACKAGES_SUCCESS';
export const FETCH_CREDIT_PACKAGES_FAIL = 'FETCH_CREDIT_PACKAGES_FAIL';

export const FETCH_CREDIT_PACKAGE_START = 'FETCH_CREDIT_PACKAGE_START';
export const FETCH_CREDIT_PACKAGE_SUCCESS = 'FETCH_CREDIT_PACKAGE_SUCCESS';
export const FETCH_CREDIT_PACKAGE_FAIL = 'FETCH_CREDIT_PACKAGE_FAIL';

export const CREATE_CREDIT_PACKAGE_START = 'CREATE_CREDIT_PACKAGE_START';
export const CREATE_CREDIT_PACKAGE_SUCCESS = 'CREATE_CREDIT_PACKAGE_SUCCESS';
export const CREATE_CREDIT_PACKAGE_FAIL = 'CREATE_CREDIT_PACKAGE_FAIL';

export const UPDATE_CREDIT_PACKAGE_START = 'UPDATE_CREDIT_PACKAGE_START';
export const UPDATE_CREDIT_PACKAGE_SUCCESS = 'UPDATE_CREDIT_PACKAGE_SUCCESS';
export const UPDATE_CREDIT_PACKAGE_FAIL = 'UPDATE_CREDIT_PACKAGE_FAIL';

export const DELETE_CREDIT_PACKAGE_START = 'DELETE_CREDIT_PACKAGE_START';
export const DELETE_CREDIT_PACKAGE_SUCCESS = 'DELETE_CREDIT_PACKAGE_SUCCESS';
export const DELETE_CREDIT_PACKAGE_FAIL = 'DELETE_CREDIT_PACKAGE_FAIL';

export const RESET_CREATED_OR_EDITED_CREDIT_PACKAGE =
  'RESET_CREATED_OR_EDITED_CREDIT_PACKAGE';
export const RESET_CREDIT_PACKAGES_STORE = 'RESET_CREDIT_PACKAGES_STORE';

export const LOGOUT = 'LOGOUT';

export type CreditPackageActionTypes =
  | typeof FETCH_CREDIT_PACKAGES_START
  | typeof FETCH_CREDIT_PACKAGES_SUCCESS
  | typeof FETCH_CREDIT_PACKAGES_FAIL
  | typeof FETCH_CREDIT_PACKAGE_START
  | typeof FETCH_CREDIT_PACKAGE_SUCCESS
  | typeof FETCH_CREDIT_PACKAGE_FAIL
  | typeof CREATE_CREDIT_PACKAGE_START
  | typeof CREATE_CREDIT_PACKAGE_SUCCESS
  | typeof CREATE_CREDIT_PACKAGE_FAIL
  | typeof UPDATE_CREDIT_PACKAGE_START
  | typeof UPDATE_CREDIT_PACKAGE_SUCCESS
  | typeof UPDATE_CREDIT_PACKAGE_FAIL
  | typeof DELETE_CREDIT_PACKAGE_START
  | typeof DELETE_CREDIT_PACKAGE_SUCCESS
  | typeof DELETE_CREDIT_PACKAGE_FAIL
  | typeof RESET_CREATED_OR_EDITED_CREDIT_PACKAGE
  | typeof RESET_CREDIT_PACKAGES_STORE
  | typeof LOGOUT;
