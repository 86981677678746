import axios from '../../config/Axios/axios-instance';
import {
  createEventPurposeFail,
  createEventPurposeStart,
  createEventPurposeSuccess,
  deleteEventPurposeFail,
  deleteEventPurposeStart,
  deleteEventPurposeSuccess,
  fetchEventPurposeFail,
  fetchEventPurposesFail,
  fetchEventPurposesStart,
  fetchEventPurposesSuccess,
  fetchEventPurposeStart,
  fetchEventPurposeSuccess,
  setEventPurposesFilterValues,
  setUpdateNeeded,
  updateEventPurposeFail,
  updateEventPurposeStart,
  updateEventPurposeSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';

export type EventPurposeCreateRequest = {
  name: string;
};

export type EventPurposeUpdateRequest = {
  id: number;
  name: string;
};

export const fetchEventPurposes =
  (params?: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchEventPurposesStart());

    if (params && saveFilters) {
      dispatch(setEventPurposesFilterValues(params));
    }
    return axios
      .get(API_URLS.eventPurpose, { params })
      .then((response) => {
        dispatch(fetchEventPurposesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchEventPurposesFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const fetchEventPurpose =
  (eventPurposeId: number) => (dispatch: Dispatch) => {
    dispatch(fetchEventPurposeStart());
    return axios
      .get(`${API_URLS.eventPurpose}/${eventPurposeId}`)
      .then((response) => {
        dispatch(fetchEventPurposeSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchEventPurposeFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const createEventPurpose =
  (inputs: EventPurposeCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setUpdateNeeded());
    dispatch(createEventPurposeStart());
    return axios
      .post(API_URLS.eventPurpose, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createEventPurposeSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.EVENT_PURPOSE_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createEventPurposeFail(err.response.data.message));
      });
  };

export const updateEventPurpose =
  (inputs: EventPurposeUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateEventPurposeStart());
    const { id, ...updatedEventPurpose } = inputs;
    return axios
      .patch(`${API_URLS.eventPurpose}/${id}`, {
        ...updatedEventPurpose,
      })
      .then(() => {
        dispatch(updateEventPurposeSuccess());
        dispatch(setUpdateNeeded());
        showToast(translate(intl, 'SUCCESS.EVENT_PURPOSE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateEventPurposeFail(err.response.data.message));
      });
  };

export const deleteEventPurpose =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteEventPurposeStart());
    return axios
      .delete(`${API_URLS.eventPurpose}/${id}`)
      .then(() => {
        dispatch(deleteEventPurposeSuccess());
        dispatch(setUpdateNeeded());
        showToast(translate(intl, 'SUCCESS.EVENT_PURPOSE_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteEventPurposeFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };
