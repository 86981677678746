import { Certificate } from './Certificate';
import { Event } from './Event';
import { Asset } from './Asset';
import { ParticipantItem } from './ParticipantItem';
import { User } from './User';

export enum ParticipantType {
  // eslint-disable-next-line no-unused-vars
  PARTICIPANT = 'PARTICIPANT',
  // eslint-disable-next-line no-unused-vars
  LECTURER = 'LECTURER',
}

export enum ParticipantStatus {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  ACCEPTED = 'ACCEPTED',
}

export interface Participant {
  isNotified: boolean;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  type: ParticipantType;
  certificate: Certificate;
  participantItems: ParticipantItem[];
  user: User;
  status?: ParticipantStatus;
  material?: Asset[];
  event: Event;
}

export type ParticipantsList = {
  result: Participant[];
  total: number;
};

export type SelectedParticipants = {
  ids: number[];
  eventId: number;
};
