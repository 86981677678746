export const SET_REVIEW_START = 'SET_REVIEW_START';
export const SET_REVIEW_SUCCESS = 'SET_REVIEW_SUCCESS';
export const SET_REVIEW_FAIL = 'SET_REVIEW_FAIL';

export const FETCH_REVIEW_START = 'FETCH_REVIEW_START';
export const FETCH_REVIEW_SUCCESS = 'FETCH_REVIEW_SUCCESS';
export const FETCH_REVIEW_FAIL = 'FETCH_REVIEW_FAIL';

export type ReviewActionTypes =
  | typeof SET_REVIEW_START
  | typeof SET_REVIEW_SUCCESS
  | typeof SET_REVIEW_FAIL
  | typeof FETCH_REVIEW_START
  | typeof FETCH_REVIEW_SUCCESS
  | typeof FETCH_REVIEW_FAIL;
