import axios from '../../config/Axios/axios-instance';
import {
  createParticipantActivityFail,
  createParticipantActivityStart,
  createParticipantActivitySuccess,
  deleteParticipantActivityFail,
  deleteParticipantActivityStart,
  deleteParticipantActivitySuccess,
  fetchParticipantActivityFail,
  fetchParticipantActivitiesFail,
  fetchParticipantActivitiesStart,
  fetchParticipantActivitiesSuccess,
  fetchParticipantActivityStart,
  fetchParticipantActivitySuccess,
  setParticipantActivitiesFilterValues,
  setUpdateNeeded,
  updateParticipantActivityFail,
  updateParticipantActivityStart,
  updateParticipantActivitySuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

export type ParticipantActivityCreateRequest = {
  name: string;
};

export type ParticipantActivityUpdateRequest = {
  id: number;
  name: string;
};

export const fetchParticipantActivities =
  (params?: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchParticipantActivitiesStart());

    if (params && saveFilters) {
      dispatch(setParticipantActivitiesFilterValues(params));
    }

    return axios
      .get(API_URLS.participantActivity, { params })
      .then((response) => {
        dispatch(fetchParticipantActivitiesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchParticipantActivitiesFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const fetchParticipantActivity =
  (participantActivityId: number) => (dispatch: Dispatch) => {
    dispatch(fetchParticipantActivityStart());
    return axios
      .get(`${API_URLS.participantActivity}/${participantActivityId}`)
      .then((response) => {
        dispatch(fetchParticipantActivitySuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchParticipantActivityFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const createParticipantActivity =
  (inputs: ParticipantActivityCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setUpdateNeeded());
    dispatch(createParticipantActivityStart());
    return axios
      .post(API_URLS.participantActivity, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createParticipantActivitySuccess(response.data));
        showToast(
          translate(intl, 'SUCCESS.PARTICIPANT_ACTIVITY_CREATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(createParticipantActivityFail(err.response.data.message));
      });
  };

export const updateParticipantActivity =
  (inputs: ParticipantActivityUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateParticipantActivityStart());
    const { id, ...updatedParticipantActivity } = inputs;
    return axios
      .patch(`${API_URLS.participantActivity}/${id}`, {
        ...updatedParticipantActivity,
      })
      .then(() => {
        dispatch(updateParticipantActivitySuccess());
        dispatch(setUpdateNeeded());
        showToast(
          translate(intl, 'SUCCESS.PARTICIPANT_ACTIVITY_UPDATE', 'success'),
        );
      })
      .catch((err) => {
        dispatch(updateParticipantActivityFail(err.response.data.message));
      });
  };

export const deleteParticipantActivity =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteParticipantActivityStart());
    return axios
      .delete(`${API_URLS.participantActivity}/${id}`)
      .then(() => {
        dispatch(deleteParticipantActivitySuccess());
        dispatch(setUpdateNeeded());
        showToast(
          translate(intl, 'SUCCESS.PARTICIPANT_ACTIVITY_DELETE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteParticipantActivityFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };
