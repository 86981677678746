import * as actionTypes from './actionTypes';
import { EventRepresentativeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  EventRepresentative,
  EventRepresentativesList,
} from '../../domain/EventRepresentative';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type EventRepresentativeStateType = {
  eventRepresentativesList: EventRepresentativesList | null;
  eventRepresentativesListLoading: boolean;
  eventRepresentativesListError: HttpError;
  eventRepresentativesFilter: ListParams;
  eventRepresentativesListUpdateNeeded: boolean;
  eventRepresentative: EventRepresentative | null;
  eventRepresentativeLoading: boolean;
  eventRepresentativeError: HttpError;
  eventRepresentativeCreateLoading: boolean;
  eventRepresentativeCreateError: HttpError;
  eventRepresentativeCreateSuccess: boolean;
  eventRepresentativeUpdateLoading: boolean;
  eventRepresentativeUpdateError: HttpError;
  eventRepresentativeUpdateSuccess: boolean;
  eventRepresentativeDeleteLoading: boolean;
  eventRepresentativeDeleteError: HttpError;
  createdEventRepresentative: EventRepresentative | null;
};

export type EventRepresentativeActionType = EventRepresentativeStateType & {
  type: EventRepresentativeActionTypes;
};

export const initialState: EventRepresentativeStateType = {
  eventRepresentativesList: null,
  eventRepresentativesListLoading: true,
  eventRepresentativesListError: null,
  eventRepresentativesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'position',
  },
  eventRepresentativesListUpdateNeeded: false,
  eventRepresentative: null,
  eventRepresentativeLoading: false,
  eventRepresentativeError: null,
  eventRepresentativeCreateLoading: false,
  eventRepresentativeCreateError: null,
  eventRepresentativeCreateSuccess: false,
  eventRepresentativeUpdateLoading: false,
  eventRepresentativeUpdateError: null,
  eventRepresentativeUpdateSuccess: false,
  eventRepresentativeDeleteLoading: false,
  eventRepresentativeDeleteError: null,
  createdEventRepresentative: null,
};

const fetchEventRepresentativesStart = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativesListLoading: true,
  eventRepresentativeUpdateSuccess: false,
  eventRepresentativeCreateSuccess: false,
  eventRepresentativeDeleteError: null,
  eventRepresentative: null,
});

const fetchEventRepresentativesSuccess = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativesList: action.eventRepresentativesList,
  eventRepresentativesListLoading: false,
  eventRepresentativesListError: null,
  eventRepresentativesListUpdateNeeded: false,
});

const fetchEventRepresentativesFail = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativesListError: action.eventRepresentativesListError,
  eventRepresentativesListLoading: false,
});

const fetchEventRepresentativeStart = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeLoading: true,
  eventRepresentativeUpdateSuccess: false,
  eventRepresentativeCreateSuccess: false,
  eventRepresentativeCreateError: null,
  eventRepresentativeUpdateError: null,
});

const fetchEventRepresentativeSuccess = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentative: action.eventRepresentative,
  eventRepresentativeLoading: false,
  eventRepresentativeError: null,
});

const fetchEventRepresentativeFail = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeError: action.eventRepresentativeError,
  eventRepresentativeLoading: false,
});

const createEventRepresentativeStart = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeCreateLoading: true,
});

const createEventRepresentativeSuccess = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeCreateLoading: false,
  eventRepresentativeCreateError: null,
  eventRepresentativeCreateSuccess: true,
  eventRepresentativesListUpdateNeeded: true,
  createdEventRepresentative: action.createdEventRepresentative,
});

const createEventRepresentativeFail = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeCreateLoading: false,
  eventRepresentativeCreateError: action.eventRepresentativeCreateError,
});

const updateEventRepresentativeStart = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeUpdateLoading: true,
});

const updateEventRepresentativeSuccess = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeUpdateLoading: false,
  eventRepresentativeUpdateError: null,
  eventRepresentativeUpdateSuccess: true,
  eventRepresentativesListUpdateNeeded: true,
});

const updateEventRepresentativeFail = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeUpdateLoading: false,
  eventRepresentativeUpdateError: action.eventRepresentativeUpdateError,
});

const deleteEventRepresentativeStart = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeDeleteLoading: true,
});

const deleteEventRepresentativeSuccess = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeDeleteLoading: false,
  eventRepresentativeDeleteError: null,
  eventRepresentativesListUpdateNeeded: true,
});

const deleteEventRepresentativeFail = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativeDeleteLoading: false,
  eventRepresentativeDeleteError: action.eventRepresentativeDeleteError,
});

const setEventRepresentativesFilter = (
  state: EventRepresentativeStateType,
  action: EventRepresentativeActionType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativesFilter: action.eventRepresentativesFilter,
});

const resetStore = (): EventRepresentativeStateType => ({
  eventRepresentativesList: null,
  eventRepresentativesListLoading: true,
  eventRepresentativesListError: null,
  eventRepresentativesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'position',
  },
  eventRepresentativesListUpdateNeeded: false,
  eventRepresentative: null,
  eventRepresentativeLoading: false,
  eventRepresentativeError: null,
  eventRepresentativeCreateLoading: false,
  eventRepresentativeCreateError: null,
  eventRepresentativeCreateSuccess: false,
  eventRepresentativeUpdateLoading: false,
  eventRepresentativeUpdateError: null,
  eventRepresentativeUpdateSuccess: false,
  eventRepresentativeDeleteLoading: false,
  eventRepresentativeDeleteError: null,
  createdEventRepresentative: null,
});

const setUpdateNeeded = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  eventRepresentativesListUpdateNeeded: true,
});

const resetCreatedEventRepresentative = (
  state: EventRepresentativeStateType,
): EventRepresentativeStateType => ({
  ...state,
  createdEventRepresentative: null,
});

const reducer = (
  state = initialState,
  action: EventRepresentativeActionType,
) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_REPRESENTATIVES_START:
      return fetchEventRepresentativesStart(state);
    case actionTypes.FETCH_EVENT_REPRESENTATIVES_SUCCESS:
      return fetchEventRepresentativesSuccess(state, action);
    case actionTypes.FETCH_EVENT_REPRESENTATIVES_FAIL:
      return fetchEventRepresentativesFail(state, action);
    case actionTypes.FETCH_EVENT_REPRESENTATIVE_START:
      return fetchEventRepresentativeStart(state);
    case actionTypes.FETCH_EVENT_REPRESENTATIVE_SUCCESS:
      return fetchEventRepresentativeSuccess(state, action);
    case actionTypes.FETCH_EVENT_REPRESENTATIVE_FAIL:
      return fetchEventRepresentativeFail(state, action);
    case actionTypes.CREATE_EVENT_REPRESENTATIVE_START:
      return createEventRepresentativeStart(state);
    case actionTypes.CREATE_EVENT_REPRESENTATIVE_SUCCESS:
      return createEventRepresentativeSuccess(state, action);
    case actionTypes.CREATE_EVENT_REPRESENTATIVE_FAIL:
      return createEventRepresentativeFail(state, action);
    case actionTypes.UPDATE_EVENT_REPRESENTATIVE_START:
      return updateEventRepresentativeStart(state);
    case actionTypes.UPDATE_EVENT_REPRESENTATIVE_SUCCESS:
      return updateEventRepresentativeSuccess(state);
    case actionTypes.UPDATE_EVENT_REPRESENTATIVE_FAIL:
      return updateEventRepresentativeFail(state, action);
    case actionTypes.DELETE_EVENT_REPRESENTATIVE_START:
      return deleteEventRepresentativeStart(state);
    case actionTypes.DELETE_EVENT_REPRESENTATIVE_SUCCESS:
      return deleteEventRepresentativeSuccess(state);
    case actionTypes.DELETE_EVENT_REPRESENTATIVE_FAIL:
      return deleteEventRepresentativeFail(state, action);
    case actionTypes.SET_EVENT_REPRESENTATIVES_FILTER:
      return setEventRepresentativesFilter(state, action);
    case actionTypes.RESET_EVENT_REPRESENTATIVE_STORE:
      return resetStore();
    case actionTypes.SET_EVENT_REPRESENTATIVE_UPDATE_NEEDED:
      return setUpdateNeeded(state);
    case actionTypes.RESET_CREATED_EVENT_REPRESENTATIVE:
      return resetCreatedEventRepresentative(state);
    default:
      return state;
  }
};

export default reducer;
