import download from 'downloadjs';
import { Dispatch } from 'redux';
import { API_URLS } from '../globalService';
import { showToast } from '../../utility/toast/toast';
import axios from '../../config/Axios/axios-instance';
import {
  downloadInvoiceFail,
  downloadInvoiceStart,
  downloadInvoiceSuccess,
} from './actions';
import { Invoice } from '../../domain/Invoice';

export const downloadInvoice =
  (invoiceId: number, invoice: Invoice) => (dispatch: Dispatch) => {
    dispatch(downloadInvoiceStart());
    return axios
      .get(`${API_URLS.invoice}/${invoiceId}`, { responseType: 'blob' })
      .then((response) => {
        dispatch(downloadInvoiceSuccess());
        download(
          new Blob([response.data], { type: 'application/pdf' }),
          `Invoice ${invoice.invoiceNumber}.pdf`,
          'application/pdf',
        );
      })
      .catch((err) => {
        dispatch(downloadInvoiceFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };
