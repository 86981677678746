import axios from '../../config/Axios/axios-instance';
import {
  createEventFail,
  createEventStart,
  createEventSuccess,
  deleteEventFail,
  deleteEventStart,
  deleteEventSuccess,
  downloadFileFail,
  downloadFileStart,
  downloadFileSuccess,
  fetchEventFail,
  fetchEventsFail,
  fetchEventsStart,
  fetchEventsSuccess,
  fetchEventStart,
  fetchEventSuccess,
  generateCertificatesFail,
  generateCertificatesStart,
  generateCertificatesSuccess,
  generateParticipantCertificateFail,
  generateParticipantCertificateStart,
  generateParticipantCertificateSuccess,
  getAssetThumbnailFail,
  getAssetThumbnailStart,
  getAssetThumbnailSuccess,
  getCertificationStateFail,
  getCertificationStateStart,
  getCertificationStateSuccess,
  sendBulkParticipantsCertificatesFail,
  sendBulkParticipantsCertificatesStart,
  sendBulkParticipantsCertificatesSuccess,
  sendCertificatesFail,
  sendCertificatesStart,
  sendCertificatesSuccess,
  sendParticipantCertificateFail,
  sendParticipantCertificateStart,
  sendParticipantCertificateSuccess,
  setEventsFilterValues,
  updateAssetFail,
  updateAssetStart,
  updateAssetSuccess,
  updateEventFail,
  updateEventStart,
  updateEventSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { setUpdateNeeded } from '../participant/actions';
import download from 'downloadjs';
import { API_URLS } from '../globalService';
import { SelectedParticipants } from '../../domain/Participant';
import {
  EventListType,
  EventStatus,
  GetEventTabType,
} from '../../domain/Event';
import { Roles } from '../../domain/Role';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';
import { ListType } from '../../domain/Common';

export type EventCreateRequest = {
  name: string;
  hours: number;
  eventDate: string;
  id: number;
};

export type DraftCreateRequest = {
  status: EventStatus;
  companyId?: number | null;
  duplicate?: boolean;
  eventId?: number;
  isPersonal?: boolean;
};

export type DraftUpdateRequest = {
  status: EventStatus;
  companyId: number | null;
  id: number;
};

export type EventUpdatePersonalRequest = {
  id: number;
  name: string;
  dateFrom: string;
  dateTo: string;
  status: EventStatus;
  city: string;
  country: string;
};

export type EventUpdateRequest = {
  id: number;
  name: string;
  hours: number;
  eventDate: string;
  templateSettingsId: number;
};

export type EventsUpdateRequest = {
  id: number;
  children: EventsUpdateRequest[];
};

export type AssetUpdateRequest = {
  id: number;
  background?: File;
  programme?: File | File[];
  programmeFiles?: string[];
  documents?: File | File[];
  documentFiles?: string[];
  material?: File | File[];
  materialFiles?: string[];
  participantItemId?: number;
};

export type GetEventRequest = {
  type?: GetEventTabType;
  selectedRole: Roles | null;
  listType?: ListType;
};

export type FetchEventsParams = ListParams & {
  eventListType?: EventListType;
};

export const fetchEvents =
  (params: FetchEventsParams, saveFilters?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchEventsStart());

    if (saveFilters) {
      dispatch(setEventsFilterValues(params));
    }

    return axios
      .get(API_URLS.events, { params })
      .then((response) => {
        dispatch(fetchEventsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchEventsFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const fetchEvent =
  (eventId: number, params: GetEventRequest) => (dispatch: Dispatch) => {
    dispatch(fetchEventStart());
    return axios
      .get(`${API_URLS.events}/${eventId}`, { params })
      .then((response) => {
        dispatch(fetchEventSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchEventFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const createEvent =
  (inputs: EventCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createEventStart());
    const { id, ...updatedEvent } = inputs;

    return axios
      .patch(`${API_URLS.events}/${id}`, {
        ...updatedEvent,
      })
      .then((response) => {
        dispatch(createEventSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.EVENT_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createEventFail(err.response.data.message));
      });
  };

export const draftCreate =
  (inputs: DraftCreateRequest) => (dispatch: Dispatch) => {
    dispatch(createEventStart());
    return axios
      .post(API_URLS.events, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createEventSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createEventFail(err.response.data.message));
      });
  };

export const draftUpdate =
  (inputs: DraftUpdateRequest) => (dispatch: Dispatch) => {
    dispatch(updateEventStart());
    const { id, ...updatedEvent } = inputs;
    return axios
      .patch(`${API_URLS.events}/${id}`, {
        ...updatedEvent,
      })
      .then((response) => {
        dispatch(updateEventSuccess());
      })
      .catch((err) => {
        dispatch(updateEventFail(err.response.data.message));
      });
  };

export const updateEvent =
  (inputs: EventUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateEventStart());
    const { id, ...updatedEvent } = inputs;
    return axios
      .patch(`${API_URLS.events}/${id}`, {
        ...updatedEvent,
      })
      .then(() => {
        dispatch(updateEventSuccess());
        showToast(translate(intl, 'SUCCESS.EVENT_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateEventFail(err.response.data.message));
      });
  };

export const createPersonalEvent =
  (inputs: EventUpdatePersonalRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createEventStart());
    const { id, ...updatedEvent } = inputs;
    return axios
      .patch(`${API_URLS.events}/${id}/personal`, {
        ...updatedEvent,
      })
      .then((response) => {
        dispatch(createEventSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.EVENT_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createEventFail(err.response.data.message));
      });
  };

export const updatePersonalEvent =
  (inputs: EventUpdatePersonalRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateEventStart());
    const { id, ...updatedEvent } = inputs;
    return axios
      .patch(`${API_URLS.events}/${id}/personal`, {
        ...updatedEvent,
      })
      .then(() => {
        dispatch(updateEventSuccess());

        if (inputs.status !== EventStatus.DRAFT) {
          showToast(translate(intl, 'SUCCESS.EVENT_UPDATE'), 'success');
        }
      })
      .catch((err) => {
        dispatch(updateEventFail(err.response.data.message));
      });
  };

export const deleteEvent =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteEventStart());
    return axios
      .delete(`${API_URLS.events}/${id}`)
      .then(() => {
        dispatch(deleteEventSuccess());
        showToast(translate(intl, 'SUCCESS.EVENT_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteEventFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const generateCertificates =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(generateCertificatesStart());
    return axios
      .post(`${API_URLS.events}/${id}/generateCertificates`)
      .then(() => {
        dispatch(generateCertificatesSuccess());
        dispatch(setUpdateNeeded());
        showToast(
          translate(intl, 'SUCCESS.EVENT_CERTIFICATES_CREATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(generateCertificatesFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const generateSingleParticipantCertificate =
  (eventId: number, id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(generateParticipantCertificateStart());
    return axios
      .post(
        `${API_URLS.events}/${eventId}/generate-participant-certificate/${id}`,
      )
      .then(() => {
        dispatch(generateParticipantCertificateSuccess());
        dispatch(setUpdateNeeded());
        showToast(translate(intl, 'SUCCESS.CERTIFICATE_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(generateParticipantCertificateFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const sendCertificates =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(sendCertificatesStart());
    return axios
      .post(`${API_URLS.events}/${id}/sendCertificates`)
      .then(() => {
        dispatch(sendCertificatesSuccess());
        showToast(translate(intl, 'SUCCESS.CERTIFICATES_SENT'), 'success');
      })
      .catch((err) => {
        dispatch(sendCertificatesFail(err.response.data.message));
      });
  };

export const sendParticipantCertificate =
  (eventId: number, id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(sendParticipantCertificateStart());
    return axios
      .post(`${API_URLS.events}/${eventId}/send-participant-certificate/${id}`)
      .then(() => {
        dispatch(sendParticipantCertificateSuccess());
        showToast(translate(intl, 'SUCCESS.CERTIFICATE_SENT'), 'success');
      })
      .catch((err) => {
        dispatch(sendParticipantCertificateFail(err.response.data.message));
      });
  };

export const sendBulkParticipantsCertificates =
  (data: SelectedParticipants, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(sendBulkParticipantsCertificatesStart());
    return axios
      .post(`${API_URLS.events}/bulk/send-participant-certificates`, data)
      .then(() => {
        dispatch(sendBulkParticipantsCertificatesSuccess());
        showToast(translate(intl, 'SUCCESS.CERTIFICATES_SENT'), 'success');
      })
      .catch((err) => {
        dispatch(
          sendBulkParticipantsCertificatesFail(err.response.data.message),
        );
      });
  };

export const getCertificationState = (id: number) => (dispatch: Dispatch) => {
  dispatch(getCertificationStateStart());
  return axios
    .get(`${API_URLS.events}/${id}/getCertificationState`)
    .then((response) => {
      dispatch(getCertificationStateSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getCertificationStateFail(err.response.data.message));
    });
};

export const downloadFile =
  (eventId: number, assetId: number, fileName: string, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(downloadFileStart());
    return axios
      .get(`${API_URLS.events}/${eventId}/download-file/${assetId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(downloadFileSuccess());
        download(
          new Blob([response.data], {
            type: 'image/jpeg, image/png, application/zip, application/pdf',
          }),
          fileName,
          'application/octet-stream',
        );
      })
      .catch(async (err) => {
        const errorResponse = JSON.parse(await err.response.data.text());
        dispatch(downloadFileFail(errorResponse.error));
        showToast(translate(intl, errorResponse.message), 'error');
      });
  };

export const updateAsset =
  (inputs: AssetUpdateRequest, isFinal: boolean, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateAssetStart());
    const form = new FormData();
    Object.keys(inputs).forEach((inputKey) => {
      // @ts-ignore
      const value = inputs[inputKey];

      if (inputKey === 'documents' || inputKey === 'programme') {
        for (let i = 0; i < value.length; i++) {
          form.append(inputKey, value[i]);
        }
      } else if (inputKey === 'background') {
        form.append(inputKey, value);
      } else if (value) {
        form.append(inputKey, JSON.stringify(value));
      }
    });
    return axios
      .patch(`${API_URLS.events}/${inputs.id}/update-files`, form)
      .then((response) => {
        dispatch(updateAssetSuccess(response.data));
        isFinal &&
          showToast(translate(intl, 'SUCCESS.ASSET_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateAssetFail(err.response.data.message));
      });
  };

export const uploadBackground =
  (inputs: AssetUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateAssetStart());
    const form = new FormData();
    Object.keys(inputs).forEach((inputKey) => {
      // @ts-ignore
      const value = inputs[inputKey];

      if (inputKey === 'background') {
        form.append(inputKey, value);
      } else if (value) {
        form.append(inputKey, JSON.stringify(value));
      }
    });
    return axios
      .patch(`${API_URLS.events}/${inputs.id}/upload-background`, form)
      .then((response) => {
        dispatch(updateAssetSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.BACKGROUND_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateAssetFail(err.response.data.message));
      });
  };

export const getAssetThumbnail =
  (eventId: number, assetId: number) => (dispatch: Dispatch) => {
    dispatch(getAssetThumbnailStart());
    return axios
      .get(`${API_URLS.events}/${eventId}/download-file/${assetId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/octet-binary',
        });
        dispatch(getAssetThumbnailSuccess({ blob, assetId }));
      })
      .catch((err) => {
        dispatch(getAssetThumbnailFail(err.response.data.message));
      });
  };
