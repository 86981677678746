export const FETCH_TRANSLATIONS_START = 'FETCH_TRANSLATIONS_START';
export const FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAIL = 'FETCH_TRANSLATIONS_FAIL';

export const UPDATE_TRANSLATIONS_START = 'UPDATE_TRANSLATIONS_START';
export const UPDATE_TRANSLATIONS_SUCCESS = 'UPDATE_TRANSLATIONS_SUCCESS';
export const UPDATE_TRANSLATIONS_FAIL = 'UPDATE_TRANSLATIONS_FAIL';

export const SET_TRANSLATIONS_FILTER = 'SET_TRANSLATIONS_FILTER';

export const RESET_TRANSLATIONS_STORE = 'RESET_TRANSLATIONS_STORE';

export type TranslationActionTypes =
  | typeof FETCH_TRANSLATIONS_START
  | typeof FETCH_TRANSLATIONS_SUCCESS
  | typeof FETCH_TRANSLATIONS_FAIL
  | typeof UPDATE_TRANSLATIONS_START
  | typeof UPDATE_TRANSLATIONS_SUCCESS
  | typeof UPDATE_TRANSLATIONS_FAIL
  | typeof SET_TRANSLATIONS_FILTER
  | typeof RESET_TRANSLATIONS_STORE;
