import { IntlShape } from 'react-intl';
import { ErrorMessageObject } from '../validation/validation';

export const translate = (
  intl: IntlShape,
  id: string,
  defaultMessage?: string,
) =>
  intl.formatMessage({
    id,
    defaultMessage: defaultMessage || id,
  });

export const getTranslatedError = (
  errors: Array<ErrorMessageObject> | undefined,
  intl: IntlShape,
): string | undefined => {
  if (!errors || errors.length === 0) {
    return;
  }

  const message = errors.map((error: { label: string; parameter: string }) => ({
    errorMessage: translate(intl, error.label, ''),
    parameter: error.parameter,
  }));

  const errorMessageToShow = message[0].errorMessage;
  const errorMessageParameter = message[0].parameter;

  if (errorMessageToShow.includes(':value')) {
    return errorMessageToShow.replace(':value', errorMessageParameter);
  }

  return errorMessageToShow;
};

export const getTranslatedLabel = (
  label: string | undefined,
  intl: IntlShape,
) => {
  if (!label) {
    return;
  }

  return translate(intl, label, '');
};

export const getTranslatedPlaceholder = (
  placeholder: string | undefined,
  intl: IntlShape,
) => {
  if (!placeholder) {
    return;
  }

  return translate(intl, placeholder, '');
};
