export const FETCH_GLOBAL_SETTING_START = 'FETCH_GLOBAL_SETTING_START';
export const FETCH_GLOBAL_SETTING_SUCCESS = 'FETCH_GLOBAL_SETTING_SUCCESS';
export const FETCH_GLOBAL_SETTING_FAIL = 'FETCH_GLOBAL_SETTING_FAIL';

export const UPDATE_GLOBAL_SETTING_START = 'UPDATE_GLOBAL_SETTING_START';
export const UPDATE_GLOBAL_SETTING_SUCCESS = 'UPDATE_GLOBAL_SETTING_SUCCESS';
export const UPDATE_GLOBAL_SETTING_FAIL = 'UPDATE_GLOBAL_SETTING_FAIL';

export type GlobalSettingActionTypes =
  | typeof FETCH_GLOBAL_SETTING_START
  | typeof FETCH_GLOBAL_SETTING_SUCCESS
  | typeof FETCH_GLOBAL_SETTING_FAIL
  | typeof UPDATE_GLOBAL_SETTING_START
  | typeof UPDATE_GLOBAL_SETTING_SUCCESS
  | typeof UPDATE_GLOBAL_SETTING_FAIL;
