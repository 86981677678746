import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { EventType, EventTypesList } from '../../domain/EventType';
import { ListParams } from '../../hooks/useList/useList';

export const fetchEventTypesStart = () => ({
  type: actionTypes.FETCH_EVENT_TYPES_START,
});

export const fetchEventTypesSuccess = (eventTypesList: EventTypesList) => ({
  type: actionTypes.FETCH_EVENT_TYPES_SUCCESS,
  eventTypesList,
});

export const fetchEventTypesFail = (eventTypesListError: HttpError) => ({
  type: actionTypes.FETCH_EVENT_TYPES_FAIL,
  eventTypesListError,
});

export const fetchEventTypeStart = () => ({
  type: actionTypes.FETCH_EVENT_TYPE_START,
});

export const fetchEventTypeSuccess = (eventType: EventType) => ({
  type: actionTypes.FETCH_EVENT_TYPE_SUCCESS,
  eventType,
});

export const fetchEventTypeFail = (eventTypeError: HttpError) => ({
  type: actionTypes.FETCH_EVENT_TYPE_FAIL,
  eventTypeError,
});

export const createEventTypeStart = () => ({
  type: actionTypes.CREATE_EVENT_TYPE_START,
});

export const createEventTypeSuccess = (createdEventType: EventType) => ({
  type: actionTypes.CREATE_EVENT_TYPE_SUCCESS,
  createdEventType,
});

export const createEventTypeFail = (eventTypeCreateError: string) => ({
  type: actionTypes.CREATE_EVENT_TYPE_FAIL,
  eventTypeCreateError,
});

export const updateEventTypeStart = () => ({
  type: actionTypes.UPDATE_EVENT_TYPE_START,
});

export const updateEventTypeSuccess = () => ({
  type: actionTypes.UPDATE_EVENT_TYPE_SUCCESS,
});

export const updateEventTypeFail = (eventTypeUpdateError: string) => ({
  type: actionTypes.UPDATE_EVENT_TYPE_FAIL,
  eventTypeUpdateError,
});

export const deleteEventTypeStart = () => ({
  type: actionTypes.DELETE_EVENT_TYPE_START,
});

export const deleteEventTypeSuccess = () => ({
  type: actionTypes.DELETE_EVENT_TYPE_SUCCESS,
});

export const deleteEventTypeFail = (eventTypeDeleteError: string) => ({
  type: actionTypes.DELETE_EVENT_TYPE_FAIL,
  eventTypeDeleteError,
});

export const setEventTypesFilterValues = (eventTypesFilter: ListParams) => ({
  type: actionTypes.SET_EVENT_TYPES_FILTER,
  eventTypesFilter,
});

export const resetEventTypeStore = () => ({
  type: actionTypes.RESET_EVENT_TYPE_STORE,
});

export const setUpdateNeeded = () => ({
  type: actionTypes.SET_EVENT_TYPE_UPDATE_NEEDED,
});

export const resetCreatedEventType = () => ({
  type: actionTypes.RESET_CREATED_EVENT_TYPE,
});
