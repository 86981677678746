/* eslint-disable no-unused-vars */
export enum Extension {
  ZIP = 'zip',
  PDF = 'pdf',
  XLSX = 'xlsx',
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  MP4 = 'mp4',
}

export enum ActionType {
  GENERATE_CERTIFICATE = 'GENERATE_CERTIFICATE',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  DETAILS = 'DETAILS',
  DOWNLOAD = 'DOWNLOAD',
  SIGN_CERTIFICATE = 'SIGN_CERTIFICATE',
  DELETE_BULK = 'DELETE_BULK',
  GENERATE_SINGLE_CERTIFICATE = 'GENERATE_SINGLE_CERTIFICATE',
  SEND_CERTIFICATE = 'SEND_CERTIFICATE',
  SEND_BULK_CERTIFICTAES = 'SEND_BULK_CERTIFICATES',
  BULK_SIGN = 'BULK_SIGN',
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  DUPLICATE = 'DUPLICATE',
  ADD_CREDITS = 'ADD_CREDITS',
  ADD_MATERIAL = 'ADD_MATERIAL',
  CHANGE_QUALIFIED = 'CHANGE_QUALIFIED',
  CHANGE_UNQUALIFIED = 'CHANGE_UNQUALIFIED',
  EXCEL_EXPORT = 'EXCEL_EXPORT',
  PDF_EXPORT = 'PDF_EXPORT',
  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
  CONFIRM_CERTIFICATE = 'CONFIRM_CERTIFICATE',
}

export enum ListType {
  GLOBAL = 'GLOBAL',
  COMPANY = 'COMPANY',
}

export enum FormInputType {
  TEXT_AREA = 'TEXT_AREA',
  FILE = 'FILE',
  TEXT = 'TEXT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  DATE_TIME = 'DATE_TIME',
  RICH_TEXT = 'RICH_TEXT',
  SWITCHER = 'SWITCHER',
  NUMBER = 'NUMBER',
  PASSWORD = 'PASSWORD',
  THUMBNAIL_SELECT = 'THUMBNAIL_SELECT',
  CUSTOM = 'CUSTOM',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  CURRENCY = 'CURRENCY',
  TIME = 'TIME',
}

export const emptyValue = '---';
