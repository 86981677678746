import * as actionTypes from './actionTypes';
import { CompanyActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Company } from '../../domain/Company';
import { ListResults } from '../../common/List/List';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type CompanyStateType = {
  companyOptions: Company[];
  companyOptionsLoading: boolean;
  companyOptionsError: HttpError;
  companiesListUpdateNeeded: boolean;
  selectedCompany: number | null;
  companySelectLoading: boolean;
  companySelectSuccess: boolean;
  companySelectError: HttpError;
  company: Company | null;
  companyLoading: boolean;
  companyError: HttpError;
  isUpdateNeeded: boolean;
  companiesList: ListResults<Company> | null;
  companiesListLoading: boolean;
  companiesListError: HttpError;
  companyUpdateLoading: boolean;
  companyUpdateError: HttpError;
  companyUpdateSuccess: boolean;
  companyFilter: ListParams;
  companyCreateLoading: boolean;
  companyCreateError: HttpError;
  companyCreateSuccess: boolean;
  createdCompany: Company | null;
  editCompany: Company | null;
  editCompanyLoading: boolean;
  editCompanyError: HttpError;
  companyDeleteLoading: boolean;
  companyDeleteError: HttpError;
  companyCreditsAddLoading: boolean;
  companyCreditsAddError: HttpError;
  companyCreditsAddSuccess: boolean;
};

export type CompanyActionType = CompanyStateType & {
  type: CompanyActionTypes;
};

export const initialState: CompanyStateType = {
  companyOptions: [],
  companyOptionsLoading: true,
  companyOptionsError: null,
  companiesListUpdateNeeded: false,
  selectedCompany: null,
  companySelectLoading: false,
  companySelectSuccess: false,
  companySelectError: null,
  company: null,
  companyLoading: false,
  companyError: null,
  isUpdateNeeded: false,
  companiesList: null,
  companiesListLoading: false,
  companiesListError: null,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyUpdateSuccess: false,
  companyFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'company.name',
  },
  companyCreateLoading: false,
  companyCreateError: null,
  companyCreateSuccess: false,
  createdCompany: null,
  editCompany: null,
  editCompanyLoading: false,
  editCompanyError: null,
  companyDeleteLoading: false,
  companyDeleteError: null,
  companyCreditsAddLoading: false,
  companyCreditsAddError: null,
  companyCreditsAddSuccess: false,
};

const fetchCompanyOptionsStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyOptionsLoading: true,
  isUpdateNeeded: false,
});

const fetchCompanyOptionsSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptions: action.companyOptions,
  companyOptionsLoading: false,
  companyOptionsError: null,
});

const fetchCompanyOptionsFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptionsError: action.companyOptionsError,
  companyOptionsLoading: false,
});

const setSelectedCompany = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
});

const selectCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companySelectLoading: true,
});

const selectCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
  companySelectLoading: false,
  companySelectSuccess: true,
  companySelectError: null,
  isUpdateNeeded: true,
});

const selectCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companySelectError: action.companySelectError,
  companySelectLoading: false,
});

const fetchCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyLoading: true,
});

const fetchCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  company: action.company,
  companyLoading: false,
  companyError: null,
});

const fetchCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyError: action.companyError,
  companyLoading: false,
});

const fetchCompaniesStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companiesListLoading: true,
});

const fetchCompaniesSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesList: action.companiesList,
  companiesListLoading: false,
  companiesListError: null,
});

const fetchCompaniesFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesListError: action.companiesListError,
  companiesListLoading: false,
});

const resetCompaniesListStore = (state: CompanyStateType) => ({
  ...state,
  companiesList: null,
  companiesListSuccess: false,
  companiesListError: null,
});

const updateCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: true,
});

const updateCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyUpdateSuccess: true,
  companiesListUpdateNeeded: true,
});

const updateCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateError: action.companyUpdateError,
});

const resetCompanyStore = (): CompanyStateType => ({
  ...initialState,
});

const resetCompanyCreateUpdateStore = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyCreateSuccess: false,
  companyCreateError: null,
  companyUpdateSuccess: false,
  companyUpdateError: null,
  editCompany: null,
});

const createCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyCreateLoading: true,
});

const createCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateError: null,
  companyCreateSuccess: true,
  createdCompany: action.createdCompany,
});

const createCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateError: action.companyCreateError,
});

const fetchEditCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  editCompanyLoading: true,
});

const fetchEditCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  editCompany: action.editCompany,
  editCompanyLoading: false,
  editCompanyError: null,
});

const fetchEditCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  editCompanyError: action.companyError,
  editCompanyLoading: false,
});

const deleteCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyDeleteLoading: true,
  companiesListUpdateNeeded: false,
});

const deleteCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyDeleteLoading: false,
  companyDeleteError: null,
  companiesListUpdateNeeded: true,
});

const deleteCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyDeleteLoading: false,
  companyDeleteError: action.companyDeleteError,
});

const addCompanyCreditsStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyCreditsAddLoading: true,
});

const addCompanyCreditsSuccess = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyCreditsAddLoading: false,
  companyCreditsAddError: null,
  companyCreditsAddSuccess: true,
});

const addCompanyCreditsFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreditsAddLoading: false,
  companyCreditsAddError: action.companyCreditsAddError,
});

const resetCompanyCreditsAddStore = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyCreditsAddLoading: false,
  companyCreditsAddSuccess: false,
  companyCreditsAddError: null,
});

const reducer = (state = initialState, action: CompanyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_OPTIONS_START:
      return fetchCompanyOptionsStart(state);
    case actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS:
      return fetchCompanyOptionsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_OPTIONS_FAIL:
      return fetchCompanyOptionsFail(state, action);
    case actionTypes.FETCH_COMPANY_START:
      return fetchCompanyStart(state);
    case actionTypes.FETCH_COMPANY_SUCCESS:
      return fetchCompanySuccess(state, action);
    case actionTypes.FETCH_COMPANY_FAIL:
      return fetchCompanyFail(state, action);
    case actionTypes.SET_SELECTED_COMPANY:
      return setSelectedCompany(state, action);
    case actionTypes.SELECT_COMPANY_START:
      return selectCompanyStart(state);
    case actionTypes.SELECT_COMPANY_SUCCESS:
      return selectCompanySuccess(state, action);
    case actionTypes.SELECT_COMPANY_FAIL:
      return selectCompanyFail(state, action);
    case actionTypes.FETCH_COMPANIES_START:
      return fetchCompaniesStart(state);
    case actionTypes.FETCH_COMPANIES_SUCCESS:
      return fetchCompaniesSuccess(state, action);
    case actionTypes.FETCH_COMPANIES_FAIL:
      return fetchCompaniesFail(state, action);
    case actionTypes.RESET_COMPANIES_LIST_STORE:
      return resetCompaniesListStore(state);
    case actionTypes.UPDATE_COMPANY_START:
      return updateCompanyStart(state);
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return updateCompanySuccess(state);
    case actionTypes.UPDATE_COMPANY_FAIL:
      return updateCompanyFail(state, action);
    case actionTypes.CREATE_COMPANY_START:
      return createCompanyStart(state);
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return createCompanySuccess(state, action);
    case actionTypes.CREATE_COMPANY_FAIL:
      return createCompanyFail(state, action);
    case actionTypes.FETCH_EDIT_COMPANY_START:
      return fetchEditCompanyStart(state);
    case actionTypes.FETCH_EDIT_COMPANY_SUCCESS:
      return fetchEditCompanySuccess(state, action);
    case actionTypes.FETCH_EDIT_COMPANY_FAIL:
      return fetchEditCompanyFail(state, action);
    case actionTypes.DELETE_COMPANY_START:
      return deleteCompanyStart(state);
    case actionTypes.DELETE_COMPANY_SUCCESS:
      return deleteCompanySuccess(state);
    case actionTypes.DELETE_COMPANY_FAIL:
      return deleteCompanyFail(state, action);
    case actionTypes.RESET_COMPANY_STORE:
      return resetCompanyStore();
    case actionTypes.RESET_COMPANY_CREATE_STORE:
      return resetCompanyCreateUpdateStore(state);
    case actionTypes.ADD_COMPANY_CREDITS_START:
      return addCompanyCreditsStart(state);
    case actionTypes.ADD_COMPANY_CREDITS_SUCCESS:
      return addCompanyCreditsSuccess(state);
    case actionTypes.ADD_COMPANY_CREDITS_FAIL:
      return addCompanyCreditsFail(state, action);
    case actionTypes.RESET_COMPANY_CREDITS_ADD_STORE:
      return resetCompanyCreditsAddStore(state);
    default:
      return state;
  }
};

export default reducer;
