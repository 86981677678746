import * as actionTypes from './actionTypes';
import { EventPurposeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { EventPurpose, EventPurposesList } from '../../domain/EventPurpose';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type EventPurposeStateType = {
  eventPurposesList: EventPurposesList | null;
  eventPurposesListLoading: boolean;
  eventPurposesListError: HttpError;
  eventPurposesFilter: ListParams;
  eventPurposesListUpdateNeeded: boolean;
  eventPurpose: EventPurpose | null;
  eventPurposeLoading: boolean;
  eventPurposeError: HttpError;
  eventPurposeCreateLoading: boolean;
  eventPurposeCreateError: HttpError;
  eventPurposeCreateSuccess: boolean;
  eventPurposeUpdateLoading: boolean;
  eventPurposeUpdateError: HttpError;
  eventPurposeUpdateSuccess: boolean;
  eventPurposeDeleteLoading: boolean;
  eventPurposeDeleteError: HttpError;
  createdEventPurpose: EventPurpose | null;
};

export type EventPurposeActionType = EventPurposeStateType & {
  type: EventPurposeActionTypes;
};

export const initialState: EventPurposeStateType = {
  eventPurposesList: null,
  eventPurposesListLoading: true,
  eventPurposesListError: null,
  eventPurposesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  eventPurposesListUpdateNeeded: false,
  eventPurpose: null,
  eventPurposeLoading: false,
  eventPurposeError: null,
  eventPurposeCreateLoading: false,
  eventPurposeCreateError: null,
  eventPurposeCreateSuccess: false,
  eventPurposeUpdateLoading: false,
  eventPurposeUpdateError: null,
  eventPurposeUpdateSuccess: false,
  eventPurposeDeleteLoading: false,
  eventPurposeDeleteError: null,
  createdEventPurpose: null,
};

const fetchEventPurposesStart = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  eventPurposesListLoading: true,
  eventPurposeUpdateSuccess: false,
  eventPurposeCreateSuccess: false,
  eventPurposeDeleteError: null,
  eventPurpose: null,
});

const fetchEventPurposesSuccess = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurposesList: action.eventPurposesList,
  eventPurposesListLoading: false,
  eventPurposesListError: null,
  eventPurposesListUpdateNeeded: false,
});

const fetchEventPurposesFail = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurposesListError: action.eventPurposesListError,
  eventPurposesListLoading: false,
});

const fetchEventPurposeStart = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeLoading: true,
  eventPurposeUpdateSuccess: false,
  eventPurposeCreateSuccess: false,
  eventPurposeCreateError: null,
  eventPurposeUpdateError: null,
});

const fetchEventPurposeSuccess = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurpose: action.eventPurpose,
  eventPurposeLoading: false,
  eventPurposeError: null,
});

const fetchEventPurposeFail = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeError: action.eventPurposeError,
  eventPurposeLoading: false,
});

const createEventPurposeStart = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeCreateLoading: true,
});

const createEventPurposeSuccess = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeCreateLoading: false,
  eventPurposeCreateError: null,
  eventPurposeCreateSuccess: true,
  eventPurposesListUpdateNeeded: true,
  createdEventPurpose: action.createdEventPurpose,
});

const createEventPurposeFail = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeCreateLoading: false,
  eventPurposeCreateError: action.eventPurposeCreateError,
});

const updateEventPurposeStart = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeUpdateLoading: true,
});

const updateEventPurposeSuccess = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeUpdateLoading: false,
  eventPurposeUpdateError: null,
  eventPurposeUpdateSuccess: true,
  eventPurposesListUpdateNeeded: true,
});

const updateEventPurposeFail = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeUpdateLoading: false,
  eventPurposeUpdateError: action.eventPurposeUpdateError,
});

const deleteEventPurposeStart = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeDeleteLoading: true,
});

const deleteEventPurposeSuccess = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeDeleteLoading: false,
  eventPurposeDeleteError: null,
  eventPurposesListUpdateNeeded: true,
});

const deleteEventPurposeFail = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurposeDeleteLoading: false,
  eventPurposeDeleteError: action.eventPurposeDeleteError,
});

const setEventPurposesFilter = (
  state: EventPurposeStateType,
  action: EventPurposeActionType,
): EventPurposeStateType => ({
  ...state,
  eventPurposesFilter: action.eventPurposesFilter,
});

const resetStore = (): EventPurposeStateType => ({
  eventPurposesList: null,
  eventPurposesListLoading: true,
  eventPurposesListError: null,
  eventPurposesFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  eventPurposesListUpdateNeeded: false,
  eventPurpose: null,
  eventPurposeLoading: false,
  eventPurposeError: null,
  eventPurposeCreateLoading: false,
  eventPurposeCreateError: null,
  eventPurposeCreateSuccess: false,
  eventPurposeUpdateLoading: false,
  eventPurposeUpdateError: null,
  eventPurposeUpdateSuccess: false,
  eventPurposeDeleteLoading: false,
  eventPurposeDeleteError: null,
  createdEventPurpose: null,
});

const setUpdateNeeded = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  eventPurposesListUpdateNeeded: true,
});

const resetCreatedEventPurpose = (
  state: EventPurposeStateType,
): EventPurposeStateType => ({
  ...state,
  createdEventPurpose: null,
});

const reducer = (state = initialState, action: EventPurposeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_PURPOSES_START:
      return fetchEventPurposesStart(state);
    case actionTypes.FETCH_EVENT_PURPOSES_SUCCESS:
      return fetchEventPurposesSuccess(state, action);
    case actionTypes.FETCH_EVENT_PURPOSES_FAIL:
      return fetchEventPurposesFail(state, action);
    case actionTypes.FETCH_EVENT_PURPOSE_START:
      return fetchEventPurposeStart(state);
    case actionTypes.FETCH_EVENT_PURPOSE_SUCCESS:
      return fetchEventPurposeSuccess(state, action);
    case actionTypes.FETCH_EVENT_PURPOSE_FAIL:
      return fetchEventPurposeFail(state, action);
    case actionTypes.CREATE_EVENT_PURPOSE_START:
      return createEventPurposeStart(state);
    case actionTypes.CREATE_EVENT_PURPOSE_SUCCESS:
      return createEventPurposeSuccess(state, action);
    case actionTypes.CREATE_EVENT_PURPOSE_FAIL:
      return createEventPurposeFail(state, action);
    case actionTypes.UPDATE_EVENT_PURPOSE_START:
      return updateEventPurposeStart(state);
    case actionTypes.UPDATE_EVENT_PURPOSE_SUCCESS:
      return updateEventPurposeSuccess(state);
    case actionTypes.UPDATE_EVENT_PURPOSE_FAIL:
      return updateEventPurposeFail(state, action);
    case actionTypes.DELETE_EVENT_PURPOSE_START:
      return deleteEventPurposeStart(state);
    case actionTypes.DELETE_EVENT_PURPOSE_SUCCESS:
      return deleteEventPurposeSuccess(state);
    case actionTypes.DELETE_EVENT_PURPOSE_FAIL:
      return deleteEventPurposeFail(state, action);
    case actionTypes.SET_EVENT_PURPOSES_FILTER:
      return setEventPurposesFilter(state, action);
    case actionTypes.RESET_EVENT_PURPOSE_STORE:
      return resetStore();
    case actionTypes.SET_EVENT_PURPOSE_UPDATE_NEEDED:
      return setUpdateNeeded(state);
    case actionTypes.RESET_CREATED_EVENT_PURPOSE:
      return resetCreatedEventPurpose(state);
    default:
      return state;
  }
};

export default reducer;
