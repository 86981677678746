import axios from '../../config/Axios/axios-instance';
import {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  setUsersFilterValues,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
  fetchUserStart,
  fetchUserSuccess,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchCurrentUserFail,
  createTopUpStart,
  createTopUpSuccess,
  createTopUpFail,
  fetchPublicUsersNumberStart,
  fetchPublicUsersNumberSuccess,
  fetchPublicUsersNumberFail,
  createUserStart,
  createUserSuccess,
  createUserFail,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { translate } from '../../utility/messageTranslator/translate';
import { IntlShape } from 'react-intl';

export type UserUpdateRequest = {
  id: string;
  role: string;
  firstName: string;
  lastName: string;
};

export type UserCreateRequest = {
  role: string;
  firstName: string;
  lastName: string;
  email: string;
};

export const fetchUsers =
  (params: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchUsersStart());

    if (saveFilters) {
      dispatch(setUsersFilterValues(params));
    }

    return axios
      .get(API_URLS.users, { params })
      .then((response) => {
        dispatch(fetchUsersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUsersFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const fetchUser = (userId: number) => (dispatch: Dispatch) => {
  dispatch(fetchUserStart());
  return axios
    .get(`${API_URLS.users}/${userId}`)
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUsersFail(err.response.data.message));
      showToast(err.response.data.message, 'error');
    });
};

export const updateUser =
  (inputs: UserUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    const { id, ...updateUser } = inputs;
    return axios
      .patch(`${API_URLS.users}/${id}`, {
        ...updateUser,
      })
      .then(() => {
        dispatch(updateUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserFail(err.response.data.message));
      });
  };

export const createUser =
  (inputs: UserCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createUserStart());
    return axios
      .post(API_URLS.users, {
        ...inputs,
      })
      .then(() => {
        dispatch(createUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createUserFail(err.response.data.message));
      });
  };

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());

  return axios
    .get(`${API_URLS.users}/me`)
    .then((response) => {
      dispatch(fetchCurrentUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
      if (
        err.response.data.statusCode !== 401 &&
        err.response.data.message !== 'Unauthorized'
      ) {
        showToast(err.response.data.message, 'error');
      }
    });
};

export const createTopUp = (id: number) => (dispatch: Dispatch) => {
  dispatch(createTopUpStart());
  return axios
    .post(`${API_URLS.orders}/${id}`)
    .then((response) => {
      dispatch(createTopUpSuccess(response.data));
    })
    .catch((err) => {
      dispatch(createTopUpFail(err.response.data.message));
    });
};

export const fetchPublicUsersNumber = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicUsersNumberStart());
  return axios
    .get(`${API_URLS.users}/public-count`)
    .then((response) => {
      dispatch(fetchPublicUsersNumberSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicUsersNumberFail(err));
    });
};
