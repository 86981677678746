import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  CertificateType,
  CertificateTypesList,
} from '../../domain/CertificateType';
import { ListParams } from '../../hooks/useList/useList';

export const fetchCertificateTypesStart = () => ({
  type: actionTypes.FETCH_CERTIFICATE_TYPES_START,
});

export const fetchCertificateTypesSuccess = (
  certificateTypesList: CertificateTypesList,
) => ({
  type: actionTypes.FETCH_CERTIFICATE_TYPES_SUCCESS,
  certificateTypesList,
});

export const fetchCertificateTypesFail = (
  certificateTypesListError: HttpError,
) => ({
  type: actionTypes.FETCH_CERTIFICATE_TYPES_FAIL,
  certificateTypesListError,
});

export const fetchCertificateTypeStart = () => ({
  type: actionTypes.FETCH_CERTIFICATE_TYPE_START,
});

export const fetchCertificateTypeSuccess = (
  certificateType: CertificateType,
) => ({
  type: actionTypes.FETCH_CERTIFICATE_TYPE_SUCCESS,
  certificateType,
});

export const fetchCertificateTypeFail = (certificateTypeError: HttpError) => ({
  type: actionTypes.FETCH_CERTIFICATE_TYPE_FAIL,
  certificateTypeError,
});

export const createCertificateTypeStart = () => ({
  type: actionTypes.CREATE_CERTIFICATE_TYPE_START,
});

export const createCertificateTypeSuccess = (
  createdCertificateType: CertificateType,
) => ({
  type: actionTypes.CREATE_CERTIFICATE_TYPE_SUCCESS,
  createdCertificateType,
});

export const createCertificateTypeFail = (
  certificateTypeCreateError: string,
) => ({
  type: actionTypes.CREATE_CERTIFICATE_TYPE_FAIL,
  certificateTypeCreateError,
});

export const updateCertificateTypeStart = () => ({
  type: actionTypes.UPDATE_CERTIFICATE_TYPE_START,
});

export const updateCertificateTypeSuccess = () => ({
  type: actionTypes.UPDATE_CERTIFICATE_TYPE_SUCCESS,
});

export const updateCertificateTypeFail = (
  certificateTypeUpdateError: string,
) => ({
  type: actionTypes.UPDATE_CERTIFICATE_TYPE_FAIL,
  certificateTypeUpdateError,
});

export const deleteCertificateTypeStart = () => ({
  type: actionTypes.DELETE_CERTIFICATE_TYPE_START,
});

export const deleteCertificateTypeSuccess = () => ({
  type: actionTypes.DELETE_CERTIFICATE_TYPE_SUCCESS,
});

export const deleteCertificateTypeFail = (
  certificateTypeDeleteError: string,
) => ({
  type: actionTypes.DELETE_CERTIFICATE_TYPE_FAIL,
  certificateTypeDeleteError,
});

export const setCertificateTypesFilterValues = (
  certificateTypesFilter: ListParams,
) => ({
  type: actionTypes.SET_CERTIFICATE_TYPES_FILTER,
  certificateTypesFilter,
});

export const resetCertificateTypeStore = () => ({
  type: actionTypes.RESET_CERTIFICATE_TYPE_STORE,
});

export const setUpdateNeeded = () => ({
  type: actionTypes.SET_CERTIFICATE_TYPE_UPDATE_NEEDED,
});

export const resetCreatedCertificateType = () => ({
  type: actionTypes.RESET_CREATED_CERTIFICATE_TYPE,
});
