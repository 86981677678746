import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createCreditPackageFail,
  createCreditPackageStart,
  createCreditPackageSuccess,
  deleteCreditPackageFail,
  deleteCreditPackageStart,
  deleteCreditPackageSuccess,
  fetchCreditPackageFail,
  fetchCreditPackagesFail,
  fetchCreditPackagesStart,
  fetchCreditPackagesSuccess,
  fetchCreditPackageStart,
  fetchCreditPackageSuccess,
  updateCreditPackageFail,
  updateCreditPackageStart,
  updateCreditPackageSuccess,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/credit-packages';

export type CreditPackageCreateRequest = {
  name: string;
  price: number;
  credits: number;
  additionalCredits: number;
  description: string;
};

export type CreditPackageUpdateRequest = {
  id: number;
  name: string;
  price: number;
  credits: number;
  additionalCredits: number;
  description: string;
};

export const fetchCreditPackages =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchCreditPackagesStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchCreditPackagesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCreditPackagesFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };

export const fetchCreditPackage = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchCreditPackageStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchCreditPackageSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCreditPackageFail(err.response.data.error));
      showToast(err.response.data.message, 'error');
    });
};

export const createCreditPackage =
  (inputs: CreditPackageCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createCreditPackageStart());
    return axios
      .post(API_URL, { ...inputs })
      .then((response) => {
        dispatch(createCreditPackageSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.CREDIT_PACKAGE_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createCreditPackageFail(err.response.data.message));
        showToast(`${err.response.data.message}`, 'error');
      });
  };

export const updateCreditPackage =
  (inputs: CreditPackageUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateCreditPackageStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, { ...inputs })
      .then(() => {
        dispatch(updateCreditPackageSuccess());
        showToast(translate(intl, 'SUCCESS.CREDIT_PACKAGE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCreditPackageFail(err.response.data.message));
        showToast(`${err.response.data.message}`, 'error');
      });
  };

export const deleteCreditPackage =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteCreditPackageStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteCreditPackageSuccess());
        showToast(translate(intl, 'SUCCESS.CREDIT_PACKAGE_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteCreditPackageFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };
