import { Roles } from '../../domain/Role';

export const getState = (
  selectedRole: Roles | null,
  selectedCompany: number | null,
) => {
  if (!selectedRole) {
    return;
  }

  if (selectedRole !== Roles.ADMIN) {
    return false;
  }

  return selectedCompany ? false : true;
};
