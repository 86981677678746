import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Layout.module.scss';
import Navigation from '../Navigation/Navigation';
import Sidebar from '../Sidebar/Sidebar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { CookieConsent } from 'react-cookie-consent';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Property } from 'csstype';
import routes from '../../config/Router/routes';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import { Company } from '../../domain/Company';
import Modal from '../Modal/Modal';
import CompanySelectForm from '../../components/Company/CompanySelectForm/CompanySelectForm';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as companyService from '../../store/company/service';
import { User } from '../../domain/User';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { fetchCompany } from '../../store/company/service';
import { ListParams } from '../../hooks/useList/useList';
import { Roles } from '../../domain/Role';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  currentUser: User | null;
  companyOptions: Company[];
  onCompanyOptionsFetch: (params: ListParams) => void;
  selectedCompany: number | null;
  onFetchCompany: (selectedCompany: number) => void;
  selectedRole: Roles | null;
  isSuccess: boolean;
  onSelectedCompanyUpdate: (selectedCompanyId: number | null) => void;
  isCompanyOptionsLoading: boolean;
};

const MOBILE_BREAK_POINT = 1200;

const cookieConsentStyles = {
  container: {
    background: 'rgba(79, 136, 144, 0.9)',
    display: 'flex',
    flexDirection: 'column' as Property.FlexDirection,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    textAlign: 'center' as Property.TextAlign,
    flex: 1,
    margin: 0,
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  button: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '8px 22px',
    color: 'black',
    fontWeight: 400,
    fontSize: '0.9375rem',
    fontFamily: '"Open Sans", sans-serif',
    margin: 0,
  },
};

const Layout = ({
  children,
  isAuthenticated,
  currentUser,
  companyOptions,
  onCompanyOptionsFetch,
  selectedCompany,
  onFetchCompany,
  selectedRole,
  isSuccess,
  onSelectedCompanyUpdate,
  isCompanyOptionsLoading,
}: Props) => {
  const location = useLocation();
  const { width } = useWindowSize();
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    if (
      !currentUser?.roles.every(
        (role) => role.role === Roles.USER || role.role === Roles.LECTURER,
      ) &&
      selectedRole &&
      selectedRole !== Roles.USER &&
      selectedRole !== Roles.LECTURER
    ) {
      onCompanyOptionsFetch(DEFAULT_LIST_PARAMS);
    }
  }, [currentUser, selectedRole, isSuccess]);

  const DEFAULT_LIST_PARAMS: ListParams = {
    selectedRole: selectedRole,
  };

  useEffect(() => {
    if (selectedCompany && companyOptions.length === 0) {
      return;
    }

    if (
      !selectedCompany &&
      !isCompanyOptionsLoading &&
      companyOptions.length > 0 &&
      companyOptions[0].id &&
      ((selectedRole === Roles.ORGANISER &&
        currentUser?.companies.some(
          (company) => company.id === companyOptions[0].id,
        )) ||
        selectedRole === Roles.MANAGER)
    ) {
      onSelectedCompanyUpdate(companyOptions[0].id);
      localStorage.setItem('selectedCompany', companyOptions[0].id.toString());
      return setCompany(companyOptions[0]);
    }

    setCompany(
      companyOptions.find(
        (companyOption) => companyOption.id === selectedCompany,
      ),
    );
  }, [companyOptions, selectedCompany]);

  useEffect(() => {
    if (company && company.id) {
      onFetchCompany(company.id);
    }
  }, [company]);

  const renderCookieConsent = () => {
    return (
      <CookieConsent
        location="bottom"
        buttonText={translate(intl, 'GENERIC.COOKIE_CONSENT_AGREE', 'Agree')}
        declineButtonText={translate(
          intl,
          'GENERIC.COOKIE_CONSENT_PARTIALLY_AGREE',
          'Agree with required cookies',
        )}
        cookieName="cookieConsent"
        declineCookieValue="true"
        enableDeclineButton
        style={cookieConsentStyles.container}
        buttonStyle={cookieConsentStyles.button}
        declineButtonStyle={cookieConsentStyles.button}
        buttonWrapperClasses={styles.buttonContainer}
        contentStyle={cookieConsentStyles.content}
        expires={150}
      >
        {translate(
          intl,
          'GENERIC.COOKIE_CONSENT_TEXT',
          'Our site is using cookies to customize the content and improve browsing experience.',
        )}{' '}
        <Link to={routes.privacyPolicy} className={styles.moreInfo}>
          {translate(intl, 'GENERIC.COOKIE_CONSENT_MORE_INFO', 'More info')}
        </Link>
      </CookieConsent>
    );
  };

  if (
    !isAuthenticated ||
    location.pathname.includes(`${routes.registration}/`)
  ) {
    return (
      <>
        {children}
        {renderCookieConsent()}
      </>
    );
  }

  if (
    location.pathname === routes.homepage ||
    location.pathname === routes.privacyPolicy ||
    location.pathname === routes.termsOfUse
  ) {
    return <>{children}</>;
  }

  const onCompanySelect = () => {
    setIsModalOpen(false);
    if (!selectedCompany) {
      history.push(routes.events.list);
    }

    if (
      location.pathname !== routes.events.list &&
      location.pathname !== routes.events.allEventsList &&
      location.pathname !== routes.organiserEvents
    ) {
      return selectedRole === Roles.ADMIN
        ? history.push(routes.events.list)
        : history.push(routes.organiserEvents);
    }

    return history.go(0);
  };

  const getCurrentCompany = () => {
    if (
      currentUser &&
      !currentUser.roles.some(
        (role) => role.role === Roles.ADMIN || Roles.ORGANISER,
      )
    ) {
      return null;
    }

    return (
      <CurrentAccount
        title={translate(intl, 'COMPANIES.NO_COMPANY_SELECTED')}
        subTitle={
          company
            ? `${translate(intl, 'COMPANIES.SELECTED_COMPANY')}:`
            : translate(intl, 'COMPANIES.CLICK_TO_SELECT')
        }
        companyName={company?.name}
        companyOwner={`${company?.owner?.profile?.firstName} ${company?.owner?.profile?.lastName}`}
        selectedRole={selectedRole}
        onClick={() => !isModalOpen && setIsModalOpen(true)}
      >
        <Modal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          title={translate(intl, 'COMPANIES.SELECT_COMPANY_TITLE')}
          className={styles.companySelectModal}
        >
          {isModalOpen && (
            <CompanySelectForm onSuccessSelect={onCompanySelect} />
          )}
        </Modal>
      </CurrentAccount>
    );
  };

  return (
    <div className={styles.layout}>
      <Navigation currentAccount={getCurrentCompany()} />
      {width && width >= MOBILE_BREAK_POINT && (
        <Sidebar currentAccount={getCurrentCompany()} />
      )}
      <div className={styles.withSidebar}>
        <div className={styles.content}>{children}</div>
      </div>
      {renderCookieConsent()}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedRole: state.auth.role,
  currentUser: state.user.currentUser,
  companyOptions: state.company.companyOptions,
  selectedCompany: state.company.selectedCompany,
  isSuccess: state.company.companyCreateSuccess,
  isCompanyOptionsLoading: state.company.companyOptionsLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyOptionsFetch: (params: ListParams) =>
    dispatch(companyService.fetchCompanyOptions(params)),
  onFetchCompany: (selectedCompany: number) =>
    dispatch(fetchCompany(selectedCompany)),
  onSelectedCompanyUpdate: (selectedCompanyId: number | null) =>
    dispatch(companyService.selectCompany(selectedCompanyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
