export const FETCH_PARTICIPANT_ACTIVITIES_START =
  'FETCH_PARTICIPANT_ACTIVITIES_START';
export const FETCH_PARTICIPANT_ACTIVITIES_SUCCESS =
  'FETCH_PARTICIPANT_ACTIVITIES_SUCCESS';
export const FETCH_PARTICIPANT_ACTIVITIES_FAIL =
  'FETCH_PARTICIPANT_ACTIVITIES_FAIL';

export const FETCH_PARTICIPANT_ACTIVITY_START =
  'FETCH_PARTICIPANT_ACTIVITY_START';
export const FETCH_PARTICIPANT_ACTIVITY_SUCCESS =
  'FETCH_PARTICIPANT_ACTIVITY_SUCCESS';
export const FETCH_PARTICIPANT_ACTIVITY_FAIL =
  'FETCH_PARTICIPANT_ACTIVITY_FAIL';

export const CREATE_PARTICIPANT_ACTIVITY_START =
  'CREATE_PARTICIPANT_ACTIVITY_START';
export const CREATE_PARTICIPANT_ACTIVITY_SUCCESS =
  'CREATE_PARTICIPANT_ACTIVITY_SUCCESS';
export const CREATE_PARTICIPANT_ACTIVITY_FAIL =
  'CREATE_PARTICIPANT_ACTIVITY_FAIL';

export const UPDATE_PARTICIPANT_ACTIVITY_START =
  'UPDATE_PARTICIPANT_ACTIVITY_START';
export const UPDATE_PARTICIPANT_ACTIVITY_SUCCESS =
  'UPDATE_PARTICIPANT_ACTIVITY_SUCCESS';
export const UPDATE_PARTICIPANT_ACTIVITY_FAIL =
  'UPDATE_PARTICIPANT_ACTIVITY_FAIL';

export const DELETE_PARTICIPANT_ACTIVITY_START =
  'DELETE_PARTICIPANT_ACTIVITY_START';
export const DELETE_PARTICIPANT_ACTIVITY_SUCCESS =
  'DELETE_PARTICIPANT_ACTIVITY_SUCCESS';
export const DELETE_PARTICIPANT_ACTIVITY_FAIL =
  'DELETE_PARTICIPANT_ACTIVITY_FAIL';

export const SET_PARTICIPANT_ACTIVITIES_FILTER =
  'SET_PARTICIPANT_ACTIVITIES_FILTER';

export const RESET_PARTICIPANT_ACTIVITY_STORE =
  'RESET_PARTICIPANT_ACTIVITY_STORE';

export const SET_PARTICIPANT_ACTIVITY_UPDATE_NEEDED =
  'SET_PARTICIPANT_ACTIVITY_UPDATE_NEEDED';

export const RESET_CREATED_PARTICIPANT_ACTIVITY =
  'RESET_CREATED_PARTICIPANT_ACTIVITY';

export type ParticipantActivityActionTypes =
  | typeof FETCH_PARTICIPANT_ACTIVITIES_START
  | typeof FETCH_PARTICIPANT_ACTIVITIES_SUCCESS
  | typeof FETCH_PARTICIPANT_ACTIVITIES_FAIL
  | typeof FETCH_PARTICIPANT_ACTIVITY_START
  | typeof FETCH_PARTICIPANT_ACTIVITY_SUCCESS
  | typeof FETCH_PARTICIPANT_ACTIVITY_FAIL
  | typeof CREATE_PARTICIPANT_ACTIVITY_START
  | typeof CREATE_PARTICIPANT_ACTIVITY_SUCCESS
  | typeof CREATE_PARTICIPANT_ACTIVITY_FAIL
  | typeof UPDATE_PARTICIPANT_ACTIVITY_START
  | typeof UPDATE_PARTICIPANT_ACTIVITY_SUCCESS
  | typeof UPDATE_PARTICIPANT_ACTIVITY_FAIL
  | typeof DELETE_PARTICIPANT_ACTIVITY_START
  | typeof DELETE_PARTICIPANT_ACTIVITY_SUCCESS
  | typeof DELETE_PARTICIPANT_ACTIVITY_FAIL
  | typeof SET_PARTICIPANT_ACTIVITIES_FILTER
  | typeof RESET_PARTICIPANT_ACTIVITY_STORE
  | typeof SET_PARTICIPANT_ACTIVITY_UPDATE_NEEDED
  | typeof RESET_CREATED_PARTICIPANT_ACTIVITY;
