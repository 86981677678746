import * as actionTypes from './actionTypes';
import { Translation, TranslationsList } from '../../domain/Translation';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListParams } from '../../hooks/useList/useList';

export const fetchTranslationsStart = () => ({
  type: actionTypes.FETCH_TRANSLATIONS_START,
});

export const fetchTranslationsSuccess = (
  translationsList: TranslationsList,
) => ({
  type: actionTypes.FETCH_TRANSLATIONS_SUCCESS,
  translationsList,
});

export const fetchTranslationsFail = (translationsListError: HttpError) => ({
  type: actionTypes.FETCH_TRANSLATIONS_FAIL,
  translationsListError,
});

export const updateTranslationsStart = () => ({
  type: actionTypes.UPDATE_TRANSLATIONS_START,
});

export const updateTranslationsSuccess = (updatedTranslation: Translation) => {
  return {
    type: actionTypes.UPDATE_TRANSLATIONS_SUCCESS,
    updatedTranslation,
  };
};

export const updateTranslationsFail = (updateTranslationError: HttpError) => ({
  type: actionTypes.UPDATE_TRANSLATIONS_FAIL,
  updateTranslationError,
});

export const setTranslationsFilterValues = (
  translationsFilter: ListParams,
) => ({
  type: actionTypes.SET_TRANSLATIONS_FILTER,
  translationsFilter,
});

export const resetTranslationStore = () => ({
  type: actionTypes.RESET_TRANSLATIONS_STORE,
});
