import axios from '../../config/Axios/axios-instance';
import {
  createParticipantFail,
  createParticipantStart,
  createParticipantSuccess,
  deleteParticipantFail,
  deleteParticipantStart,
  deleteParticipantSuccess,
  fetchParticipantsFail,
  fetchParticipantsStart,
  fetchParticipantsSuccess,
  importParticipantsStart,
  importParticipantsSuccess,
  setParticipantsFilterValues,
  updateParticipantFail,
  updateParticipantStart,
  updateParticipantSuccess,
  importParticipantsFail,
  deleteMultipleParticipantsStart,
  deleteMultipleParticipantsSuccess,
  deleteMultipleParticipantsFail,
  participantConfirmSuccess,
  participantConfirmStart,
  participantConfirmFail,
  fetchParticipantStart,
  fetchParticipantSuccess,
  fetchParticipantFail,
  downloadFileStart,
  downloadFileSuccess,
  downloadFileFail,
  updateAssetFail,
  updateAssetStart,
  updateAssetSuccess,
  getAssetThumbnailStart,
  getAssetThumbnailSuccess,
  getAssetThumbnailFail,
  downloadAllAssetsStart,
  downloadAllAssetsSuccess,
  downloadAllAssetsFail,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { setUpdateNeeded } from '../event/actions';
import { API_URLS } from '../globalService';
import {
  ParticipantType,
  SelectedParticipants,
} from '../../domain/Participant';
import { ParticipantItem } from '../../domain/ParticipantItem';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { ConfirmationRequest } from '../manager/service';
import download from 'downloadjs';
import { AssetUpdateRequest } from '../event/service';
import { Roles } from '../../domain/Role';

export type ImportParticipantsRequest = {
  participants: ParticipantCreateRequest[];
  eventId: number;
};

export type ParticipantGetRequest = {
  userId: number;
  eventId: number;
};

export type ParticipantCreateRequest = {
  firstName: string;
  lastName: string;
  email: string;
  eventId: number;
  type: ParticipantType;
  participantItems?: ParticipantItem[];
  sendNotification?: boolean;
};

export type ParticipantUpdateRequest = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  eventId: number;
  participantItems?: ParticipantItem[];
};

export type ParticipantsUpdateRequest = {
  id: number;
  children: ParticipantsUpdateRequest[];
};

export type FetchParticipantsParams = ListParams & {
  eventId: number;
  type: ParticipantType;
};

export type DownloadAllLecturerAssetsDto = {
  assetsIds: number[];
  zipFileName: string;
};

export type GetEventParticipantRequest = {
  type: ParticipantType;
  participantId?: number;
  selectedRole: Roles | null;
};

export const fetchParticipants =
  (params: FetchParticipantsParams, saveFilters?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchParticipantsStart());

    if (saveFilters) {
      dispatch(setParticipantsFilterValues(params));
    }

    return axios
      .get(API_URLS.participant, { params })
      .then((response) => {
        dispatch(fetchParticipantsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchParticipantsFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const fetchParticipant =
  (eventId: number, params?: GetEventParticipantRequest) =>
  (dispatch: Dispatch) => {
    dispatch(fetchParticipantStart());
    return axios
      .get(`${API_URLS.participant}/${eventId}`, { params })
      .then((response) => {
        dispatch(fetchParticipantSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchParticipantFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };

export const fetchParticipantByConfirmationCode =
  (confirmationToken: string) => (dispatch: Dispatch) => {
    dispatch(fetchParticipantStart());
    return axios
      .get(`${API_URLS.participant}/confirmation-code/${confirmationToken}`)
      .then((response) => {
        dispatch(fetchParticipantSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchParticipantFail(err.response.data.error));
        showToast(err.response.data.message, 'error');
      });
  };

export const createParticipant =
  (inputs: ParticipantCreateRequest, type: ParticipantType, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setUpdateNeeded());
    dispatch(createParticipantStart());
    return axios
      .post(API_URLS.participant, {
        ...inputs,
      })
      .then(() => {
        dispatch(createParticipantSuccess());
        showToast(
          type === ParticipantType.PARTICIPANT
            ? translate(intl, 'SUCCESS.PARTICIPANT_CREATE')
            : translate(intl, 'SUCCESS.LECTURER_CREATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(createParticipantFail(err.response.data.message));
      });
  };

export const importParticipants =
  (inputs: ImportParticipantsRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setUpdateNeeded());
    dispatch(importParticipantsStart());
    return axios
      .post(`${API_URLS.participant}/createParticipants`, {
        ...inputs,
      })
      .then(() => {
        dispatch(importParticipantsSuccess());
        showToast(translate(intl, 'SUCCESS.PARTICIPANTS_IMPORT'), 'success');
      })
      .catch((err) => {
        dispatch(importParticipantsFail(err.response.data.message));
      });
  };

export const updateParticipant =
  (inputs: ParticipantUpdateRequest, type: ParticipantType, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateParticipantStart());
    const { id, ...updatedParticipant } = inputs;
    return axios
      .patch(`${API_URLS.participant}/${id}`, {
        ...updatedParticipant,
      })
      .then(() => {
        dispatch(updateParticipantSuccess());
        dispatch(setUpdateNeeded());
        showToast(
          type === ParticipantType.PARTICIPANT
            ? translate(intl, 'SUCCESS.PARTICIPANT_UPDATE')
            : translate(intl, 'SUCCESS.LECTURER_UPDATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(updateParticipantFail(err.response.data.message));
      });
  };

export const deleteParticipant =
  (id: number, eventId: number, type: ParticipantType, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(deleteParticipantStart());
    return axios
      .delete(`${API_URLS.participant}/${id}/events/${eventId}`)
      .then(() => {
        dispatch(setUpdateNeeded());
        dispatch(deleteParticipantSuccess());
        showToast(
          type === ParticipantType.PARTICIPANT
            ? translate(intl, 'SUCCESS.PARTICIPANT_DELETE')
            : translate(intl, 'SUCCESS.LECTURER_DELETE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteParticipantFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const multipleParticipantsDelete =
  (data: SelectedParticipants, type: ParticipantType, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(deleteMultipleParticipantsStart());
    return axios
      .delete(`${API_URLS.participant}/bulk/delete`, {
        data,
      })
      .then(() => {
        dispatch(setUpdateNeeded());
        dispatch(deleteMultipleParticipantsSuccess());
        showToast(
          type === ParticipantType.PARTICIPANT
            ? translate(intl, 'SUCCESS.PARTICIPANTS_DELETE_MULTIPLE')
            : translate(intl, 'SUCCESS.LECTURERS_DELETE_MULTIPLE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteMultipleParticipantsFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const confirmParticipant =
  (inputs: ConfirmationRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(participantConfirmStart());
    return axios
      .post(`${API_URLS.participant}/confirm-participant`, {
        ...inputs,
      })
      .then(() => {
        dispatch(participantConfirmSuccess());
        showToast(translate(intl, 'SUCCESS.REQUEST_CONFIRM'), 'success');
      })
      .catch((err) => {
        dispatch(participantConfirmFail(err.response.data.error));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };

export const downloadFile =
  (participantId: number, assetId: number, fileName: string, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(downloadFileStart());
    return axios
      .get(
        `${API_URLS.participant}/${participantId}/download-file/${assetId}`,
        {
          responseType: 'blob',
        },
      )
      .then(async (response) => {
        dispatch(downloadFileSuccess());
        download(
          new Blob([response.data], {
            type: 'image/jpeg, image/png, application/zip, application/pdf',
          }),
          fileName,
          'application/octet-stream',
        );
      })
      .catch(async (err) => {
        const errorResponse = JSON.parse(await err.response.data.text());

        dispatch(downloadFileFail(errorResponse.error));
        showToast(translate(intl, errorResponse.message), 'error');
      });
  };

export const downloadParticipantItemFile =
  (participantId: number, assetId: number, fileName: string, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(downloadFileStart());
    return axios
      .get(
        `${API_URLS.participant}/${participantId}/download-file/participant-item/${assetId}`,
        {
          responseType: 'blob',
        },
      )
      .then(async (response) => {
        dispatch(downloadFileSuccess());
        download(
          new Blob([response.data], {
            type: 'image/jpeg, image/png, application/zip, application/pdf',
          }),
          fileName,
          'application/octet-stream',
        );
      })
      .catch(async (err) => {
        const errorResponse = JSON.parse(await err.response.data.text());

        dispatch(downloadFileFail(errorResponse.error));
        showToast(translate(intl, errorResponse.message), 'error');
      });
  };

export const downloadAllLecturerAssets =
  (
    downloadAllLecturerAssetsDto: DownloadAllLecturerAssetsDto,
    intl: IntlShape,
    id?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(downloadAllAssetsStart(id));

    return axios
      .post(
        `${API_URLS.participant}/download-all-assets`,
        downloadAllLecturerAssetsDto,
        {
          responseType: 'blob',
        },
      )
      .then(async (response) => {
        dispatch(downloadAllAssetsSuccess(id));

        download(
          new Blob([response.data], {
            type: 'application/zip',
          }),
          downloadAllLecturerAssetsDto.zipFileName,
          'application/zip',
        );
      })
      .catch(async (err) => {
        const errorResponse = JSON.parse(await err.response.data.text());

        dispatch(downloadAllAssetsFail(errorResponse.error));
        showToast(translate(intl, errorResponse.message), 'error');
      });
  };

export const updateAsset =
  (
    inputs: AssetUpdateRequest,
    isFinal: boolean,
    intl: IntlShape,
    id?: number,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(updateAssetStart(id));
    const form = new FormData();
    Object.keys(inputs).forEach((inputKey) => {
      // @ts-ignore
      const value = inputs[inputKey];

      if (inputKey === 'material') {
        for (let i = 0; i < value.length; i++) {
          form.append(inputKey, value[i]);
        }
      } else if (value) {
        form.append(inputKey, JSON.stringify(value));
      }
    });
    return axios
      .patch(`${API_URLS.participant}/${inputs.id}/update-files`, form)
      .then((response) => {
        dispatch(updateAssetSuccess(response.data, id));
        isFinal &&
          showToast(translate(intl, 'SUCCESS.ASSET_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateAssetFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const getAssetThumbnail =
  (participantId: number, assetId: number) => (dispatch: Dispatch) => {
    dispatch(getAssetThumbnailStart());
    return axios
      .get(
        `${API_URLS.participant}/${participantId}/download-file/${assetId}`,
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/octet-binary',
        });
        dispatch(getAssetThumbnailSuccess({ blob, assetId }));
      })
      .catch((err) => {
        dispatch(getAssetThumbnailFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };

export const sendNotification = (id: number, intl: IntlShape) => () => {
  return axios
    .post(`${API_URLS.participant}/send-notification/${id}`)
    .then(() => {
      showToast(translate(intl, 'SUCCESS.SEND_NOTIFICATION'), 'success');
    })
    .catch((err) => {
      showToast(err?.response?.data?.message, 'error');
    });
};

export const getParticipantItemAssetThumbnail =
  (participantItemId: number, assetId: number) => (dispatch: Dispatch) => {
    dispatch(getAssetThumbnailStart());
    return axios
      .get(
        `${API_URLS.participant}/${participantItemId}/download-file/participant-item/${assetId}`,
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/octet-binary',
        });
        dispatch(getAssetThumbnailSuccess({ blob, assetId }));
      })
      .catch((err) => {
        dispatch(getAssetThumbnailFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };
