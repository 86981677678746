import * as actionTypes from './actionTypes';
import { OrganisationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Organisation, OrganisationsList } from '../../domain/Organisation';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type OrganisationStateType = {
  organisationsList: OrganisationsList | null;
  organisationsListLoading: boolean;
  organisationsListError: HttpError;
  organisationsFilter: ListParams;
  organisationsListUpdateNeeded: boolean;
  organisation: Organisation | null;
  organisationLoading: boolean;
  organisationError: HttpError;
  organisationCreateLoading: boolean;
  organisationCreateError: HttpError;
  organisationCreateSuccess: boolean;
  organisationUpdateLoading: boolean;
  organisationUpdateError: HttpError;
  organisationUpdateSuccess: boolean;
  organisationDeleteLoading: boolean;
  organisationDeleteError: HttpError;
  createdOrganisation: Organisation | null;
};

export type OrganisationActionType = OrganisationStateType & {
  type: OrganisationActionTypes;
};

export const initialState: OrganisationStateType = {
  organisationsList: null,
  organisationsListLoading: true,
  organisationsListError: null,
  organisationsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  organisationsListUpdateNeeded: false,
  organisation: null,
  organisationLoading: false,
  organisationError: null,
  organisationCreateLoading: false,
  organisationCreateError: null,
  organisationCreateSuccess: false,
  organisationUpdateLoading: false,
  organisationUpdateError: null,
  organisationUpdateSuccess: false,
  organisationDeleteLoading: false,
  organisationDeleteError: null,
  createdOrganisation: null,
};

const fetchOrganisationsStart = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  organisationsListLoading: true,
  organisationUpdateSuccess: false,
  organisationCreateSuccess: false,
  organisationDeleteError: null,
  organisation: null,
});

const fetchOrganisationsSuccess = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisationsList: action.organisationsList,
  organisationsListLoading: false,
  organisationsListError: null,
  organisationsListUpdateNeeded: false,
});

const fetchOrganisationsFail = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisationsListError: action.organisationsListError,
  organisationsListLoading: false,
});

const fetchOrganisationStart = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  organisationLoading: true,
  organisationUpdateSuccess: false,
  organisationCreateSuccess: false,
  organisationCreateError: null,
  organisationUpdateError: null,
});

const fetchOrganisationSuccess = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisation: action.organisation,
  organisationLoading: false,
  organisationError: null,
});

const fetchOrganisationFail = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisationError: action.organisationError,
  organisationLoading: false,
});

const createOrganisationStart = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  organisationCreateLoading: true,
});

const createOrganisationSuccess = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisationCreateLoading: false,
  organisationCreateError: null,
  organisationCreateSuccess: true,
  organisationsListUpdateNeeded: true,
  createdOrganisation: action.createdOrganisation,
});

const createOrganisationFail = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisationCreateLoading: false,
  organisationCreateError: action.organisationCreateError,
});

const updateOrganisationStart = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  organisationUpdateLoading: true,
});

const updateOrganisationSuccess = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  organisationUpdateLoading: false,
  organisationUpdateError: null,
  organisationUpdateSuccess: true,
  organisationsListUpdateNeeded: true,
});

const updateOrganisationFail = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisationUpdateLoading: false,
  organisationUpdateError: action.organisationUpdateError,
});

const deleteOrganisationStart = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  organisationDeleteLoading: true,
});

const deleteOrganisationSuccess = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  organisationDeleteLoading: false,
  organisationDeleteError: null,
  organisationsListUpdateNeeded: true,
});

const deleteOrganisationFail = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisationDeleteLoading: false,
  organisationDeleteError: action.organisationDeleteError,
});

const setOrganisationsFilter = (
  state: OrganisationStateType,
  action: OrganisationActionType,
): OrganisationStateType => ({
  ...state,
  organisationsFilter: action.organisationsFilter,
});

const resetStore = (): OrganisationStateType => ({
  organisationsList: null,
  organisationsListLoading: true,
  organisationsListError: null,
  organisationsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'name',
  },
  organisationsListUpdateNeeded: false,
  organisation: null,
  organisationLoading: false,
  organisationError: null,
  organisationCreateLoading: false,
  organisationCreateError: null,
  organisationCreateSuccess: false,
  organisationUpdateLoading: false,
  organisationUpdateError: null,
  organisationUpdateSuccess: false,
  organisationDeleteLoading: false,
  organisationDeleteError: null,
  createdOrganisation: null,
});

const setUpdateNeeded = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  organisationsListUpdateNeeded: true,
});

const resetCreatedOrganisation = (
  state: OrganisationStateType,
): OrganisationStateType => ({
  ...state,
  createdOrganisation: null,
});

const reducer = (state = initialState, action: OrganisationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORGANISATIONS_START:
      return fetchOrganisationsStart(state);
    case actionTypes.FETCH_ORGANISATIONS_SUCCESS:
      return fetchOrganisationsSuccess(state, action);
    case actionTypes.FETCH_ORGANISATIONS_FAIL:
      return fetchOrganisationsFail(state, action);
    case actionTypes.FETCH_ORGANISATION_START:
      return fetchOrganisationStart(state);
    case actionTypes.FETCH_ORGANISATION_SUCCESS:
      return fetchOrganisationSuccess(state, action);
    case actionTypes.FETCH_ORGANISATION_FAIL:
      return fetchOrganisationFail(state, action);
    case actionTypes.CREATE_ORGANISATION_START:
      return createOrganisationStart(state);
    case actionTypes.CREATE_ORGANISATION_SUCCESS:
      return createOrganisationSuccess(state, action);
    case actionTypes.CREATE_ORGANISATION_FAIL:
      return createOrganisationFail(state, action);
    case actionTypes.UPDATE_ORGANISATION_START:
      return updateOrganisationStart(state);
    case actionTypes.UPDATE_ORGANISATION_SUCCESS:
      return updateOrganisationSuccess(state);
    case actionTypes.UPDATE_ORGANISATION_FAIL:
      return updateOrganisationFail(state, action);
    case actionTypes.DELETE_ORGANISATION_START:
      return deleteOrganisationStart(state);
    case actionTypes.DELETE_ORGANISATION_SUCCESS:
      return deleteOrganisationSuccess(state);
    case actionTypes.DELETE_ORGANISATION_FAIL:
      return deleteOrganisationFail(state, action);
    case actionTypes.SET_ORGANISATIONS_FILTER:
      return setOrganisationsFilter(state, action);
    case actionTypes.RESET_ORGANISATION_STORE:
      return resetStore();
    case actionTypes.SET_ORGANISATION_UPDATE_NEEDED:
      return setUpdateNeeded(state);
    case actionTypes.RESET_CREATED_ORGANISATION:
      return resetCreatedOrganisation(state);
    default:
      return state;
  }
};

export default reducer;
