import axios from '../../config/Axios/axios-instance';
import {
  createOrganisationFail,
  createOrganisationStart,
  createOrganisationSuccess,
  deleteOrganisationFail,
  deleteOrganisationStart,
  deleteOrganisationSuccess,
  fetchOrganisationFail,
  fetchOrganisationsFail,
  fetchOrganisationsStart,
  fetchOrganisationsSuccess,
  fetchOrganisationStart,
  fetchOrganisationSuccess,
  setOrganisationsFilterValues,
  setUpdateNeeded,
  updateOrganisationFail,
  updateOrganisationStart,
  updateOrganisationSuccess,
} from './actions';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import { showToast } from '../../utility/toast/toast';
import { API_URLS } from '../globalService';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

export type OrganisationCreateRequest = {
  name: string;
};

export type OrganisationUpdateRequest = {
  id: number;
  name: string;
};

export const fetchOrganisations =
  (params?: ListParams, saveFilters?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchOrganisationsStart());

    if (params && saveFilters) {
      dispatch(setOrganisationsFilterValues(params));
    }

    return axios
      .get(API_URLS.organisation, { params })
      .then((response) => {
        dispatch(fetchOrganisationsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchOrganisationsFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const fetchOrganisation =
  (organisationId: number) => (dispatch: Dispatch) => {
    dispatch(fetchOrganisationStart());
    return axios
      .get(`${API_URLS.organisation}/${organisationId}`)
      .then((response) => {
        dispatch(fetchOrganisationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchOrganisationFail(err.response.data.message));
        if (
          err.response.data.statusCode !== 401 &&
          err.response.data.message !== 'Unauthorized'
        ) {
          showToast(err.response.data.message, 'error');
        }
      });
  };

export const createOrganisation =
  (inputs: OrganisationCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setUpdateNeeded());
    dispatch(createOrganisationStart());
    return axios
      .post(API_URLS.organisation, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createOrganisationSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.ORGANISATION_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createOrganisationFail(err.response.data.message));
      });
  };

export const updateOrganisation =
  (inputs: OrganisationUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateOrganisationStart());
    const { id, ...updatedOrganisation } = inputs;
    return axios
      .patch(`${API_URLS.organisation}/${id}`, {
        ...updatedOrganisation,
      })
      .then(() => {
        dispatch(updateOrganisationSuccess());
        dispatch(setUpdateNeeded());
        showToast(translate(intl, 'SUCCESS.ORGNISATION_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateOrganisationFail(err.response.data.message));
      });
  };

export const deleteOrganisation =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteOrganisationStart());
    return axios
      .delete(`${API_URLS.organisation}/${id}`)
      .then(() => {
        dispatch(deleteOrganisationSuccess());
        dispatch(setUpdateNeeded());
        showToast(translate(intl, 'SUCCESS.ORGANISATION_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteOrganisationFail(err.response.data.message));
        showToast(err.response.data.message, 'error');
      });
  };
