import React, { useEffect } from 'react';
import { CreditPackage } from '../../../domain/CreditPackage';
import styles from './CreditPackageItem.module.scss';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import Button from '../../../common/Button/Button';
import cartIcon from '../../../assets/icons/cart.svg';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as userService from '../../../store/user/service';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';

type Props = {
  creditPackage: CreditPackage;
  onCreateCreditPackageBuy: (id: number) => void;
  isLoading: boolean;
  isSuccess: boolean;
  topUpSuccessRedirect: string | null;
};

export const CreditPackageItem = ({
  creditPackage,
  onCreateCreditPackageBuy,
  isLoading,
  isSuccess,
  topUpSuccessRedirect,
}: Props) => {
  const intl = useIntl();

  const additionalCreditsNeeded = () => {
    if (creditPackage.additionalCredits > 0) {
      return `+ ${creditPackage.additionalCredits} ${translate(
        intl,
        'CREDIT_PACKAGE.CREDITS',
      )}`;
    }
    return;
  };

  useEffect(() => {
    if (isSuccess && topUpSuccessRedirect) {
      window.location.href = topUpSuccessRedirect;
      return;
    }
  }, [isSuccess]);

  return (
    <div className={styles.creditPackageItem}>
      <div className={styles.title}>{creditPackage.name}</div>
      <div className={styles.creditPackageBox}>
        <div className={styles.price}>{creditPackage.price} &euro;</div>
        <div className={styles.credits}>
          {`${creditPackage.credits} ${translate(
            intl,
            'CREDIT_PACKAGE.CREDITS',
          )}`}
          {creditPackage.additionalCredits > 0 && (
            <span>
              &nbsp;&#124;&nbsp;
              <span className={styles.bonusCredits}>
                {additionalCreditsNeeded()}
              </span>
            </span>
          )}
        </div>
        <div className={styles.description}>{creditPackage.description}</div>
        <Button
          buttonVariant="contained"
          color="primary"
          className={styles.orderButton}
          isLoading={isLoading}
          onClick={() => onCreateCreditPackageBuy(creditPackage.id)}
        >
          {translate(intl, 'CREDIT_PACKAGE.BUY_CREDITS')}
          <img
            src={cartIcon}
            alt={translate(intl, 'COMMON.CART_ICON')}
            className={styles.cartIcon}
          />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.user.topUpCreateLoading,
  isSuccess: state.user.topUpCreateSuccess,
  topUpSuccessRedirect: state.user.topUpSuccessRedirect,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCreateCreditPackageBuy: (id: number) =>
    dispatch(userService.createTopUp(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditPackageItem);
