import * as actionTypes from './actionTypes';
import { GlobalSettingActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { GlobalSetting } from '../../domain/GlobalSetting';

export type GlobalSettingStateType = {
  globalSetting: GlobalSetting | null;
  globalSettingLoading: boolean;
  globalSettingError: HttpError;
  globalSettingUpdateLoading: boolean;
  globalSettingUpdateError: HttpError;
  globalSettingUpdateSuccess: boolean;
};

export type GlobalSettingActionType = GlobalSettingStateType & {
  type: GlobalSettingActionTypes;
};

export const initialState: GlobalSettingStateType = {
  globalSetting: null,
  globalSettingLoading: true,
  globalSettingError: null,
  globalSettingUpdateLoading: false,
  globalSettingUpdateError: null,
  globalSettingUpdateSuccess: false,
};

const fetchGlobalSettingsStart = (
  state: GlobalSettingStateType,
): GlobalSettingStateType => ({
  ...state,
  globalSettingLoading: true,
});

const fetchGlobalSettingsSuccess = (
  state: GlobalSettingStateType,
  action: GlobalSettingActionType,
): GlobalSettingStateType => {
  return {
    ...state,
    globalSetting: action.globalSetting,
    globalSettingLoading: false,
    globalSettingError: null,
  };
};

const fetchGlobalSettingsFail = (
  state: GlobalSettingStateType,
  action: GlobalSettingActionType,
): GlobalSettingStateType => ({
  ...state,
  globalSettingError: action.globalSettingError,
  globalSettingLoading: false,
});

const updateGlobalSettingStart = (
  state: GlobalSettingStateType,
): GlobalSettingStateType => ({
  ...state,
  globalSettingUpdateLoading: true,
});

const updateGlobalSettingSuccess = (
  state: GlobalSettingStateType,
): GlobalSettingStateType => ({
  ...state,
  globalSettingUpdateLoading: false,
  globalSettingUpdateError: null,
  globalSettingUpdateSuccess: true,
});

const updateGlobalSettingFail = (
  state: GlobalSettingStateType,
  action: GlobalSettingActionType,
): GlobalSettingStateType => ({
  ...state,
  globalSettingUpdateLoading: false,
  globalSettingUpdateError: action.globalSettingUpdateError,
});

const reducer = (state = initialState, action: GlobalSettingActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_GLOBAL_SETTING_START:
      return fetchGlobalSettingsStart(state);
    case actionTypes.FETCH_GLOBAL_SETTING_SUCCESS:
      return fetchGlobalSettingsSuccess(state, action);
    case actionTypes.FETCH_GLOBAL_SETTING_FAIL:
      return fetchGlobalSettingsFail(state, action);
    case actionTypes.UPDATE_GLOBAL_SETTING_START:
      return updateGlobalSettingStart(state);
    case actionTypes.UPDATE_GLOBAL_SETTING_SUCCESS:
      return updateGlobalSettingSuccess(state);
    case actionTypes.UPDATE_GLOBAL_SETTING_FAIL:
      return updateGlobalSettingFail(state, action);
    default:
      return state;
  }
};

export default reducer;
