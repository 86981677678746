import * as actionTypes from './actionTypes';
import { EventActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { EventsList, Event, CertificationState } from '../../domain/Event';
import { DEFAULT_LIST_PARAMS, ListParams } from '../../hooks/useList/useList';

export type AssetBlob = {
  blob: Blob;
  assetId: number;
};

export type EventStateType = {
  eventsList: EventsList | null;
  eventsListLoading: boolean;
  eventsListError: HttpError;
  eventsFilter: ListParams;
  eventsListUpdateNeeded: boolean;
  eventCreateLoading: boolean;
  eventCreateError: HttpError;
  eventCreateSuccess: boolean;
  createdEvent: Event | null;
  eventUpdateLoading: boolean;
  eventUpdateError: HttpError;
  eventUpdateSuccess: boolean;
  eventDeleteLoading: boolean;
  eventDeleteError: HttpError;
  event: Event | null;
  eventLoading: boolean;
  eventError: HttpError;
  generateCertificatesComplete: boolean;
  generateCertificatesLoading: boolean;
  generateCertificatesError: HttpError;
  generateParticipantCertificateLoading: boolean;
  generateParticipantCertificateError: HttpError;
  eventEditUpdateNeeded: boolean;
  sendCertificatesLoading: boolean;
  sendCertificatesError: HttpError;
  sendParticipantCertificateLoading: boolean;
  sendParticipantCertificateError: HttpError;
  sendParticipantCertificateSuccess: boolean;
  sendParticipantsCertificatesLoading: boolean;
  sendParticipantsCertificatesError: HttpError;
  sendParticipantsCertificatesSuccess: boolean;
  certificationState: CertificationState | null;
  certificationStateError: HttpError;
  currentEventData: any | null;
  downloadFileLoading: boolean;
  downloadFileError: HttpError;
  assetUpdateLoading: boolean;
  assetUpdateError: HttpError;
  assetUpdateSuccess: boolean;
  getAssetThumbnailLoading: boolean;
  getAssetThumbnailError: HttpError;
  getAssetThumbnailSuccess: boolean;
  assetThumbnailBlobs: AssetBlob[];
};

export type EventActionType = EventStateType & {
  type: EventActionTypes;
  assetThumbnailBlob: AssetBlob;
};

export const initialState: EventStateType = {
  eventsList: null,
  eventsListLoading: true,
  eventsListError: null,
  eventsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'eventDate',
    sortDirection: 'DESC',
  },
  eventsListUpdateNeeded: false,
  eventCreateLoading: false,
  eventCreateError: null,
  eventCreateSuccess: false,
  eventUpdateLoading: false,
  eventUpdateError: null,
  eventUpdateSuccess: false,
  eventDeleteLoading: false,
  eventDeleteError: null,
  event: null,
  eventLoading: false,
  eventError: null,
  createdEvent: null,
  generateCertificatesComplete: false,
  generateCertificatesLoading: false,
  generateCertificatesError: null,
  eventEditUpdateNeeded: false,
  sendCertificatesLoading: false,
  sendCertificatesError: null,
  sendParticipantCertificateLoading: false,
  sendParticipantCertificateError: null,
  sendParticipantCertificateSuccess: false,
  sendParticipantsCertificatesLoading: false,
  sendParticipantsCertificatesError: null,
  sendParticipantsCertificatesSuccess: false,
  certificationState: null,
  certificationStateError: null,
  currentEventData: null,
  downloadFileLoading: false,
  downloadFileError: null,
  assetUpdateLoading: false,
  assetUpdateError: null,
  assetUpdateSuccess: false,
  getAssetThumbnailLoading: false,
  getAssetThumbnailError: null,
  getAssetThumbnailSuccess: false,
  assetThumbnailBlobs: [],
  generateParticipantCertificateLoading: false,
  generateParticipantCertificateError: null,
};

const fetchEventsStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventsListLoading: true,
  event: null,
  eventUpdateSuccess: false,
  eventCreateSuccess: false,
  eventDeleteError: null,
});

const fetchEventsSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventsList: action.eventsList,
  eventsListLoading: false,
  eventsListError: null,
  eventsListUpdateNeeded: false,
});

const fetchEventsFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventsListError: action.eventsListError,
  eventsListLoading: false,
});

const fetchEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventLoading: true,
  eventUpdateSuccess: false,
  eventCreateSuccess: false,
  eventCreateError: null,
  eventUpdateError: null,
});

const fetchEventSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  event: action.event,
  eventLoading: false,
  eventError: null,
  eventEditUpdateNeeded: false,
});

const fetchEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventError: action.eventError,
  eventLoading: false,
});

const createEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventCreateLoading: true,
});

const createEventSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventCreateLoading: false,
  eventCreateError: null,
  eventCreateSuccess: true,
  createdEvent: action.createdEvent,
});

const createEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventCreateLoading: false,
  eventCreateError: action.eventCreateError,
});

const updateEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventUpdateLoading: true,
});

const updateEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventUpdateLoading: false,
  eventUpdateError: null,
  eventUpdateSuccess: true,
});

const updateEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventUpdateLoading: false,
  eventUpdateError: action.eventUpdateError,
});

const deleteEventStart = (state: EventStateType): EventStateType => ({
  ...state,
  eventDeleteLoading: true,
});

const deleteEventSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  eventDeleteLoading: false,
  eventDeleteError: null,
  eventsListUpdateNeeded: true,
});

const deleteEventFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventDeleteLoading: false,
  eventDeleteError: action.eventDeleteError,
  eventsListUpdateNeeded: true,
});

const setEventsFilter = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  eventsFilter: action.eventsFilter,
});

const generateCertificatesStart = (state: EventStateType): EventStateType => ({
  ...state,
  generateCertificatesLoading: true,
  generateCertificatesComplete: false,
});

const generateCertificatesSuccess = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  generateCertificatesLoading: false,
  generateCertificatesComplete: true,
  generateCertificatesError: null,
  eventEditUpdateNeeded: true,
});

const generateCertificatesFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  generateCertificatesLoading: false,
  generateCertificatesError: action.generateCertificatesError,
});

const generateParticipantCertificateStart = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  generateParticipantCertificateLoading: true,
  generateCertificatesComplete: false,
});

const generateParticipantCertificateSuccess = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  generateParticipantCertificateLoading: false,
  generateCertificatesComplete: true,
  generateParticipantCertificateError: null,
  eventEditUpdateNeeded: true,
});

const generateParticipantCertificateFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  generateParticipantCertificateLoading: false,
  generateParticipantCertificateError:
    action.generateParticipantCertificateError,
});

const sendCertificatesStart = (state: EventStateType): EventStateType => ({
  ...state,
  sendCertificatesLoading: true,
});

const sendCertificatesSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  sendCertificatesLoading: false,
  sendCertificatesError: null,
  eventEditUpdateNeeded: true,
});

const sendCertificatesFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  sendCertificatesLoading: false,
  sendCertificatesError: action.sendCertificatesError,
});

const sendParticipantCertificateStart = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  sendParticipantCertificateLoading: true,
});

const sendParticipantCertificateSuccess = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  sendParticipantCertificateLoading: false,
  sendParticipantCertificateError: null,
  sendParticipantCertificateSuccess: true,
  eventEditUpdateNeeded: true,
});

const sendParticipantCertificateFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  sendParticipantCertificateLoading: false,
  sendParticipantCertificateSuccess: false,
  sendParticipantCertificateError: action.sendParticipantCertificateError,
});

const sendBulkParticipantsCertificatesStart = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  sendParticipantsCertificatesLoading: true,
});

const sendBulkParticipantsCertificatesSuccess = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  sendParticipantsCertificatesLoading: false,
  sendParticipantsCertificatesError: null,
  sendParticipantsCertificatesSuccess: true,
  eventEditUpdateNeeded: true,
});

const sendBulkParticipantsCertificatesFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  sendParticipantsCertificatesLoading: false,
  sendParticipantsCertificatesSuccess: false,
  sendParticipantsCertificatesError: action.sendParticipantsCertificatesError,
});

const getCertificationStateStart = (state: EventStateType): EventStateType => ({
  ...state,
});

const getCertificationStateSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  certificationStateError: null,
  certificationState: action.certificationState,
});

const getCertificationStateFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  certificationStateError: action.certificationStateError,
});

const setCurrentEventData = (
  state: EventStateType,
  action: any,
): EventStateType => ({
  ...state,
  currentEventData: action.payload?.inputsData,
});

const downloadFileStart = (state: EventStateType): EventStateType => ({
  ...state,
  downloadFileLoading: true,
});

const downloadFileSuccess = (state: EventStateType): EventStateType => ({
  ...state,
  downloadFileLoading: false,
  downloadFileError: null,
});

const downloadFileFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  downloadFileError: action.downloadFileError,
  downloadFileLoading: false,
});

const updateAssetStart = (state: EventStateType): EventStateType => ({
  ...state,
  assetUpdateLoading: true,
});

const updateAssetSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  assetUpdateLoading: false,
  assetUpdateError: null,
  assetUpdateSuccess: true,
  event: action.event,
});

const updateAssetFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  assetUpdateLoading: false,
  assetUpdateError: action.assetUpdateError,
});

const getAssetThumbnailStart = (state: EventStateType): EventStateType => ({
  ...state,
  getAssetThumbnailLoading: true,
});

const getAssetThumbnailSuccess = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  getAssetThumbnailLoading: false,
  getAssetThumbnailError: null,
  assetThumbnailBlobs: [
    ...state.assetThumbnailBlobs,
    action.assetThumbnailBlob,
  ],
});

const getAssetThumbnailFail = (
  state: EventStateType,
  action: EventActionType,
): EventStateType => ({
  ...state,
  getAssetThumbnailError: action.getAssetThumbnailError,
  getAssetThumbnailLoading: false,
});

const resetStore = (): EventStateType => ({
  eventsList: null,
  eventsListLoading: true,
  eventsListError: null,
  eventsFilter: {
    ...DEFAULT_LIST_PARAMS,
    sortBy: 'eventDate',
    sortDirection: 'DESC',
  },
  eventsListUpdateNeeded: false,
  eventCreateLoading: false,
  eventCreateError: null,
  eventCreateSuccess: false,
  eventUpdateLoading: false,
  eventUpdateError: null,
  eventUpdateSuccess: false,
  eventDeleteLoading: false,
  eventDeleteError: null,
  event: null,
  eventLoading: false,
  eventError: null,
  createdEvent: null,
  generateCertificatesComplete: false,
  generateCertificatesLoading: false,
  generateCertificatesError: null,
  eventEditUpdateNeeded: false,
  sendCertificatesLoading: false,
  sendCertificatesError: null,
  sendParticipantCertificateLoading: false,
  sendParticipantCertificateError: null,
  sendParticipantCertificateSuccess: false,
  sendParticipantsCertificatesLoading: false,
  sendParticipantsCertificatesError: null,
  sendParticipantsCertificatesSuccess: false,
  certificationState: null,
  certificationStateError: null,
  currentEventData: null,
  downloadFileLoading: false,
  downloadFileError: null,
  assetUpdateLoading: false,
  assetUpdateError: null,
  assetUpdateSuccess: false,
  getAssetThumbnailLoading: false,
  getAssetThumbnailError: null,
  getAssetThumbnailSuccess: false,
  assetThumbnailBlobs: [],
  generateParticipantCertificateLoading: false,
  generateParticipantCertificateError: null,
});

const setUpdateNeeded = (state: EventStateType): EventStateType => ({
  ...state,
  generateCertificatesComplete: false,
});

const resetEventAssetError = (state: EventStateType): EventStateType => ({
  ...state,
  assetUpdateError: null,
});

const resetEventAssetSuccessStatus = (
  state: EventStateType,
): EventStateType => ({
  ...state,
  assetUpdateSuccess: false,
});

const resetEventError = (state: EventStateType): EventStateType => ({
  ...state,
  eventUpdateError: null,
  eventCreateError: null,
});

const reducer = (state = initialState, action: EventActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENTS_START:
      return fetchEventsStart(state);
    case actionTypes.FETCH_EVENTS_SUCCESS:
      return fetchEventsSuccess(state, action);
    case actionTypes.FETCH_EVENTS_FAIL:
      return fetchEventsFail(state, action);
    case actionTypes.FETCH_EVENT_START:
      return fetchEventStart(state);
    case actionTypes.FETCH_EVENT_SUCCESS:
      return fetchEventSuccess(state, action);
    case actionTypes.FETCH_EVENT_FAIL:
      return fetchEventFail(state, action);
    case actionTypes.CREATE_EVENT_START:
      return createEventStart(state);
    case actionTypes.CREATE_EVENT_SUCCESS:
      return createEventSuccess(state, action);
    case actionTypes.CREATE_EVENT_FAIL:
      return createEventFail(state, action);
    case actionTypes.UPDATE_EVENT_START:
      return updateEventStart(state);
    case actionTypes.UPDATE_EVENT_SUCCESS:
      return updateEventSuccess(state);
    case actionTypes.UPDATE_EVENT_FAIL:
      return updateEventFail(state, action);
    case actionTypes.DELETE_EVENT_START:
      return deleteEventStart(state);
    case actionTypes.DELETE_EVENT_SUCCESS:
      return deleteEventSuccess(state);
    case actionTypes.DELETE_EVENT_FAIL:
      return deleteEventFail(state, action);
    case actionTypes.SET_EVENTS_FILTER:
      return setEventsFilter(state, action);
    case actionTypes.GENERATE_CERTIFICATES_START:
      return generateCertificatesStart(state);
    case actionTypes.GENERATE_CERTIFICATES_SUCCESS:
      return generateCertificatesSuccess(state);
    case actionTypes.GENERATE_CERTIFICATES_FAIL:
      return generateCertificatesFail(state, action);
    case actionTypes.GENERATE_PARTICIPANT_CERTIFICATE_START:
      return generateParticipantCertificateStart(state);
    case actionTypes.GENERATE_PARTICIPANT_CERTIFICATE_SUCCESS:
      return generateParticipantCertificateSuccess(state);
    case actionTypes.GENERATE_PARTICIPANT_CERTIFICATE_FAIL:
      return generateParticipantCertificateFail(state, action);
    case actionTypes.SEND_CERTIFICATES_START:
      return sendCertificatesStart(state);
    case actionTypes.SEND_CERTIFICATES_SUCCESS:
      return sendCertificatesSuccess(state);
    case actionTypes.SEND_CERTIFICATES_FAIL:
      return sendCertificatesFail(state, action);
    case actionTypes.GET_CERTIFICATION_STATE_START:
      return getCertificationStateStart(state);
    case actionTypes.SEND_PARTICIPANT_CERTIFICATE_SUCCESS:
      return sendParticipantCertificateSuccess(state);
    case actionTypes.SEND_PARTICIPANT_CERTIFICATE_START:
      return sendParticipantCertificateStart(state);
    case actionTypes.SEND_PARTICIPANT_CERTIFICATE_FAIL:
      return sendParticipantCertificateFail(state, action);
    case actionTypes.SEND_PARTICIPANTS_CERTIFICATES_SUCCESS:
      return sendBulkParticipantsCertificatesSuccess(state);
    case actionTypes.SEND_PARTICIPANTS_CERTIFICATES_START:
      return sendBulkParticipantsCertificatesStart(state);
    case actionTypes.SEND_PARTICIPANTS_CERTIFICATES_FAIL:
      return sendBulkParticipantsCertificatesFail(state, action);
    case actionTypes.GET_CERTIFICATION_STATE_START:
      return getCertificationStateStart(state);
    case actionTypes.GET_CERTIFICATION_STATE_SUCCESS:
      return getCertificationStateSuccess(state, action);
    case actionTypes.GET_CERTIFICATION_STATE_FAIL:
      return getCertificationStateFail(state, action);
    case actionTypes.RESET_EVENT_STORE:
      return resetStore();
    case actionTypes.SET_EVENTS_UPDATE_NEEDED:
      return setUpdateNeeded(state);
    case actionTypes.SET_CURRENT_EVENT_DATA:
      return setCurrentEventData(state, action);
    case actionTypes.DOWNLOAD_FILE_START:
      return downloadFileStart(state);
    case actionTypes.DOWNLOAD_FILE_SUCCESS:
      return downloadFileSuccess(state);
    case actionTypes.DOWNLOAD_FILE_FAIL:
      return downloadFileFail(state, action);
    case actionTypes.UPDATE_ASSET_START:
      return updateAssetStart(state);
    case actionTypes.UPDATE_ASSET_SUCCESS:
      return updateAssetSuccess(state, action);
    case actionTypes.UPDATE_ASSET_FAIL:
      return updateAssetFail(state, action);
    case actionTypes.RESET_EVENT_ASSET_ERROR:
      return resetEventAssetError(state);
    case actionTypes.GET_ASSET_THUMBNAIL_START:
      return getAssetThumbnailStart(state);
    case actionTypes.GET_ASSET_THUMBNAIL_SUCCESS:
      return getAssetThumbnailSuccess(state, action);
    case actionTypes.GET_ASSET_THUMBNAIL_FAIL:
      return getAssetThumbnailFail(state, action);
    case actionTypes.RESET_EVENT_ASSET_SUCCESS_STATUS:
      return resetEventAssetSuccessStatus(state);
    case actionTypes.RESET_EVENT_ERROR:
      return resetEventError(state);
    default:
      return state;
  }
};

export default reducer;
